import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import {
  Body,
  HeaderActionButton,
  ImageSwiper,
  SafeAreaView,
} from "~riata/components";
import { useGetNode } from "~riata/graphql";

const AttachmentImageGallery = ({ navigation, route }) => {
  const { node: nodeId, index } = route.params;
  const { node } = useGetNode(nodeId);

  return (
    <SafeAreaView bottom={false}>
      <View style={styles.headerContainer}>
        <HeaderActionButton variant="down" onPress={() => navigation.pop()} />
      </View>
      <Body keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 80}>
        {node?.attachments && (
          <ImageSwiper index={index} attachments={node.attachments.edges} />
        )}
      </Body>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    height: 50,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AttachmentImageGallery;
