import React from "react";
import { StyleSheet, View } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";

const FlatListSeparator = () => {
  const { newColors } = useColors();

  const dividerStyles = useComposeStyles(styles.divider, {
    backgroundColor: newColors.separator.light.main,
  });

  return <View style={dividerStyles} />;
};

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: "transparent",
    width: "100%",
  },
});

export default FlatListSeparator;
