import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { WatchQueryFetchPolicy } from "apollo-client";
import gql from "graphql-tag";
import { DeckConnection, CardStatus } from "~riata/generated/graphql";

export const QUERY = gql`
  query {
    getDecks {
      edges {
        node {
          id
          name
          style
          url
          cards(filter: { statusIn: [${CardStatus.Unread}, ${CardStatus.Viewed}] }) {
            edges {
              node {
                id
                name
                createdAt
                ... on ActionCard {
                  actionURL
                  style
                }

                ... on BookmarkCard {
                  actionURL
                  bookmarkIcon: icon
                  content
                  isExternal
                }

                ... on PreviewCard {
                  name
                  body
                  icon
                  externalId
                  actionURL
                  avatarURLs {
                    url
                  }
                }
                ... on ContentCard {
                  body
                  actionURL
                  footerExtra
                  hashtags(filter: { promoted: true }) {
                    edges {
                      node {
                        name
                        color {
                          color
                        }
                      }
                    }
                  }
                  creator {
                    id
                    displayName
                    company {
                      id
                      name
                    }
                    avatar {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type GetDecksResult = { getDecks: DeckConnection };

type GetDecksProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type UseGetDecks = (props?: GetDecksProps) => QueryResult<GetDecksResult>;

const useGetDecks: UseGetDecks = (props) => {
  const fetchPolicy = props?.fetchPolicy ?? "cache-and-network";
  return useQuery<GetDecksResult>(QUERY, {
    fetchPolicy: fetchPolicy,
  });
};

export default useGetDecks;
