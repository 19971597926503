import { ApolloError } from "apollo-client";
import React from "react";
import { StyleSheet, View } from "react-native";
import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs";
import { Text } from "~riata/components/typography";
import { useColors } from "~riata/hooks";
import { Row } from "../Row";

type ErrorProps = {
  error: ApolloError | string;
  refetch?: () => void;
};

type ErrorComponent = (props: ErrorProps) => JSX.Element;

const Error: ErrorComponent = ({ error, refetch }) => {
  const { newColors } = useColors();
  let errorMessage = "";

  if (!error) {
    return null;
  }

  if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = error.message;
  }

  return (
    <View style={style.container}>
      <Text variant="headline" color={newColors.text.error.main}>
        Oops ! Something went wrong
      </Text>
      <View style={style.errorMessageWrapper}>
        <Text>{errorMessage}</Text>
      </View>
      {refetch ? (
        <Button onPress={refetch}>
          <Row align="center">
            <CustomIcon name="refresh" size={20} />
            <View style={style.buttonTitleWrapper}>
              <Text variant="body" decoration="underlined">
                Try again
              </Text>
            </View>
          </Row>
        </Button>
      ) : (
        <Text variant="footnote" color={newColors.text.caption.main}>
          Please try again later
        </Text>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
  errorMessageWrapper: {
    marginVertical: 16,
  },
  buttonTitleWrapper: {
    marginLeft: 4,
  },
});

export default Error;
