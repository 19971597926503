import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import Left from "./Left";
import Right from "./Right";

type RowProps = ViewProps & {
  justify?: "left" | "right" | "center" | "around" | "between";
  align?: "top" | "bottom" | "center" | "fill";
  debug?: boolean;
};

const Row = ({ align, justify, debug = false, ...props }: RowProps) => {
  const preExistingStyles = props.style ?? {};
  props.style = StyleSheet.compose(props.style, styles.rowContainer);

  if (align) {
    props.style = StyleSheet.compose(props.style, alignments[align]);
  }

  if (justify) {
    props.style = StyleSheet.compose(props.style, justifications[justify]);
  }

  if (debug) {
    props.style = StyleSheet.compose(props.style, styles.debug);
  }

  props.style = StyleSheet.compose(props.style, preExistingStyles);

  return <View {...props} />;
};

Row.Left = Left;
Row.Right = Right;

const justifications = StyleSheet.create({
  center: {
    justifyContent: "center",
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  around: {
    justifyContent: "space-around",
  },
  between: {
    justifyContent: "space-between",
  },
});

const alignments = StyleSheet.create({
  top: {
    alignItems: "flex-start",
  },
  bottom: {
    alignItems: "flex-end",
  },
  center: {
    alignItems: "center",
  },
  fill: {
    alignItems: "stretch",
  },
});

const styles = StyleSheet.create({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  debug: {
    borderColor: "red",
    borderWidth: 1,
  },
});

export default Row;
