import React from "react";
import { Image, StyleSheet, View } from "react-native";

import { CustomIcon } from "../icons";

const IntegrationAvatar = ({ integration, size = 30, ...props }) => {
  if (props.uriPath || integration.avatar) {
    const uri = props.uriPath
      ? props.uriPath
      : `data:${integration.avatar.type};base64,${integration.avatar.data}`;
    return <Image style={styles.imageContainer} source={{ uri: uri }} />;
  }
  return (
    <View style={styles.iconContainer}>
      <CustomIcon size={20} name="bookmark" />
    </View>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  customIcon: {
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    resizeMode: "contain",
    borderRadius: 25,
    width: 50,
    height: 50,
  },
});

export default IntegrationAvatar;
