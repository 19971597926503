import React, { useReducer } from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";

const TappableBackground = ({ onPress }) => {
  /**
   * One way boolean state to prevent more than one call to the onPress callback.
   * This prop is used for calling navigation.goBack() and can cause unwanted
   * behavior if more than one press is allowed.
   */
  const [hasNotBeenCalled, callAction] = useReducer(() => false, true);

  return (
    <View style={styles.wrapper}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (hasNotBeenCalled) {
            callAction();
            onPress();
          }
        }}
      >
        <View style={styles.inner} />
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  inner: { flex: 1 },
  wrapper: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
});

export default TappableBackground;
