import React from "react";
import { Image, StyleSheet, useWindowDimensions, View } from "react-native";
import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs";
import { Text } from "~riata/components/typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWeb } from "~riata/theme/common";
import {
  BUTTON_TITLE,
  ICON_SIZE,
  IMAGE_PERCENTAGE,
  MESSAGE,
} from "./constants";
import BlueBackdrop from "~riata/resources/blue_backdrop.png";

type EmptyListProps = {
  onRefresh: () => void;
  isVisible: boolean;
};

type EmptyListComponent = (props: EmptyListProps) => JSX.Element;

const EmptyList: EmptyListComponent = ({ onRefresh, isVisible }) => {
  const { height } = useWindowDimensions();
  const { newColors } = useColors();

  const imageSource = isWeb
    ? {
        uri: BlueBackdrop,
        width: height * IMAGE_PERCENTAGE,
        height: height * IMAGE_PERCENTAGE,
      }
    : BlueBackdrop;

  const buttonStyles = useComposeStyles(styles.button, {
    backgroundColor: newColors.button.primary.main,
    opacity: isVisible ? 1 : 0,
  });

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image source={imageSource} />
        <View style={styles.iconContainer}>
          <CustomIcon name="peace" size={ICON_SIZE} />
        </View>
      </View>
      <Text variant="subhead">{MESSAGE}</Text>
      <Button variant="rounded" style={buttonStyles} onPress={onRefresh}>
        <Text color={newColors.button.primary.on} variant="subhead">
          {BUTTON_TITLE}
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    position: "absolute",
  },
  button: {
    marginTop: 32,
    backgroundColor: "transparent",
  },
});

export default React.memo(EmptyList);
