import { useMemo } from "react";
import { StyleSheet } from "react-native";

const useComposeColor = (someStyle, style) => {
  return useMemo(
    () => StyleSheet.compose(someStyle, StyleSheet.create({ style }).style),
    [someStyle, style]
  );
};

export default useComposeColor;
