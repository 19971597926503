import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActivityIndicator, StyleSheet, Dimensions } from "react-native";
import { isWeb } from "~riata/theme/common";
import { Dimension, ImageAttachment } from "~riata/generated/graphql";
import { Sentry } from "~riata/utils/sentry";
import {
  useColors,
  useComposeStyles,
  useSaveImageToGallery,
} from "~riata/hooks";
import { calculateDimensions, isLocalAttachment } from "./_utils";
import ImagePreview from "./ImagePreview";
import { DEFAULT_THUMBNAIL_SIZE } from "./constants";

const DEFAULT_DIMENSIONS = {
  height: 200,
  width: 200,
};

const _homologateDimensions = (dimensions: Dimension) => {
  if (!dimensions) {
    return DEFAULT_DIMENSIONS;
  }

  return {
    height: dimensions.height || DEFAULT_DIMENSIONS.height,
    width: dimensions.width || DEFAULT_DIMENSIONS.width,
  };
};

// TODO Theme this
export const ImageAttachmentPreview: GenericAttachmentView<ImageAttachment> = ({
  attachment,
  thumbnailSize = DEFAULT_THUMBNAIL_SIZE,
  thumbnail = false,
}) => {
  const { colors } = useColors();
  const { saveImage } = useSaveImageToGallery();
  const [imageUri, setImageSource] = useState(
    !isWeb && isLocalAttachment(attachment)
      ? attachment.file?.path
      : (attachment as ImageAttachment).url
  );

  const saveImageToGallery = useCallback(() => {
    const imageAttachment = attachment as ImageAttachment;
    saveImage(imageAttachment.url, imageAttachment.contentType);
  }, [attachment, saveImage]);

  useEffect(() => {
    if (!isWeb) return;
    if (isLocalAttachment(attachment)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSource(e?.target?.result);
      };
      reader.readAsDataURL(attachment.file);
    }
  }, [attachment]);

  const imageStyle = useComposeStyles(style.image, {
    borderColor: colors.background.main,
  });

  const dimensions = useMemo(() => {
    return calculateDimensions(
      _homologateDimensions((attachment as ImageAttachment).dimensions),
      Dimensions.get("window").width * 0.8
    );
  }, [attachment]);

  if (!attachment) {
    return null;
  }

  if (thumbnail) {
    return (
      <>
        <ImagePreview
          onError={() => {
            Sentry.captureException({ message: "Failed to load image" });
          }}
          style={[
            isLocalAttachment(attachment) && attachment.loading
              ? style.loading
              : style.attachment,
            { height: thumbnailSize, width: thumbnailSize },
          ]}
          uri={imageUri}
          onLongPress={saveImageToGallery}
        />
        {isLocalAttachment(attachment) && attachment.loading && (
          <ActivityIndicator
            color={colors.activityIndicator.lightContrast.main}
            style={style.loadingIndicator}
          />
        )}
      </>
    );
  }

  return (
    <ImagePreview
      style={imageStyle}
      dimensions={dimensions}
      uri={imageUri}
      onLongPress={saveImageToGallery}
    />
  );
};

const style = StyleSheet.create({
  attachment: {
    borderRadius: 6,
    height: 48,
    width: 48,
  },
  loading: {
    opacity: 0.5,
    borderRadius: 6,
    height: 48,
    width: 48,
  },
  loadingIndicator: {
    position: "absolute",
    width: 24,
    height: 24,
    left: 12,
    top: 12,
  },
  container: {
    borderRadius: 8,
  },
  image: {
    borderColor: "transparent",
    borderRadius: 8,
    borderWidth: 2,
  },
});
ImageAttachmentPreview.canDisplay = (obj: any): obj is ImageAttachment =>
  obj.__typename === "ImageAttachment" || obj?.file?.mime?.includes("image");

export default ImageAttachmentPreview;
