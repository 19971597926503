import React from "react";
import { ColorValue, StyleSheet, View, ViewStyle } from "react-native";
import { CustomIcon } from "../../icons";
import { useColors, useComposeStyles } from "~riata/hooks";
import { User } from "~riata/generated/graphql";

export const MEDIUM_ICON_SIZE = 30;

type AvatarProps = {
  user: Pick<User, "id">;
  width?: number;
  height?: number;
  outlined?: boolean;
  outlinedColor?: ColorValue;
};

const DEFAULT_OUTLINE_COLOR: ColorValue = "white";

export const Avatar = ({
  user,
  width = MEDIUM_ICON_SIZE,
  height = MEDIUM_ICON_SIZE,
  outlined = false,
  outlinedColor = DEFAULT_OUTLINE_COLOR,
}: AvatarProps) => {
  const { randomColor, newColors } = useColors();

  const containerColor = randomColor(user);

  const toCompose: ViewStyle = { backgroundColor: containerColor.main };

  if (width !== MEDIUM_ICON_SIZE || height !== MEDIUM_ICON_SIZE) {
    toCompose.width = width;
    toCompose.height = height;
  }

  if (outlinedColor !== DEFAULT_OUTLINE_COLOR) {
    toCompose.borderColor = outlinedColor;
  }

  const containerStyles = useComposeStyles(
    outlined ? styles.containerOutlined : styles.container,
    toCompose
  );

  return (
    <View style={containerStyles} testID="container">
      <CustomIcon
        name="avatar"
        // Not a huge fan of this magic number. It works for now.
        size={width / 2}
        color={newColors.icon.primary.main}
      />
    </View>
  );
};

const container: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  borderRadius: 500,
  width: MEDIUM_ICON_SIZE,
  height: MEDIUM_ICON_SIZE,
};

const styles = StyleSheet.create({
  container,
  containerOutlined: {
    ...container,
    borderColor: DEFAULT_OUTLINE_COLOR,
    borderWidth: 2,
  },
});

export default Avatar;
