import { useLinkTo } from "@react-navigation/native";
import React, { useMemo } from "react";
import { FlatList, Linking, StyleSheet, View } from "react-native";

import { CompanyHeader, Button, Text } from "~riata/components";
import { CustomIcon } from "~riata/components/icons";
import { useGetEmptyDashboardLinks } from "~riata/graphql";
import { useColors, useComposeStyles } from "~riata/hooks";

export const EmptyDashboard = () => {
  const { colors } = useColors();
  const linkTo = useLinkTo();
  const { links: extraLinks } = useGetEmptyDashboardLinks();

  const buttonStyles = useComposeStyles(styles.buttons, {
    backgroundColor: colors.button.accent1.main,
  });

  const iconStyles = useComposeStyles(styles.icon, {
    color: colors.button.accent1.on,
  });

  const links = useMemo(() => {
    return [
      {
        id: "messages-link",
        icon: "chat_outlined",
        name: "Messages",
        url: "/messages",
      },
      {
        id: "bulletins-link",
        icon: "bulletins",
        name: "Bulletins",
        url: "/bulletins",
      },
      ...extraLinks,
    ];
  }, [extraLinks]);

  return (
    <View style={styles.container}>
      <CompanyHeader />

      <Text variant="largeTitle">Congrats!</Text>
      <Text variant="subhead">You're all caught up!</Text>

      <View style={styles.listContainer}>
        <FlatList
          style={styles.buttonsContainer}
          data={links}
          numColumns={2}
          renderItem={({ item }) => {
            return (
              <Button
                style={buttonStyles}
                onPress={() =>
                  item.url[0] === "/"
                    ? linkTo(item.url)
                    : Linking.openURL(item.url)
                }
              >
                <CustomIcon style={iconStyles} name={item.icon} />
                <Text
                  variant="headline"
                  style={styles.buttonLabel}
                  color={colors.button.accent1.on}
                >
                  {item.name}
                </Text>
              </Button>
            );
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  listContainer: {
    width: "100%",
    paddingHorizontal: 24,
  },

  icon: {
    color: "transparent",
  },
  buttonsContainer: {
    marginTop: 24,
    flex: 1,
  },
  buttonLabel: {
    marginLeft: 12,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 12,
    margin: 12,
    flex: 1,
    borderRadius: 8,
    paddingVertical: 9,
    // Here as transparent so it can be composed above
    backgroundColor: "transparent",
  },
});
