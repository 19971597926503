import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { useLinkTo } from "@react-navigation/native";

// Components
import { NewCard as Card, Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";
import { useColors, useFeatureFlags } from "~riata/hooks";
import HashTagHeader from "./HashTagHeader";
import BulletinDateSubtitle from "../BulletinDateSubtitle";
import UserDetails from "./UserDetails";
import BulletinSectionHeader from "./BulletinSectionHeader";
import { BulletinDateLabel } from "../BulletinDate";
import { BulletinExpiresBanner } from "../BulletinExpires";

// Utils
import { getCalendarSpec, hasHashtags } from "./helpers";

// Types
import { BulletinCardComponent } from "./types";

const BulletinCard: BulletinCardComponent = ({
  bulletin,
  previousBulletin,
}) => {
  const { newColors } = useColors();
  const linkTo = useLinkTo();
  const { flags } = useFeatureFlags();
  const {
    id,
    publishedAt,
    name,
    content,
    scheduledAt,
    lastEditedTimestamp,
    isDraft,
  } = bulletin;

  const prevPublishedAt = previousBulletin?.publishedAt;
  const prevLastEditedAt = previousBulletin?.lastEditedTimestamp;
  const prevScheduledAt = previousBulletin?.scheduledAt;

  const containsHashtags = hasHashtags(bulletin);

  const previousDiff = useMemo(() => {
    if (isDraft) {
      return getCalendarSpec(prevLastEditedAt);
    }

    if (prevScheduledAt) {
      return getCalendarSpec(prevScheduledAt);
    }

    return getCalendarSpec(prevPublishedAt);
  }, [prevPublishedAt, prevScheduledAt, prevLastEditedAt, isDraft]);

  const currentDiff = useMemo(() => {
    if (isDraft) {
      return getCalendarSpec(lastEditedTimestamp);
    }
    if (scheduledAt) {
      return getCalendarSpec(scheduledAt);
    }
    return getCalendarSpec(publishedAt);
  }, [publishedAt, scheduledAt, lastEditedTimestamp, isDraft]);

  const showHeader = currentDiff !== previousDiff;

  const isScheduled = Boolean(scheduledAt) && !Boolean(publishedAt);

  return (
    <View key={id}>
      {showHeader && (
        <BulletinSectionHeader
          isDraft={isDraft}
          lastEditedAt={lastEditedTimestamp}
          publishedAt={publishedAt}
          scheduledAt={scheduledAt}
        />
      )}
      <Card
        shadowVariant="bulletinCard"
        borderRadius={8}
        containerStyles={styles.container}
        onPress={() => {
          if (bulletin.isDraft) {
            linkTo(`/bulletin/draft/${id}`);
          } else {
            linkTo(`/bulletin/${id}`);
          }
        }}
      >
        {containsHashtags && <HashTagHeader bulletin={bulletin} />}
        {isScheduled && (
          <BulletinDateLabel
            date={scheduledAt}
            containerStyles={styles.scheduleLabelContainer}
          />
        )}
        <Row style={styles.previewContainer}>
          <View style={styles.nameWrapper}>
            <Text
              numberOfLines={1}
              variant="title5"
              fontWeight="mediumBold"
              color={newColors.text.regular.main}
            >
              {name}
            </Text>
          </View>
          {!containsHashtags && !isScheduled && (
            <View style={styles.dateSubtitleWrapper}>
              <BulletinDateSubtitle
                date={
                  bulletin.isDraft
                    ? bulletin.lastEditedTimestamp
                    : publishedAt || scheduledAt
                }
              />
            </View>
          )}
        </Row>
        <Row style={styles.contentWrapper}>
          <Text
            variant="newSubhead"
            fontWeight="regular"
            numberOfLines={1}
            color={newColors.text.footnote.main}
          >
            {content}
          </Text>
        </Row>
        <UserDetails creator={bulletin.creator} via={bulletin.via?.name} />
        {flags.expiringBulletins && (
          <BulletinExpiresBanner bulletin={bulletin} justify="right" />
        )}
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  previewContainer: {
    gap: 8,
  },
  scheduleLabelContainer: {
    marginBottom: 8,
  },
  nameWrapper: {
    flex: 1,
  },
  dateSubtitleWrapper: {
    marginLeft: "auto",
  },
  contentWrapper: {
    marginTop: 8,
    marginBottom: 10,
  },
});

export default React.memo(BulletinCard);
