import React from "react";
import { Body, SafeAreaView } from "~riata/components";
import { MessageListSubScreen } from "../MessageListSubScreen";
import { InputBarSubScreen } from "../InputBarSubScreen";
import { useArchiveStatus } from "~riata/graphql";
import { ArchivedChannelInput } from "../ArchivedChannelInput";
import { Platform, useWindowDimensions } from "react-native";
import { Scalars } from "~riata/generated/graphql";

const messageToCursor = (m: Scalars["ID"]) => `chat:message:${m}`;

//////
// Renders the screen users interact with when in channels
//
// This screen dispatches to subscreens to ensure that there's a full
// separation between the message list and the various data-layer
// interactions that need to happen there and user input.
//
// Without this separation, the input bar re-renders anytime the
// following things happen:
//    - New message is received
//    - useGetMessages is updated via a cache-and-network update
//    - Reactions are added / removed
//    - Probably a few more that don't come to mind.
//
// This code feels a little weird – and it is – but it's trying to
// isolate user input from data coming from the server so we don't
// accidentally lose information that the user has entered.
const ConversationScreen = ({ navigation, route }) => {
  const channelID = route.params?.channel ?? false;
  const { height } = useWindowDimensions();
  const message = route.params?.message
    ? messageToCursor(route.params.message)
    : null;
  // TODO Error on false… something went wrong

  // Thinking you can optimize this by using `useGetChannel` instead and use the
  // cache that's created / updated within MessageListSubScreen? Nope, won't work
  // here. When messages are received, the cache for the `getChannels` mutation is
  // updated and that hook returns _everything_. That causes this component to
  // re-render which causes a lot of grief for folks typing messages because
  // the InputBar resets.
  const isArchived = useArchiveStatus(channelID);
  console.debug("ConversationScreen firing");

  return (
    <SafeAreaView bottom={false}>
      <Body
        keyboardVerticalOffset={Platform.OS === "ios" ? 60 : -height}
        behavior={"padding"}
      >
        <MessageListSubScreen
          channelID={channelID}
          message={message}
          navigation={navigation}
        />
        {isArchived ? (
          <ArchivedChannelInput />
        ) : (
          <InputBarSubScreen channelID={channelID} />
        )}
      </Body>
    </SafeAreaView>
  );
};

export default ConversationScreen;
