import React from "react";
import { Image, StyleSheet } from "react-native";
import { avatarSize, isWeb } from "~riata/theme/common";
import { AvatarContainer } from "./avatars/AvatarContainer";

const rodiobot = require("~riata/resources/rodiobot.png");

// TODO Package a RodioBot image within the app
const RodioBotAvatar = ({ small = true }) => {
  const imageSource =
    isWeb && !rodiobot.uri
      ? {
          uri: rodiobot,
          width: 135,
          height: 180,
        }
      : rodiobot;

  const style = styles.rodiobot;
  return (
    <AvatarContainer primary>
      <Image resizeMode="contain" style={style} source={imageSource} />
    </AvatarContainer>
  );
};

const styles = StyleSheet.create({
  rodiobot: {
    height: avatarSize.normal,
    width: avatarSize.normal,
  },
});

export default RodioBotAvatar;
