import { useCallback, useRef, useState } from "react";
import { secureGet, secureSet } from "~riata/utils/storage";
import doRefresh from "./refresh";
import { ClearFunction } from "~riata/contexts/CredentialsContext";

const AUTH_CONFIG = process.env.RIATA_AUTH_CONFIG as unknown as RiataAuthConfig;

const useCredentials = () => {
  const credentialsRef = useRef<false | Credentials>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [latest, setLatest] = useState<number>(Date.now());

  const triggerRerender = useCallback(() => {
    console.debug("useCredentials > triggerRerender invoked");
    setLatest(Date.now());
  }, []);

  const clear = useCallback<ClearFunction>(
    async ({ rerender } = { rerender: false }) => {
      await secureSet(false);
      credentialsRef.current = false;
      if (rerender) {
        triggerRerender();
      }
    },
    [triggerRerender]
  );

  const init = useCallback(async () => {
    // TODO Check to see if this should be refreshed before proceeding
    credentialsRef.current = await secureGet();
    setLoaded(true);
  }, []);

  const get = useCallback(() => credentialsRef.current, []);

  const refresh = useCallback(async () => {
    console.debug("useCredentialsInternal > refresh > invoked");
    if (!credentialsRef.current) {
      const msg = `useCredentialsInternal > refresh > refresh() called prior to credentials being initialized`;
      console.warn(msg);
      return Promise.reject(new Error(msg));
    }
    console.debug("useCI > refresh", { credentials: credentialsRef.current });
    let refreshResult;
    try {
      refreshResult = await doRefresh(AUTH_CONFIG, credentialsRef.current);
    } catch (error) {
      refreshResult = false;
    }
    console.debug("useCredentialsInternal > refresh > done", { refreshResult });
    const credentials: false | Credentials =
      refreshResult === false
        ? refreshResult
        : {
            token: refreshResult.accessToken,
            refreshToken: refreshResult.refreshToken,
          };
    secureSet(credentials);
    credentialsRef.current = credentials;
    return true;
  }, []);

  const set = useCallback(
    async (credentials: Credentials, { rerender } = { rerender: false }) => {
      await secureSet(credentials);
      credentialsRef.current = credentials;
      if (rerender) {
        triggerRerender();
      }
    },
    [triggerRerender]
  );

  return { clear, get, init, latest, loaded, refresh, set, triggerRerender };
};

export default useCredentials;
