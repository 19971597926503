import React from "react";
import { StyleSheet, View } from "react-native";
import { useColors } from "~riata/hooks";
import { AppHeader, SafeAreaView } from "../layout";
import { HeaderActionButton } from "../misc";
import { Text } from "../typography";

const EditProfileError = ({ navigation, error }) => {
  const { newColors } = useColors();

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title={"User Profile"}
      />
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <Text variant="headline" color={newColors.text.error.main}>
            Error Fetching User
          </Text>
          <Text
            variant="subhead"
            color={newColors.text.caption.main}
            style={styles.message}
          >
            {error}
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  wrapper: {
    margin: 16,
  },
  message: {
    marginTop: 8,
  },
});

export default EditProfileError;
