import React from "react";

import { CustomIcon } from "../CustomIcon";

export const TAB_BAR_ICON_SIZE = 18;

const InputBarIcon = ({ name, style = {}, ...props }) => {
  return (
    <CustomIcon name={name} size={TAB_BAR_ICON_SIZE} style={style} {...props} />
  );
};

export default InputBarIcon;
