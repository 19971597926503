const REPLY_REGEX = />[\s\S]*[\n]{2}/gim;
const REPLY_MAX_CHARS = 280;

export const editorIsEmpty = (
  text,
  attachments,
  inFlight,
  attachmentsLoading
) => {
  const noText = text.trim().length <= 0;

  return (
    (noText && attachments.length === 0) ||
    inFlight === true ||
    attachmentsLoading
  );
};

export const affixReply = (message, { text, sender }) => {
  let replyText = text
    .substring(0, REPLY_MAX_CHARS)
    .replace(/\n{2,}/gim, "\n>\n> ");

  if (text.length > REPLY_MAX_CHARS) {
    replyText = replyText + "...";
  }

  return `> **${sender}**\n${replyText}\n\n` + message;
};

export const removePreviousReply = ({ sender, text }) => {
  if (text.match(REPLY_REGEX)) {
    const Text = text.replace(REPLY_REGEX, "");
    return { sender, text: Text };
  }
  return { sender, text };
};
