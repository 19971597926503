import React, { useMemo } from "react";
import { PressableProps } from "react-native";
import { Bulletin } from "~riata/generated/graphql";
import { useColors } from "~riata/hooks";
import { ActivityIndicator } from "../../activityIndicator";
import { Button } from "../../inputs";
import { Text } from "../../typography";
import { getActionButton } from "./utils";

type BulletinDetailActionButtonProps = PressableProps & {
  bulletin: Bulletin;
};

type BulletinDetailActionButtonComponent = (
  props: BulletinDetailActionButtonProps
) => JSX.Element;

const BulletinDetailActionButton: BulletinDetailActionButtonComponent = ({
  bulletin,
  disabled,
  onPress,
}) => {
  const { newColors } = useColors();
  const { buttonBgColor, buttonText } = useMemo(
    () => getActionButton(bulletin),
    [bulletin]
  );
  const color = newColors.button[buttonBgColor].on;

  return (
    <Button
      color={buttonBgColor}
      variant="rounded"
      disabled={disabled}
      onPress={onPress}
    >
      {disabled ? (
        <ActivityIndicator color={color} />
      ) : (
        <Text variant="title4" color={color}>
          {buttonText}
        </Text>
      )}
    </Button>
  );
};

export default BulletinDetailActionButton;
