import React from "react";
import { Text } from "./typography";
import pluralize from "pluralize";
import { useColors } from "~riata/hooks";

interface PluralizeTextProps {
  style?: object;
  length: number;
  text?: string;
  color?: string;
}

const PluralizeText = ({
  style = {},
  length,
  text,
  color,
}: PluralizeTextProps) => {
  const { newColors } = useColors();
  const textColor = color ?? newColors.text.regular.main;
  return (
    <Text style={style} color={textColor}>
      {length} {pluralize(text, length)}
    </Text>
  );
};

export default PluralizeText;
