import React from "react";
import { StyleSheet, View } from "react-native";
import color from "color";

import { useColors, useComposeStyles } from "~riata/hooks";
import { ChevronDown } from "../../icons";

const ModalHeader = () => {
  const { newColors } = useColors();
  const headerStyles = useComposeStyles(styles.header, {
    backgroundColor: newColors.header.main,
  });
  return (
    <View style={headerStyles}>
      <ChevronDown
        size={18}
        style={styles.icon}
        color={color(newColors.icon.primary.main).fade(0.5).toString()}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "auto",
    height: 48,
    backgroundColor: "transparent",
  },
  icon: {
    marginTop: 6,
  },
});

export default ModalHeader;
