import { Image as RNImage } from "react-native";
import { isWeb } from "~riata/theme/common";
import { Sentry } from "~riata/utils/sentry";
import { MimeTypeMap } from "~riata/graphql/mutations/CreateAttachment";
import { RNFetchBlob } from "~riata/components/FetchBlob";

/**
 * Helper function that will render and calculate an image to send it's dimensions
 * as they are required by the server in order to render an image.
 * @param attachment
 * @returns
 */
const calculateDimensions = async (attachment): Promise<Dimensions> => {
  if (isWeb) {
    return new Promise((resolve, reject) => {
      const _URL = window.URL || window.webkitURL;
      const objectUrl = _URL.createObjectURL(attachment);

      let img = new Image();
      img.onload = () => {
        _URL.revokeObjectURL(objectUrl);
        resolve({
          width: img.width,
          height: img.height,
        });
      };

      img.onerror = (err) => {
        reject(err);
      };

      img.src = objectUrl;
    });
  }

  return new Promise((resolve, reject) => {
    RNImage.getSize(
      attachment.path,
      (width, height) =>
        resolve({
          width,
          height,
        }),
      (error) => reject(error)
    );
  });
};

const generateCreateAttachmentPromise = (attachment, createAttachment) => {
  return new Promise((resolve, reject) => {
    createAttachment(attachment)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const uploadAttachment = async (
  uploadUrl,
  attachment,
  finishAttachmentCallback,
  removeAttachmentCallback
) => {
  if (isWeb) {
    await fetch(uploadUrl, {
      method: "POST",
      body: attachment,
      mode: "no-cors",
    })
      .then(() => finishAttachmentCallback())
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        removeAttachmentCallback();
      });
  } else {
    await RNFetchBlob.fetch(
      "POST",
      uploadUrl,
      {
        "Content-Type": MimeTypeMap[attachment.mime],
      },
      RNFetchBlob.wrap(decodeURI(attachment.path))
    )
      .then(() => finishAttachmentCallback())
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        removeAttachmentCallback();
      });
  }
};

export {
  calculateDimensions,
  generateCreateAttachmentPromise,
  uploadAttachment,
};
