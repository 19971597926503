import React, { useMemo } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";

// Components
import {
  Button,
  CustomIcon,
  RodioLoader,
  Row,
  Separator,
  TappableBackground,
  Text,
} from "~riata/components";

// Hooks
import { useGetBulletin } from "~riata/graphql";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWideDisplay } from "~riata/theme/common";
import AttachmentsList from "./AttachmentsList";

const BulletinAttachmentsScreen = ({ route, navigation }) => {
  const { id: bulletinId = "" } = route.params;
  const { newColors } = useColors();
  const { width } = useWindowDimensions();
  const isWide = useMemo(() => isWideDisplay(width), [width]);

  const { loading, bulletin } = useGetBulletin(bulletinId);

  const sheetStyles = useComposeStyles(
    isWide ? styles.sheetWide : styles.sheet,
    { backgroundColor: newColors.surface.main }
  );

  if (loading) {
    return <RodioLoader />;
  }

  return (
    <View style={isWide ? styles.containerWide : styles.container}>
      <TappableBackground
        onPress={() => {
          navigation.goBack();
        }}
      />

      <View style={sheetStyles}>
        <Row justify="center" align="center" style={styles.header}>
          <View style={styles.titleContainer}>
            <Text variant="title4">Attachments</Text>
          </View>
          <Button onPress={() => navigation.goBack()}>
            <CustomIcon name={"close"} size={20} />
          </Button>
        </Row>
        <Separator />
        <AttachmentsList bulletin={bulletin} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  containerWide: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  sheet: {
    backgroundColor: "transparent",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    minHeight: 260,
  },
  sheetWide: {
    height: "40%",
    width: "70%",
    backgroundColor: "white",
    borderRadius: 12,
  },
  header: {
    padding: 16,
  },
  titleContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -20,
  },
});

export default BulletinAttachmentsScreen;
