import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

const Left = ({ ...props }: ViewProps) => {
  const preExistingStyles = props.style ?? {};
  props.style = StyleSheet.compose(preExistingStyles, styles.container);

  return <View {...props} />;
};

const styles = StyleSheet.create({
  container: {
    marginRight: "auto",
  },
});

export default Left;
