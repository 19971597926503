import React from "react";
import { StyleSheet } from "react-native";

import { Text } from "../Text";

const Title = ({ numberOfLines = 1, children }) => {
  return (
    <Text variant="title6" numberOfLines={numberOfLines} style={styles.center}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  center: {
    alignSelf: "center",
  },
});

export default Title;
