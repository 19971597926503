import React, { useEffect, useState } from "react";

import { Button } from "../Button";
import { ActivityIndicator } from "../../activityIndicator";
import { Text } from "../../typography";
import { useColors } from "~riata/hooks";

// TODO Extract into its own component
const NextButton = ({ disabled, onPress }) => {
  const [loading, setLoading] = useState(false);
  const { newColors } = useColors();

  useEffect(() => {
    if (!loading) {
      return; // No op
    }
    return () => {
      setLoading(false);
    };
  });

  return (
    <Button
      onPress={() => {
        setLoading(true);
        onPress();
      }}
      disabled={disabled}
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <Text
          accessibilityLabel="Next"
          color={
            disabled
              ? newColors.text.disabled.main
              : newColors.text.regular.main
          }
        >
          Next
        </Text>
      )}
    </Button>
  );
};

export default NextButton;
