import React, { createContext, useRef } from "react";
import { CommonActions, DrawerActions } from "@react-navigation/routers";

const DrawerRouteContext = createContext({
  updateRoute: (name: string, params: Record<string, any>) =>
    console.debug("updateRoute Not Implemented"),
  goBack: (navigation) => console.debug("goBack Not Implemented"),
  navigateTo: (name: string, navigation, params?: any) =>
    console.debug("navigateTo Not Implemented"),
  setCheckpoint: (navigation) => console.debug("setCheckpoint Not Implemented"),
  navigateToCheckpoint: (navigation) =>
    console.debug("navigateToCheckpoint Not Implemented"),
});

const DrawerRouteContextProvider = (props) => {
  const currentRoute = useRef({ name: "", params: undefined });
  const checkpoint = useRef({
    navigationState: {
      index: 0,
      routes: [{ name: "Drawers" }],
    },
  });

  const setCheckpoint = (navigation) => {
    let navObj = navigation;

    while (!navObj.getState().routeNames.includes("Home")) {
      navObj = navObj.getParent();
    }

    checkpoint.current.navigationState = navObj.getState();
  };

  const navigateToCheckpoint = (navigation) => {
    navigation.dispatch(
      CommonActions.reset(checkpoint.current.navigationState)
    );
  };

  const updateRoute = (name: string, params: Record<string, any>) => {
    currentRoute.current.name = name;
    currentRoute.current.params = params;
  };

  /**
   * Can be used by screens that are in a nested navigator that are part of
   * parent Drawer Navigator.
   *
   * UserProfile | Conversation | Bulletin
   */
  const goBack = (navigation) => {
    const routeName = currentRoute.current.name ?? "Home";

    // Will return drawer's routes
    const routes = navigation.getParent().getState().routeNames;
    const parentScreenIndex = routes.indexOf(routeName);

    if (parentScreenIndex === -1) {
      // Current Screen is not part of the drawer navigation. This most likely
      // will be called from nested screen of a Drawer nested stack
      navigation.goBack();
    } else {
      navigation.dispatch(
        DrawerActions.jumpTo(routeName, currentRoute.current.params)
      );
    }
  };

  /**
   * Can be used by screens that are at the same level of the screens that are
   * part of the drawer navigator.
   *
   * IntegrationListScreen | NewDmScreen | MessageDetailScreen | MessageDetail |
   * FolderDetail
   */
  const navigateTo = (name: string, navigation, params = {}) => {
    navigation.dispatch(DrawerActions.jumpTo(name, params));
  };

  const value = {
    updateRoute,
    goBack,
    navigateTo,
    setCheckpoint,
    navigateToCheckpoint,
  };

  return (
    <DrawerRouteContext.Provider value={value}>
      {props.children}
    </DrawerRouteContext.Provider>
  );
};

export default DrawerRouteContextProvider;
export { DrawerRouteContext };
