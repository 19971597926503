import gql from "graphql-tag";

// Hooks
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery } from "~riata/graphql/queries";
import { ChannelType } from "~riata/generated/graphql";

const LEAVE_GROUP_CHANNEL = gql`
  mutation ($channel: ID!) {
    leaveChannel(input: { channel: $channel }) {
      errors {
        field
        messages
      }
      clientMutationId
      success
    }
  }
`;

const useLeaveGroupChannel = () => {
  const [mutation, response] = useMutation(LEAVE_GROUP_CHANNEL);

  const execute = (channelId: string) => {
    const variables = { channel: channelId };

    return mutation({
      variables,
      refetchQueries: [
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: GetChannelsQuery,
          variables: {
            filter: { isArchived: false, type: ChannelType.Direct },
          },
        },
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false, type: ChannelType.Team } },
        },
        // TODO: Missing ChannelType.Group
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response: response?.data?.leaveChannel };
};

export { useLeaveGroupChannel };
