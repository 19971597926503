import React from "react";
import { CustomIcon } from "./CustomIcon";
import Icon from "react-native-vector-icons/Octicons";
import { useColors } from "~riata/hooks";
export { DrawerIcon } from "./DrawerIcon";
export { InputBarIcon } from "./InputBarIcon";
export { NavigationIcon } from "./NavigationIcon";

const generateCustomIcon =
  (name, defaultProps = {}) =>
  (props) => {
    const { newColors } = useColors();

    return (
      <CustomIcon
        {...defaultProps}
        {...props}
        name={name}
        color={props.color ? props.color : newColors.icon.surfaceContrast.main}
      />
    );
  };

export const AddDocumentIcon = generateCustomIcon("add_document");
export const AddIcon = generateCustomIcon("add");
export const AnalyticsBarsIcon = generateCustomIcon("statistics");
export const ArchiveIcon = generateCustomIcon("archive");
export const AttachmentsIcon = generateCustomIcon("attachments");
export const BackArrow = generateCustomIcon("arrow_left");
export const BackIcon = generateCustomIcon("back");
export const BulletinsOpened = ({ color = null, ...props }) => {
  const { newColors } = useColors();
  if (color === null) {
    color = newColors.icon.surfaceContrast.main;
  }
  return <CustomIcon name="bulletins_opened" color={color} {...props} />;
};

export const BulletinsRead = ({ color = null, ...props }) => {
  const { newColors } = useColors();
  if (color === null) {
    color = newColors.icon.surfaceContrast.main;
  }
  return <CustomIcon name="bulletins_read" color={color} {...props} />;
};

export const CalendarIcon = generateCustomIcon("calendar");
export const CameraIcon = generateCustomIcon("camera");
// FIXME The icon should be a square with a borderRadius to turn it into a circle
export const ChatIcon = generateCustomIcon("chat");
export const CheckCircleIcon = generateCustomIcon("check");
export const ChevronDown = generateCustomIcon("chevron_down");
export const ChevronUp = generateCustomIcon("chevron_up");
export const ClockIcon = generateCustomIcon("clock");
export const CloseIcon = generateCustomIcon("close");
export const CommonFileTextCheckIcon = generateCustomIcon("review");
export const CurrencyDollarCircleIcon = generateCustomIcon("coin");
export const DetailsIcon = generateCustomIcon("details");
export const DetailsVerticalIcon = generateCustomIcon("details_vertical");
export const DocumentIcon = generateCustomIcon("document_1");
export const DownIcon = ({ color = null, style = { width: 24 }, ...props }) => {
  const { newColors } = useColors();
  if (color === null) {
    color = newColors.icon.surfaceContrast.main;
  }
  return (
    <CustomIcon
      color={color}
      name="back"
      style={{ ...style, transform: [{ rotate: "270deg" }] }}
      {...props}
    />
  );
};
export const FieldConnectorIcon = generateCustomIcon("fc");
export const FolderEmptyIcon = generateCustomIcon("folder_empty");
export const ForwardArrow = ({
  color = null,
  size = 15,
  style = {},
  ...props
}) => {
  const { newColors } = useColors();
  if (color === null) {
    color = newColors.icon.surfaceContrast.main;
  }
  return (
    <CustomIcon
      size={size}
      color={color}
      name="arrow_left"
      style={{ ...style, transform: [{ rotate: "180deg" }] }}
      {...props}
    />
  );
};
export const HamburgerIcon = generateCustomIcon("hamburger", { size: 18 });
export const MapsPinIcon = generateCustomIcon("map");
export const MedicalIcon = generateCustomIcon("medical");
export const NetworkNavigationIcon = generateCustomIcon("internet");
export const NumberZeroIcon = generateCustomIcon("number_zero");
export const NumberOneIcon = generateCustomIcon("number_one");
export const NumberTwoIcon = generateCustomIcon("number_two");
export const NumberThreeIcon = generateCustomIcon("number_three");
export const NumberFourIcon = generateCustomIcon("number_four");
export const NumberFiveIcon = generateCustomIcon("number_five");
export const NumberSixIcon = generateCustomIcon("number_six");
export const NumberSevenIcon = generateCustomIcon("number_seven");
export const NumberEightIcon = generateCustomIcon("number_eight");
export const NumberNineIcon = generateCustomIcon("number_nine");
export const NumberTenIcon = generateCustomIcon("number_ten");
export const NumberElevenIcon = generateCustomIcon("number_eleven");
export const NumberTwelveIcon = generateCustomIcon("number_twelve");
export const PdfIcon = generateCustomIcon("pdf");
export const PhoneIcon = generateCustomIcon("phone");
export const PictureIcon = generateCustomIcon("picture");
export const PictureDoubleLandscapeIcon = generateCustomIcon("image");
export const PinIcon = generateCustomIcon("pin");
export const PinLocationIcon = generateCustomIcon("location");
export const PlusIcon = generateCustomIcon("plus");
export const PresentationIcon = generateCustomIcon("presentation");
export const QuestionHelpSquareIcon = generateCustomIcon("question");
export const RefreshIcon = generateCustomIcon("refresh");
export const SearchIcon = generateCustomIcon("search");
export const SendIcon = generateCustomIcon("send");
export const ShareIcon = generateCustomIcon("share_2");
export const SpreadsheetIcon = generateCustomIcon("spreadsheet");
export const TagsIcon = generateCustomIcon("tag");
export const TeamIcon = generateCustomIcon("team");
export const TripleDotIcon = generateCustomIcon("menu", { size: 15 });
export const UsersRecipient = generateCustomIcon("users_recipient");
export const UnreadIcon = generateCustomIcon("unread");
export const VideoIcon = generateCustomIcon("video");
export const WeatherCloudIcon = generateCustomIcon("cloudy");

export { CustomIcon, Icon };
