import React from "react";

import RelativeTime from "../RelativeTime";
import ChannelListItemTitle from "./ChannelListItemTitle";
import NewChannelAvatar from "./NewChannelAvatar";
import { ListItem, Row } from "../layout";
import { Text } from "../typography";
import { useChannelLink } from "~riata/links";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Button } from "../inputs";
import { Platform, StyleSheet, View } from "react-native";
import StringUtils from "~riata/utils/string";

const ChannelListItem = ({ channel }) => {
  const { newColors } = useColors();
  const { onPress, ...buttonProps } = useChannelLink({ node: channel });

  const hasUnreadMessages = channel.unreadCount > 0;

  const message = channel.lastMessageContent || "";

  const textPreview = StringUtils.removeMDTokens(message);

  const containerStyles = useComposeStyles(styles.container, {
    borderBottomColor: newColors.separator.main,
  });

  const unreadIndicatorStyles = useComposeStyles(styles.unreadIndicator, {
    backgroundColor: newColors.icon.unreadCount.main,
  });

  return (
    <ListItem key={channel.id}>
      <Button style={styles.button} onPress={onPress} {...buttonProps}>
        <Row style={styles.wrapper}>
          <NewChannelAvatar
            channel={channel}
            containerStyles={styles.avatarWrapper}
            outlineColor={newColors.background.main}
            showOffDuty
          />
          <View style={containerStyles}>
            <Row style={styles.titleContainer}>
              <View style={styles.titleWrapper}>
                <ChannelListItemTitle
                  channel={channel}
                  hasUnreadMessages={hasUnreadMessages}
                />
              </View>
              <View style={styles.relativeTimeWrapper}>
                <RelativeTime time={channel.latestMessageAt} />
              </View>
            </Row>
            <Row style={styles.contentWrapper}>
              <View style={styles.textWrapper}>
                <Text
                  variant="caption3"
                  color={
                    hasUnreadMessages
                      ? newColors.text.regular.main
                      : newColors.text.footnote.main
                  }
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {textPreview.length > 0 ? (
                    textPreview
                  ) : (
                    <View style={styles.emptyText}></View>
                  )}
                </Text>
              </View>
              {hasUnreadMessages && (
                <View style={unreadIndicatorStyles}>
                  <Text
                    variant="newCaption2"
                    fontWeight="bold"
                    color={newColors.icon.unreadCount.on}
                  >
                    {channel.unreadCount}
                  </Text>
                </View>
              )}
            </Row>
          </View>
        </Row>
      </Button>
    </ListItem>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: 1,
  },
  emptyText: {
    height: 13,
  },
  avatarWrapper: {
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    paddingVertical: 14,
    paddingLeft: 16,
  },
  wrapper: {
    gap: 12,
    paddingLeft: Platform.OS === "web" ? 16 : 0,
  },
  container: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: "transparent",
    paddingVertical: 14,
    paddingRight: 16,
  },
  relativeTimeWrapper: {
    marginLeft: "auto",
  },
  titleContainer: {
    gap: 8,
  },
  titleWrapper: {
    flex: 1,
  },
  contentWrapper: {
    marginTop: 8,
    gap: 8,
  },
  textWrapper: {
    flex: 1,
  },
  unreadIndicator: {
    height: 24,
    width: 24,
    backgroundColor: "transparent",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginBottom: -2,
  },
});

export default ChannelListItem;
