import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

// Screens
import AddUsersModal from "./AddUsersModal";
import ChannelInfoSheet from "./ChannelInfo";
import ChannelUserProfileScreen from "./ChannelUserProfileScreen";

// Hooks
import { useColors } from "~riata/hooks";
import { Platform } from "react-native";

const Stack = createStackNavigator();

const ConversationInfoStack = () => {
  const { newColors } = useColors();
  return (
    <Stack.Navigator
      initialRouteName="ConversationInfoSheet"
      screenOptions={{
        cardOverlayEnabled: true,
        cardStyle: { backgroundColor: "transparent", opacity: 1 },
        cardStyleInterpolator: ({ current: { progress } }) => ({
          cardStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 0.5, 0.9, 1],
              outputRange: [0, 0.25, 0.7, 1],
            }),
            transform: [
              {
                scale: progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                  extrapolate: "clamp",
                }),
              },
            ],
          },
          overlayStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
              extrapolate: "clamp",
            }),
            backdropFilter: "blur(1px)",
          },
        }),
        presentation: "transparentModal",
      }}
    >
      <Stack.Screen
        name="ConversationInfoSheet"
        component={ChannelInfoSheet}
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: newColors.background.main,
            height: Platform.OS !== "ios" ? 0 : 50,
            shadowColor: "transparent",
            shadowOffset: {
              height: 0,
              width: 0,
            },
            shadowOpacity: 0,
            elevation: 0,
          },
          headerLeft: () => null,
          title: "",
        }}
      />
      <Stack.Screen
        name="AddUsersModal"
        component={AddUsersModal}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ChannelUserProfileScreen"
        component={ChannelUserProfileScreen}
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: newColors.background.main,
            height: Platform.OS !== "ios" ? 0 : 50,
            shadowColor: "transparent",
            shadowOffset: {
              height: 0,
              width: 0,
            },
            shadowOpacity: 0,
            elevation: 0,
          },
          headerLeft: () => null,
          title: "",
        }}
      />
    </Stack.Navigator>
  );
};

export default ConversationInfoStack;
