import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import color from "color";

// Components
import BulletinDateSubtitle from "../BulletinDateSubtitle";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

// Hooks
import { useColors } from "~riata/hooks";

// Types
import { HashTagHeaderComponent } from "./types";

const HashTagHeader: HashTagHeaderComponent = ({ bulletin }) => {
  const { newColors } = useColors();
  const { publishedAt, hashtags } = bulletin;

  const hashtagsIndicators = useMemo(() => {
    const slicedHashtags = hashtags.edges.slice(0, 3);

    return slicedHashtags.map((edge) => {
      const bgColor =
        edge.node?.color?.color || newColors.variants.primary.main;

      return (
        <View
          key={`${edge.node.id}-indicator`}
          style={[
            styles.hashTagIndicator,
            {
              backgroundColor: bgColor,
            },
          ]}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashtags]);

  const hashtagsLabels = useMemo(() => {
    const slicedHashtags = hashtags.edges.slice(0, 3);

    return slicedHashtags.map((edge) => {
      const bgColor =
        edge.node?.color?.color || newColors.variants.primary.main;
      return (
        <View
          key={`${edge.node.id}-label`}
          style={[
            styles.hashTagLabel,
            { backgroundColor: color(bgColor).fade(0.9).string() },
          ]}
        >
          <Text variant="label1" color={bgColor}>
            #{edge.node.name}
          </Text>
        </View>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashtags]);

  return (
    <View style={styles.container}>
      <View style={styles.labelsWrapper}>
        <Row>{hashtagsIndicators}</Row>
      </View>
      <Row>
        <Row>{hashtagsLabels}</Row>
        <View style={styles.dateWrapper}>
          <BulletinDateSubtitle date={publishedAt} />
        </View>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 9,
  },
  labelsWrapper: {
    position: "absolute",
    top: -12,
    right: 0,
  },
  dateWrapper: {
    marginLeft: "auto",
  },
  hashTagIndicator: {
    height: 6,
    width: 48,
    backgroundColor: "transparent",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  hashTagLabel: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 4,
    backgroundColor: "transparent",
  },
});

export default HashTagHeader;
