import React, { useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";

// Components
import {
  BottomSheet,
  Button,
  Row,
  TappableBackground,
  Text,
  UserList,
  UserSelectSearch,
} from "~riata/components";

// Hooks
import {
  useColors,
  useComposeStyles,
  useTrackSelectedUsers,
} from "~riata/hooks";

// Utils
import { filterCurrentMembers } from "./utils";
import { Sentry } from "~riata/utils/sentry";

// Types
import {
  useAddMembersToChannel,
  useGetChannel,
  useGetUsers,
} from "~riata/graphql";

const AddUsersModal = ({ navigation, route }) => {
  const channelId = route?.params?.channel;

  const [filter, setFilter] = useState("");

  const { newColors } = useColors();
  const { channel } = useGetChannel(channelId);
  const { selectedUsers, toggleUser, cleanSelectedUsers } =
    useTrackSelectedUsers();
  const { execute: addMembers } = useAddMembersToChannel();
  const { loading, error, userList, refetch } = useGetUsers({
    filter: { query: filter },
  });

  const headerStyles = useComposeStyles(styles.header, {
    backgroundColor: newColors.background.main,
  });

  const searchBarContainerStyles = useComposeStyles(styles.inputContainer, {
    backgroundColor: newColors.background.main,
  });

  const filteredList = useMemo(() => {
    if (!channel || !userList) {
      return [];
    }

    return filterCurrentMembers(userList, channel?.members?.edges);
  }, [userList, channel]);

  const onSearch = (text) => {
    setFilter(text);
  };

  const onCancel = () => {
    cleanSelectedUsers();
    navigation.goBack();
  };

  const onAdd = () => {
    const selectedIds = selectedUsers.map((user) => user.id);
    addMembers(channelId, selectedIds)
      .then(() => {
        cleanSelectedUsers();
        navigation.goBack();
      })
      .catch((error) => {
        console.log("Error adding members to channel");
        console.error(error);
        Sentry.captureException(error);
      });
  };

  return (
    <View style={styles.container}>
      <TappableBackground onPress={() => navigation.goBack()} />
      <BottomSheet
        onCloseHandler={() => navigation.goBack()}
        snapPoints={[0.9]}
        enabled={false}
        showDragBar={false}
      >
        <Row justify="between" align="center" style={headerStyles}>
          <Button onPress={onCancel}>
            <Text variant="callout">Cancel</Text>
          </Button>
          <Text variant="headline">Add Members</Text>
          <Button onPress={onAdd}>
            <Text variant="callout">Add</Text>
          </Button>
        </Row>

        <View>
          <View style={searchBarContainerStyles}>
            <UserSelectSearch
              selectedUsers={selectedUsers}
              removeUser={(user) => toggleUser(user)}
              onSearch={onSearch}
              inputStyles={styles.inputStyles}
              inputContainerStyles={styles.inputStyles}
              showSearchIcon
            />
          </View>
          <View style={styles.userListWrapper}>
            <UserList
              data={filteredList}
              selectedUsers={selectedUsers}
              loading={loading}
              error={error}
              refetch={refetch}
              onSelectUser={(user) => toggleUser(user)}
            />
          </View>
        </View>
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: "transparent",
  },
  searchBarContainer: { paddingHorizontal: 16, paddingVertical: 8 },
  inputContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    backgroundColor: "transparent",
  },
  inputStyles: {
    borderRadius: 8,
  },
  userListWrapper: {
    paddingTop: 8,
  },
});

export default AddUsersModal;
