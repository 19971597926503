import React from "react";
import { StyleSheet, View } from "react-native";

import { AvatarOffDutyIndicator, UserAvatar } from "../avatars";
import { useGetCurrentUser } from "~riata/graphql";
import { useColors, useComposeStyles } from "~riata/hooks";
import { avatarSize } from "~riata/theme/common";

const DirectChannelAvatar = ({
  channel,
  multiUser = false,
  unread = false,
  showStatus = true,
  size = "normal",
  avatarDisabled = false,
}) => {
  const channelMembers = channel.members.edges.map((a) => a.node);
  const { user } = useGetCurrentUser();

  const { newColors } = useColors();
  const indicatorStyle = useComposeStyles(styles.indicator, {
    backgroundColor: newColors.status.inactive.main,
  });

  if (multiUser) {
    return (
      <View>
        <View style={styles.multiUserLgAvatar}>
          <UserAvatar size={40} user={channelMembers[0]}/>
        </View>
        <View style={styles.multiUserAvatar}>
          <UserAvatar outlined size={30} user={channelMembers[1]} />
        </View>
        {/* {unread && <View style={indicatorStyle} />} */}
        <View style={indicatorStyle} />
      </View>
    );
  }

  if (user) {
    const combinedStyle = StyleSheet.flatten([
      indicatorStyle,
      styles.singleUserIndicator,
    ]);

    return (
      <>
        <UserAvatar
          style={styles.avatar}
          size={avatarSize[size]}
          user={channelMembers.find((member) => member.id !== user.id)}
          disabled={avatarDisabled}
        />
        {showStatus && (
          <AvatarOffDutyIndicator
            user={channelMembers.find((member) => member.id !== user.id)}
          />
        )}
        {unread && <View style={[combinedStyle]} />}
      </>
    );
  }

  return <></>;
};

const styles = StyleSheet.create({
  avatar: {
    alignSelf: "center",
  },
  multiUserLgAvatar: {
    position: "relative",
    top: 0,
    left: 0,
  },
  multiUserAvatar: {
    position: "absolute",
    top: 22.5,
    left: 7.5,
  },
  indicator: {
    position: "absolute",
    width: 16,
    height: 16,
    borderRadius: 8,
    top: 24,
    right: 7.5,
    backgroundColor: "transparent",
  },
  singleUserIndicator: {
    top: 13,
    right: 10,
  },
  offDutyContainer: {
    position: "absolute",
    bottom: 0,
  },
});

export default DirectChannelAvatar;
