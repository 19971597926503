import React, { useContext, useMemo } from "react";
import { Clipboard } from "~riata/utils/Clipboard";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";

import {
  ActionSheetItem,
  Row,
  Separator,
  TappableBackground,
  EmojiReactionItem,
} from "~riata/components";
import {
  useAddReaction,
  useGetPrimaryReactionTypes,
  useReportMessage,
} from "~riata/graphql";
import { useGetMessage } from "~riata/graphql/queries/GetMessage";
import { isWideDisplay } from "~riata/theme/common";
import { HeaderActionButton, RodioLoader } from "~riata/components/misc";
import { RegistryContext } from "~riata/contexts";
import { Sentry } from "~riata/utils/sentry";
import { useConcatStyles } from "~riata/hooks/useConcatStyles";
import { useColors, useToastMessage } from "~riata/hooks";

const MessageActionSheet = ({ navigation, route }) => {
  const registry = useContext(RegistryContext);
  const { message, loading: messageLoading } = useGetMessage(
    route.params.message
  );
  const { list: reactions, loading: reactionsLoading } =
    useGetPrimaryReactionTypes();
  const { execute: addReaction } = useAddReaction({ message });
  const { execute: reportMessage } = useReportMessage();
  const { showInfoToast, showDangerToast } = useToastMessage();
  const { width } = Dimensions.get("window");
  const { newColors } = useColors();
  const isWide = useMemo(() => isWideDisplay(width), [width]);
  const isLoading = messageLoading || reactionsLoading;

  // FIXME Handle error state
  console.log("rendering sheet", message);

  const containerStyles = useConcatStyles([
    styles.container,
    isWide ? styles.container_wide : [],
  ]);
  const sheetStyles = useConcatStyles([
    styles.sheet,
    { backgroundColor: newColors.surface.main },
    isWide ? styles.sheet_wide : [],
  ]);

  return (
    <View style={containerStyles}>
      <TappableBackground onPress={() => navigation.goBack()} />
      <View style={sheetStyles}>
        {isLoading ? (
          <RodioLoader />
        ) : (
          <>
            <Row style={styles.header}>
              <HeaderActionButton
                variant="back"
                onPress={() => navigation.goBack()}
              />
            </Row>

            <ScrollView horizontal style={styles.emojiContainer}>
              {reactions.slice(0, 10).map((reaction, index) => (
                <EmojiReactionItem
                  key={`${message.id}-${reaction.id}-${index}`}
                  reaction={reaction}
                  message={message}
                  isFirst={index === 0}
                  addReaction={addReaction}
                  navigation={navigation}
                />
              ))}
            </ScrollView>
            {reactions.length > 0 && <Separator />}
            <View style={styles.actionsContainer}>
              <ActionSheetItem
                icon="reply"
                title="Reply"
                action={() => {
                  registry.get("setReplyText")({
                    text: message?.content,
                    sender: message?.creator?.displayName,
                  });
                  navigation.goBack();
                }}
              />
              <ActionSheetItem
                icon="clipboard"
                title="Copy Text"
                action={() => {
                  Clipboard.setString(message.content);
                  showInfoToast("Message Copied");
                  navigation.goBack();
                }}
              />
              <ActionSheetItem
                icon="report"
                title="Report"
                danger
                action={() => {
                  if (!message) {
                    // TODO handle this error state?
                    Sentry.captureEvent({
                      logger: "UnexpectedState",
                      message: "Got a null message?",
                      extra: {
                        ...route.params,
                      },
                    });
                    return;
                  }
                  reportMessage(message)
                    .then(() => {
                      showDangerToast("Message Reported");
                      navigation.goBack();
                    })
                    .catch(Sentry.captureException);
                }}
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  container_wide: {
    justifyContent: "center",
  },
  sheet: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    minHeight: 280,
  },
  sheet_wide: {
    width: "70%",
    alignSelf: "center",
    borderRadius: 12,
  },
  header: {
    paddingHorizontal: 8,
    paddingTop: 16,
  },
  emojiContainer: {
    margin: 16,
  },
  actionsContainer: {
    marginTop: 16,
  },
});

export default MessageActionSheet;
