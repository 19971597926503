import React from "react";
import { SafeAreaView, StyleSheet } from "react-native";

import { RodioLoader } from "./misc";

export const AppLoading = () => (
  <SafeAreaView style={styles.container}>
    <RodioLoader large />
  </SafeAreaView>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
