import React, { useCallback, useMemo } from "react";
import {
  KeyboardAvoidingView,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";

// Components
import {
  SearchBar,
  Separator,
  ChannelInfoHeader,
  ChannelInfoActions,
  AppHeader,
  HeaderActionButton,
  KeyboardDismissView,
  Text,
} from "~riata/components";
import ChannelUsersList from "./ChannelUsersList";

// Hooks
import { useColors, useComposeStyles, useToastMessage } from "~riata/hooks";
import { Platform } from "react-native";
import { useMuteChannel, useUnmuteChannel } from "~riata/graphql";

const CompanyChannelInfo = ({ navigation, channel, setUserFilter }) => {
  const { colors, newColors } = useColors();
  const { execute: muteChannel } = useMuteChannel();
  const { execute: unmuteChannel } = useUnmuteChannel();
  const { showInfoToast } = useToastMessage();

  const goBack = useCallback(() => navigation.goBack(), [navigation]);

  const toggleMuted = useCallback(() => {
    channel.isMutedByUser
      ? unmuteChannel(channel.id).then(() =>
          showInfoToast("Notifications are enabled")
        )
      : muteChannel(channel.id).then(() =>
          showInfoToast("Notifications are muted")
        );
    // showInfoToast shouldn't trigger a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, muteChannel, unmuteChannel]);

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: colors.lightBackground.main,
  });

  const userListWrapperStyles = useComposeStyles(styles.userListWrapper, {
    backgroundColor: newColors.surface.main,
  });

  const mappedUsersList = useMemo(() => {
    return channel?.members.edges.map((item) => ({
      id: item.node.id,
      fullName: item.node.displayName,
      jobTitle: item.node.jobTitle,
      canMessage: item.node.canMessage,
      offDuty: item.node.offDuty,
      company: item.node.company,
      avatar: item.node.avatar,
    }));
  }, [channel]);

  const actions = useMemo(() => {
    const actions = [
      {
        label: channel?.isMutedByUser ? "Unmute" : "Mute",
        iconName: channel?.isMutedByUser ? "muted" : "unmuted",
        onPressHandler: toggleMuted,
      },
    ];

    return actions;
  }, [channel, toggleMuted]);

  const inputContainerStyles = useComposeStyles(styles.inputContainer, {
    backgroundColor: newColors.background.main,
  });

  const onSearch = (text) => setUserFilter(text);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={containerStyles}
      keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 80}
    >
      <KeyboardDismissView>
        <SafeAreaView style={containerStyles}>
          <AppHeader
            backgroundColor={newColors.background.main}
            left={<HeaderActionButton variant="back" onPress={goBack} />}
          />

          <>
            {channel && (
              <ChannelInfoHeader
                channel={channel}
                onBackPress={goBack}
                title={channel?.name}
                subtitle={`${channel?.members?.total} members`}
                avatarContainerStyles={{ justifyContent: "flex-start" }}
              />
            )}
            <ChannelInfoActions
              actions={actions}
              containerStyles={styles.actionsContainer}
            />
            <Separator color={newColors.separator.main} />
            <View style={styles.userListTitleWrapper}>
              <Text variant="title4">Channel Members</Text>
            </View>
            <View style={inputContainerStyles}>
              <SearchBar
                showSearchIcon
                placeholder={"Search for a user..."}
                onSearch={(text) => onSearch(text)}
                inputStyles={styles.inputStyles}
                containerStyles={styles.inputStyles}
              />
            </View>
            <View style={userListWrapperStyles}>
              <ChannelUsersList
                data={mappedUsersList}
                channelId={channel?.id}
              />
            </View>
          </>
        </SafeAreaView>
      </KeyboardDismissView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
  userListTitleWrapper: {
    paddingHorizontal: 16,
    marginTop: 20,
  },
  inputContainer: {
    marginTop: 20,
    paddingHorizontal: 16,
    backgroundColor: "transparent",
  },
  inputStyles: {
    borderRadius: 8,
  },
  userListWrapper: {
    flex: 1,
    backgroundColor: "transparent",
    marginTop: 12,
  },
});

export default CompanyChannelInfo;
