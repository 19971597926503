import React from "react";
import {
  Linking,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { Text } from "../typography";
import SafeAreaView from "react-native-safe-area-view";
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from "@react-navigation/drawer";

import DeviceInfo from "react-native-device-info";
import { default as ProfileDrawerItem } from "../ProfileDrawerItem";
import { NavigationIcon } from "../icons";
import { Separator } from "../misc";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useApolloClient } from "@apollo/react-hooks";
import { useColors, useComposeStyles, useLogout } from "~riata/hooks";
import { useGetCurrentUser } from "~riata/graphql";
import { useRevokeTokens } from "~riata/graphql";
import { useCredentials } from "~riata/hooks";

const BUILD_NUMBER =
  (DeviceInfo?.getBuildNumber?.() ?? "unknown") === "unknown"
    ? process.env.LARIAT_BUILD_NUMBER
    : DeviceInfo.getBuildNumber();
const VERSION_NUMBER =
  (DeviceInfo?.getVersion?.() ?? "unknown") === "unknown"
    ? process.env.LARIAT_VERSION_NUMBER
    : DeviceInfo.getVersion();

const DrawerContent = (props) => {
  const client = useApolloClient();
  const { logout } = useLogout();
  const { execute: revokeTokens } = useRevokeTokens();
  const credentials = useCredentials();
  const { newColors } = useColors();

  const insets = useSafeAreaInsets();

  const { user } = useGetCurrentUser();

  const textStyles = useComposeStyles(styles.version, {
    paddingBottom: insets.bottom,
  });

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.surface.main,
  });

  const onLogout = () => {
    revokeTokens(credentials.get()["token"]);
    logout();
  };

  return (
    <SafeAreaView
      style={containerStyles}
      forceInset={{ top: "always", horizontal: "never", bottom: "never" }}
    >
      <DrawerContentScrollView contentContainerStyle={styles.drawer}>
        <View>
          <ProfileDrawerItem />
          <DrawerItemList {...props} />
        </View>

        <View style={styles.bottomContainer}>
          <Separator />
          {user?.company?.supportURL && (
            <DrawerItem
              onPress={() => Linking.openURL(user.company.supportURL)}
              icon={() => <NavigationIcon name="support" />}
              label="Support"
              inactiveTintColor={newColors.text.regular.main}
              activeTintColor={newColors.text.regular.main}
              inactiveBackgroundColor="transparent"
              activeBackgroundColor="blue"
            />
          )}
          <DrawerItem
            onPress={onLogout}
            icon={() => <NavigationIcon name="logout" />}
            label="Logout"
            inactiveTintColor={newColors.text.regular.main}
            activeTintColor={newColors.text.regular.main}
            inactiveBackgroundColor="transparent"
            activeBackgroundColor="blue"
          />

          <TouchableWithoutFeedback
            onLongPress={() => {
              client.resetStore();
            }}
          >
            <Text
              variant="footnote"
              color={newColors.text.regular.main}
              style={textStyles}
            >{`Version ${VERSION_NUMBER} - ${BUILD_NUMBER}`}</Text>
          </TouchableWithoutFeedback>
        </View>
      </DrawerContentScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "transparent",
  },
  drawer: {
    justifyContent: "space-between",
    flexGrow: 1,
  },
  bottomContainer: {
    width: "100%",
    marginBottom: 16,
  },
  version: {
    alignSelf: "center",
  },
});

export default DrawerContent;
