import React, { useMemo } from "react";
import { StyleSheet, View, Platform } from "react-native";

import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

import { useColors, useComposeStyles } from "~riata/hooks";
import { getFormattedDateTime } from "~riata/utils/date";

import { BulletinDateBannerComponent } from "./types";


const BulletinDateBanner: BulletinDateBannerComponent = ({
  onPress,
  onCancel,
  date,
  icon = "upcoming_time",
  containerStyles = {},
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(
    Platform.OS === "web" ? styles.containerWeb : styles.container,
    containerStyles || {}
  );

  const formattedDate = useMemo(() => getFormattedDateTime(date), [date]);

  const textColor =
    icon === "upcoming_time"
      ? newColors.text.blue.main
      : newColors.text.orange.main;

  if (!date) {
    return null;
  }

  return (
    <View style={composedContainerStyles}>
      <Row align="center" justify="between">
        <Button onPress={onPress}>
          <Row align="center" style={styles.labelWrapper}>
            <CustomIcon name={icon} size={20} color={textColor} />
            <Text color={textColor}>{formattedDate}</Text>
          </Row>
        </Button>
        <Button onPress={onCancel}>
          <CustomIcon name="close" size={20} color={textColor} />
        </Button>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  containerWeb: {
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 11,
  },
  container: {},
  labelWrapper: {
    gap: 8,
  },
});

export default BulletinDateBanner;
