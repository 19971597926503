import moment from "moment";
import { Bulletin } from "~riata/generated/graphql";

const hasHashtags = (bulletin: Bulletin) => {
  return bulletin.hashtags.edges.length > 0;
};

const getCalendarSpec = (date: moment.MomentInput | null | undefined) => {
  if (!date) {
    return null;
  }
  return moment(date).calendar(null, {
    nextWeek: "dddd",
    nextDay: "[tomorrow]",
    sameDay: "[today]",
    lastDay: "[yesterday]",
    lastWeek: "[last_week]",
    sameElse: "[dd/mm/yyyy]",
  });
};

export { hasHashtags, getCalendarSpec };
