import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { QueryResult } from "@apollo/react-common";
import { BulletinInbox, BulletinInboxFilter } from "~riata/generated/graphql";

const GET_BULLETIN_INBOXES = gql`
  query GetBulletinInboxes($filter: BulletinInboxFilter) {
    getBulletinInboxes(filter: $filter) {
      edges {
        node {
          id
          name
          members {
            total
          }
        }
      }
    }
  }
`;

type InboxList = Array<BulletinInbox>;

type GetBulletinInboxFinderResponse = {
  list: InboxList;
} & QueryResult;

export const useGetBulletinInboxes = ({
  filter,
}: {
  filter?: BulletinInboxFilter;
}): GetBulletinInboxFinderResponse => {
  const { data, ...response } = useQuery(GET_BULLETIN_INBOXES, {
    variables: { filter },
    fetchPolicy: "network-only",
  });

  let list = [];
  if (data && data.getBulletinInboxes) {
    list = data.getBulletinInboxes.edges.map((a) => a.node);
  }
  return { data, list, ...response };
};
