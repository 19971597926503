import React, { useMemo } from "react";
import { BulletinComposeAttachmentsComponent } from "./types";
import {
  AttachmentThumbnailList,
  DragAndDropAttachment,
} from "~riata/components/attachments";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import { TextInput } from "~riata/components/inputs";
import { isWideDisplay } from "~riata/theme/common";
import { Separator } from "~riata/components/misc";

const BulletinComposeAttachments: BulletinComposeAttachmentsComponent = ({
  composeState,
  composeActions,
  onNavigateToAttachment,
}) => {
  const { width } = useWindowDimensions();

  const showAttachment = useMemo(() => {
    return isWideDisplay(width) || composeState.attachments.length > 0;
  }, [composeState.attachments.length, width]);

  const MemoAttachmentListView = useMemo(() => {
    return (
      showAttachment && (
        <View testID="BulletinComposeAttachments-AttachmentsList">
          <AttachmentThumbnailList
            style={{ margin: 12 }}
            attachments={composeState.attachments}
            onAddPressed={onNavigateToAttachment}
            onLongPress={(attachment) =>
              composeActions.removeAttachment(attachment.id)
            }
            thumbnail={true}
          />
          <Separator />
        </View>
      )
    );
  }, [
    composeActions,
    onNavigateToAttachment,
    showAttachment,
    composeState.attachments,
  ]);

  return (
    <DragAndDropAttachment
      fillSpace
      canAttach={composeState.payload.allowVisibility}
    >
      <>
        {MemoAttachmentListView}
        <View style={styles.bodyInputContainer}>
          <TextInput
            testID="BulletinComposeAttachments-BodyInput"
            fill={Platform.OS === "web"}
            onChangeText={composeActions.setContent}
            multiline
            placeholder="Body..."
            inputStyles={styles.input}
            value={composeState.payload.content}
          />
        </View>
      </>
    </DragAndDropAttachment>
  );
};

const styles = StyleSheet.create({
  input: {
    fontSize: 16,
    fontFamily: "HelveticaNeue",
    lineHeight: 20,
  },
  bodyInputContainer: {
    flex: 1,
    paddingRight: 8,
    paddingTop: 16,
    height: "90%",
    backgroundColor: "white",
  },
});

export default BulletinComposeAttachments;
