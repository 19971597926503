import React from "react";
import { StyleSheet } from "react-native";
import { useColors } from "~riata/hooks";
import { Card } from "../layout";

import EditProfileRow from "./EditProfileRow";

// !Consider Moving to Controllers
const ChangePasswordForm = ({
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
}) => {
  const { newColors } = useColors();
  return (
    <Card rounded style={styles.container} customColor={newColors.background.main}>
      <EditProfileRow
        name="Current Password"
        containerStyles={styles.editProfileItem}
        editable
        onEdit={(text) => setCurrentPassword(text)}
        secureTextEntry={true}
      />
      <EditProfileRow
        name="New Password"
        containerStyles={styles.editProfileItem}
        editable
        onEdit={(text) => setNewPassword(text)}
        secureTextEntry={true}
      />
      <EditProfileRow
        name="Confirm Password"
        containerStyles={styles.editProfileItem}
        editable
        onEdit={(text) => setConfirmNewPassword(text)}
        secureTextEntry={true}
      />
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 16,
    overflow: "hidden",
  },
  editProfileItem: {
    marginHorizontal: 16,
    marginVertical: 8,
  },
});

export default ChangePasswordForm;
