import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";

import { Button, TextInput } from "./inputs";
import { CloseIcon, CustomIcon } from "./icons";
import { debounce } from "debounce";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Row } from "./layout";

const SearchBar = ({
  placeholder,
  onSearch,
  debounceTime = 200,
  containerStyles = {},
  inputStyles = {},
  showSearchIcon = false,
}) => {
  const [state, setState] = useState("");
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(styles.container, {
    shadowColor: newColors.shadow.main,
    backgroundColor: newColors.surface.main,
    ...containerStyles,
  });

  const composedInputStyles = useComposeStyles(styles.input, {
    marginLeft: showSearchIcon ? 32 : 0,
    marginRight: 24,
    ...inputStyles,
  });

  const changeText = useCallback(
    (text) => {
      setState(text);
      debounce(onSearch, debounceTime)(text);
    },
    [setState, onSearch, debounceTime]
  );

  return (
    <Row style={composedContainerStyles} align="center">
      {showSearchIcon && (
        <View style={styles.startIconContainer}>
          <CustomIcon
            name="search"
            size={20}
            color={newColors.text.placeHolder.main}
          />
        </View>
      )}

      <TextInput
        value={state}
        onChangeText={changeText}
        placeholder={placeholder}
        inputStyles={composedInputStyles}
        containerStyles={styles.inputContainer}
      />

      {state.length > 0 ? (
        <View style={styles.iconContainer}>
          <Button onPress={() => changeText("")}>
            <CloseIcon size={20} />
          </Button>
        </View>
      ) : null}
    </Row>
  );
};

const styles = StyleSheet.create({
  input: {
    height: 40,
  },
  inputContainer: {
    flex: 1,
    borderRadius: 12,
  },
  container: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1,
    elevation: 2,
    shadowColor: "transparent",
    backgroundColor: "transparent",
    borderRadius: 12,
  },
  startIconContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 40,
    justifyContent: "center",
    paddingLeft: 12,
  },
  iconContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: 40,
    justifyContent: "center",
    paddingRight: 12,
  },
});

export default SearchBar;
