import React from "react";
import { StyleSheet, View } from "react-native";

import { navigate } from "~riata/navigation";
import { Button } from "../inputs/Button";
import { Text } from "../typography";
import { useColors } from "~riata/hooks";

const MessageListItemRepliesButton = ({ message }) => {
  const { newColors } = useColors();

  return (
    <View style={styles.container}>
      <Button
        onPress={() => {
          navigate("MessageDetail", { message: message.id });
        }}
      >
        <Text color={newColors.text.caption.main} variant="footnote">
          View All Replies
        </Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    marginRight: 10,
  },
});

export default MessageListItemRepliesButton;
