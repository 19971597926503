import React, { useMemo } from "react";
import { StyleSheet } from "react-native";

import { CustomIcon } from "~riata/components/icons";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

import { useColors } from "~riata/hooks";
import { getFormattedDateTime } from "~riata/utils/date";

import { BulletinDateLabelComponent } from "./types";

const BulletinDateLabel: BulletinDateLabelComponent = ({
  date,
  color,
  containerStyles = {},
}) => {
  const { newColors } = useColors();
  const textColor = color || newColors.text.regular.main;

  const formattedDateTime = useMemo(
    () => getFormattedDateTime(date.toString()),
    [date]
  );

  if (!date) {
    return null;
  }

  return (
    <Row align="center" style={[styles.container, containerStyles]}>
      <CustomIcon name="upcoming_time" size={16} color={textColor} />
      <Text variant="preview" color={textColor}>
        {formattedDateTime}
      </Text>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
});

export default BulletinDateLabel;
