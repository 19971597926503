import { useCallback } from "react";
import { prependIfNecessary } from "../edges";

// FIXME This needs tests
const useFetchPrevious = (
  type,
  { loading, hasPreviousPage, variables, fetchMore }
) =>
  useCallback(() => {
    if (loading || hasPreviousPage === false || !fetchMore) {
      return;
    }
    const updateQuery = (prev, { fetchMoreResult }) =>
      prependIfNecessary(type, prev, fetchMoreResult);
    return fetchMore({ variables, updateQuery });
  }, [type, loading, hasPreviousPage, variables, fetchMore]);

export default useFetchPrevious;
