import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Integration } from "~riata/generated/graphql";
import { fragments } from "~riata/graphql/fragments";

const query = gql`
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      ...IntegrationContent
    }
  }
  ${fragments.Integration.Content}
`;

export const useGetIntegration = (id) => {
  const { data, ...response } = useQuery(query, { variables: { id } });
  const integration: Integration | null = data?.getIntegration || null;
  return { data, integration, ...response };
};
