import { Integration } from "~riata/generated/graphql";
import { useGetIntegrationsAssociatedUrls } from "../GetIntegrations";

export const useGetAssociatedUrls = () => {
  const { integrations } = useGetIntegrationsAssociatedUrls();
  return integrations.reduce(
    (acc, a: Integration) =>
      a.associatedUrls
        ? [
            ...acc,
            ...a.associatedUrls.map((url) => {
              return { id: a.id, url };
            }),
          ]
        : acc,
    []
  );
};
