import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppLoading } from "~riata/components/AppLoading";
import { useClient } from "~riata/hooks";

export const DataProvider = ({ children }) => {
  const { client } = useClient();
  console.debug(`DataProvider, loaded: ${!!!client}`);

  if (client === false) {
    console.debug("DataProvider > AppLoading");
    return <AppLoading />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
