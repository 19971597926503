import React from "react";

import {
  BaseRodioTheme,
  ApplicationLock,
  CompanySpecificTheme,
  CredentialsProvider,
  DataProvider,
  FirebaseMessaging,
  RealTimeMessaging,
  NotificationsListener,
  Riata,
} from "~riata/controllers/base";
import { initSentry } from "~riata/utils/sentry";

import moment from "moment";
import OffDutyProvider from "./hooks/useOffDuty/OffDutyContext";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { FeatureFlagsProvider } from "./contexts";

// TODO Should this be configurable at build?
// console.disableYellowBox = true;

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1s",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmo",
    y: "1y",
    yy: "%dy",
  },
});

const App = () => {
  return (
    <NotificationsListener>
      <BaseRodioTheme>
        <CredentialsProvider>
          <DataProvider>
            <FeatureFlagsProvider>
              <ApplicationLock>
                <CompanySpecificTheme>
                  <RealTimeMessaging>
                    <FirebaseMessaging>
                      <OffDutyProvider>
                        <GestureHandlerRootView style={{ flex: 1 }}>
                          <Riata />
                        </GestureHandlerRootView>
                      </OffDutyProvider>
                    </FirebaseMessaging>
                  </RealTimeMessaging>
                </CompanySpecificTheme>
              </ApplicationLock>
            </FeatureFlagsProvider>
          </DataProvider>
        </CredentialsProvider>
      </BaseRodioTheme>
    </NotificationsListener>
  );
};

const WrappedApp = initSentry(App);

export default WrappedApp;
