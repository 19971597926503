import React, { useEffect, useMemo, useState } from "react";
import { BackHandler, Platform, StyleSheet, View } from "react-native";

import {
  Backdrop,
  Button,
  Hand,
  OffDutyHeader,
  OffDutyPicker,
  OffDutyPickerSheet,
  Row,
  Text,
} from "~riata/components";
import { useColors, useComposeStyles, useOffDuty } from "~riata/hooks";

import {
  ACK_BUTTON_TITLE,
  ADMIN_MESSAGE,
  MODAL_MESSAGE,
  MODAL_TITLE,
  NOTIFICATIONS_MESSAGE,
} from "./constants";
import { useIsFocused } from "@react-navigation/core";
import { useAcknowledgeOffDuty, useGetCurrentUser } from "~riata/graphql";
import { shouldDisableNotification } from "./utils";
import { Sentry } from "~riata/utils/sentry";
import { getOffDutyNotificationsStatus } from "~riata/utils/offDuty";

const OffDutyModal = ({ navigation }) => {
  const { user, refetch } = useGetCurrentUser();
  const { execute } = useAcknowledgeOffDuty();

  const [isWorking, setIsWorking] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [pickerIsVisible, setPickerIsVisible] = useState(false);

  const { newColors } = useColors();
  const isFocused = useIsFocused();
  const { showBanner, hideBanner } = useOffDuty();

  const extendNotifications = user?.extendNotifications || null;

  const { canEnable, endTime } = useMemo(
    () =>
      getOffDutyNotificationsStatus(
        extendNotifications?.startTime,
        extendNotifications?.hours
      ),
    [extendNotifications]
  );

  const userCanAcknowledge = user.company?.offDutyDismiss || false;
  const canEnableNotifications = canEnable && userCanAcknowledge;
  const notificationsEnabled = extendNotifications !== null;

  const acknowledge = () => {
    if (notificationsEnabled) {
      showBanner("offDuty");
      navigation.goBack();
      return;
    }

    if (!isWorking && userCanAcknowledge) {
      setIsWorking(true);

      if (canEnableNotifications) {
        execute({ userId: user.id, extendedHours: selectedValue })
          .then(() => {
            showBanner("offDuty");
            navigation.goBack();
          })
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          });
      } else {
        showBanner("offDuty");
        navigation.goBack();
      }
    }
  };

  const modalSubMessage = canEnableNotifications
    ? NOTIFICATIONS_MESSAGE
    : ADMIN_MESSAGE;

  const sheetStyles = useComposeStyles(styles.sheet, {
    backgroundColor: newColors.surface.main,
  });

  const primaryButtonStyles = useComposeStyles(styles.primaryButton, {
    backgroundColor: newColors.button.primary.main,
  });

  useEffect(() => {
    if (isFocused) {
      hideBanner();
      setIsWorking(false);
    }
    // Hide banner might cause unwanted side effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    const shouldDisableNotifications = shouldDisableNotification(
      extendNotifications?.startTime,
      extendNotifications?.hours
    );
    if (shouldDisableNotifications && notificationsEnabled) {
      if (!isWorking) {
        setIsWorking(true);
        execute({ userId: user.id, extendedHours: 0 })
          .then(() => refetch())
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error);
          })
          .finally(() => setIsWorking(false));
      }
    }
    // We want to notify the backend only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onBackPress = () => {
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      onBackPress
    );
    return () => backHandler.remove();
  }, []);

  return (
    <View style={styles.container}>
      <Backdrop />
      <View style={styles.pickerSheetContainer}>
        <OffDutyPickerSheet
          isVisible={pickerIsVisible}
          onDone={() => setPickerIsVisible(false)}
          onValueChange={(itemValue) => setSelectedValue(itemValue)}
          selectedValue={selectedValue}
        />
      </View>
      <View style={sheetStyles}>
        <OffDutyHeader />
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Text variant="title3">{MODAL_TITLE}</Text>
            <Hand />
            <Text variant="subhead" style={styles.message}>
              {MODAL_MESSAGE}
            </Text>
            <Text variant="subhead" style={styles.subMessage}>
              {modalSubMessage}
            </Text>
          </View>
          {canEnableNotifications ? (
            <Row
              style={styles.spacedRow}
              justify={Platform.OS === "web" ? "center" : "between"}
            >
              <Text fontWeight="bold" style={{ paddingRight: 8 }}>
                Enable notifications for:
              </Text>
              <OffDutyPicker
                selectedValue={selectedValue}
                onValueChange={(itemValue) => setSelectedValue(itemValue)}
                onShowOptions={() => setPickerIsVisible(true)}
              />
            </Row>
          ) : (
            <>
              {notificationsEnabled ? (
                <Row style={styles.spacedRow} justify="center">
                  <Text fontWeight="bold" style={{ paddingRight: 8 }}>
                    Notifications Enabled Until:
                  </Text>
                  <Text>{endTime}</Text>
                </Row>
              ) : null}
            </>
          )}
          {userCanAcknowledge && (
            <Row justify="right" style={styles.buttonContainer}>
              <Button style={primaryButtonStyles} onPress={acknowledge}>
                <Text variant="subhead" color={newColors.button.primary.on}>
                  {notificationsEnabled ? "Dismiss" : ACK_BUTTON_TITLE}
                </Text>
              </Button>
            </Row>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  sheet: {
    borderRadius: 12,
    height: "70%",
    width: "90%",
    backgroundColor: "transparent",
  },
  contentWrapper: {
    height: "100%",
    padding: 16,
  },
  content: {
    marginTop: 84,
    alignItems: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 32,
  },
  message: {
    textAlign: "center",
  },
  subMessage: {
    textAlign: "center",
    marginTop: 16,
  },
  buttonContainer: {
    marginTop: "auto",
  },
  spacedRow: {
    marginBottom: 32,
  },
  primaryButton: {
    backgroundColor: "transparent",
    minWidth: 150,
    height: 50,
    flex: Platform.OS === "web" ? 0 : 1,
    marginLeft: 16,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
  },
  pickerSheetContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 500,
  },
});

export default OffDutyModal;
