import React from "react";
import { StyleSheet, View } from "react-native";

import { CustomIcon } from "./icons";
import {
  useColors,
  useComposeStyles,
  useNotImplementedToast,
} from "~riata/hooks";

import { Button } from "./inputs/Button";
import { Row } from "./layout";
import { Text } from "./typography";

const ActionSheetItem = ({ icon, title, action, danger = false }) => {
  const {
    visible: warningVisible,
    toggleVisible: toggleWarningVisible,
    NotImplementedToast,
  } = useNotImplementedToast();

  const { newColors } = useColors();

  const iconStyles = useComposeStyles(styles.icon, {
    color: danger ? newColors.icon.orange.main : newColors.icon.primary.main,
  });

  const textColor = danger
    ? newColors.text.orange.main
    : newColors.text.regular.main;

  return (
    <Button
      style={styles.button}
      onPress={action ? action : toggleWarningVisible}
    >
      <Row style={styles.row}>
        <View style={styles.iconContainer}>
          <CustomIcon name={icon} size={16} style={iconStyles} />
        </View>
        <Text variant="callout" color={textColor}>
          {title}
        </Text>
      </Row>
      {warningVisible && <NotImplementedToast />}
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 50,
  },
  row: {
    paddingHorizontal: 16,
  },
  icon: {
    paddingRight: 0,
    marginRight: 0,
  },
  iconContainer: {
    marginRight: 16,
  },
});

export default ActionSheetItem;
