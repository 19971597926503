import React, { useMemo } from "react";
import { TouchableOpacity } from "react-native";
import { ButtonComponent } from "./types";
import { useIsFocused } from "@react-navigation/core";

const Button: ButtonComponent = ({
  children,
  disabled = false,
  focusedPress = false,
  onPress,
  ...props
}) => {
  const isFocused = useIsFocused();

  const isDisabled = useMemo(() => {
    return disabled || (focusedPress && !isFocused);
  }, [disabled, focusedPress, isFocused]);

  const delayPressOut = useMemo(() => {
    return focusedPress ? 250 : 0;
  }, [focusedPress]);

  return (
    <TouchableOpacity
      {...props}
      disabled={isDisabled}
      onPress={onPress}
      delayPressOut={delayPressOut}
    >
      {children}
    </TouchableOpacity>
  );
};

export default Button;
