import React, { useCallback, useEffect } from "react";
import { BulletinComposeForm, RodioLoader } from "~riata/components";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { generateGoTo } from "../helpers";
import { useAttachmentActionSheetLink } from "~riata/links";
import { StackActions } from "@react-navigation/core";
import {
  useBulletinCompose,
  useFeatureFlags,
  useProcessAttachment,
  useSaveBulletinAsDraft,
} from "~riata/hooks";
import {
  useArchiveBulletin,
  useGetBulletin,
  useUpdateBulletin,
} from "~riata/graphql";
import { BulletinComposeState } from "~riata/contexts/BulletinCompose/types";
import { BulletinScreen } from "../enums";

const BulletinDraftScreen = ({ navigation, route }) => {
  const {
    params: { id },
  } = route;

  const { bulletin, loading } = useGetBulletin(id);
  const {
    draft: { state, actions },
  } = useBulletinCompose();
  const { navigateTo } = useDrawerNavigation();
  const { execute: updateBulletin } = useUpdateBulletin();
  const { execute: archiveBulletin } = useArchiveBulletin();
  const { flags } = useFeatureFlags();

  useProcessAttachment({ actions }, "bulletinCompose");

  const action = StackActions.push("AttachmentActionSheet", {
    onAddAttachment: "onAddAttachment",
  });

  const { onPress: goToAttachmentActionSheet } = useAttachmentActionSheetLink({
    action: action,
  });

  useSaveBulletinAsDraft({
    bulletin: {
      ...state.payload,
      attachments: state.attachments?.map((attachment) => attachment.id),
      recipients: state.recipientInboxes || [],
    },
    screen: BulletinScreen.Draft,
    navigation,
  });

  const goToScheduleDateSelection = generateGoTo(
    state,
    actions,
    navigation.navigate,
    "scheduledAt",
    true
  );

  const goToExpiresDateSelection = generateGoTo(
    state,
    actions,
    navigation.navigate,
    "expires",
    true
  );

  const saveBulletin = useCallback(() => {
    try {
      updateBulletin(
        {
          id: bulletin.id,
          ...state.payload,
          attachments: state.attachments?.map((attachment) => attachment.id),
          recipientInboxes: bulletin.recipientInboxes || [],
        },
        flags.expiringBulletins
      );

      navigateTo("Bulletins", { filter: "Draft" });
    } catch {
      return;
    }
  }, [bulletin, state, flags, updateBulletin, navigateTo]);

  const deleteBulletin = useCallback(() => {
    archiveBulletin(bulletin.id);
    navigateTo("Bulletins", { filter: "Draft" });
  }, [bulletin, navigateTo, archiveBulletin]);

  useEffect(() => {
    if (bulletin && !loading) {
      actions.setPreviousValues(bulletin as unknown as BulletinComposeState);
    }
  }, [bulletin, loading, actions]);

  if (!bulletin || loading) {
    return <RodioLoader />;
  }

  return (
    <BulletinComposeForm
      composeActions={actions}
      composeState={state}
      navigate={navigation.navigate}
      onBack={saveBulletin}
      onSave={saveBulletin}
      onDelete={deleteBulletin}
      onNavigateToAttachment={goToAttachmentActionSheet}
      onNavigateToExpire={goToExpiresDateSelection}
      onNavigateToSchedule={goToScheduleDateSelection}
    />
  );
};

export default BulletinDraftScreen;
