import React, { FC, useEffect, useState } from "react";
import { WebView } from "react-native-webview";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import {
  AppHeader,
  Row,
  RodioLoader,
  SafeAreaView,
  HeaderActionButton,
  Text,
  Button,
} from "~riata/components";
import { BackArrow, ForwardArrow, RefreshIcon } from "~riata/components/icons";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useGetIntegration } from "~riata/graphql";
import { useGetRedirectUrl } from "./useGetRedirectUrl";
import { useRiataInjection } from "~riata/hooks/useRiataInjection";
import { ApolloError } from "apollo-client";
import { HEADER_ICON_SIZE } from "~riata/theme";

type LoadingErrorGuardProps = {
  isLoading: boolean;
  error: any;
  hasInitialized: boolean;
  children: React.ReactNode;
};

type ErrorProps = {
  error: ApolloError | string;
};

type ErrorComponent = (props: ErrorProps) => JSX.Element;

const Loading = () => {
  return (
    <View style={styles.feedbackContainer}>
      <RodioLoader large />
    </View>
  );
};

const Error: ErrorComponent = ({ error }) => {
  const { newColors } = useColors();

  let errorMessage = "";

  if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = error.message;
  }

  return (
    <View style={styles.feedbackContainer}>
      <Row justify="center">
        <Text variant="headline" color={newColors.text.error.main}>
          Something went wrong
        </Text>
      </Row>
      <Row justify="center" style={styles.errorContainer}>
        <Text variant="footnote">{errorMessage}</Text>
      </Row>
    </View>
  );
};

const LoadingErrorGuard: FC<LoadingErrorGuardProps> = ({
  isLoading,
  error,
  hasInitialized,
  children,
}) => {
  if (isLoading || !hasInitialized) {
    console.log("Showing Loading Guard");
    return <Loading />;
  }

  if (!hasInitialized && error) {
    return <Error error={error} />;
  }

  return <>{children}</>;
};

const IntegrationDetail = ({
  navigation,
  route: {
    params: { id, path = "" },
  },
}) => {
  const { loading, integration } = useGetIntegration(id);
  const { newColors } = useColors();
  const { width, height } = useWindowDimensions();
  const [showFooter, setShowFooter] = useState(false);

  const { integrationId, redirectUrl, isBusy, redirectError } =
    useGetRedirectUrl({
      id,
      path,
    });

  const { actions, handleMessageDispatch, ref, state, toInject } =
    useRiataInjection({ navigation, defaultBackScreen: "Integrations" });

  const isLoading = loading || isBusy || !redirectUrl;
  const hasInitialized = integrationId === id;

  const headerBackgroundColor = integration?.color
    ? integration.color
    : newColors.header.main;

  const webViewStyle = useComposeStyles(styles.webView, {
    width,
    height,
    backgroundColor: newColors.surface.main,
  });

  useEffect(() => {
    setShowFooter(false);
  }, [id]);

  return (
    <SafeAreaView>
      <AppHeader
        style={{
          backgroundColor: headerBackgroundColor,
        }}
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
            iconSize={HEADER_ICON_SIZE}
          />
        }
        title={state.title ?? integration?.name ?? "…"}
        right={
          state.canGoForward && (
            <HeaderActionButton
              variant="forward"
              onPress={actions.handleForward}
            />
          )
        }
      />
      <LoadingErrorGuard
        isLoading={isLoading}
        hasInitialized={hasInitialized}
        error={redirectError}
      >
        <WebView
          key={`integration-detail-${id}-${path}`}
          cacheEnabled={false}
          geolocationEnabled
          injectedJavaScriptBeforeContentLoaded={toInject}
          ref={ref}
          style={webViewStyle}
          allowsBackForwardNavigationGestures
          onMessage={handleMessageDispatch}
          onNavigationStateChange={actions.onWebViewNavigationStateChange}
          source={{ uri: redirectUrl }}
          onLoadStart={() => setShowFooter(false)}
          onLoadEnd={() => setShowFooter(true)}
          renderLoading={() => <Loading />}
          renderError={(errorDomain, errorCode, errorDesc) => {
            const errorMsg = `${errorDomain}\nCode: ${errorCode}\n${errorDesc}`;
            return <Error error={errorMsg} />;
          }}
          webviewDebuggingEnabled={true}
        />
        {hasInitialized && showFooter && (
          <Row justify="between" align="center" style={styles.footer}>
            <Row>
              <Button onPress={actions.handleBack}>
                <BackArrow size={24} />
              </Button>
              <Button onPress={actions.handleForward}>
                <ForwardArrow size={24} />
              </Button>
            </Row>
            <Button onPress={actions.reload}>
              <RefreshIcon size={24} />
            </Button>
          </Row>
        )}
      </LoadingErrorGuard>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  webView: {
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
  },
  footer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: Platform.OS === "android" ? 16 : 0,
  },
  feedbackContainer: {
    height: "100%",
    width: "100%",
  },
  errorContainer: {
    padding: 16,
  },
});

export default IntegrationDetail;
