import React, { useCallback, useMemo } from "react";
import { useWindowDimensions, View } from "react-native";
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from "react-native-gesture-handler";
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Row } from "../Row";

enum SheetStatus {
  Closed = 1,
  Open = 0,
}

type BottomSheetProps = {
  onCloseHandler: () => void;
  snapPoints: Array<number>;
  children: React.ReactNode;
  enabled?: boolean;
  showDragBar?: boolean;
};

type BottomSheetComponent = (props: BottomSheetProps) => JSX.Element;

/**
 * Bottom Sheet that will snap to defined values and close when reaching bottom
 * threshold.
 * @prop snapPoints: Array<number between 0-1> will determine screen snapPoints
 * @prop onCloseHandler: will be called when exceeding bottom threshold
 * @returns
 */
const BottomSheet: BottomSheetComponent = ({
  enabled = true,
  children,
  snapPoints,
  showDragBar = true,
  onCloseHandler,
}) => {
  const { newColors } = useColors();
  const { height: windowHeight } = useWindowDimensions();
  const yPosition = useSharedValue(0);
  const sheetStatus = useSharedValue(SheetStatus.Open);
  const currentSnapPoint = useSharedValue(0);

  const measurements = useMemo(() => {
    const sortedSnapPoints = snapPoints.sort((a, b) => a - b);

    /**
     * Smallest value in the array. This value will determine the initial height of the
     * sheet and the 0 point for upwards and downwards movement.
     */
    const offset = sortedSnapPoints[0];

    /**
     * Biggest value in the array. This value will determine the maximum height
     * of the sheet.
     */
    const sheetHeight = sortedSnapPoints[snapPoints.length - 1] * 100;
    const sheetBottom = offset * 100;

    const windowSnapPoints = sortedSnapPoints.map((point) => {
      if (point === offset) {
        return 0;
      }

      // Bigger values will be upward snapPoints
      return -(windowHeight * (point - offset));
    });

    return {
      sheetHeight: `${sheetHeight}%`,
      sheetBottom: `-${sheetHeight - sheetBottom}%`,
      windowSnapPoints,
      closingPoint: offset * windowHeight,
      limit: windowSnapPoints[windowSnapPoints.length - 1],
    };
  }, [snapPoints, windowHeight]);

  const onEndHandler = useCallback(() => {
    if (sheetStatus.value === SheetStatus.Closed) {
      onCloseHandler();
    }
  }, [sheetStatus, onCloseHandler]);

  const panGestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { startY: number; offsetY: number }
  >({
    onStart: (_, ctx) => {
      ctx.startY = yPosition.value;
    },
    onActive: (event, ctx) => {
      // TODO: Calculate deceleration in upper limit
      if (event.translationY < measurements.limit - currentSnapPoint.value) {
        // Drag Up
        yPosition.value = measurements.limit; // Hard Limit
      } else {
        // Drag Down
        yPosition.value = ctx.startY + event.translationY;
      }
    },
    onEnd: (event) => {
      const y = yPosition.value;

      let smallestSnapPoint = null;
      let animateTo = 0;

      if (y >= measurements.closingPoint / 2) {
        // Bottom threshold has been exceeded. The Sheet must close
        animateTo = measurements.closingPoint;
        sheetStatus.value = SheetStatus.Closed;
      } else {
        measurements.windowSnapPoints.forEach((point) => {
          if (point <= 0) {
            const currentPoint = Math.abs(point);
            const currentY = Math.abs(y);

            const difference = point === 0 ? currentY : currentPoint - currentY;

            if (!smallestSnapPoint) {
              smallestSnapPoint = difference;
              animateTo = point;
              return;
            }

            if (difference >= 0 && difference < smallestSnapPoint) {
              smallestSnapPoint = difference;
              animateTo = point;
            }
            sheetStatus.value = SheetStatus.Open;
          }
        });
      }

      currentSnapPoint.value = animateTo;
      yPosition.value = withSpring(animateTo, {
        velocity: event.velocityY,
        damping: 5,
        overshootClamping: true,
      });
    },
  });

  const animationStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: yPosition.value }],
      height: measurements.sheetHeight,
      bottom: measurements.sheetBottom,
      backgroundColor: newColors.surface.main,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    };
  });

  const dragBarStyles = useComposeStyles(styles.dragBar, {
    backgroundColor: newColors.separator.light,
  });

  return (
    <PanGestureHandler
      onGestureEvent={panGestureHandler}
      onEnded={onEndHandler}
      enabled={enabled}
    >
      <Animated.View style={[animationStyles]}>
        {showDragBar && (
          <Row justify="center" align="center" style={styles.dragBarContainer}>
            <View style={dragBarStyles} />
          </Row>
        )}
        {children}
      </Animated.View>
    </PanGestureHandler>
  );
};

const styles = {
  dragBarContainer: {
    paddingVertical: 8,
  },
  dragBar: {
    backgroundColor: "transparent",
    width: "10%",
    height: 4,
    borderRadius: 100,
  },
};

export default BottomSheet;
