import React, { FC, useMemo } from "react";
import { Pressable, View, StyleSheet, useWindowDimensions } from "react-native";

// Components
import { ActivityIndicator } from "~riata/components/activityIndicator";
import { CustomIcon } from "~riata/components/icons";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

// Hooks
import { useColors } from "~riata/hooks";

// Constants
import { WIDTH_FOR_PERMANENT_DRAWER } from "~riata/theme/common";
import { ICON_SIZE, VARIANTS } from "./constants";

// Types
import { HeaderActionButtonProps } from "./types";
import { HEADER_ICON_SIZE } from "~riata/theme";

const HeaderActionButton: FC<HeaderActionButtonProps> = ({
  onPress,
  variant,
  disabled = false,
  loading = false,
  iconSize = HEADER_ICON_SIZE,
}) => {
  const { newColors } = useColors();
  const { width } = useWindowDimensions();

  const { accessibilityLabel, title: variantTitle } = VARIANTS[variant];
  const { name: iconName, styles: iconStyles } = VARIANTS[variant].icon;

  const enabledColor = useMemo(() => {
    return disabled
      ? newColors.icon.primary.disabled
      : newColors.icon.primary.main;
  }, [disabled, newColors]);

  const hideButton = useMemo(() => {
    return width >= WIDTH_FOR_PERMANENT_DRAWER;
  }, [width]);

  if (hideButton && variant === "drawer") {
    /**
     * When screen width is grater than the threshold for a permanent drawer we
     * need to hide the drawer action button
     */
    return null;
  }

  return (
    <Pressable
      testID={`headerActionButton-${variant}`}
      onPress={onPress}
      disabled={disabled || loading}
      hitSlop={8}
      accessibilityLabel={accessibilityLabel}
    >
      <Row align="center" justify="center" style={styles.container}>
        {loading && <ActivityIndicator />}
        {iconName && !loading && (
          <CustomIcon
            size={iconSize}
            name={iconName}
            color={enabledColor}
            style={iconStyles}
          />
        )}
        {iconName && variantTitle && <View style={styles.spaceDivider}></View>}
        {variantTitle && !loading && (
          <Text variant="body" color={enabledColor}>
            {variantTitle}
          </Text>
        )}
      </Row>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  spaceDivider: {
    width: 8,
  },
  container: {
    minWidth: ICON_SIZE,
  },
});

export { HeaderActionButton };
