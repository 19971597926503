import React from "react";
import Color from "color";
import {
  ActivityIndicator as RNActivityIndicator,
  ActivityIndicatorProps as RNActivityIndicatorProps,
} from "react-native";

import { useColors } from "~riata/hooks";

export interface ActivityIndicatorProps extends RNActivityIndicatorProps {
  baseColor?: Color;
}

export const ActivityIndicator = ({
  baseColor,
  color,
  ...props
}: ActivityIndicatorProps) => {
  if (!color && baseColor) {
    color =
      baseColor.contrast(baseColor.grayscale()) > 1.1
        ? baseColor.hex()
        : baseColor.darken(0.25).hex();
  }
  return <RNActivityIndicator color={color} {...props} />;
};

export const LightIndicator = (props) => {
  const { colors } = useColors();

  return <ActivityIndicator color={colors.indicator.light.main} {...props} />;
};

export const DarkIndicator = (props) => {
  const { colors } = useColors();

  return <ActivityIndicator color={colors.indicator.dark.main} {...props} />;
};
