import React from "react";
import { StyleSheet } from "react-native";
import _ from "lodash";

import { TwitterAttachmentView, AttachmentThumbnailList } from "../attachments";
import { Message } from "~riata/generated/graphql";

type MessageListItemAttachmentListProps = {
  message: Message;
};

export const MessageListItemAttachmentList = ({
  message,
}: MessageListItemAttachmentListProps) => {
  if (message?.attachments?.edges?.length === 0) {
    return null;
  }
  const [tweetAttachments, otherAttachments] = _.partition(
    message?.attachments?.edges,
    (edge) => TwitterAttachmentView.canDisplay(edge.node)
  );
  return (
    <>
      {tweetAttachments &&
        tweetAttachments.map((edge) => (
          <TwitterAttachmentView key={edge.node.id} attachment={edge.node} />
        ))}
      {otherAttachments && (
        <AttachmentThumbnailList
          style={styles.attachments}
          parent={message}
          attachments={otherAttachments.map((edge) => edge.node)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  attachments: {
    marginTop: 6,
    marginBottom: 6,
  },
});

export default MessageListItemAttachmentList;
