import React from "react";
import { StyleSheet } from "react-native";

import { Text } from "../typography";
import { Row } from "../layout";

const IntegrationSectionHeader = ({ title }) => (
  <Row style={styles.container}>
    <Text variant="title3">{title}</Text>
  </Row>
);

const styles = StyleSheet.create({
  container: {
    padding: 12,
  },
});

export default IntegrationSectionHeader;
