import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { PageInfo } from "~riata/generated/graphql";
import { fragments } from "../fragments";

const NULL_PAGE_INFO: PageInfo = {
  startCursor: null,
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const GET_COMMENTS = gql`
  query GetComments($id: ID!, $last: Int, $before: String) {
    node(id: $id) {
      ... on Bulletin {
        id
        comments(last: $last, before: $before) {
          pageInfo {
            startCursor
            hasPreviousPage
          }
          edges {
            node {
              ...CommentContent
            }
          }
        }
      }
    }
  }
  ${fragments.Comment.Content}
`;

export const useGetComments = (id) => {
  const variables = { id, last: 20, before: null };
  const { data, loading, error, fetchMore } = useQuery(GET_COMMENTS, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  const pageInfo = data?.node?.comments?.pageInfo ?? NULL_PAGE_INFO;
  const { hasPreviousPage } = pageInfo ?? NULL_PAGE_INFO;
  const comments =
    data?.node?.comments?.edges
      ?.slice()
      .reverse()
      .map((edge) => edge.node) ?? [];

  const updateQuery = (prev, { fetchMoreResult }) => {
    console.log("fetching more comments");
    var updatedComments = {
      __typename: prev.node.comments.__typename,
      pageInfo: fetchMoreResult.node.comments.pageInfo,
      edges: [
        ...fetchMoreResult.node.comments.edges,
        ...prev.node.comments.edges,
      ],
    };
    var result = { ...prev, node: { ...prev.node, comments: updatedComments } };
    return result;
  };

  const fetchPrevious = () => {
    fetchMore({
      variables: { ...variables, before: pageInfo.startCursor },
      updateQuery,
    });
  };

  return {
    comments,
    loading,
    error,
    fetchPrevious,
    hasPreviousPage,
  };
};
