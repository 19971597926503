// Default timeout is set to 30 seconds
async function fetchWithTimeout(
  uri,
  options = {},
  timeout = 30000
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("Request timed out."));
    }, timeout);
    fetch(uri, options).then(
      (response) => {
        clearTimeout(timer);
        resolve(response);
      },
      (err) => {
        clearTimeout(timer);
        reject(err);
      }
    );
  });
}

export { fetchWithTimeout };
