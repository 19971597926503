import React from "react";
import emoji from "node-emoji";
import { StyleSheet } from "react-native";

import { useAddReaction, useRemoveReaction } from "~riata/graphql";

import { Button } from "~riata/components/inputs";
import { Text } from "~riata/components/typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useLinkTo } from "@react-navigation/native";

const MessageListItemReactionItem = ({ message, reaction }) => {
  const { execute: addReaction } = useAddReaction({ message });
  const { execute: removeReaction } = useRemoveReaction({ message });
  const linkTo = useLinkTo();

  const { newColors } = useColors();

  const textColor = reaction.userSent
    ? newColors.reaction.userSent.on
    : newColors.reaction.default.on;

  const onPress = () =>
    (reaction.userSent ? removeReaction : addReaction)(reaction);

  const buttonStyles = useComposeStyles(styles.button, {
    backgroundColor: reaction.userSent
      ? newColors.reaction.userSent.main
      : newColors.reaction.default.main,
  });

  return (
    <Button
      onPress={onPress}
      style={buttonStyles}
      onLongPress={() => {
        linkTo(
          `/channel/${message.channel.id}/${message.id}/reaction/${reaction.name}`
        );
      }}
    >
      <Text variant="caption1" fontWeight="bold" color={textColor}>
        {emoji.emojify(`:${reaction.name}:`)} {reaction.total}
      </Text>
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    justifyContent: "flex-start",
    marginRight: 4,
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
});

export default MessageListItemReactionItem;
