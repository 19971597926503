import React, { useState, useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import _ from "lodash";

import {
  SafeAreaView,
  AppHeader,
  Body,
  ChangePasswordForm,
  Row,
  Text,
  HeaderActionButton,
} from "~riata/components";
import { useChangePassword } from "~riata/graphql";
import { Sentry } from "~riata/utils/sentry";
import { useColors, useComposeStyles } from "~riata/hooks";

const DEFAULT_STATE = {
  editedFields: {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  },
  loading: false,
  errorMessage: "",
};

enum ERRORS {
  TOO_SHORT = "This password is too short. It must contain at least 8 characters.\n",
  ALL_NUMBERS = "This password is entirely numeric.\n",
  DO_NOT_MATCH = "The passwords do not match.\n",
}
const validatePassword = ({ newPassword, confirmNewPassword }) => {
  return (
    passwordTooShort(newPassword) +
    passwordAllNumbers(newPassword) +
    passwordsDoNotMatch(newPassword, confirmNewPassword)
  );
};

const passwordTooShort = (password) => {
  return password.length > 0 && password.length < 8 ? ERRORS.TOO_SHORT : "";
};

const passwordAllNumbers = (password) => {
  return password.length > 0 && !isNaN(password as any)
    ? ERRORS.ALL_NUMBERS
    : "";
};

const passwordsDoNotMatch = (password, confirmPassword) => {
  return confirmPassword &&
    confirmPassword.length > 0 &&
    password !== confirmPassword
    ? ERRORS.DO_NOT_MATCH
    : "";
};

export const ChangePasswordScreen = ({ navigation }) => {
  const [state, setState] = useState(DEFAULT_STATE);

  const { execute } = useChangePassword();
  const { newColors } = useColors();

  const setCurrentPassword = useCallback(
    (currentPassword) => {
      const editedFields = { ...state.editedFields, currentPassword };

      setState({
        ...state,
        editedFields,
        errorMessage: validatePassword({
          newPassword: editedFields.newPassword,
          confirmNewPassword: editedFields.confirmNewPassword,
        }),
      });
    },
    [state]
  );

  const setNewPassword = useCallback(
    (newPassword) => {
      const editedFields = { ...state.editedFields, newPassword };
      setState({
        ...state,
        editedFields,
        errorMessage: validatePassword({
          newPassword: editedFields.newPassword,
          confirmNewPassword: editedFields.confirmNewPassword,
        }),
      });
    },
    [state]
  );

  const setConfirmNewPassword = useCallback(
    (confirmNewPassword) => {
      const editedFields = { ...state.editedFields, confirmNewPassword };
      setState({
        ...state,
        editedFields,
        errorMessage: validatePassword({
          newPassword: editedFields.newPassword,
          confirmNewPassword: editedFields.confirmNewPassword,
        }),
      });
    },
    [state]
  );

  const setErrorMessage = useCallback(
    (errorMessage) => setState({ ...state, errorMessage }),
    [state]
  );

  const setLoading = (loading) => setState({ ...state, loading });

  const onSave = () => {
    setLoading(true);
    execute({
      currentPassword: state.editedFields.currentPassword,
      newPassword: state.editedFields.newPassword,
    })
      .then((response) => {
        const { success, errors } = response.data?.changePassword;
        if (success) {
          return navigation.goBack();
        }
        if (errors.length === 0) {
          console.warn(
            "We didn't get success or any errors? Should never get here.",
            response
          );
        }

        setErrorMessage(errors.map((e) => e.messages.join("\n")).join("\n"));
      })
      .catch(Sentry.captureException);
  };

  const canSave = useMemo(() => {
    return !_.some(state.editedFields, _.isEmpty) && !state.errorMessage.length;
  }, [state.editedFields, state.errorMessage.length]);

  const wrapperStyles = useComposeStyles(styles.wrapper, {
    backgroundColor: newColors.background.main,
  });

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="back"
            onPress={() => navigation.goBack()}
          />
        }
        title="Password Change"
        right={
          canSave && (
            <HeaderActionButton
              variant="save"
              disabled={!canSave}
              loading={state.loading}
              onPress={onSave}
            />
          )
        }
      />
      <Body dismissOnTap skipKeyboardAvoiding>
        <View style={wrapperStyles}>
          <ChangePasswordForm
            setCurrentPassword={setCurrentPassword}
            setNewPassword={setNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
            {...state.editedFields}
          />
          <Row justify="center" align="center">
            <Text
              variant="subhead"
              fontWeight="bold"
              color={newColors.text.error.main}
            >
              {state.errorMessage}
            </Text>
          </Row>
        </View>
      </Body>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: "transparent",
  },
});

export default ChangePasswordScreen;
