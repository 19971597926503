import moment from "moment";

const shouldDisableNotification = (
  dateTime: string | undefined,
  extendedHours: number | undefined
) => {
  if (!dateTime || !extendedHours) {
    return false;
  }

  const endDateTime = moment(new Date(dateTime))
    .add(extendedHours, "hours")
    .toDate();
  const currentDateTime = new Date();

  return currentDateTime >= endDateTime;
};

export { shouldDisableNotification };
