import { useEffect, useState } from "react";
import { Bulletin, BulletinStatus } from "~riata/generated/graphql";
import { useGetBulletins } from "~riata/graphql";

const useBulletinTakeOver = ({ navigation }) => {
  const { bulletins } = useGetBulletins({
    filter: {
      status: BulletinStatus.Unread,
      isRequired: true,
      isPriority: true,
      isScheduled: false
    },
  });
  const [visitedBulletin, setVisitedBulletin] = useState<Bulletin>();

  useEffect(() => {
    if (bulletins.length > 0 && visitedBulletin !== bulletins[0]) {
      const bulletinToVisit = bulletins[0];
      // Wait for component to finish rendering before navigating.
      setTimeout(() => {
        navigation.navigate("Bulletin", {
          screen: "BulletinDetails",
          params: { id: bulletinToVisit.id },
        });

        setVisitedBulletin(bulletinToVisit);
      }, 300);
    }
  }, [bulletins, visitedBulletin, navigation]);
};

export default useBulletinTakeOver;
