import { useEffect, useState } from "react";
import { useCreateIntegrationRedirect } from "~riata/graphql";
import { Sentry } from "~riata/utils/sentry";

export const useGetRedirectUrl = ({ id, path }) => {
  const {
    execute,
    loading: redirectLoading,
    data,
    error: redirectError,
  } = useCreateIntegrationRedirect({ integration: id, destination: path });
  const [isBusy, setIsBusy] = useState(false);
  const [state, setState] = useState({
    integration: null,
    url: "",
    path: null,
  });

  useEffect(() => {
    if (redirectLoading || isBusy) {
      return;
    }

    if (state.integration === id && state.path === path) {
      return;
    }

    setIsBusy(true);
    execute()
      .then((response) => {
        setState({
          path,
          ...(response.data.createIntegrationRedirect as {
            integration: any;
            url: string;
          }),
        });
      })
      .catch(Sentry.captureException)
      .finally(() => setIsBusy(false));
  }, [execute, id, path, data, redirectLoading, state, isBusy]);

  return {
    integrationId: state.integration,
    redirectUrl: state.url ?? null,
    isBusy: redirectLoading || isBusy,
    redirectError,
  };
};
