import { useEffect, useMemo, useReducer } from "react";

export type EditProfileRow = {
  value: string | null;
  error: string | null;
};

export type State = {
  firstName: EditProfileRow;
  lastName: EditProfileRow;
  displayName: EditProfileRow;
  jobTitle: EditProfileRow;
  phone: EditProfileRow;
  fileChooserResult: any;
  loading: boolean;
  modal: boolean;
};

export const DEFAULT_STATE: State = {
  firstName: { value: null, error: null },
  lastName: { value: null, error: null },
  displayName: { value: null, error: null },
  jobTitle: { value: null, error: null },
  phone: { value: null, error: null },
  fileChooserResult: null,
  loading: false,
  modal: false,
};

export enum EditProfileActions {
  setInitialValues,
  setFirstName,
  setLastName,
  setDisplayName,
  setJobTitle,
  setPhone,
  setFileChooserResult,
  setLoading,
  setModal,
  resetState,
}

export const reducer = (prevState, action) => {
  switch (action.type) {
    case EditProfileActions.setInitialValues:
      return {
        ...prevState,
        firstName: {
          value: action.user.firstName,
          error: null,
        },
        lastName: {
          value: action.user.lastName,
          error: null,
        },
        displayName: {
          value: action.user.displayName,
          error: null,
        },
        jobTitle: {
          value: action.user.jobTitle,
          error: null,
        },
        phone: {
          value: action.user.phone,
          error: null,
        },
        modal: false,
        loading: false,
        fileChooserResult: null,
      };

    case EditProfileActions.setFirstName:
      return {
        ...prevState,
        firstName: {
          value: action.value,
          error: action.error,
        },
      };

    case EditProfileActions.setLastName:
      return {
        ...prevState,
        lastName: {
          value: action.value,
          error: action.error,
        },
      };

    case EditProfileActions.setDisplayName:
      return {
        ...prevState,
        displayName: {
          value: action.value,
          error: action.error,
        },
      };

    case EditProfileActions.setJobTitle:
      return {
        ...prevState,
        jobTitle: {
          value: action.value,
          error: action.error,
        },
      };
    case EditProfileActions.setPhone:
      return {
        ...prevState,
        phone: {
          value: action.value,
          error: action.error,
        },
      };
    case EditProfileActions.setFileChooserResult:
      return {
        ...prevState,
        fileChooserResult: action.result,
        modal: false,
      };

    case EditProfileActions.setLoading:
      return {
        ...prevState,
        loading: action.loading,
      };

    case EditProfileActions.setModal:
      return {
        ...prevState,
        modal: action.modal,
      };

    default:
      return DEFAULT_STATE;
  }
};

export const useEditProfileState = ({ user }) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);

  useEffect(() => {
    if (user) {
      dispatch({ type: EditProfileActions.setInitialValues, user });
    }
  }, [user]);

  const actions = useMemo(
    () => ({
      updateFirstName: (value, error = null) => {
        dispatch({
          type: EditProfileActions.setFirstName,
          value,
          error,
        });
      },
      updateLastName: (value, error = null) => {
        dispatch({
          type: EditProfileActions.setLastName,
          value,
          error,
        });
      },
      updateDisplayName: (value, error = null) => {
        dispatch({
          type: EditProfileActions.setDisplayName,
          value,
          error,
        });
      },
      updateJobTitle: (value, error = null) => {
        dispatch({
          type: EditProfileActions.setJobTitle,
          value,
          error,
        });
      },
      updatePhone: (value, error = null) => {
        dispatch({
          type: EditProfileActions.setPhone,
          value,
          error,
        });
      },
      updateFileChooserResult: (result) => {
        dispatch({
          type: EditProfileActions.setFileChooserResult,
          result,
        });
      },
      setLoading: (loading) => {
        dispatch({
          type: EditProfileActions.setLoading,
          loading,
        });
      },
      setModal: (modal) => {
        dispatch({
          type: EditProfileActions.setModal,
          modal,
        });
      },
      resetState: (user) => {
        dispatch({ type: EditProfileActions.setInitialValues, user });
      },
    }),
    []
  );

  return { state, dispatch, actions };
};
