import React from "react";
import { StyleSheet, View, TextInput } from "react-native";

// Components
import { Row } from "~riata/components/layout";
import ChannelAvatar from "../../ChannelAvatar";
import { Text } from "~riata/components/typography";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { ChannelInfoHeaderComponent } from "./types";
import { OffDutyLabel } from "../../OffDutyLabel";
import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs";
import { CORE_FONT } from "~riata/theme/fonts";

const ChannelInfoHeader: ChannelInfoHeaderComponent = ({
  containerStyles = {},
  avatarContainerStyles = {},
  channel,
  title = "",
  subtitle = "",
  statusEnabled = false,
  offDuty = false,
  inputText = "",
  onChangeText = (text: string) => {},
  submitGroupName = () => {},
  cantEditGroupName = false,
  inputIsVisible = false,
  avatarDisabled = false,
  onBackPress,
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  const nameInputStyles = useComposeStyles(styles.nameInput, {
    backgroundColor: newColors.surface.main,
    borderColor: newColors.separator.main,
  });

  return (
    <View style={composedContainerStyles}>
      <Row style={composedContainerStyles} justify="center">
        <ChannelAvatar
          channel={channel}
          size="large"
          showStatus={false}
          style={{ container: avatarContainerStyles }}
          avatarDisabled={avatarDisabled}
        />
      </Row>
      <View style={styles.titleWrapper}>
        <Row justify="center">
          <Text variant="newTitle2" style={styles.title}>
            {title}
          </Text>
        </Row>
        {cantEditGroupName && inputIsVisible && (
          <View style={styles.nameInputContainer}>
            <TextInput
              value={inputText}
              onChangeText={onChangeText}
              defaultValue={channel?.name}
              style={nameInputStyles}
            />
            <Button
              style={styles.nameInputSubmitButton}
              onPress={submitGroupName}
            >
              <CustomIcon name="check_mark" size={20} />
            </Button>
          </View>
        )}
        <Row justify="center" style={styles.topMargin}>
          <Text variant="subtitle" color={newColors.text.caption.main}>
            {subtitle}
          </Text>
        </Row>
        {statusEnabled && (
          <Row justify="center" style={styles.topMargin}>
            <OffDutyLabel offDuty={offDuty} />
          </Row>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  titleWrapper: {
    marginTop: 20,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: "center",
    lineHeight: 26,
  },
  topMargin: {
    marginTop: 12,
  },
  nameInputContainer: {
    marginTop: 32,
  },
  nameInput: {
    backgroundColor: "transparent",
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "transparent",
    paddingLeft: 12,
    paddingRight: 44,
    paddingVertical: 10,
    fontSize: 18,
    lineHeight: 20,
    fontFamily: CORE_FONT,
    fontWeight: "400",
  },
  nameInputSubmitButton: {
    position: "absolute",
    right: 12,
    top: 12,
  },
});

export default ChannelInfoHeader;
