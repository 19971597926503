import React from "react";
import { View } from "react-native";

import { DragDownContainerComponent } from "./types";

const DragDownContainer: DragDownContainerComponent = ({
  onDragDown,
  enabled = true,
  children,
}) => {
  return <View>{children}</View>;
};

export default DragDownContainer;
