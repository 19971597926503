import React, { useEffect, useMemo, useState } from "react";
import { KeyboardAvoidingView, StyleSheet, View } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

// Components
import {
  AppHeader,
  CustomIcon,
  HeaderActionButton,
  KeyboardDismissView,
  Row,
  Text,
  TextInput,
  UserList,
  UserSelectSearch,
} from "~riata/components";

// Hooks
import {
  useColors,
  useComposeStyles,
  useTrackSelectedUsers,
} from "~riata/hooks";
import { useGetCurrentUser, useGetUsers } from "~riata/graphql";
import { useCreateGroupChannel } from "~riata/graphql/mutations/CreateDm";

// Utils
import { getFilteredUserList, navigateToNewChannel } from "../utils";

export const NewGroupScreen = ({ navigation }) => {
  const [filter, setFilter] = useState("");
  const [channelName, setChannelName] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const { newColors } = useColors();
  const { selectedUsers, toggleUser } = useTrackSelectedUsers();
  const { execute: createGroupChannel, channel: createdChannel } =
    useCreateGroupChannel();
  const { user } = useGetCurrentUser();
  const { loading, error, userList, refetch } = useGetUsers({
    filter: { query: filter },
  });

  const isCreateDisabled = selectedUsers.length <= 0 || isWorking;
  const hasTitle = channelName.trim().length > 0;

  const filteredList = useMemo(() => {
    return getFilteredUserList(userList, user);
  }, [userList, user]);

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const channelNameInputContainerStyles = useComposeStyles(
    styles.channelNameInputContainer,
    {
      shadowColor: newColors.shadow.main,
      backgroundColor: newColors.surface.main,
    }
  );

  const inputStyles = useComposeStyles(styles.channelNameInput, {
    fontWeight: channelName.length > 0 ? "500" : "400",
  });

  const userListWrapperStyles = useComposeStyles(styles.userListWrapper, {
    backgroundColor: newColors.surface.main,
  });

  const handleCreateGroupChannel = () => {
    const selectedUserIDs = selectedUsers.map((a) => {
      return a.id;
    });

    setIsWorking(true);
    createGroupChannel(selectedUserIDs, channelName).finally(() =>
      setIsWorking(false)
    );
  };

  const onSearch = (text) => {
    setFilter(text);
  };

  const handleChannelNameChange = (text: string) => {
    setChannelName(text);
  };

  useEffect(() => {
    if (createdChannel) {
      // If channel creating is successful then navigate to the channel
      // Rebuild tree when navigation
      navigateToNewChannel(navigation, createdChannel);
    }
    // prevent {navigation} from triggering a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdChannel]);

  return (
    <KeyboardAvoidingView style={containerStyles}>
      <SafeAreaView style={containerStyles} forceInset={{ bottom: "never" }}>
        <AppHeader
          backgroundColor={newColors.header.main}
          left={
            <HeaderActionButton variant="back" onPress={navigation.goBack} />
          }
          title="New Group Channel"
          right={
            <HeaderActionButton
              variant="create"
              onPress={handleCreateGroupChannel}
              disabled={isCreateDisabled || !hasTitle}
            />
          }
        />
        <KeyboardDismissView containerStyles={containerStyles}>
          <Row style={styles.newGroupChannelLabelWrapper}>
            <Text variant="newFootnote" color={newColors.text.footnote.main}>
              A private multi-member channel that other users can invite team
              members to.
            </Text>
          </Row>
          <View style={styles.channelNameInputWrapper}>
            <Row style={channelNameInputContainerStyles} align="center">
              <View style={styles.channelNameInputIconContainer}>
                <CustomIcon
                  name="group_channel"
                  size={20}
                  color={newColors.text.placeHolder.main}
                />
              </View>
              <TextInput
                placeholder="Channel Name"
                inputStyles={inputStyles}
                onChangeText={handleChannelNameChange}
                value={channelName}
                containerStyles={styles.inputContainer}
              />
            </Row>
          </View>
          <View style={styles.userSelectSearchWrapper}>
            <UserSelectSearch
              showSearchIcon
              selectedUsers={selectedUsers}
              removeUser={(user) => {
                toggleUser(user);
              }}
              onSearch={onSearch}
              inputContainerStyles={styles.input}
              inputStyles={styles.input}
            />
          </View>
          <View style={userListWrapperStyles}>
            <UserList
              data={filteredList}
              selectedUsers={selectedUsers}
              loading={loading}
              error={error}
              refetch={refetch}
              onSelectUser={(user) => toggleUser(user)}
            />
          </View>
        </KeyboardDismissView>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  newGroupChannelLabelWrapper: {
    marginHorizontal: 16,
    marginTop: 12,
  },
  channelNameInputWrapper: {
    margin: 16,
    borderRadius: 12,
  },
  channelNameInputContainer: {
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1,
    elevation: 2,
    shadowColor: "transparent",
    backgroundColor: "transparent",
    borderRadius: 12,
  },
  channelNameInputIconContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 40,
    justifyContent: "center",
    paddingLeft: 12,
  },
  channelNameInput: {
    height: 40,
    marginLeft: 34,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 17,
    borderRadius: 12,
  },
  userSelectSearchWrapper: {
    marginHorizontal: 16,
  },
  userListWrapper: {
    backgroundColor: "transparent",
    flex: 1,
    marginTop: 12,
  },
  input: { borderRadius: 12 },
  inputContainer: {
    flex: 1,
    borderRadius: 12,
  },
});

export default NewGroupScreen;
