import { useNavigation } from "@react-navigation/core";
import React, { useEffect, useMemo, useState } from "react";
import emoji from "node-emoji";

import { StyleSheet, View, useWindowDimensions, FlatList } from "react-native";
import {
  HeaderActionButton,
  RodioLoader,
  Row,
  Separator,
  TappableBackground,
  Text,
  UserAvatar,
} from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWideDisplay } from "~riata/theme/common";
import { useGetMessage } from "~riata/graphql/queries/GetMessage";

const renderItem = ({ item }) => {
  const { displayName } = item;
  return (
    <Row style={renderItemStyles.container}>
      <UserAvatar user={item} />
      <View style={renderItemStyles.textWrapper}>
        <Text variant="subhead">{displayName}</Text>
      </View>
    </Row>
  );
};

const renderSeparator = () => {
  return <Separator />;
};

const getItemLayout = (_, index) => {
  // Based off renderItem dimensions
  return {
    length: 50,
    offset: 50 * (index + 1),
    index,
  };
};

const ReactionDetailsScreen = ({ route }) => {
  const { message: messageId, reaction } = route.params;

  const navigation = useNavigation();
  const { newColors } = useColors();
  const { width } = useWindowDimensions();
  const [userList, setUserList] = useState([]);
  const isWide = useMemo(() => isWideDisplay(width), [width]);

  const { loading, message } = useGetMessage(messageId);
  const summaries = useMemo(() => {
    return message?.reactions?.summary || [];
  }, [message]);

  const sheetStyles = useComposeStyles(styles.sheet, {
    backgroundColor: newColors.surface.main,
  });

  useEffect(() => {
    const reactionSummary = summaries.filter(
      (summary) => summary.name === reaction
    )[0];

    if (reactionSummary) {
      setUserList(reactionSummary.users);
    }
  }, [summaries, reaction]);

  if (loading) {
    return <RodioLoader />;
  }

  return (
    <View style={isWide ? styles.containerWide : styles.container}>
      <TappableBackground onPress={() => navigation.goBack()} />
      <View style={[sheetStyles, isWide ? styles.sheet_wide : []]}>
        <Row style={styles.header}>
          <HeaderActionButton
            variant="back"
            onPress={() => navigation.goBack()}
          />
        </Row>
        <Separator />
        <View style={styles.reactionContainer}>
          <Text variant="title1">{emoji.emojify(`:${reaction}:`)}</Text>
        </View>
        <Separator />
        <FlatList
          removeClippedSubviews
          style={styles.cardsList}
          data={userList}
          keyExtractor={(item) => item.id}
          onEndReachedThreshold={0}
          ItemSeparatorComponent={renderSeparator}
          getItemLayout={getItemLayout}
          renderItem={renderItem}
          maxToRenderPerBatch={20}
          updateCellsBatchingPeriod={200}
          windowSize={5}
          initialNumToRender={20}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  containerWide: {
    flex: 1,
    justifyContent: "center",
  },
  sheet: {
    backgroundColor: "transparent",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: "50%",
    position: "absolute",
    width: "100%",
  },
  sheet_wide: {
    width: "70%",
    maxHeight: "70%",
    minHeight: "30%",
    alignSelf: "center",
    borderRadius: 12,
  },
  header: {
    padding: 16,
  },
  reactionContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  nameContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 16,
    paddingTop: 0,
  },
  cardsList: {
    flex: 1,
  },
});

const renderItemStyles = StyleSheet.create({
  container: { paddingHorizontal: 16, paddingVertical: 8 },
  textWrapper: {
    marginLeft: 8,
  },
});

export { ReactionDetailsScreen };
