import React from "react";
import { usePostMessageMutation } from "~riata/graphql";
import { InputBar } from "~riata/components";

export const InputBarSubScreen = ({ channelID }) => {
  const { execute: sendMessage } = usePostMessageMutation({
    channel: channelID,
  });

  return <InputBar nodeID={channelID} onSendMessage={sendMessage} />;
};
