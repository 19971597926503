import React from "react";
import { useComposeStyles } from "~riata/hooks";
import { UserAvatarComponent } from "./types";
import { Image, ImageStyle, Platform, StyleSheet, View } from "react-native";
import { Avatar } from "../Avatar";

export const DEFAULT_ICON_SIZE = 30;
export const DEFAULT_OUTLINE_COLOR = "white";

const UserAvatar: UserAvatarComponent = ({
  user,
  outlineColor = DEFAULT_OUTLINE_COLOR,
  outlined = false,
  size = DEFAULT_ICON_SIZE,
  containerStyle = {},
}) => {
  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyle
  );

  const imageStyles = useComposeStyles(styles.image, {
    height: outlined ? size + 2 : size,
    width: outlined ? size + 2 : size,
    borderRadius: size / 2,
    borderWidth: outlined ? 2 : 0,
    borderColor: outlineColor,
  });

  if (!user) {
    return null;
  }

  if (user.avatar) {
    const uri = user.avatar.url;

    return (
      <View style={composedContainerStyles}>
        <Image style={imageStyles as ImageStyle} source={{ uri: uri }} />
      </View>
    );
  }

  return (
    <View style={composedContainerStyles}>
      <Avatar
        user={user}
        width={outlined ? size + 2 : size}
        height={outlined ? size + 2 : size}
        outlined={outlined}
        outlinedColor={outlineColor}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  image: {
    ...Platform.select({
      android: { resizeMode: null },
      default: { resizeMode: "contain" },
    }),
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE,
  },
});

export default UserAvatar;
