import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { QUERY } from "../queries/GetCurrentUser";

export const MODIFY_USER = gql`
  mutation (
    $id: ID!
    $file: Upload
    $firstName: String
    $lastName: String
    $displayName: String
    $jobTitle: String
    $phone: String
  ) {
    modifyUser(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        displayName: $displayName
        jobTitle: $jobTitle
        phone: $phone
      }
      avatar: $file
    ) {
      clientMutationId
      user {
        id
        fullName
        firstName
        lastName
        phone
        displayName
        avatar {
          url
        }
        jobTitle
      }
    }
  }
`;

export const useModifyUser = () => {
  const [mutation, response] = useMutation(MODIFY_USER);
  const successful = response?.data ? true : false;

  const execute = ({
    user,
    firstName,
    lastName,
    displayName,
    jobTitle,
    phone,
    file,
  }) => {
    const variables = {
      id: user.id,
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      jobTitle: jobTitle,
      phone: phone,
      file,
    };
    return mutation({
      variables,
      refetchQueries: [
        { query: QUERY, variables: { id: user.id } },
        { query: QUERY },
      ],
    });
  };
  return { execute, response, successful };
};
