import React, { useCallback, useMemo, useState } from "react";
import { Alert, Platform, ScrollView, StyleSheet, View } from "react-native";
import { useLinkTo } from "@react-navigation/native";

// Components
import {
  AppHeader,
  BulletinDetailHeader,
  BulletinDetailsActionButton,
  BulletinDetailsSender,
  HeaderActionButton,
  MarkdownContent,
  Row,
  SafeAreaView,
  Separator,
  HashtagCard,
  LoadingErrorGuard,
} from "~riata/components";

// Hooks
import { useColors, useComposeStyles, useToastMessage } from "~riata/hooks";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import {
  useArchiveBulletin,
  useGetBulletin,
  useGetCurrentUser,
  useMarkBulletinRead,
} from "~riata/graphql";

// Types
import { BulletinStatus } from "~riata/generated/graphql";

// Utils
import {
  getAnalyticsVisibility,
  getArchiveBulletinVisibility,
  getBackAvailability,
} from "./utils";
import { Sentry } from "~riata/utils/sentry";
import { useMarkAsViewed } from "./useMarkAsViewed";
import { CONFIRM_DELETE, DELETE_TITLE } from "./constnats";

const BulletinDetailScreen = ({ route }) => {
  const { id: bulletinId = "" } = route.params;

  // Use error and refetch from useGetBulletin to handle error
  const {
    loading: loadingBulletin,
    bulletin,
    error: bulletinError,
    refetch: refetchBulletins,
  } = useGetBulletin(bulletinId);
  const { execute: archiveBulletin } = useArchiveBulletin();
  const {
    user,
    loading: loadingUser,
    error: userError,
    refetch: refetchUser,
  } = useGetCurrentUser();
  const { showErrorToast } = useToastMessage();

  const { goBack } = useDrawerNavigation();
  const { newColors } = useColors();
  const { execute } = useMarkBulletinRead();
  const linkTo = useLinkTo();

  const [isBusy, setIsBusy] = useState(false);

  const isLoading = loadingBulletin && loadingUser;
  const error = bulletinError || userError;

  const canSeeComments = bulletin?.allowComments;
  const canSeeAttachments = bulletin?.attachments?.edges?.length > 0;
  const canSeeAnalytics = useMemo(
    () => getAnalyticsVisibility(user, bulletin),
    [user, bulletin]
  );

  const backIsAllowed = useMemo(
    () => getBackAvailability(bulletin),
    [bulletin]
  );

  const canSeeArchiveBulletin = useMemo(
    () => getArchiveBulletinVisibility(user, bulletin),
    [user, bulletin]
  );

  const reload = useCallback(() => {
    setIsBusy(true);
    Promise.all([refetchBulletins(), refetchUser()])
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => setIsBusy(false));
  }, [refetchBulletins, refetchUser]);

  const deleteBulletin = useCallback(() => {
    setIsBusy(true);
    archiveBulletin(bulletin.id)
      .then(() => {
        setIsBusy(false);
        goBack();
      })
      .catch((error) => {
        setIsBusy(false);
        showErrorToast(
          "Oops! could not archive Bulletin",
          "Please try again later",
          error
        );
      });
  }, [archiveBulletin, bulletin?.id, goBack, showErrorToast]);

  const onDeleteScheduledBulletin = useCallback(() => {
    if (Platform.OS !== "web") {
      Alert.alert(DELETE_TITLE, CONFIRM_DELETE, [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Delete",
          onPress: () => deleteBulletin(),
          style: "destructive",
        },
      ]);
    } else {
      if (window.confirm(CONFIRM_DELETE)) {
        deleteBulletin();
      }
    }
  }, [deleteBulletin]);

  const markAsRead = useCallback(() => {
    setIsBusy(true); // To prevent multiple onPress events
    execute(bulletin.id)
      .then(() => {
        // The bulletin has been acknowledged of marked as read by the user.
        // Default behavior is to return to previous screen
        setIsBusy(false);
        goBack();
      })
      .catch((error) => {
        setIsBusy(false);
        if (bulletin.isRequired) {
          // Allow user to return to the app instead of remaining in a takeover screen
          goBack();
          Sentry.captureException(error);
        } else {
          // User can manually ho back so we will show an error toast.
          showErrorToast(
            "Oops! could not mark Bulletin as read",
            "Please try again later",
            error
          ); // Has integrated Sentry exception capturing
        }
      });
  }, [bulletin?.id, bulletin?.isRequired, execute, goBack, showErrorToast]);

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.surface.main,
  });

  const canMarkAsViewed = useMemo(() => {
    if (!bulletin || !user) {
      return false;
    }

    if (bulletin?.creator?.id === user.id) {
      return false;
    }

    return (
      bulletin.status !== BulletinStatus.Viewed &&
      bulletin.status !== BulletinStatus.Read
    );
  }, [user, bulletin]);

  useMarkAsViewed({ bulletinId: bulletin?.id, canMarkAsViewed });

  return (
    <SafeAreaView bottom={false} backgroundColor={newColors.surface.main}>
      <AppHeader
        backgroundColor={newColors.surface.main}
        left={
          backIsAllowed && (
            <HeaderActionButton variant="close" onPress={goBack} />
          )
        }
        right={
          <Row>
            {canSeeComments && (
              <HeaderActionButton
                variant="chatOutlined"
                onPress={() => linkTo(`/bulletin/${bulletin.id}/comments`)}
                iconSize={20}
              />
            )}

            {canSeeAttachments && (
              <View style={styles.leftMargin}>
                <HeaderActionButton
                  variant="attachments"
                  onPress={() => linkTo(`/bulletin/${bulletin.id}/attachments`)}
                  iconSize={20}
                />
              </View>
            )}
          </Row>
        }
      />
      <LoadingErrorGuard isLoading={isLoading} error={error} refetch={reload}>
        <View style={containerStyles}>
          <BulletinDetailHeader
            bulletin={bulletin}
            showAnalytics={canSeeAnalytics}
          />

          <Separator
            color={newColors.separator.light.main}
            style={styles.topSpace}
          />
          <Row justify="between" align="center" style={{ marginTop: 16 }}>
            <BulletinDetailsSender
              creator={bulletin?.creator}
              date={bulletin?.publishedAt || bulletin?.scheduledAt}
            />
            {bulletin?.hashtags?.edges.length > 0 && (
              <HashtagCard hashtag={bulletin?.hashtags.edges[0].node} />
            )}
          </Row>
          <Separator
            color={newColors.separator.light.main}
            style={styles.topSpace}
          />
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.contentContainer}
          >
            <MarkdownContent text={bulletin?.content} />
          </ScrollView>
          {canSeeArchiveBulletin ? (
            <BulletinDetailsActionButton
              testID="scheduled-action-button"
              onPress={onDeleteScheduledBulletin}
              disabled={isBusy}
              bulletin={bulletin}
            />
          ) : (
            <BulletinDetailsActionButton
              testID="action-button"
              onPress={markAsRead}
              disabled={isBusy}
              bulletin={bulletin}
            />
          )}
        </View>
      </LoadingErrorGuard>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginTop: 16,
    paddingBottom: Platform.OS === "ios" ? 32 : 16,
    flex: 1,
    backgroundColor: "transparent",
  },
  topSpace: {
    marginTop: 16,
  },
  contentContainer: {
    marginTop: 16,
    paddingBottom: 16,
  },
  leftMargin: {
    marginLeft: 16,
  },
});

export default BulletinDetailScreen;
