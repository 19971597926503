import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ulid } from "ulid";
import { CommentableNode } from "~riata/generated/graphql";
import { fragments } from "../fragments";
import { GET_BULLETIN } from "../queries/GetBulletin";
import { GET_BULLETINS } from "../queries/GetBulletins";
import { GET_COMMENTS } from "../queries/GetComments";

const POST_COMMENT = gql`
  mutation PostComment($input: PostCommentInput!) {
    postComment(input: $input) {
      comment {
        ...CommentContent
      }
    }
  }
  ${fragments.Comment.Content}
`;

export const usePostComment = ({ nodeId }) => {
  const [mutation, response] = useMutation(POST_COMMENT);
  const execute = (content: string) => {
    const variables = {
      input: {
        id: ulid(),
        content,
        repliesTo: { id: nodeId, type: CommentableNode.Bulletin },
      },
    };
    return mutation({
      variables,
      refetchQueries: [
        { query: GET_BULLETINS },
        { query: GET_BULLETIN, variables: { id: nodeId } },
        {
          query: GET_COMMENTS,
          variables: { id: nodeId, before: null, last: 20 },
        },
      ],
    });
  };
  return { execute, response };
};
