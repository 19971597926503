import { ApolloError } from "apollo-client";
import React, { useState } from "react";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import WebView from "react-native-webview";
import {
  AppHeader,
  BackArrow,
  Button,
  ForwardArrow,
  HeaderActionButton,
  RefreshIcon,
  RodioLoader,
  Row,
  SafeAreaView,
  Text,
} from "~riata/components";
import { useGetBookmark } from "~riata/graphql";
import { useColors, useComposeStyles, useGetRedirectUrl } from "~riata/hooks";
import { useRiataInjection } from "~riata/hooks/useRiataInjection";

type LoadingErrorGuardProps = {
  children: React.ReactNode;
  error: any;
  loading: boolean;
};

type LoadingErrorComponent = (props: LoadingErrorGuardProps) => JSX.Element;

type ErrorProps = {
  error: ApolloError | string;
};

type ErrorComponent = (props: ErrorProps) => JSX.Element;

const Loading = () => {
  return (
    <View style={styles.feedbackContainer}>
      <RodioLoader large />
    </View>
  );
};

const Error: ErrorComponent = ({ error }) => {
  const { newColors } = useColors();
  let errorMessage = "";

  if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = error.message;
  }

  return (
    <View style={styles.feedbackContainer}>
      <Row justify="center">
        <Text variant="headline" color={newColors.text.error.main}>
          Something went wrong
        </Text>
      </Row>
      <Row justify="center" style={styles.errorContainer}>
        <Text variant="footnote">{errorMessage}</Text>
      </Row>
    </View>
  );
};

const LoadingErrorGuard: LoadingErrorComponent = ({
  loading,
  error,
  children,
}) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return <>{children}</>;
};

const BookmarkDetailsScreen = ({ navigation, route }) => {
  const { id: bookmarkId } = route.params;
  const {
    node: bookmark,
    loading: loadingBookmark,
    error: bookmarkError,
  } = useGetBookmark(bookmarkId);
  const {
    redirectUrl,
    isBusy,
    error: urlError,
    initializedId,
  } = useGetRedirectUrl({
    bookmarkId: bookmark?.id,
    destination: bookmark?.actionURL,
  });

  const { newColors } = useColors();
  const { width, height } = useWindowDimensions();
  const [showFooter, setShowFooter] = useState(false);

  const { actions, handleMessageDispatch, ref, toInject } = useRiataInjection({
    navigation,
    defaultBackScreen: "Home",
  });

  const webViewStyle = useComposeStyles(styles.webView, {
    width,
    height,
    backgroundColor: newColors.surface.main,
  });

  const hasInitialized = initializedId === bookmark?.id;
  const isLoading = loadingBookmark || isBusy || !hasInitialized;
  const error = bookmarkError || urlError;

  return (
    <SafeAreaView>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title={bookmark?.name}
      />
      <LoadingErrorGuard loading={isLoading} error={error}>
        <WebView
          key={`bookmark-detail-${bookmark?.id}`}
          cacheEnabled={false}
          geolocationEnabled
          injectedJavaScriptBeforeContentLoaded={toInject}
          ref={ref}
          style={webViewStyle}
          allowsBackForwardNavigationGestures
          onMessage={handleMessageDispatch}
          onNavigationStateChange={actions.onWebViewNavigationStateChange}
          source={{ uri: redirectUrl }}
          onLoadStart={() => setShowFooter(false)}
          onLoadEnd={() => setShowFooter(true)}
          renderLoading={() => <Loading />}
          renderError={(errorDomain, errorCode, errorDesc) => {
            const errorMsg = `${errorDomain}\nCode: ${errorCode}\n${errorDesc}`;
            return <Error error={errorMsg} />;
          }}
          webviewDebuggingEnabled={true}
        />
        {hasInitialized && showFooter && (
          <Row justify="between" align="center" style={styles.footer}>
            <Row>
              <Button onPress={actions.handleBack}>
                <BackArrow size={24} />
              </Button>
              <Button onPress={actions.handleForward}>
                <ForwardArrow size={24} />
              </Button>
            </Row>
            <Button onPress={actions.reload}>
              <RefreshIcon size={24} />
            </Button>
          </Row>
        )}
      </LoadingErrorGuard>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    backgroundColor: "transparent",
    width: 50,
    height: 50,
  },
  footer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: Platform.OS === "android" ? 16 : 0,
  },
  feedbackContainer: {
    height: "100%",
    width: "100%",
  },
  errorContainer: {
    padding: 16,
  },
});

export { BookmarkDetailsScreen };
