import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Text } from "../../typography";
import IntegrationAvatar from "../IntegrationAvatar";
import { Card, Row } from "~riata/components/layout";
import { Button } from "~riata/components/inputs";

// Hooks
import { useIntegrationLauncher } from "./useIntegrationLauncher";

// Utils
import StringUtils from "~riata/utils/string";

const IntegrationListItem = ({ integration }) => {
  const launchIntegration = useIntegrationLauncher({ integration });

  const description = useMemo(
    () => StringUtils.trimWithTail(integration.description, 280),
    [integration]
  );

  return (
    <Card rounded spaced style={styles.container}>
      <Button onPress={() => launchIntegration()}>
        <Row align="center" style={styles.contentWrapper}>
          <IntegrationAvatar size={30} integration={integration} />
          <View style={styles.descriptionContainer}>
            <Text variant="title4">{integration.name}</Text>
            <Text
              variant="footnote"
              style={styles.description}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {description}
            </Text>
          </View>
        </Row>
      </Button>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  contentWrapper: {
    gap: 24,
  },
  descriptionContainer: {
    flex: 1,
  },
  description: {
    marginTop: 8,
  },
});

export default IntegrationListItem;
