import React, { useCallback, useMemo } from "react";
import { StyleSheet, View, ScrollView, SafeAreaView } from "react-native";
import {
  Text,
  Separator,
  ChannelInfoActions,
  AppHeader,
  HeaderActionButton,
  UserInfoHeader,
} from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWeb } from "~riata/theme/common";
import { generateAttributes, openPhoneNumber } from "./utils";
import { useGetChannel } from "~riata/graphql";

const ChannelUserProfileScreen = ({ navigation, route }) => {
  const userId = route.params?.userId ?? false;
  const channelId = route.params?.channel ?? false;

  const { channel } = useGetChannel(channelId, "", "cache-and-network");
  const peerUser = useMemo(() => {
    return channel?.members?.edges.filter((edge) => edge.node.id === userId)[0]
      ?.node;
  }, [channel, userId]);
  const { newColors } = useColors();

  const goBack = useCallback(() => navigation.goBack(), [navigation]);

  const attributes = peerUser?.attributes.edges.map((edge) => edge.node.name);

  const canCall = !isWeb && peerUser?.phone?.length > 0;

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const callPeer = useCallback(() => {
    let phoneNumber = `tel:${peerUser?.phone}`;
    openPhoneNumber(phoneNumber);
  }, [peerUser]);

  const actions = useMemo(() => {
    const actions = [];

    if (canCall) {
      actions.push({
        label: "Call",
        iconName: "phone",
        onPressHandler: callPeer,
      });
    }

    return actions;
  }, [canCall, callPeer]);

  const attributesList = useMemo(() => {
    return generateAttributes(attributes);
  }, [attributes]);

  return (
    <SafeAreaView style={containerStyles}>
      <AppHeader
        backgroundColor={newColors.background.main}
        left={<HeaderActionButton variant="back" onPress={goBack} />}
      />

      <View style={{ flex: 1 }}>
        <UserInfoHeader
          user={peerUser}
          onBackPress={goBack}
          title={peerUser?.displayName}
          subtitle={peerUser?.jobTitle}
          offDuty={peerUser?.offDuty}
          statusEnabled={peerUser?.company.offDuty}
        />
        <ChannelInfoActions
          actions={actions}
          containerStyles={styles.actionsContainer}
        />
        <Separator color={newColors.separator.main} />
        <View style={styles.attributesTitleWrapper}>
          <Text variant="headline">Attributes</Text>
        </View>
        <ScrollView>
          <View style={styles.attributesWrapper}>{attributesList}</View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    paddingHorizontal: 16,
    marginVertical: 26,
  },
  attributesTitleWrapper: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  attributesWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 16,
    gap: 8,
  },
});

export default ChannelUserProfileScreen;
