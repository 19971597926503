import React, { useMemo } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";

const NOOP = () => {};
const DEFAULT_BEHAVIOR = Platform.OS === "ios" ? "padding" : "height";

type BodyStyle = ViewStyle & {
  ".padded"?: ViewStyle;
};

type BodyProps = {
  behavior?: "padding" | "height" | "position";
  children: JSX.Element | React.ReactNode;
  dismissOnTap?: boolean;
  skipKeyboardAvoiding?: boolean;
  style?: BodyStyle;
  styleName?: string;
  keyboardVerticalOffset?: number;
};

type BodyComponent = (props: BodyProps) => JSX.Element;

const Body: BodyComponent = ({
  behavior = DEFAULT_BEHAVIOR,
  dismissOnTap = true,
  skipKeyboardAvoiding = false,
  children,
  keyboardVerticalOffset = 44,
}) => {
  const Wrapper = useMemo(
    () =>
      ({ children }) =>
        skipKeyboardAvoiding ? (
          <View style={styles.container}>{children}</View>
        ) : (
          <KeyboardAvoidingView
            keyboardVerticalOffset={keyboardVerticalOffset}
            behavior={behavior}
            style={styles.container}
          >
            {children}
          </KeyboardAvoidingView>
        ),
    // FIXME THis is a symptom of a bigger problem we need to track down
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [behavior, keyboardVerticalOffset, skipKeyboardAvoiding]
  );

  return (
    <TouchableWithoutFeedback
      onPress={dismissOnTap ? Keyboard.dismiss : NOOP}
      style={styles.container}
    >
      <Wrapper>{children}</Wrapper>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Body;
