import React from "react";
import { Picker } from "@react-native-picker/picker";

const generatePickerOptions = () => {
  const pickerOptions = [];

  for (let i = 1; i <= 10; i++) {
    pickerOptions.push(
      <Picker.Item label={`${i} hour${i > 1 ? "s" : ""}`} value={i} key={i} />
    );
  }

  return pickerOptions;
};

export { generatePickerOptions };
