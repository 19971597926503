import { useNavigation } from "@react-navigation/core";
import React, { useMemo } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import {
  Row,
  Separator,
  TappableBackground,
  BulletinRecipientsList,
  Text,
  Button,
  CustomIcon,
} from "~riata/components";
import { useGetBulletinRecipients } from "~riata/graphql";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWideDisplay } from "~riata/theme/common";

const BulletinRecipientsScreen = ({
  route: {
    params: { id },
  },
}) => {
  const navigation = useNavigation();
  const { newColors } = useColors();
  const { width } = useWindowDimensions();

  const { loading, recipientsList, hasNextPage, fetchNext } =
    useGetBulletinRecipients(id);

  const canFetchMore = !loading && hasNextPage;

  const isWide = useMemo(() => isWideDisplay(width), [width]);

  const sheetStyles = useComposeStyles(
    isWide ? styles.sheetWide : styles.sheet,
    { backgroundColor: newColors.surface.main }
  );

  return (
    <View style={isWide ? styles.containerWide : styles.container}>
      <TappableBackground onPress={() => navigation.goBack()} />
      <View style={sheetStyles}>
        <Row justify="center" align="center" style={styles.header}>
          <View style={styles.titleContainer}>
            <Text variant="title4">Recipients</Text>
          </View>
          <Button onPress={() => navigation.goBack()}>
            <CustomIcon name={"close"} size={20} />
          </Button>
        </Row>
        <Separator />
        <View style={styles.statsContainer}>
          <BulletinRecipientsList
            recipientsList={recipientsList}
            canFetchMore={canFetchMore}
            isLoading={loading}
            fetchNext={fetchNext}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  containerWide: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  sheet: {
    backgroundColor: "transparent",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    maxHeight: "70%",
    minHeight: "35%",
  },
  sheetWide: {
    maxHeight: "70%",
    minHeight: "35%",
    width: "70%",
    backgroundColor: "white",
    borderRadius: 12,
  },
  header: {
    padding: 16,
  },
  statsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingBottom: 16,
  },
  titleContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -20,
  },
});

export default BulletinRecipientsScreen;
