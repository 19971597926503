import React, { useCallback, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Dimensions,
  StyleSheet,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from "react-native";
import {
  PinchGestureHandler,
  State,
  TapGestureHandler,
  TouchableOpacity,
} from "react-native-gesture-handler";
import Animated, {
  and,
  block,
  Clock,
  cond,
  diff,
  eq,
  lessThan,
  multiply,
  neq,
  or,
  set,
  stopClock,
  sub,
  useCode,
} from "react-native-reanimated";
import {
  Vector,
  pinchActive,
  pinchBegan,
  translate,
  timing,
  usePinchGestureHandler,
  useTapGestureHandler,
  useValue,
  useVector,
  vec,
} from "react-native-redash/lib/module/v1";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { DocumentAttachmentPreview } from "./DocumentAttachmentPreview";
import { PdfAttachmentPreview } from "./PdfAttachmentPreview";
import { useFileViewer, useSaveImageToGallery } from "~riata/hooks";
import { decayVector } from "../AnimationUtils";
import {
  ImageAttachment,
  OfficeAttachment,
  PdfAttachment,
} from "~riata/generated/graphql";
import ImagePreview from "./ImagePreview";
import { calculateDimensions } from "./_utils";
import { measures } from "~riata/theme";

interface GalleryItemProps {
  panState: Animated.Node<State>;
  panTranslation: Vector;
  panVelocity: Vector;
  isActive: Animated.Node<0 | 1>;
  swipeX: Animated.Value<number>;
  attachment: ImageAttachment | OfficeAttachment | PdfAttachment;
}

const GalleryItem = ({
  panState,
  panTranslation,
  panVelocity,
  isActive,
  swipeX,
  attachment,
}: GalleryItemProps) => {
  const { width, height } = useWindowDimensions();
  const insets = useSafeAreaInsets();
  const { itemWidth, itemHeight } = useMemo(
    () =>
      width > height
        ? {
            itemWidth: width - insets.left - insets.right,
            itemHeight: height - measures.toolbarHeight,
          }
        : {
            itemWidth: width,
            itemHeight:
              height - insets.top - insets.bottom - measures.toolbarHeight,
          },
    [height, insets.bottom, insets.left, insets.right, insets.top, width]
  );

  const fullWidthStyle = useMemo(
    () =>
      StyleSheet.create({
        style: { width: itemWidth, height: itemHeight },
      }).style,
    [itemWidth, itemHeight]
  );

  const { showFile } = useFileViewer();
  const CANVAS = vec.create(itemWidth, itemHeight);
  const CENTER = vec.divide(CANVAS, 2);

  const shouldDecay = useValue(0);
  const clock = vec.create(new Clock(), new Clock());
  const origin = useVector(0, 0);
  const scale = useValue(1);
  const scaleOffset = useValue(1);
  const translation = vec.createValue(0, 0);
  const offset = useVector(0, 0);
  const {
    gestureHandler: pinchHandler,
    numberOfPointers,
    state: pinchState,
    scale: gestureScale,
    focal: pinchFocal,
  } = usePinchGestureHandler();

  const { gestureHandler: tapHandler, state: tapState } =
    useTapGestureHandler();

  const adjustedFocal = vec.sub(pinchFocal, vec.add(CENTER, offset));
  const minVec = vec.multiply(-0.5, CANVAS, sub(scale, 1));
  const maxVec = vec.minus(minVec);
  const clamped = vec.sub(
    vec.clamp(vec.add(offset, panTranslation), minVec, maxVec),
    offset
  );

  useCode(
    () =>
      block([
        //This view is the currently visible image and we are swiping. Set the x translation of the swiper to the distance from our snap point.
        cond(and(isActive, eq(panState, State.ACTIVE)), [
          vec.set(translation, clamped),
          set(swipeX, sub(panTranslation.x, clamped.x)),
        ]),
        //When pinch beings, set the origin to the focal of the pinch
        cond(pinchBegan(pinchState), [vec.set(origin, adjustedFocal)]),
        //While pinch is active, set translation to the focal of the pinch scaled by the pinch scale
        cond(pinchActive(pinchState, numberOfPointers), [
          vec.set(
            translation,
            vec.add(
              vec.sub(adjustedFocal, origin),
              origin,
              vec.multiply(-1, gestureScale, origin)
            )
          ),
        ]),
        //Snap back.  When the end the pinch zoomed out beyond 1 or when we double tap
        cond(
          or(
            and(
              or(eq(pinchState, State.END), eq(pinchState, State.UNDETERMINED)),
              lessThan(scale, 1)
            ),
            and(eq(tapState, State.END), neq(scale, 1))
          ),
          [
            set(scale, timing({ from: scale, to: 1 })),
            set(scaleOffset, scale),
            set(gestureScale, 1),
            vec.set(translation, 0),
            vec.set(pinchFocal, 0),
            vec.set(offset, 0),
          ]
        ),
        cond(
          and(
            isActive,
            or(eq(panState, State.END), eq(panState, State.UNDETERMINED)),
            or(eq(pinchState, State.END), eq(pinchState, State.UNDETERMINED))
          ),
          [
            vec.set(offset, vec.add(offset, translation)),
            set(scaleOffset, scale),
            set(gestureScale, 1),
            vec.set(translation, 0),
            vec.set(pinchFocal, 0),
          ]
        ),
        // when the pan and pinch end siultaneously, we should start decaying to slide to a stop.
        cond(
          and(
            isActive,
            neq(diff(panState), 0),
            eq(panState, State.END),
            neq(pinchState, State.ACTIVE)
          ),
          [set(shouldDecay, 1)]
        ),
        // if we should decay, decay towards the end of the image clamped to either end.
        cond(shouldDecay, [
          vec.set(
            offset,
            vec.clamp(
              decayVector(offset, vec.multiply(0.5, panVelocity), clock),
              minVec,
              maxVec
            )
          ),
        ]),
        // if pan or pinch is active, we do not need to decay and stop the clocks for the decay animation.
        cond(
          and(
            isActive,
            or(eq(panState, State.ACTIVE), eq(pinchState, State.ACTIVE))
          ),
          [stopClock(clock.x), stopClock(clock.y), set(shouldDecay, 0)]
        ),
        set(scale, multiply(gestureScale, scaleOffset)),
      ]),
    [adjustedFocal, origin, pinchState]
  );

  const [loading, setLoading] = useState(false);

  const { saveImage } = useSaveImageToGallery();

  const saveImageToGallery = useCallback(() => {
    const imageAttachment = attachment as ImageAttachment;
    saveImage(imageAttachment.url, imageAttachment.contentType);
  }, [attachment, saveImage]);

  if (attachment.__typename.includes("ImageAttachment")) {
    const imageAttachment = attachment as ImageAttachment;

    if (imageAttachment.contentType === "image/gif") {
      // GIFs will not render correctly when using Animated.Image
      const dimensions = calculateDimensions(
        imageAttachment.dimensions,
        Dimensions.get("window").width
      );
      return (
        <View style={[styles.gif_container, fullWidthStyle]}>
          <ImagePreview
            key={imageAttachment.url}
            style={styles.gif}
            dimensions={dimensions}
            uri={imageAttachment.url}
            resizeMode="contain"
            onLoadStart={() => setLoading(true)}
            onLoadEnd={() => setLoading(false)}
            onLongPress={saveImageToGallery}
          />
          {loading && (
            <ActivityIndicator size="large" style={styles.loadingIndicator} />
          )}
        </View>
      );
    }

    return (
      <TapGestureHandler {...tapHandler} numberOfTaps={2}>
        <Animated.View>
          <PinchGestureHandler {...pinchHandler}>
            <Animated.View
              key={imageAttachment.url}
              style={[styles.image_container, fullWidthStyle]}
            >
              <TouchableWithoutFeedback onLongPress={saveImageToGallery}>
                <Animated.Image
                  onLoadStart={() => setLoading(true)}
                  onLoadEnd={() => setLoading(false)}
                  source={{ uri: imageAttachment.url }}
                  style={[
                    styles.image,
                    {
                      transform: [
                        ...translate(vec.add(offset, translation)),
                        { scale },
                      ],
                    },
                  ]}
                />
              </TouchableWithoutFeedback>
              {loading && (
                <ActivityIndicator
                  size="large"
                  style={styles.loadingIndicator}
                />
              )}
            </Animated.View>
          </PinchGestureHandler>
        </Animated.View>
      </TapGestureHandler>
    );
  }

  if (DocumentAttachmentPreview.canDisplay(attachment)) {
    return (
      <TouchableOpacity
        onPress={() => {
          showFile(attachment);
        }}
        key={attachment.url}
        style={[fullWidthStyle, styles.button]}
      >
        <DocumentAttachmentPreview attachment={attachment} />
      </TouchableOpacity>
    );
  }
  if (PdfAttachmentPreview.canDisplay(attachment))
    return (
      <TouchableOpacity
        onPress={() => {
          showFile(attachment);
        }}
        key={attachment.url}
        style={[fullWidthStyle, styles.button]}
      >
        <PdfAttachmentPreview attachment={attachment} />
      </TouchableOpacity>
    );
};
const styles = StyleSheet.create({
  image_container: {
    overflow: "hidden",
  },
  gif_container: {
    overflow: "hidden",
    justifyContent: "center",
  },
  gif: {
    alignSelf: "center",
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    width: undefined,
    height: undefined,
    resizeMode: "contain",
  },
  loadingIndicator: {
    flex: 1,
    alignSelf: "center",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default GalleryItem;
