export enum BulletinFilter {
  All = "All",
  Unread = "Unread",
  Expired = "Expired",
  Read = "Read",
  Sent = "Sent",
  Scheduled = "Scheduled",
  Draft = "Draft",
}

export enum BulletinScreen {
  Compose = "Compose",
  Inbox = "Inbox",
  Draft = "Draft",
}
