import { createContext } from "react";

export type ClearFunction = (options: { rerender?: boolean }) => Promise<void>;

type Context = {
  clear: ClearFunction;
  get: () => false | Credentials;
  init: () => Promise<void>;
  latest: number;
  loaded: boolean;
  refresh: (options: { rerender?: boolean }) => Promise<boolean>;
  set: (
    credentials: Credentials,
    options?: { rerender?: boolean }
  ) => Promise<void>;
  triggerRerender: () => void;
};

const initial: Context = {
  clear: () => Promise.reject("CredentialsContext > clear > Not implemented"),
  get: () => false,
  init: () => Promise.reject("CredentialsContext > init > Not implemented"),
  latest: -0,
  loaded: false,
  refresh: () => Promise.reject("Credentials > refresh > Not implemented"),
  set: () => Promise.reject("Credentials > set > Not implemented"),
  triggerRerender: () => {},
};

export const CredentialsContext = createContext<Context>(initial);
