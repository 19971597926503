import React from "react";
import Icon from "react-native-vector-icons/Octicons";

import { useColors } from "~riata/hooks";

const DrawerIcon = (props) => {
  const { newColors } = useColors();

  return (
    <Icon
      {...props}
      size={18}
      name="three-bars"
      color={newColors.icon.surfaceContrast.main}
    />
  );
};

export default DrawerIcon;
