import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import { CustomIcon } from "../icons";
import { useColors } from "~riata/hooks";
import { isLocalAttachment } from "./_utils";
import { MimeTypeMap } from "~riata/graphql/mutations/CreateAttachment";
import { ContentType } from "~riata/generated/graphql";
import { OfficeAttachment } from "~riata/generated/graphql";
import { DEFAULT_THUMBNAIL_SIZE } from "./constants";
import { ATTACHMENT_PREVIEW_ICON_SIZE } from "~riata/theme";

const DocumentAttachmentPreview: GenericAttachmentView<OfficeAttachment> = ({
  attachment,
  thumbnailSize = DEFAULT_THUMBNAIL_SIZE,
  thumbnail = false,
}) => {
  const { colors } = useColors();

  const contentType = isLocalAttachment(attachment)
    ? MimeTypeMap[attachment.file.mime]
    : MimeTypeMap[attachment.contentType];

  let iconBackgroundColor = colors.icon.accent2.main;
  let icon = (
    <CustomIcon
      name="doc"
      size={thumbnail ? thumbnailSize / 2 : ATTACHMENT_PREVIEW_ICON_SIZE}
      style={{ color: colors.icon.accent2.on }}
    />
  );

  switch (contentType) {
    case ContentType.Ppt:
    case ContentType.Pptx: {
      iconBackgroundColor = colors.icon.accent3.main;
      icon = (
        <CustomIcon
          name="doc"
          size={thumbnail ? thumbnailSize / 2 : ATTACHMENT_PREVIEW_ICON_SIZE}
          style={{ color: colors.icon.accent3.on }}
        />
      );
      break;
    }
    case ContentType.Xls:
    case ContentType.Xlsx: {
      iconBackgroundColor = colors.icon.accent1.main;
      icon = (
        <CustomIcon
          name="doc"
          size={thumbnail ? thumbnailSize / 2 : ATTACHMENT_PREVIEW_ICON_SIZE}
          style={{ color: colors.icon.accent1.on }}
        />
      );
      break;
    }
  }

  return (
    <View
      style={[
        styles.preview,
        { backgroundColor: iconBackgroundColor },
        thumbnail
          ? { ...styles.thumbnail, height: thumbnailSize, width: thumbnailSize }
          : {},
        isLocalAttachment(attachment) && attachment.loading
          ? styles.loading
          : {},
      ]}
    >
      {icon}
      {isLocalAttachment(attachment) && attachment.loading && (
        <ActivityIndicator
          color={colors.activityIndicator.lightContrast.main}
          style={styles.loadingIndicator}
        />
      )}
    </View>
  );
};

DocumentAttachmentPreview.canDisplay = (obj: any): obj is OfficeAttachment =>
  obj.__typename === "OfficeAttachment" ||
  obj?.file?.mime?.includes("application/");

const styles = StyleSheet.create({
  thumbnail: {
    marginTop: 0,
    width: 48,
    height: 48,
  },
  preview: {
    flexDirection: "column",
    borderRadius: 6,
    height: 96,
    width: 96,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "white",
  },
  loading: {
    opacity: 0.5,
  },
  loadingIndicator: {
    position: "absolute",
    width: 24,
    height: 24,
    left: 12,
    top: 12,
  },
});

export { DocumentAttachmentPreview };
