import React from "react";
import moment from "moment";
import { Text } from "../typography";
import { useColors } from "~riata/hooks";

const SentTime = ({ date, color = undefined }) => {
  const { newColors } = useColors();

  const textColor = color || newColors.text.caption.main;

  const sentDate = date ? moment(date).format("h:mm A") : "";
  return (
    <Text variant="subhead" color={textColor}>
      {sentDate}
    </Text>
  );
};

export default SentTime;
