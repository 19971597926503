import { useQuery } from "@apollo/react-hooks";
import { WatchQueryFetchPolicy } from "apollo-client";
import gql from "graphql-tag";

export const QUERY = gql`
  query GetChannel($id: ID!, $query: String) {
    getChannel(filter: { id: $id }) {
      id
      name
      slug
      isDirect
      isMutedByUser
      isGroup
      unreadCount
      isArchived
      latestMessageAt
      members(filter: { query: $query }) {
        total
        edges {
          node {
            id
            username
            fullName
            displayName
            firstName
            lastName
            phone
            offDuty
            avatar {
              url
            }
            jobTitle
            company {
              id
              name
              offDuty
            }
            canMessage
            attributes {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useGetChannel = (
  id,
  query = "",
  fetchPolicy: WatchQueryFetchPolicy = "network-only"
) => {
  const { data, ...response } = useQuery(QUERY, {
    variables: { id, query },
    fetchPolicy,
  });
  const channel = data?.getChannel || null;
  return { data, channel, ...response };
};

export default useGetChannel;
