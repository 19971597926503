import React from "react";
import { StyleSheet } from "react-native";

import { Button } from "../../inputs/Button";
import { Row } from "../../layout";
import { Text } from "../../typography";
import { useColors } from "~riata/hooks";

const DeckHeader = ({ title, onViewAll = null }) => {
  const { newColors } = useColors();
  return (
    <Row style={styles.container}>
      <Row.Left>
        <Text
          numberOfLines={1}
          variant="title5"
          color={newColors.text.subtitle.main}
        >
          {title}
        </Text>
      </Row.Left>
      {onViewAll && (
        <Row.Right>
          <Button onPress={onViewAll}>
            <Text variant="title5" fontWeight="bold">
              View All
            </Text>
          </Button>
        </Row.Right>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
});

export default DeckHeader;
