import React, { useCallback } from "react";
import { Image, Platform, StyleSheet } from "react-native";

import { Avatar } from "../Avatar";
import { Sentry } from "~riata/utils/sentry";
import { useColors, useComposeStyles, useFeatureFlags } from "~riata/hooks";
import { useMemo } from "react";
import { Button } from "~riata/components/inputs/NewButton";
import { useGetCurrentUser } from "~riata/graphql";
import { useNavigation } from "@react-navigation/native";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

export const MEDIUM_ICON_SIZE = 30;

const UserAvatar = ({
  user = null,
  size = MEDIUM_ICON_SIZE,
  borderWidth = 1,
  outlined = false,
  shadow = false,
  showStatus = false,
  empty = false,
  disabled = false,
  ...props
}) => {
  const currentUser = useGetCurrentUser();
  const navigation = useNavigation<any>();
  const { setCheckpoint } = useDrawerNavigation();
  const { flags } = useFeatureFlags();
  const { container = {}, ...rest } = props.style || {};
  const { newColors } = useColors();

  const composedStyle = useComposeStyles(styles.container, props.style ?? {});

  const containerStyles = useMemo(() => {
    const shadowStyle = {
      shadowColor: newColors.shadow.main,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.08,
      shadowRadius: 6,

      elevation: 3,
    };

    return StyleSheet.flatten([composedStyle, shadow ? shadowStyle : {}]);
  }, [composedStyle, newColors, shadow]);

  const imageContainerStyles = useComposeStyles(
    {
      borderRadius: size / 2,
      width: size,
      height: size,
      borderWidth: outlined ? borderWidth : 0,
    },
    {
      backgroundColor: newColors.status.disabled.main,
    }
  );

  const imageStyles = useMemo(() => {
    return StyleSheet.flatten([
      imageContainerStyles,
      container,
      rest,
      styles.image,
    ]);
  }, [imageContainerStyles, container, rest]);

  const onPressDisabled = useMemo(() => {
    if (!flags.clickableAvatars || !user?.id) {
      return true;
    }

    return disabled || user?.id === currentUser.user.id;
  }, [user, disabled, currentUser, flags]);

  const onPress = useCallback(() => {
    if (!flags.clickableAvatars) {
      return;
    }
    // Setting current navigation state as checkpoint so we can return to it after
    // backing from the ProfileScreen
    setCheckpoint();

    if (user?.id) {
      navigation.navigate("ProfileScreen", {
        user: user.id,
      });
    }
  }, [user, navigation, flags, setCheckpoint]);

  if (empty) {
    const emptyStyles = StyleSheet.flatten([containerStyles, styles.empty]);
    return (
      <Button
        disabled={onPressDisabled}
        onPress={onPress}
        testID="UserAvatar-container"
        style={emptyStyles}
      />
    );
  }

  if (!user && !props.uriPath) {
    Sentry.captureEvent({
      logger: "UnexpectedState",
      message: "UserAvatar invoked without user or uriPath",
      extra: { user, props },
    });
  }

  if (props.uriPath || user?.avatar) {
    const uri = props.uriPath ? props.uriPath : user.avatar.url;

    return (
      <Button
        disabled={onPressDisabled}
        onPress={onPress}
        testID="UserAvatar-container"
        style={containerStyles}
      >
        <Image style={imageStyles} source={{ uri: uri }} />
      </Button>
    );
  }

  return (
    <Button
      disabled={onPressDisabled}
      onPress={onPress}
      testID="UserAvatar-container"
      style={containerStyles}
    >
      <Avatar
        user={user}
        width={size}
        height={size}
        outlined={outlined}
        outlinedColor={showStatus ? newColors.status.active.main : undefined}
      />
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: null,
    height: 40,
    justifyContent: "center",
    paddingTop: 0,
    width: 40,
  },
  outlined: {
    borderWidth: 2,
  },
  empty: {
    height: 0,
  },
  image: {
    ...Platform.select({
      android: { resizeMode: null },
      default: { resizeMode: "contain" },
    }),
  },
});

export default UserAvatar;
