import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { fragments } from "../fragments";
import { PageInfo } from "~riata/generated/graphql";
import { Sentry } from "~riata/utils/sentry";

export const GET_BULLETINS = gql`
  query GetBulletins($filter: BulletinFilters, $first: Int, $before: String) {
    getBulletins(filter: $filter, first: $first, before: $before) {
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...BulletinContent
        }
      }
    }
  }

  ${fragments.Bulletin.Content}
`;
const NULL_PAGE_INFO: PageInfo = {
  startCursor: null,
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const subscriber = {
  type: "Bulletin",
  execute: async ({ client, data }) => {
    const query = GET_BULLETINS;
    let prev;
    try {
      prev = client.readQuery({ query });
    } catch (err) {
      // TODO Add additional context to this showing user, bulletin, etc.
      Sentry.captureException(err);
      return;
    }
    const cursor = `bulletins:bulletin:${data.node.id}`;
    const updated = {
      getBulletins: {
        __typename: prev.getBulletins.__typename,
        pageInfo: {
          ...prev.getBulletins.pageInfo,
          endCursor: cursor,
          hasNextPage: false,
        },
        edges: [
          ...prev.getBulletins.edges,
          {
            __typename: "BulletinEdge",
            cursor,
            node: data.node,
          },
        ],
      },
    };
    client.writeQuery({ query, data: updated });
  },
};

export const useGetBulletins = (props) => {
  const filter = props?.filter || {};
  const fetchPolicy = props?.fetchPolicy ?? "cache-and-network";
  const variables = { filter, first: 20, before: null };

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_BULLETINS, {
    variables,
    fetchPolicy,
  });

  const pageInfo = data?.getBulletins?.pageInfo ?? NULL_PAGE_INFO;
  const updateQuery = (prev, { fetchMoreResult }) => {
    const updated = {
      getBulletins: {
        __typename: prev?.getBulletins?.__typename,
        pageInfo: {
          ...pageInfo,
          hasNextPage: fetchMoreResult.getBulletins.pageInfo.hasNextPage,
          endCursor: fetchMoreResult.getBulletins.pageInfo.endCursor,
        },
        edges: [
          ...prev?.getBulletins?.edges,
          ...fetchMoreResult.getBulletins.edges,
        ],
      },
    };
    return updated;
  };

  const fetchPrevious = () => {
    if (pageInfo.hasNextPage)
      fetchMore({
        variables: { ...variables, before: pageInfo.endCursor },
        updateQuery,
      });
  };

  // TODO: This doesn't really do anything.
  const onSearch = (text) => {
    // TODO Do something here
  };
  const bulletins = data?.getBulletins
    ? data.getBulletins.edges.map((a) => a.node)
    : [];
  return {
    bulletins,
    loading,
    error,
    onSearch,
    fetchPrevious,
    refetch,
  };
};
