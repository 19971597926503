import ApolloClient from "apollo-client";
import gql from "graphql-tag";
import { AuthInput } from "~riata/generated/graphql";
import { AuthMutationQuery } from "../mutations";
import * as GetCurrentUser from "../queries/GetCurrentUser";

const ON_EDIT_USER_SUBSCRIPTION = gql`
  subscription ($token: String!, $user: ID!) {
    onEditUser(token: $token, user: $user) {
      id
      firstName
      lastName
      offDuty
    }
  }
`;

export const initializeOffDutySubscription = async (
  client,
  token,
  navigation,
  user_id,
  checkAndHideBanner
) => {
  const subscriber = GetCurrentUser.subscriber;
  const apolloClient = client as ApolloClient<object>;
  const variables: { input: AuthInput } = {
    input: { bearer: token },
  };

  // Get authentication token
  const response = await apolloClient.mutate({
    mutation: AuthMutationQuery,
    variables,
  });

  console.log({ token: response.data.auth.token, user: user_id });

  const subscription = await apolloClient.subscribe({
    query: ON_EDIT_USER_SUBSCRIPTION,
    variables: { token: response.data.auth.token, user: user_id },
  });

  console.log("Returned Subscription");

  if (!subscription) {
    console.log(
      "initializeOffDutySubscription  apolloClient.subscribe({...}) => returned null"
    );
  }

  return subscription.subscribe({
    error: (error) => {
      console.error(
        "initializeOffDutySubscription  subscription.subscribe({...})  error",
        error
      );
    },

    next: (subscriptionData) => {
      subscriber.execute({
        data: subscriptionData.data.onEditUser,
        navigation,
        checkAndHideBanner,
      });
    },
  });
};
