import React from "react";
import {
  StyleSheet,
  Text as RNText,
  TextProps as RNTextProps,
} from "react-native";
import { useColors } from "~riata/hooks";
import { fonts } from "~riata/theme/common";
import { newFonts } from "~riata/theme/fonts";

type TextProps = RNTextProps & {
  alignment?: "center";
  color?: string;
  variant?:
    | "largeTitle"
    | "title1"
    | "title2"
    | "newTitle2"
    | "title3"
    | "title4"
    | "title5"
    | "title6"
    | "title7"
    | "subtitle"
    | "headline"
    | "body"
    | "bold"
    | "callout"
    | "subhead"
    | "newSubhead"
    | "footnote"
    | "newFootnote"
    | "caption1"
    | "newCaption1"
    | "caption2"
    | "newCaption2"
    | "figure"
    | "label1"
    | "preview"
    | "caption3"
    | "caption4";
  fontWeight?: "bold" | "regular" | "mediumBold" | "extraBold";
  fontStyle?: "italic" | "regular";
  decoration?: "underlined" | "regular";
  children: React.ReactNode;
};

type CustomTextProps = Partial<TextProps>;

const Text = ({
  alignment,
  variant,
  color,
  fontWeight,
  fontStyle,
  decoration,
  ...props
}: TextProps) => {
  const { newColors } = useColors();

  const overrideStyles = props.style;

  if (variant) {
    props.style = StyleSheet.compose(props.style, variants[variant]);
  }

  if (alignment) {
    props.style = StyleSheet.compose(props.style, alignments[alignment]);
  }

  if (color) {
    props.style = StyleSheet.compose(props.style, {
      color: color,
    });
  } else {
    props.style = StyleSheet.compose(props.style, {
      color: newColors.text.regular.main,
    });
  }

  if (fontWeight) {
    props.style = StyleSheet.compose(props.style, fontWeights[fontWeight]);
  }

  if (fontStyle) {
    props.style = StyleSheet.compose(props.style, fontStyles[fontStyle]);
  }

  if (decoration) {
    props.style = StyleSheet.compose(props.style, decorations[decoration]);
  }

  // Pass in provider styles
  props.style = StyleSheet.compose(props.style, overrideStyles);

  return <RNText {...props} />;
};

const alignments = StyleSheet.create({
  center: {
    alignSelf: "center",
    textAlign: "center",
  },
});

const fontWeights = StyleSheet.create({
  regular: {
    fontWeight: "400",
  },
  extraBold: {
    fontWeight: "700",
  },
  bold: {
    fontWeight: "600",
  },
  mediumBold: {
    fontWeight: "500",
  },
});

const fontStyles = StyleSheet.create({
  regular: {
    fontStyle: "normal",
  },
  italic: {
    fontStyle: "italic",
  },
});

const decorations = StyleSheet.create({
  underlined: {
    textDecorationLine: "underline",
  },
  regular: {
    textDecorationLine: "none",
  },
});

const variants = StyleSheet.create({
  largeTitle: fonts.largeTitle,
  title1: fonts.title1,
  title2: fonts.title2,
  newTitle2: newFonts.title2,
  title3: fonts.title3,
  title4: fonts.title4,
  title5: newFonts.title5,
  title6: newFonts.title6,
  title7: newFonts.title7,
  subtitle: newFonts.subtitle,
  headline: fonts.headline,
  body: fonts.body,
  callout: fonts.callout,
  subhead: fonts.subhead,
  newSubhead: newFonts.subhead,
  footnote: fonts.footnote,
  newFootnote: newFonts.footnote,
  caption1: fonts.caption1,
  newCaption1: newFonts.caption1,
  caption2: fonts.caption2,
  newCaption2: newFonts.caption2,
  caption3: newFonts.caption3,
  caption4: newFonts.caption4,
  label1: newFonts.label1,
  figure: newFonts.figure,
  preview: newFonts.preview,
});

export default Text;
export type { CustomTextProps };
