import React from "react";
import { useMemo } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import Animated, {
  add,
  and,
  clockRunning,
  cond,
  divide,
  eq,
  floor,
  multiply,
  neq,
  not,
  onChange,
  set,
  sub,
  useCode,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  clamp,
  snapPoint,
  timing,
  useClock,
  usePanGestureHandler,
  useValue,
} from "react-native-redash/lib/module/v1";

import GalleryItem from "./GalleryItem";
import { useColors, useComposeStyles } from "~riata/hooks";

const ImageSwiper = ({ attachments, index }) => {
  const { width, height } = useWindowDimensions();
  const { newColors } = useColors();
  const insets = useSafeAreaInsets();

  const swipeContainerStyles = useComposeStyles(
    { ...StyleSheet.absoluteFillObject },
    {
      backgroundColor: newColors.background.main,
    }
  );

  const { scrollWidth, scrollHeight, itemWidth } = useMemo(
    () =>
      width > height
        ? {
            itemWidth: width - insets.left - insets.right,
            scrollWidth:
              (width - insets.left - insets.right) * attachments?.length ?? 0,
            scrollHeight: height,
          }
        : {
            itemWidth: width,
            scrollWidth: width * attachments?.length ?? 0,
            scrollHeight: height - insets.top - insets.bottom,
          },
    [
      width,
      height,
      insets.left,
      insets.right,
      insets.top,
      insets.bottom,
      attachments,
    ]
  );
  const imageContainerStyle = useMemo(
    () =>
      StyleSheet.create({
        style: {
          width: scrollWidth,
          height: scrollHeight,
          flexDirection: "row",
        },
      }).style,
    [scrollWidth, scrollHeight]
  );

  const snapPoints = useMemo(
    () =>
      attachments?.map((_, index) => {
        console.log(itemWidth * index);
        return index * -itemWidth;
      }),
    [attachments, itemWidth]
  );
  const clock = useClock();
  const swipeIndex = useValue(index);
  const offsetX = useValue(snapPoints[index]);
  const translateX = useValue(snapPoints[index]);
  const translationX = useValue(snapPoints[index]);
  const { gestureHandler, state, velocity, translation } =
    usePanGestureHandler();

  const to = useMemo(
    () =>
      clamp(
        snapPoint(translateX, velocity.x, snapPoints),
        multiply(-itemWidth, add(swipeIndex, 1)),
        multiply(-itemWidth, sub(swipeIndex, 1))
      ),
    [itemWidth, snapPoints, swipeIndex, translateX, velocity.x]
  );
  useCode(
    () => [
      onChange(
        translationX,
        cond(eq(state, State.ACTIVE), [
          set(translateX, add(offsetX, translationX)),
        ])
      ),
      cond(and(eq(state, State.END), neq(translateX, 0)), [
        set(translateX, timing({ clock, from: translateX, to })),
        set(offsetX, translateX),
        cond(not(clockRunning(clock)), [
          set(swipeIndex, floor(divide(translateX, -itemWidth))),
        ]),
      ]),
    ],
    []
  );

  return (
    attachments.length > 0 && (
      <View style={swipeContainerStyles}>
        <PanGestureHandler {...gestureHandler}>
          <Animated.View style={StyleSheet.absoluteFill}>
            <Animated.View
              style={[imageContainerStyle, { transform: [{ translateX }] }]}
            >
              {attachments.map((attachment, i) => (
                <GalleryItem
                  isActive={eq(swipeIndex, i)}
                  panTranslation={translation}
                  panVelocity={velocity}
                  panState={state}
                  swipeX={translationX}
                  key={attachment.node.id}
                  attachment={attachment.node}
                />
              ))}
            </Animated.View>
          </Animated.View>
        </PanGestureHandler>
      </View>
    )
  );
};

export default ImageSwiper;
