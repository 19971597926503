export enum Actions {
  ADD_ATTACHMENT,
  FINISH_ATTACHMENT,
  REMOVE_ATTACHMENT,
  SET_NAME,
  SET_CONTENT,
  TOGGLE_ADMIN,
  TOGGLE_PRIORITY,
  TOGGLE_REQUIRED,
  TOGGLE_COMMENTS,
  TOGGLE_VISIBILITY,
  RESET,
  UPDATE_ATTACHMENT,
  SET_SCHEDULED_AT,
  REMOVE_SCHEDULED_AT,
  SET_RECIPIENT_INBOXES,
  SET_EXPIRES,
  REMOVE_EXPIRES,
  SET_PREVIOUS_VALUES
}
