import React from "react";
import { StyleSheet, View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import WebView from "react-native-webview";
import { useRiataInjection } from "~riata/hooks/useRiataInjection";

type IntegrationAttachmentDetailProps = StackScreenProps<
  RootStackParamList,
  "IntegrationAttachmentDetail"
>;

// FIXME We should probably not be passing in the raw URL
const IntegrationAttachmentDetail = ({
  navigation,
  route,
}: IntegrationAttachmentDetailProps) => {
  const { actions, handleMessageDispatch, ref, toInject } = useRiataInjection({
    navigation,
    defaultBackScreen: "Integrations",
  });
  const uri = route?.params?.attachment;

  return (
    <View style={styles.mainContainer}>
      <WebView
        source={{ uri }}
        geolocationEnabled
        injectedJavaScriptBeforeContentLoaded={toInject}
        ref={ref}
        allowsBackForwardNavigationGestures
        onMessage={handleMessageDispatch}
        onNavigationStateChange={actions.onWebViewNavigationStateChange}
        containerStyle={styles.webView}
        webviewDebuggingEnabled={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  webView: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
});

export default IntegrationAttachmentDetail;
