import React, { useMemo } from "react";
import { Button } from "~riata/components/inputs";
import { BottomSheet, Row } from "~riata/components/layout";
import { Picker } from "@react-native-picker/picker";
import { StyleSheet } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";
import { generatePickerOptions } from "./utils";

const PickerSheet: PickerSheetComponent = ({
  isVisible,
  selectedValue,
  onValueChange,
  onDone,
}) => {
  const { newColors } = useColors();

  const pickerStyles = useComposeStyles(styles.picker, {
    backgroundColor: newColors.surface.main,
  });

  const pickerOptions = useMemo(() => generatePickerOptions(), []);

  if (!isVisible) {
    return null;
  }

  return (
    <BottomSheet snapPoints={[0.4]} onCloseHandler={onDone}>
      <Row justify="right" style={styles.headerContainer}>
        <Button onPress={onDone}>Done</Button>
      </Row>
      <Picker
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        mode="dropdown"
        style={pickerStyles}
      >
        {pickerOptions}
      </Picker>
    </BottomSheet>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: 16,
  },
  picker: {
    backgroundColor: "transparent",
  },
});

export default PickerSheet;
