import React from "react";
import { FlatList, FlatListProps, StyleSheet } from "react-native";

// Components
import { BulletinCard } from "./BulletinCard";
import { EmptyList } from "./EmptyList";

// Hooks
import { useErrorToast } from "~riata/hooks";

// Types
import { Bulletin } from "~riata/generated/graphql";

// TODO Should we just change bulletins to data?
type BasicFlatListProps = Omit<FlatListProps<Bulletin>, "data" | "renderItem">;
type BulletinCardListProps = BasicFlatListProps & {
  bulletins: Array<Bulletin>;
  loading?: boolean;
  style?: any; // TODO Fix this to a real type
  error?: any; // TODO Fix this to a real type
  fetchPrevious?: () => void;
};

type BulletinCardListComponent = (props: BulletinCardListProps) => JSX.Element;

const BulletinCardList: BulletinCardListComponent = ({
  bulletins,
  fetchPrevious = () => console.log("Not Implemented"),
  loading,
  style,
  error,
  ...props
}) => {
  const { RodioError, showToast, isDismissed } = useErrorToast(error);

  if (error && !isDismissed) {
    showToast("An error occurred while fetching bulletins...");
  }

  return (
    <>
      <FlatList
        {...props}
        data={bulletins}
        style={styles.flatList}
        contentContainerStyle={styles.contentContainer}
        onEndReached={() => {
          fetchPrevious();
        }}
        ListEmptyComponent={
          <EmptyList onRefresh={fetchPrevious} isVisible={!loading} />
        }
        onEndReachedThreshold={0.3}
        keyExtractor={(item, index) => item.id.toString()}
        renderItem={(item) => {
          const bulletin = item.item;
          const previous = bulletins[item.index - 1];
          return (
            <BulletinCard bulletin={bulletin} previousBulletin={previous} />
          );
        }}
      />
      <RodioError />
    </>
  );
};

const styles = StyleSheet.create({
  flatList: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  loaderContainer: {
    marginTop: 30,
  },
  contentContainer: {
    paddingHorizontal: 16,
    gap: 12,
    paddingBottom: 32,
  },
});

export default BulletinCardList;
