import React from "react";
import { FlatList, StyleSheet } from "react-native";

import { Text } from "../../typography";
import { BookmarkCard } from "~riata/generated/graphql";
import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs/Button";
import { Card, Row } from "~riata/components/layout";
import { useColors, useComposeStyles, useLinkOpener } from "~riata/hooks";
type BookmarkCards = Array<BookmarkCard>;

type BookmarkCardListProps = {
  cards: BookmarkCards;
};

const BookmarkCardList = ({ cards }: BookmarkCardListProps) => {
  const { newColors } = useColors();
  const linkOpener = useLinkOpener();

  const numColumns = cards.length > 2 ? 2 : cards.length;

  const cardStyles = useComposeStyles(styles.card, {
    backgroundColor: newColors.card.bookmark.main,
    borderColor: newColors.card.bookmark.border,
  });

  const renderBookmarkCard = (item) => {
    const card = item.item;
    const firstItem = item.index <= 1;
    const actionUrl = card.actionURL;

    const containerStyles = firstItem
      ? styles.firstItemContainer
      : styles.container;

    return (
      <Card rounded spaced={!firstItem} style={[containerStyles, cardStyles]}>
        <Button
          onPress={() => {
            linkOpener(actionUrl, card.isExternal, card.id);
          }}
        >
          <Row align="center" justify="between">
            <CustomIcon
              size={48}
              name={(card as any).bookmarkIcon}
              color={newColors.card.bookmark.on}
            />
            <Text
              numberOfLines={2}
              ellipsizeMode="tail"
              variant="headline"
              color={newColors.card.bookmark.on}
              style={styles.text}
            >
              {card.name}
            </Text>
          </Row>
        </Button>
      </Card>
    );
  };

  return (
    <FlatList
      key={`bookmark-card-list-${numColumns}`}
      data={cards}
      keyExtractor={(item) => item.id}
      numColumns={numColumns}
      renderItem={renderBookmarkCard}
    />
  );
};

const styles = StyleSheet.create({
  card: {
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
  firstItemContainer: {
    flexGrow: 1,
    padding: 12,
    marginTop: 0,
    marginHorizontal: 12,
    marginBottom: 12,
  },
  container: {
    flexGrow: 1,
    padding: 12,
  },
  text: {
    flex: 1,
    textAlign: "left",
    marginLeft: 16,
  },
});

export default BookmarkCardList;
