import React from "react";
import { StyleSheet } from "react-native";
import moment from "moment";

import { Text } from "../typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const BulletinDateSubtitle = ({ date }) => {
  const { newColors } = useColors();

  const textStyles = useComposeStyles(styles.text, {
    color: newColors.text.footnote.main,
  });

  if (!date) {
    date = new Date();
    console.debug("FIXME: Got unknown date");
  }

  return (
    <Text style={textStyles} variant="caption2">
      {moment(date).fromNow()}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    marginLeft: "auto",
    alignSelf: "flex-end",
  },
});

export default BulletinDateSubtitle;
