import React from "react";
import { StyleSheet, View } from "react-native";
import { CustomIcon } from "../icons";
import { useColors, useComposeStyles } from "~riata/hooks";
import { avatarSize } from "~riata/theme/common";
import { useConcatStyles } from "~riata/hooks/useConcatStyles";

const TeamChannelAvatar = ({ channel, unread = false, size = "small" }) => {
  const { randomColor, colors } = useColors();

  const containerColor = randomColor(channel.id);

  const indicatorStyle = useComposeStyles(styles.indicator, {
    backgroundColor: colors.status.inactive.main,
  });

  const containerStyles = useConcatStyles([
    styles.container,
    { backgroundColor: containerColor.main },
    { width: avatarSize[size], height: avatarSize[size] },
  ]);

  return (
    <View style={containerStyles}>
      <CustomIcon
        name="channel"
        size={24}
        node={channel}
        color={containerColor.on}
      />
      {unread && <View style={indicatorStyle} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: avatarSize.normal,
    height: avatarSize.normal,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    borderWidth: 2,
    borderColor: "transparent",
  },
  indicator: {
    position: "absolute",
    width: 16,
    height: 16,
    borderRadius: 8,
    top: 0,
    right: -5,
    backgroundColor: "transparent",
  },
});

export default TeamChannelAvatar;
