import React from "react";
import { StyleSheet } from "react-native";
import { Linking, TouchableOpacity, View } from "react-native";

import { Text } from "../typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isLocalAttachment } from "./_utils";
import { TwitterAttachment } from "~riata/generated/graphql";

const TwitterAttachmentView: GenericAttachmentView<TwitterAttachment> = ({
  attachment,
}) => {
  const { colors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: colors.icon.accent2.main,
    borderLeftColor: colors.icon.accent2.main,
  });

  if (isLocalAttachment(attachment)) {
    // This shouldn't happen, but just in case we get a bad attachment
    return null;
  }

  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(attachment.url);
      }}
    >
      <View style={containerStyles}>
        <Text style={styles.text}>From Twitter</Text>
        <Text>{attachment.content}</Text>
      </View>
    </TouchableOpacity>
  );
};

TwitterAttachmentView.canDisplay = (obj: any): obj is TwitterAttachment =>
  obj?.__typename === "TwitterAttachment";

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderLeftWidth: 2,
  },
  text: {
    fontWeight: "600",
    fontStyle: "italic",
  },
});

export default TwitterAttachmentView;
