import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { Text } from "../../typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { fonts } from "~riata/theme/common";

const NULL_STYLE = StyleSheet.create({ style: {} });
const Button = ({
  disabled = false,
  color = "transparent",
  variant = null,
  style = NULL_STYLE.style,
  children,
  ...props
}) => {
  const { newColors } = useColors();
  const themedStyle = useComposeStyles(styles[color], {
    backgroundColor: (newColors.button[color] ?? {})[
      disabled ? "disabled" : "main"
    ],
  });
  const themedText = useComposeStyles(styles[`${color}Text`], {
    color: newColors.button[color].on,
  });

  if (typeof children === "string") {
    children = <Text style={themedText}>{children}</Text>;
  }
  return (
    <Pressable
      style={[themedStyle, styles[variant], style]}
      {...props}
      disabled={disabled}
    >
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  default: { backgroundColor: "transparent" },
  primary: {
    backgroundColor: "transparent",
  },

  primaryText: {
    ...fonts.body,
    textAlign: "center",
    alignSelf: "center",
    color: "transparent",
  },

  rounded: {
    borderRadius: 100,
    paddingVertical: 12,
    paddingHorizontal: 36,
    alignItems: "center",
  },

  form: {
    borderRadius: 16,
  },

  filter: {
    backgroundColor: "white",
    borderRadius: 8,
  },
});

export default Button;
