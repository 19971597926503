import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import { useColors } from "~riata/hooks";

type CardProps = ViewProps & {
  rounded?: boolean;
  spaced?: boolean;
  color?: "primary" | "danger" | "warning" | "success" | "notice";
  customColor?: string;
};

const Card = ({
  rounded = false,
  spaced = false,
  color,
  customColor,
  ...props
}: CardProps) => {
  const { colors, newColors } = useColors();
  const preExistingStyles = props.style ?? {};

  props.style = StyleSheet.compose(props.style, styles.cardContainer);
  props.style = StyleSheet.compose(props.style, {
    backgroundColor: newColors.surface.main,
    shadowColor: newColors.shadow.main,
  });

  if (color) {
    props.style = StyleSheet.compose(props.style, {
      backgroundColor: colors.actionCard[color].main,
    });
  }

  if (customColor) {
    props.style = StyleSheet.compose(props.style, {
      backgroundColor: customColor,
    });
  }

  if (rounded) {
    props.style = StyleSheet.compose(props.style, styles.rounded);
  }

  if (spaced) {
    props.style = StyleSheet.compose(props.style, styles.spaced);
  }

  props.style = StyleSheet.compose(props.style, preExistingStyles);

  return <View {...props} />;
};

const styles = StyleSheet.create({
  cardContainer: {
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.04,
    shadowRadius: 1,
    elevation: 2,
    backgroundColor: "transparent",
  },
  rounded: {
    borderRadius: 8,
    padding: 0,
  },
  spaced: {
    marginHorizontal: 12,
    marginVertical: 12,
    padding: 0,
  },
});

export default Card;
