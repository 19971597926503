import React from "react";
import { BulletinComposeInputComponent } from "./types";
import { Row } from "~riata/components/layout";
import { TextInput } from "~riata/components/inputs";
import { Button } from "~riata/components/inputs/NewButton";
import { CustomIcon, DownIcon } from "~riata/components/icons";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Alert, Platform, StyleSheet } from "react-native";

const BulletinComposeInput: BulletinComposeInputComponent = ({
  composeActions,
  composeState,
  onDelete,
}) => {
  const { newColors } = useColors();
  const dropDownButtonStyles = useComposeStyles(styles.dropDownButton, {
    transform: [{ rotate: composeState.adminVisible ? "180deg" : "0deg" }],
  });

  const onDeletePress = () => {
    const message = "Are you sure you want to delete this bulletin?";
    if (Platform.OS !== "web") {
      Alert.alert("Delete Draft", message, [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Delete",
          onPress: onDelete,
          style: "destructive",
        },
      ]);
    } else {
      if (window.confirm(message)) {
        onDelete();
      }
    }
  };

  return (
    <Row style={styles.inputContainer} align="center">
      <TextInput
        onChangeText={composeActions.setName}
        fill
        placeholder="Bulletin title..."
        inputStyles={styles.input}
        value={composeState.payload.name}
      />
      <Button
        onPress={composeActions.toggleAdmin}
        accessibilityLabel="Show bulletin admin"
        style={dropDownButtonStyles}
      >
        <DownIcon size={16} />
      </Button>
      {composeState.payload.isDraft && (
        <Button onPress={onDeletePress}>
          <CustomIcon name="trash_can" color={newColors.icon.danger.main} size={16} />
        </Button>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  input: {
    fontSize: 16,
    fontFamily: "HelveticaNeue",
    lineHeight: 20,
  },
  inputContainer: {
    height: 70,
    gap: 8,
    paddingRight: 8,
  },
  dropDownButton: {
    paddingBottom: 8,
  },
});

export default BulletinComposeInput;
