import React, { useMemo } from "react";
import { BulletinComposeHeaderComponent } from "./types";
import { AppHeader, Row } from "~riata/components/layout";
import { HeaderActionButton } from "~riata/components/misc";

const BulletinComposeHeader: BulletinComposeHeaderComponent = ({
  composeState,
  navigationCallback,
  onBack,
  onSave,
}) => {
  const attachmentsLoading = useMemo(
    () => !composeState.attachments.every((a) => a.loading === false),
    [composeState.attachments]
  );

  const disableNext =
    composeState.payload.name === "" ||
    composeState.payload.content === "" ||
    attachmentsLoading;

  const onNext = () => {
    if (disableNext) {
      return;
    }

    navigationCallback("BulletinInboxSelect", {
      bulletin: {
        ...composeState.payload,
        attachments: composeState.attachments.map((a) => a.id),
        recipientInboxes: composeState.recipientInboxes || [],
      },
    });
  };

  return (
    <AppHeader
      left={<HeaderActionButton variant="back" onPress={onBack} />}
      title="Compose"
      right={
        <Row justify="right" style={{ gap: 8 }}>
          <HeaderActionButton variant="save" onPress={onSave} />
          <HeaderActionButton
            variant="next"
            disabled={disableNext}
            onPress={onNext}
          />
        </Row>
      }
    />
  );
};

export default BulletinComposeHeader;
