import { useCallback } from "react";
import { useAppStateChange } from "../useAppStateChange";
import { useCreateBulletin, useUpdateBulletin } from "~riata/graphql";
import { useIsFocused } from "@react-navigation/core";
import { resetBulletinNavigationAfterMutation } from "~riata/controllers/bulletins/helpers";
import { hasSomeTruthyValues } from "~riata/utils/object";
import { useFeatureFlags } from "../useFeatureFlags";

const useSaveBulletinAsDraft = ({ bulletin, screen, navigation }) => {
  // Decompose bulletin as create mutation won't accept recipientInboxes
  const { recipientInboxes, recipients, id, ...rest } = bulletin;
  const { execute: createBulletin } = useCreateBulletin();
  const { execute: updateBulletin } = useUpdateBulletin();
  const { flags } = useFeatureFlags();

  const isFocused = useIsFocused();

  const background = useCallback(() => {
    // Prevent previous mounted screen to call this listener
    if (!isFocused) return;

    if (bulletin.isDraft) {
      try {
        updateBulletin(
          {
            ...rest,
            id,
            recipientInboxes: bulletin.recipients || [],
          },
          flags.expiringBulletins
        );
      } catch {
        return;
      }
    } else {
      try {
        if (hasSomeTruthyValues({ ...rest }, ["allowVisibility"])) {
          createBulletin(
            {
              ...rest,
              isDraft: true,
              recipients,
            },
            flags.expiringBulletins
          );

          resetBulletinNavigationAfterMutation(navigation, screen);
        }
      } catch {
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulletin, screen, navigation]);

  useAppStateChange({
    background,
  });
};

export { useSaveBulletinAsDraft };
