import React from "react";
import { useColors } from "~riata/hooks";
import { createStackNavigator } from "@react-navigation/stack";
import { ConversationInfoStack } from "../ChannelInfoSheet";
import { ConversationScreen } from "../ConversationScreen";
import { MessageActionSheet } from "../MessageActionSheet";
import {
  AttachmentActionSheet,
  AttachmentImageGallery,
  IntegrationAttachmentActionSheet,
} from "../../attachments";
import { ReactionDetailsScreen } from "../ReactionDetailsScreen";

const Stack = createStackNavigator();

const ConversationStack = () => {
  const { newColors } = useColors();
  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        cardOverlayEnabled: true,
        cardStyle: { backgroundColor: "transparent", opacity: 1, flex: 1 },
        cardStyleInterpolator: ({ current: { progress } }) => ({
          cardStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 0.5, 0.9, 1],
              outputRange: [0, 0.25, 0.7, 1],
            }),
            transform: [
              {
                scale: progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                  extrapolate: "clamp",
                }),
              },
            ],
          },
          overlayStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
              extrapolate: "clamp",
            }),
            // Has no effect on native, but is _required_ for web to work
            // Without the backdropFilter, the ConversationDetail screen has its
            // content bleed through to the modal. Not sure why.
            backdropFilter: "blur(1px)",
          },
        }),
        headerStyle: {
          backgroundColor: newColors.header.main,
        },
        presentation: "transparentModal",
      })}
    >
      <Stack.Screen
        name="ConversationDetail"
        component={ConversationScreen}
        options={{ title: "" }}
      />

      <Stack.Screen
        name="ConversationInfo"
        component={ConversationInfoStack}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MessageActionSheet"
        component={MessageActionSheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AttachmentActionSheet"
        component={AttachmentActionSheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="IntegrationAttachmentActionSheet"
        component={IntegrationAttachmentActionSheet}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AttachmentImageGallery"
        component={AttachmentImageGallery}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ReactionDetailScreen"
        component={ReactionDetailsScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default ConversationStack;
