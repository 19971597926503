import React, { useMemo } from "react";
import { TouchableOpacity, View, FlatList, StyleSheet } from "react-native";

import { ActivityIndicator } from "../activityIndicator";
import { ulid } from "ulid";
import { UserAvatar } from "../avatars";
import { Text } from "../typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const SuggestionCellContainer = ({ suggestion, ...props }) => {
  const { newColors } = useColors();

  const cellContainerStyles = useComposeStyles(styles.cell, {
    borderBottomColor: newColors.separator.main,
  });

  const suggestionLabel = useMemo(() => {
    let label = suggestion.item.name;

    if (suggestion.type === "@" && suggestion.item.username) {
      label = label + ` (@${suggestion.item.username})`;
    }

    return label;
  }, [suggestion]);

  return (
    <TouchableOpacity {...props} style={cellContainerStyles}>
      {suggestion.item.type === "mention" && suggestion.avatar && (
        <UserAvatar user={suggestion.item}></UserAvatar>
      )}
      <Text
        variant="subhead"
        color={newColors.text.regular.main}
        style={styles.title}
      >
        {suggestionLabel}
      </Text>
    </TouchableOpacity>
  );
};

const generateRenderItem =
  (onSuggestionSelect, suggestionType) =>
  ({ item }) => {
    const suggestion = { item, type: suggestionType };
    return (
      <SuggestionCellContainer
        onPress={() => {
          onSuggestionSelect(suggestion);
        }}
        suggestion={suggestion}
      />
    );
  };

const randomKey = () => ulid();

const AutoCompleteList = ({
  loading = false,
  suggestions,
  suggestionType,
  onSuggestionSelect,
}) => {
  const renderItem = useMemo(() => {
    return generateRenderItem(onSuggestionSelect, suggestionType);
  }, [onSuggestionSelect, suggestionType]);

  return loading ? (
    <View style={styles.loading}>
      <ActivityIndicator />
    </View>
  ) : (
    <View style={styles.container}>
      <FlatList
        keyboardShouldPersistTaps="always"
        style={styles.list}
        data={suggestions}
        // Use a basically random ID here because we need to avoid collisions when the
        // same value is re-displayed. Not entirely sure why FlatList won't recognize
        // that the value is the same and simply reuse it which is the behavior _I_
        // anticipate, but not what's happening.
        keyExtractor={randomKey}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  cell: {
    width: "100%",
    paddingLeft: 15,
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    height: 40,
    flexDirection: "row",
  },
  loading: {
    width: "100%",
    height: 40,
    justifyContent: "center",
  },
  container: {
    width: "100%",
    maxHeight: 130,
    position: "relative",
    bottom: 0,
    flexDirection: "row",
  },
  title: {
    marginLeft: 10,
  },
  list: {
    flex: 1,
    maxHeight: 121,
  },
});

export default AutoCompleteList;
