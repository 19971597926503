import React from "react";
import { StyleSheet, View } from "react-native";

import { UserAvatar } from "../avatars";
import MessageListItemInfo from "./MessageListItemInfo";
import MarkdownContent from "~riata/components/MarkdownContent";
import MessageListItemRepliesButton from "./MessageListItemRepliesButton";
import { Row } from "../layout";
import { useColors, useComposeStyles } from "~riata/hooks";

// TODO This should render what it's provided with a footer for "View All Replies"
const MessageListItemRepliesItem = ({ message, index }) => {
  const { newColors } = useColors();

  const textStyles = useComposeStyles(styles.text, {
    color: newColors.text.regular.main,
  });

  switch (index) {
    case 0:
    case 1:
      return (
        <Row>
          <UserAvatar size={25} user={message.creator} />

          <View style={styles.container}>
            <MessageListItemInfo data={message} showName={true} />
            <MarkdownContent
              styleOverrides={{ text: textStyles }}
              text={message.content}
            />
          </View>
        </Row>
      );

    case 2:
      return <MessageListItemRepliesButton message={message} />;

    default:
      // FIXME Remove once this renders what it is provided
      return null;
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    marginTop: 2,
    fontSize: 15,
  },
});

export default MessageListItemRepliesItem;
