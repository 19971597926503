import React from "react";
import { StyleSheet, View } from "react-native";
import {
  AppHeader,
  Body,
  BulletinCardList,
  DeckHeader,
  Row,
  Text,
  RodioLoader,
  SafeAreaView,
  Separator,
  HeaderActionButton,
} from "~riata/components";
import { useGetBulletins, useGetHashtag } from "~riata/graphql";
import { useUpdateSelectedRouteIfFocused } from "~riata/hooks";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

const FolderDetailScreen = ({
  navigation,
  route: {
    params: { tag },
  },
}) => {
  const { loading, hashtag } = useGetHashtag({ name: tag });
  const { navigateTo, updateSelectedRoute } = useDrawerNavigation();
  const { bulletins } = useGetBulletins({
    filter: { hashtags: { query: tag }, isScheduled: false },
    fetchPolicy: "cache-and-network",
  });

  useUpdateSelectedRouteIfFocused(updateSelectedRoute);

  return (
    <SafeAreaView>
      <AppHeader
        left={
          <HeaderActionButton
            variant="back"
            onPress={() => navigateTo("Folders")}
          />
        }
        title={`#${tag}`}
      />
      {loading && !hashtag ? (
        <RodioLoader />
      ) : (
        <Body>
          <Row style={styles.analyticsContainer}>
            <View style={styles.centered}>
              <Text variant="subhead">Messages</Text>
              <Text variant="largeTitle">{hashtag?.messageCount}</Text>
            </View>
            <View style={styles.centered}>
              <Text variant="subhead">Bulletins</Text>
              <Text variant="largeTitle">{hashtag?.bulletinCount}</Text>
            </View>
          </Row>
          <Separator />
          <DeckHeader title="Bulletins" />
          <BulletinCardList bulletins={bulletins} loading={bulletins.loading} />
        </Body>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  header: { marginTop: 16, marginBottom: 8 },
  analyticsContainer: {
    marginBottom: 8,
    marginTop: 16,
  },
  centered: {
    flex: 1,
    alignItems: "center",
  },
});

export default FolderDetailScreen;
