import React from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment";

import UserInfoContainer from "../../UserInfoContainer";
import { Button } from "../../inputs/Button";
import { Card, Row } from "../../layout";
import { Separator } from "../../misc";
import { Text } from "../../typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import * as graphql from "~riata/generated/graphql";

type ContentCardProps = {
  data: graphql.ContentCard;
  first?: boolean;
  onPress: any;
};

const ContentCard = ({ data, first = false, onPress }: ContentCardProps) => {
  const [modal, setModal] = React.useState<boolean>();
  const toggleModal = () => setModal(!modal);

  const { newColors } = useColors();

  const hashtagColor =
    data.hashtags.edges.length && data.hashtags.edges[0].node.color
      ? data.hashtags.edges[0].node.color.color
      : newColors.tag.primary.main;

  const contentStyles = useComposeStyles(styles.content, {
    borderLeftColor: hashtagColor,
  });

  const cardContainerStyles = useComposeStyles(
    first ? styles.firstItemContainer : styles.container,
    {
      shadowColor: newColors.shadow.main,
    }
  );

  return (
    <Card key={data.id} rounded spaced={!first} style={cardContainerStyles}>
      <Button onPress={onPress} onLongPress={toggleModal}>
        <Row style={contentStyles}>
          <View style={styles.wrapper}>
            <Row justify="between">
              <Text
                numberOfLines={1}
                variant="headline"
                style={styles.nameWrapper}
                color={newColors.text.regular.main}
              >
                {data.name}
              </Text>
              <Text
                variant="footnote"
                style={styles.date}
                color={newColors.text.footnote.main}
              >
                {moment(data.createdAt).fromNow()}
              </Text>
            </Row>
            <Row>
              <Text
                numberOfLines={2}
                variant="footnote"
                color={newColors.text.footnote.main}
              >
                {data.body}
              </Text>
            </Row>
            <Separator style={styles.separator} />
            <Row>
              <UserInfoContainer
                user={data.creator}
                footer={data.footerExtra}
              />
            </Row>
          </View>
        </Row>
      </Button>
    </Card>
  );
};

const styles = StyleSheet.create({
  firstItemContainer: {
    marginTop: 0,
    marginHorizontal: 12,
    marginBottom: 12,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 2,
    elevation: 4,
  },
  container: {
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 2,
    elevation: 4,
  },
  nameWrapper: {
    flex: 1,
  },
  content: {
    borderLeftWidth: 8,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  wrapper: {
    flex: 1,
  },
  date: {
    opacity: 0.5,
    alignSelf: "flex-end",
    marginLeft: 8,
  },
  separator: {
    marginVertical: 8,
  },
});

export default ContentCard;
