import _ from "lodash";

const ranges = [
  "\u00a9",
  "\u00ae",
  "[\u2000-\u3300]",
  "\ud83c[\ud000-\udfff]",
  "\ud83d[\ud000-\udfff]",
  "\ud83e[\ud000-\udfff]",
  " ", // Also allow spaces
].join("|");

const SINGLE_EMOJI = new RegExp(`^(${ranges})$`);

export const isEmoji = (s) => s.match(SINGLE_EMOJI);

export const isEmojiOnly = (s) => {
  if (s.length > 10) {
    return false;
  }
  return _.toArray(s).every(isEmoji);
};

export enum TokenizedText {
  String,
  Hashtag,
  Mention,
}

const tokenFromMatch = (match) => {
  if (match[0].startsWith("#")) {
    return {
      type: TokenizedText.Hashtag,
      value: match[0],
    };
  } else if (match[0].startsWith("@")) {
    return {
      type: TokenizedText.Mention,
      value: match[0],
    };
  } else {
    return {
      type: TokenizedText.String,
      value: match,
    };
  }
};

/**
 * ! This method is no longer used
 * TODO: Remove
 * @param text
 * @returns
 */
export const tokenizeText = (text) => {
  const matches = text?.matchAll ? text.matchAll(/[#|@][^ ]+/g) : text;
  let tokens = [];
  var position = 0;
  for (const match of matches) {
    if (match.index > 0) {
      tokens.push({
        type: TokenizedText.String,
        value: text.substring(position, match.index),
      });
    }
    tokens.push(tokenFromMatch(match));
    position = match.index + match[0].length;
  }
  if (tokens.length === 0) {
    return [
      {
        type: TokenizedText.String,
        value: text,
      },
    ];
  }

  if (text.length > position) {
    tokens.push({
      type: TokenizedText.String,
      value: text.substring(position),
    });
  }
  return tokens;
};
