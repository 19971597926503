import React from "react";
import { Alert } from "react-native";
import { Linking } from "react-native";
import { AttributeBadge } from "~riata/components";

import { User } from "~riata/generated/graphql";
import { Sentry } from "~riata/utils/sentry";

const filterCurrentMembers = (
  users: Array<User>,
  currentMembers: Array<{ node: User }>
) => {
  const currentMembersMap = new Map<string, User>();
  currentMembers.forEach((member) => {
    currentMembersMap.set(member.node.id, member.node);
  });

  return users.filter((user) => !currentMembersMap.has(user.id));
};

const openPhoneNumber = (phoneNumber: string) => {
  Linking.canOpenURL(phoneNumber)
    .then((supported) => {
      if (!supported) {
        Sentry.captureEvent({
          logger: "Phone Service not Available",
          message: "Using OS phone services is not supported",
        });
        Alert.alert("Couldn't communicate with phone services");
      } else {
        return Linking.openURL(phoneNumber);
      }
    })
    .catch((err) => {
      Sentry.captureException(err);
      console.log(err);
    });
};

const generateAttributes = (attributes) => {
  if (attributes) {
    return attributes.map((attribute, idx) => (
      <AttributeBadge key={idx} title={attribute} />
    ));
  }

  return null;
};

export { openPhoneNumber, generateAttributes, filterCurrentMembers };
