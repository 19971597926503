import { CommonActions, useNavigation } from "@react-navigation/core";
import { useState } from "react";

import RodioError from "~riata/components/RodioError";
import { Sentry } from "~riata/utils/sentry";

const useErrorToast = (error) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  const navigation = useNavigation();

  const dismiss = () => {
    setIsDismissed(true);
    setModalVisible(false);

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: "Home" }],
      })
    );
  };

  const showToast = (text) => {
    if (!isModalVisible) {
      console.warn(text);
      Sentry.captureEvent({
        logger: "useErrorToast",
        message: error.message,
      });

      setModalVisible(true);
    }
  };

  return {
    RodioError: RodioError.withProps({
      isVisible: isModalVisible,
      error: error,
      onDismiss: dismiss,
    }),
    isDismissed,
    showToast,
  };
};

export default useErrorToast;
