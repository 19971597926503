import { useContext, useEffect } from "react";

import { RegistryContext } from "~riata/contexts";
import { useCreateAttachment } from "~riata/graphql";

import { Sentry } from "~riata/utils/sentry";
import {
  calculateDimensions,
  generateCreateAttachmentPromise,
  uploadAttachment,
} from "./helpers";

const useProcessAttachment = (
  { actions }: UseProcessAttachmentProps,
  channelId: string
) => {
  // TODO Error checking here
  const { execute: createAttachment } = useCreateAttachment();
  const registry = useContext(RegistryContext);

  useEffect(() => {
    registry.add("onAddAttachment", async (attachments) => {
      registry.remove("onAddAttachment");

      const mappedAttachments = await Promise.all(
        attachments.map(async (attachment) => {
          actions.addAttachment(attachment, channelId);

          if (
            attachment.mime?.includes("image") &&
            (!attachment.height || !attachment.width)
          ) {
            const dimensions = await calculateDimensions(attachment)
              .then((res) => res)
              .catch((err) => {
                console.error(err);
                Sentry.captureException(err);

                // Couldn't calculate dimensions, returning default dimensions
                return {
                  height: 250,
                  width: 250,
                };
              });

            // Include dimensions to current attachment before it's uploaded
            attachment.height = dimensions.height;
            attachment.width = dimensions.width;
          }
          attachment.createAttachment = generateCreateAttachmentPromise(
            attachment,
            createAttachment
          );

          return attachment;
        })
      );

      const responses = await Promise.all(
        mappedAttachments.map((attachment) => attachment.createAttachment)
      );

      responses.forEach(async (response, index) => {
        const { id } = response.data.createAttachment.attachment;

        const attachment = mappedAttachments[index];
        actions.updateAttachment(attachment, id, channelId);

        if (attachment.mime) {
          const { uploadUrl } = response.data.createAttachment.attachment;

          const removeAttachmentCallback = () => {
            actions.removeAttachment(id, channelId);
          };

          const finishAttachmentCallback = () => {
            actions.finishAttachment(id, channelId);
          };

          await uploadAttachment(
            uploadUrl,
            attachment,
            finishAttachmentCallback,
            removeAttachmentCallback
          );
        }
      });
    });
  }, [registry, createAttachment, actions, channelId]);
};

export default useProcessAttachment;
