import React, { useMemo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { User } from "~riata/generated/graphql";
import { AvatarOffDutyIndicator, UserAvatar } from "../../avatars";
import { Row } from "../../layout";
import { Text } from "../../typography";
import { getFromNowDate } from "./utils";
import { useColors } from "~riata/hooks";

type BulletinDetailSenderProps = {
  creator: User;
  date: string;
  containerStyles?: StyleProp<ViewStyle>;
};

type BulletinDetailSenderComponent = (
  props: BulletinDetailSenderProps
) => JSX.Element;

const BulletinDetailSender: BulletinDetailSenderComponent = ({
  creator,
  date,
  containerStyles = {},
}) => {
  const { newColors } = useColors();
  const fromNowDate = useMemo(() => getFromNowDate(date), [date]);

  if (!creator) {
    return null;
  }

  return (
    <Row style={containerStyles}>
      <View style={styles.avatarContainer}>
        <UserAvatar size={48} user={creator} outlined={false} />
        <View style={styles.offDutyContainer}>
          <AvatarOffDutyIndicator user={creator} />
        </View>
      </View>
      <View style={styles.userDetailsContainer}>
        <Row>
          <Text>Sent by </Text>
          <Text fontWeight="bold">{creator.displayName}</Text>
        </Row>
        <View>
          <Text variant="footnote" color={newColors.text.footnote.main}>
            {fromNowDate}
          </Text>
        </View>
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  userDetailsContainer: {
    marginLeft: 16,
  },
  avatarContainer: {
    alignItems: "center",
  },
  offDutyContainer: {
    marginLeft: 2,
  },
});

export default BulletinDetailSender;
