/**
 * Will trim a string if necessary and append a trailing tail(...) to the end
 *
 * @param str
 * @param maxLength
 * @returns
 */
const trimWithTail = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.slice(0, maxLength)}...`;
};

/**
 * Will remove any MD token from a string
 *
 * @param str
 * @returns
 */
const removeMDTokens = (str: string = "") => {
  return str.replace(/(#+|\*+|_+|`+|~+|>+)/gim, "");
};

const StringUtils = {
  trimWithTail,
  removeMDTokens,
};

export default StringUtils;
