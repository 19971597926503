import gql from "graphql-tag";
import { MutationRefreshTokenArgs } from "~riata/generated/graphql";

export const MUTATION = gql`
  mutation RefreshToken($token: String!, $refreshToken: String!) {
    refreshToken(token: $token, refreshToken: $refreshToken) {
      token
    }
  }
`;

export const execute = async (
  credentials: MutationRefreshTokenArgs,
  client: InitializedApolloClient
) => {
  // TODO Error handling
  let response = await client.mutate({
    mutation: MUTATION,
    variables: credentials,
  });
  return response.data.refreshToken.token;
};
