import { Bulletin, Scalars } from "~riata/generated/graphql";
import {
  AttachmentFile,
  BulletinComposeActions,
  BulletinComposeState,
} from "./types";

export const INITIAL_STATE: BulletinComposeState = {
  payload: {
    id: "",
    name: "",
    content: "",
    isRequired: false,
    isPriority: false,
    allowComments: false,
    allowVisibility: true,
    scheduledAt: null,
    expires: null,
    isDraft: false,
  },
  adminVisible: false,
  attachments: [],
  recipients: [],
  recipientInboxes: [],
};

export const INITIAL_ACTIONS: BulletinComposeActions = {
  addAttachment: (file: AttachmentFile) =>
    console.debug("addAttachment not implemented"),
  finishAttachment: (id: Scalars["ID"]) =>
    console.debug("finishAttachment not implemented"),
  removeAttachment: (id: Scalars["ID"]) =>
    console.debug("removeAttachment not implemented"),
  setName: (name: string) => console.debug("setName not implemented"),
  setScheduledAt: (scheduledAt: Date) =>
    console.debug("setScheduledAt not implemented"),
  setExpires: (expires: Date) => console.debug("setExpires not implemented"),
  setPreviousValues: (bulletin: Bulletin) =>
    console.debug("setPreviousValues not implemented"),
  removeScheduledAt: () => console.debug("removeScheduledAt not implemented"),
  removeExpires: () => console.debug("removeExpires not implemented"),
  setRecipientInboxes: (inboxes: Array<Scalars["ID"]>) =>
    console.debug("setRecipientInboxes not implemented"),
  setContent: (content: string) => console.debug("setContent not implemented"),
  toggleAdmin: () => console.debug("toggleAdmin not implemented"),
  togglePriority: () => console.debug("togglePriority not implemented"),
  toggleRequired: () => console.debug("toggleRequired not implemented"),
  toggleComments: () => console.debug("toggleComments not implemented"),
  toggleVisibility: () => console.debug("toggleVisibility not implemented"),
  updateAttachment: (file: AttachmentFile, id: Scalars["ID"]) =>
    console.debug("updateAttachment not implemented"),
};
