import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { isWeb } from "~riata/theme/common";

const fieldConnector = require("~riata/resources/field-connector.png");

// TODO Allow providing a custom company / image
const CompanyHeader = () => {
  const imageSource =
    isWeb && !fieldConnector.uri
      ? {
          uri: fieldConnector,
          width: 150,
          height: 180,
        }
      : fieldConnector;

  return (
    <View style={styles.fieldConnector}>
      <Image source={imageSource} style={!isWeb ? styles.image : {}} />
    </View>
  );
};

const styles = StyleSheet.create({
  fieldConnector: {
    flex: 1,
    marginVertical: 24,
    height: 204,
    width: 204,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

export default CompanyHeader;
