import React from "react";
import { RefreshControl } from "react-native";

import { ChannelList } from "~riata/components";
import { useGetChannels } from "~riata/graphql";
import { useRefresh } from "~riata/hooks";

export const ChatChannelsScreen = React.forwardRef(
  ({ navigation, filter }: { navigation: any; filter: any }, ref) => {
    const { loading, error, fetchNext, list, refetch } = useGetChannels({
      filter,
    });
    const { onRefresh, refreshing } = useRefresh({ ref, refetch });

    return (
      <ChannelList
        channels={list}
        error={error}
        loading={loading}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        onEndReached={fetchNext}
      />
    );
  }
);

export default ChatChannelsScreen;
