import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { QUERY } from "../queries/GetCurrentUser";

export const ACKNOWLEDGE_OFF_DUTY = gql`
  mutation ($input: OffDutyAcknowledgementInput!) {
    offDutyAcknowledgement(input: $input) {
      extendedHours
      acknowledgedTime
      extendNotifications
      success
    }
  }
`;

export const useAcknowledgeOffDuty = () => {
  const [mutation, response] = useMutation(ACKNOWLEDGE_OFF_DUTY);
  const successful = response?.data ? true : false;

  const execute = ({ userId, extendedHours = 0 }) => {
    const variables = {
      input: {
        id: userId,
        extendedHours,
      },
    };

    return mutation({
      variables,
      refetchQueries: [{ query: QUERY }],
    });
  };
  return { execute, response, successful };
};
