import React from "react";
import { StyleSheet, View } from "react-native";
import { CustomTextProps, Text } from "./typography";

import { Row } from "./layout";
import { useColors, useComposeStyles } from "~riata/hooks";

type SeparatorVariant = "divider" | "message" | "subtitle" | "newSubhead";

type TextSeparatorHeaderProps = {
  title: string;
  large?: boolean;
  variant?: SeparatorVariant;
};

type TextSeparatorHeaderComponent = (
  props: TextSeparatorHeaderProps
) => JSX.Element;

const _getVariantStyles = (variant: SeparatorVariant) => {
  if (variant === "divider") {
    return styles.textDividerContainer;
  }

  if (variant === "message") {
    return styles.messageDividerContainer;
  }

  return {};
};

const _getTextVariant = (variant: SeparatorVariant): CustomTextProps => {
  if (variant === "divider") {
    return { variant: "body", fontWeight: "mediumBold" };
  }

  if (variant === "message") {
    return { variant: "caption3", fontWeight: "mediumBold" };
  }

  return { variant: "newSubhead", fontWeight: "regular" };
};

const TextSeparatorRule = () => {
  const { newColors } = useColors();

  const rulerStyles = useComposeStyles(styles.ruler, {
    backgroundColor: newColors.separator.main,
  });

  return <View style={rulerStyles} />;
};

const TextSeparatorHeader: TextSeparatorHeaderComponent = ({
  title,
  variant = "divider",
}) => {
  const { newColors } = useColors();

  const isDivider = variant === "divider";
  const isMessage = variant === "message";
  const isSubtitle = variant === "subtitle" || variant === "newSubhead";

  const textColor =
    variant === "divider"
      ? newColors.text.disabled.main
      : newColors.text.regular.main;

  const justifyContent = isSubtitle ? "left" : "center";
  const textVariant = _getTextVariant(variant);
  const textContainerStyles = useComposeStyles(_getVariantStyles(variant), {
    backgroundColor: isMessage ? newColors.message.system.main : "transparent",
  });

  return (
    <Row justify={justifyContent} style={styles.container}>
      {isDivider && <TextSeparatorRule />}
      <View style={textContainerStyles}>
        <Text
          variant={textVariant.variant}
          color={textColor}
          fontWeight={textVariant.fontWeight}
        >
          {title}
        </Text>
      </View>
      {isDivider && <TextSeparatorRule />}
    </Row>
  );
};

const styles = StyleSheet.create({
  ruler: {
    flex: 1,
    marginHorizontal: 10,
    backgroundColor: "transparent",
    height: 1,
    width: "100%",
  },
  container: {
    marginVertical: 8,
  },
  textDividerContainer: {},
  messageDividerContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
  },
});

export default TextSeparatorHeader;
