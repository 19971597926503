import React from "react";
import { StyleSheet, View } from "react-native";

import { ImageAttachmentPreview } from "./ImageAttachmentPreview";
import { FlatList, Row } from "../layout";
import AttachmentPreview from "./AttachmentPreview";
import { ImageAttachment } from "~riata/generated/graphql";

// We might not have an ID while the upload is pending, so drop a random ID in temporarily
const keyExtractor = (item, index) => `attachment-${item.id}-${index}`;

const AttachmentThumbnailList = ({
  attachments,
  parent = null,
  onAddPressed = null,
  onLongPress = null,
  thumbnail = false,
  style = {},
}) => {
  if (attachments) {
    return (
      <Row justify="left" style={style}>
        <FlatList
          style={styles.list}
          horizontal
          data={
            onAddPressed !== null
              ? [...attachments, { type: "add_button", id: "key" }]
              : attachments
          }
          renderItem={({ item, index }) => {
            return (
              <AttachmentPreview
                key={`attachment-${item.id}-${index}`}
                attachment={item}
                parent={parent}
                index={index}
                onLongPress={onLongPress}
                thumbnail={attachments.length > 1 || thumbnail}
                onAddPressed={onAddPressed}
              />
            );
          }}
          keyExtractor={keyExtractor}
          ItemSeparatorComponent={() => <View style={styles.separator}></View>}
        />
      </Row>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  list: {
    backgroundColor: "transparent",
  },
  separator: {
    width: 12,
  },
});

AttachmentThumbnailList.canDisplay = (obj: any): obj is ImageAttachment => {
  return ImageAttachmentPreview.canDisplay(obj);
};

export default AttachmentThumbnailList;
