import React from "react";
import { StyleSheet, View } from "react-native";
import { CustomIcon } from "../CustomIcon";
import { useColors } from "~riata/hooks";

const NAVIGATION_ITEM_ICON_SIZE = 18;

const NavigationIcon = ({ name, color = null }) => {
  const { newColors } = useColors();
  let iconColor = newColors.icon.surfaceContrast.main;

  if (color) {
    iconColor = color;
  }

  return (
    <View style={styles.container}>
      <CustomIcon
        style={styles.icon}
        name={name}
        color={iconColor}
        size={NAVIGATION_ITEM_ICON_SIZE}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 30,
    marginRight: -25,
  },
  icon: {
    backgroundColor: "transparent",
  },
});
export default NavigationIcon;
