import { Bulletin, Message } from "../../generated/graphql";

export * from "./edges";
export * from "./hooks";
export { useDebugInfo } from "./useDebugInfo";

export function isBulletin(obj: any): obj is Bulletin {
  return obj?.__typename === "Bulletin";
}

export function isMessage(obj: any): obj is Message {
  return obj?.__typename === "Message";
}
