import React, { useCallback, useState } from "react";
import { RefreshControl, SectionList } from "react-native";
import { useGetDecks } from "~riata/graphql/queries";
import {
  useLinkOpener,
  useRefetchOnFocus,
  useUpdateSelectedRouteIfFocused,
} from "~riata/hooks";

import {
  AppHeader,
  Body,
  BookmarkCardList,
  DeckHeader,
  getRenderingCard,
  PreviewCardList,
  RodioLoader,
  SafeAreaView,
  HeaderActionButton,
} from "~riata/components";
import { DeckStyle } from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { EmptyDashboard } from "./EmptyDashboard";
import { Sentry } from "~riata/utils/sentry";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { HEADER_ICON_SIZE } from "~riata/theme";

export const DashboardScreen = ({ navigation }) => {
  const linkOpener = useLinkOpener();
  const { updateSelectedRoute } = useDrawerNavigation();

  const [refreshing, setRefreshing] = useState(false);
  // TODO Handle error
  const { data, loading, refetch } = useGetDecks();

  useRefetchOnFocus({ navigation, loading, refetch });

  const onRefresh = useCallback(() => {
    if (!refetch) {
      return;
    }
    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setRefreshing(false);
      });
  }, [refetch]);

  const refreshControl = (
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
  );

  const renderHorizontalDeck = ({ section: { data } }) => {
    return <PreviewCardList cards={data[0].list} />;
  };

  const renderGridDeck = ({ section: { data } }) => {
    return <BookmarkCardList cards={data[0].list} />;
  };

  // TODO Make this part of the SectionList instead of a sub-list
  const renderVerticalDeck = ({ item, index }) => {
    const CardComponent = getRenderingCard(item);
    return (
      <CardComponent
        first={index === 0}
        key={item.id}
        data={item}
        onPress={() => linkOpener(item.actionURL)}
      />
    );
  };

  // TODO Detect type and render different decks
  const renderItem = (props) => {
    switch (props.section.deck.style) {
      case DeckStyle.Vertical:
        return renderVerticalDeck(props);

      case DeckStyle.Horizontal:
        return renderHorizontalDeck(props);

      case DeckStyle.Grid:
        return renderGridDeck(props);
    }
    return null;
  };

  const sectionDataMapper = (deck) => {
    if (deck.style === DeckStyle.Vertical) {
      return deck.cards.edges.map((a) => a.node);
    } else {
      return [{ deck, id: deck.id, list: deck.cards.edges.map((a) => a.node) }];
    }
  };
  const sections =
    data?.getDecks?.edges
      .filter(({ node: { cards } }) => cards.edges.length > 0)
      .map((edge) => {
        const deck = edge.node;
        // TODO Only return something valid if its meant to be displayed emptyk
        return {
          name: deck.name,
          key: `card-${deck.id}`,
          deck,
          onViewAll: deck.url
            ? () => {
                // FIXME Remove once all of the `/-/` URLs from v4 are removed server side
                let { url } = deck;
                if (deck.url.indexOf("//-/") !== -1) {
                  url = url.replace("-/", "");
                }
                linkOpener(url);
              }
            : null,
          data: sectionDataMapper(deck),
        };
      }) || [];

  const renderSectionHeader = ({ section }) => {
    const { name, onViewAll } = section;
    return <DeckHeader title={name} onViewAll={onViewAll} />;
  };

  useUpdateSelectedRouteIfFocused(updateSelectedRoute);

  return (
    <SafeAreaView bottom={true}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
            iconSize={HEADER_ICON_SIZE}
          />
        }
        right={
          isWeb && <HeaderActionButton variant="refresh" onPress={onRefresh} />
        }
      />

      <Body skipKeyboardAvoiding>
        {loading && !data?.getDecks ? (
          <RodioLoader />
        ) : (
          <SectionList
            ListEmptyComponent={EmptyDashboard}
            stickySectionHeadersEnabled={false}
            refreshControl={refreshControl}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            sections={sections}
          />
        )}
      </Body>
    </SafeAreaView>
  );
};
