import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Bulletin } from "~riata/generated/graphql";
import { navigate } from "~riata/navigation";
import { fragments } from "../fragments";

export const GET_BULLETIN = gql`
  query GetBulletin($id: ID!) {
    node(id: $id) {
      ... on Bulletin {
        ...BulletinContent
      }
    }
  }
  ${fragments.Bulletin.Content}
`;

export const subscriber = {
  type: "Bulletin",
  execute: async ({ data, client }) => {
    const bulletin: Bulletin = data.node;

    client.writeQuery({
      query: GET_BULLETIN,
      variables: { id: data.node.id },
      data,
    });

    if (
      bulletin.isPriority &&
      bulletin.isRequired &&
      (bulletin.status === "UNREAD" || bulletin.status === null)
    ) {
      navigate("Bulletin", {
        screen: "BulletinDetails",
        params: { id: bulletin.id },
      });
    }
  },
};

type GetBulletinResult = { node?: Bulletin };
type UseGetBulletin = (
  id: string
) => QueryResult<GetBulletinResult> & { bulletin?: Bulletin };

export const useGetBulletin: UseGetBulletin = (id) => {
  const { data, ...useQueryResult } = useQuery(GET_BULLETIN, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const bulletin = data?.node || {};
  return { bulletin, data, ...useQueryResult };
};
