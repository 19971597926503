import React from "react";
import { ScrollView } from "react-native";
import { MessageListItem, RodioLoader } from "~riata/components";
import { useGetMessages } from "~riata/graphql";

const MessageDetailSubScreen = ({ message }) => {
  const cursor = `chat:message:${message.id}`;
  const { loading: beforeLoading, list: before } = useGetMessages({
    channel: message.channel.id,
    before: cursor,
    last: 5,
  });
  const { loading: afterLoading, list: after } = useGetMessages({
    channel: message.channel.id,
    after: cursor,
    first: 20,
  });
  if (beforeLoading || afterLoading) {
    return <RodioLoader />;
  }
  return (
    <ScrollView style={{ flex: 1 }}>
      {before?.map((m) => {
        return <MessageListItem hideHeader key={m.id} message={m} />;
      })}
      <MessageListItem
        highlighted
        hideHeader
        message={message}
        previous={null}
      />
      {after?.map((m) => {
        return <MessageListItem hideHeader key={m.id} message={m} />;
      })}
    </ScrollView>
  );
};

export default MessageDetailSubScreen;
