import React from "react";
import { StyleSheet, View } from "react-native";

import { Card, Row, Text } from "~riata/components";

import { useColors } from "~riata/hooks";

const DebugItem = ({ label, value }) => {
  const { newColors } = useColors();

  return (
    <Row style={styles.container}>
      <Card rounded style={styles.card}>
        <Row style={styles.cardHeader} align="center">
          <Text variant="headline">{label}</Text>
        </Row>
        <Row align="center">
          <View style={styles.valueContainer}>
            <Text color={newColors.text.caption.main}>{value}</Text>
          </View>
        </Row>
      </Card>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 16, marginBottom: 16 },
  card: { flex: 1, padding: 16 },
  cardHeader: { marginBottom: 16 },
  valueContainer: {
    borderColor: "transparent",
    borderWidth: 1,
    borderRadius: 8,
    padding: 8,
    flex: 1,
    minHeight: 50,
  },
});

export default DebugItem;
