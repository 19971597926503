import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  getDefaultVariables,
  GET_MESSAGES,
} from "~riata/graphql/queries/GetMessages";
import { Message, MessageEdge, Query } from "~riata/generated/graphql";
import { fragments } from "../fragments";
import { ulid } from "ulid";
import { getCursor } from "../queries/GetMessages";
import useGetCurrentUser from "../queries/GetCurrentUser";

export const POST_MESSAGE = gql`
  mutation PostMessage(
    $channel: ID!
    $content: String!
    $attachments: [ID]
    $id: ID
  ) {
    postMessage(
      input: {
        channel: $channel
        content: $content
        attachments: $attachments
        id: $id
      }
    ) {
      message {
        ...MessageContent
      }
    }
  }
  ${fragments.Message.Content}
`;

const USER_FRAGMENT = gql`
  fragment user on User {
    id
    username
    fullName
    displayName
  }
`;

const CHANNEL_FRAGMENT = gql`
  fragment channel on Channel {
    id
    slug
    name
  }
`;

export const usePostMessageMutation = ({ channel }) => {
  const [mutation, response] = useMutation(POST_MESSAGE);
  const { user } = useGetCurrentUser();

  //PostMessagePayload
  const execute = (content: string, attachments?) => {
    return new Promise(async (resolve, reject) => {
      const currentUser = response.client.readFragment({
        id: `User:${user.id}`,
        fragment: USER_FRAGMENT,
      });

      const currentChannel = response.client.readFragment({
        id: `Channel:${channel}`,
        fragment: CHANNEL_FRAGMENT,
      });

      if (content.startsWith("{user}")) {
        content = [currentUser.displayName, content.substr(6)].join("");
      }

      const node: Message = {
        __typename: "Message",
        id: ulid(),
        channel: currentChannel,
        content,
        reactions: null,
        headline: null,
        attachments: null,
        read: true,
        fromServer: false,
        createdAt: new Date().toUTCString(),
        creator: currentUser,
      };

      mutation({
        variables: { channel, content, id: node.id, attachments },
      });

      const variables = getDefaultVariables(channel);
      type GetMessagesQuery = Pick<Query, "getMessages">;
      const { getMessages } = response.client.readQuery<GetMessagesQuery>({
        query: GET_MESSAGES,
        variables,
      });

      const messageEdge: MessageEdge = {
        __typename: "MessageEdge",
        cursor: getCursor(node),
        node,
      };
      response.client.writeQuery({
        query: GET_MESSAGES,
        variables,
        data: {
          getMessages: {
            __typename: getMessages.__typename,
            edges: [...getMessages.edges, messageEdge],
            pageInfo: {
              ...getMessages.pageInfo,
              endCursor: getCursor(node),
            },
          },
        },
      });
      resolve(null);
    });
  };

  return { execute, mutation, response };
};
