import React, { forwardRef, useMemo } from "react";

import { useGetChannel, useGetMessages } from "~riata/graphql";
import { MessageList } from "~riata/components";
import { useAppStateChange, useErrorToast } from "~riata/hooks";

import { useHeaderLayout } from "./useHeaderLayout";
import { useShouldDisplayLoading } from "./useShouldDisplayLoading";
import { useMarkAsRead } from "./useMarkAsRead";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

type MessageListSubScreenProps = {
  channelID: string;
  message?: null | string;
  navigation: any;
};

export const MessageListSubScreen = forwardRef(
  ({ channelID, message, navigation }: MessageListSubScreenProps, ref) => {
    const { channel } = useGetChannel(channelID);
    const isFocused = navigation.isFocused();
    const { loading, refetch, list, hasPreviousPage, fetchPrevious, error } =
      useGetMessages({
        channel: channelID,
        message,
        fetchPolicy: "cache-and-network",
      });
    const shouldDisplayLoading = useShouldDisplayLoading({
      list,
      loading,
      channelID,
    });
    const { goBack } = useDrawerNavigation();

    useHeaderLayout({
      channel,
      navigation,
      goBack,
      refetch,
    });

    // TODO Could / should this be moved to the server
    const hasUnread = useMemo(
      () => list.filter((message) => message.read === false).length > 0,
      [list]
    );

    useMarkAsRead({ channelID, hasUnread, isFocused });

    const { RodioError, showToast, isDismissed } = useErrorToast(error);

    if (error && !isDismissed) {
      showToast("An error occurred while fetching messages...");
    }

    useAppStateChange({
      active: () => {
        /**
         * We need to trigger a refetch after a notification is clicked and the
         * app was previously opened in a channel's sub-screen. Otherwise the
         * component wouldn't now it needed to update it's data.
         */
        console.debug("Refetching channel messages");
        refetch();
      },
    });

    console.debug("MessageListSubScreen loading");
    return (
      <>
        <MessageList
          highlight={message?.length > 0 ? message.split(":")[2] : null}
          messageList={list}
          loading={shouldDisplayLoading}
          refetch={() => {
            refetch();
          }}
          onEndReached={() => {
            // TODO: We should give a loading state indicator when fetching more messages
            if (!loading && hasPreviousPage) {
              try {
                console.log("gonna fetch more");
                fetchPrevious();
              } catch (err) {
                console.warn(
                  "MessageListSubScreen > MessageList > onEndReached: ",
                  err.message
                );
              }
            }
          }}
        />
        <RodioError />
      </>
    );
  }
);

export default MessageListSubScreen;
