import React from "react";
import { View } from "react-native";
import { StyleSheet } from "react-native";
import { Text } from "~riata/components/typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const OffDutyLabel: OffDutyLabelComponent = ({
  offDuty,
  containerStyles = {},
  fontVariant = "title5",
  fontWeight = "mediumBold",
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(styles.container, {
    ...containerStyles,
  });

  return (
    <View style={composedContainerStyles}>
      {offDuty ? (
        <Text
          variant={fontVariant}
          fontWeight={fontWeight}
          color={newColors.offDuty.off.on}
        >
          Off-duty
        </Text>
      ) : (
        <Text
          variant={fontVariant}
          fontWeight={fontWeight}
          color={newColors.offDuty.on.on}
        >
          On-duty
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default OffDutyLabel;
