import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import Modal from "react-native-modal";

import { Button } from "./inputs/Button";
import { Text } from "./typography";
import { useColors } from "~riata/hooks";
import { Card, Row } from "./layout";
import { CustomIcon } from "./icons";

type RodioAlertProps = {
  isVisible: boolean;
  variant: "danger" | "success" | "info" | "default";
  title: string;
  body: string;
  buttonText: string;
  onDismiss: () => void;
};

const _getVariant = (variant, colors) => {
  switch (variant) {
    case "danger":
      return { color: colors.icon.danger.main, icon: "alert" };
    case "success":
      return { color: colors.icon.success.main, icon: "checkmark_full" };
    case "info":
      return { color: colors.icon.primary.main, icon: "info" };
    case "default":
    default:
      return { color: colors.icon.dark.main, icon: "info" };
  }
};

const RodioAlert = ({
  isVisible,
  title,
  body,
  buttonText,
  variant = "default",
  onDismiss,
}: RodioAlertProps) => {
  const { newColors } = useColors();

  const { color, icon } = useMemo(() => {
    return _getVariant(variant, newColors);
  }, [variant, newColors]);

  return (
    <Modal isVisible={isVisible} onBackdropPress={onDismiss}>
      <Card style={styles.container} rounded>
        <Row style={styles.titleContainer} justify="center" align="center">
          <CustomIcon name={icon} size={20} color={color} />
          <Text variant="title3" color={color} style={styles.title}>
            {title}
          </Text>
        </Row>
        <View style={styles.content}>
          <Text selectable>{body}</Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button onPress={onDismiss}>
            <Text variant="footnote" color={newColors.text.caption.main}>
              {buttonText}
            </Text>
          </Button>
        </View>
      </Card>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: "60%",
  },
  titleContainer: {
    padding: 8,
  },
  title: {
    marginLeft: 8,
  },
  content: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignItems: "center",
  },
  buttonContainer: {
    alignItems: "center",
    paddingVertical: 8,
  },
});

export default RodioAlert;
