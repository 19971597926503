import {
  BulletinComposeActions,
  BulletinComposeState,
} from "~riata/contexts/BulletinCompose/types";
import { BulletinFilter, BulletinScreen } from "./enums";
import { CommonActions } from "@react-navigation/routers";
import { Scalars } from "~riata/generated/graphql";

const generateGoTo = (
  state: BulletinComposeState,
  actions: BulletinComposeActions,
  navigateCallback: (screenName, options) => void,
  dateKey: "scheduledAt" | "expires",
  isDraft: boolean = false
) => {
  const generatedFn = () => {
    if (state.adminVisible) {
      actions.toggleAdmin();
    }

    navigateCallback("DateSelectActionSheet", {
      params: { dateKey, isDraft },
    });
  };

  return generatedFn;
};

const resetBulletinNavigationAfterMutation = (
  navigation,
  from: BulletinScreen,
  filter: BulletinFilter = BulletinFilter.Draft
) => {
  const navigationObj =
    from === BulletinScreen.Inbox ? navigation.getParent() : navigation;

  navigationObj.dispatch(
    CommonActions.reset({
      index: 1,
      routes: [{ name: "Home" }, { name: "Bulletins", params: { filter } }],
    })
  );
};

const mapRecipients = (recipients) => {
  const selectedInboxIDs: Array<Scalars["ID"]> = recipients.map((a) => a.id);

  return selectedInboxIDs;
};

export { generateGoTo, resetBulletinNavigationAfterMutation, mapRecipients };
