const hasSomeTruthyValues = (obj, excludeKeys = []) => {
  const keys = Object.keys(obj).filter((key) => !excludeKeys.includes(key));

  const truthyValues = keys.filter((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return !!value;
  });

  return truthyValues.length > 0;
};

export { hasSomeTruthyValues };
