import React from "react";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { CloseIcon } from "~riata/components/icons";
import MarkdownContent from "~riata/components/MarkdownContent";
import { useColors, useComposeStyles } from "~riata/hooks";

const ReplyText = ({ message: { text, sender }, isVisible, onClose }) => {
  const { colors } = useColors();

  const replyStyles = useComposeStyles(styles.replyContainer, {
    backgroundColor: colors.reply.main,
  });

  if (!isVisible || !text || !sender) {
    return null;
  }

  // If multi-line is present, only show first line
  let message = text.split("\n")[0];
  message =
    message.length > 40 ? message.substring(0, 40).concat("...") : message;

  return (
    <View style={replyStyles}>
      <View style={styles.markdownWrapper}>
        <MarkdownContent
          text={`> **${sender}**\n${message}`}
          styleOverrides={styles.markdownContent}
        />
      </View>
      <View style={styles.closeIconContainer}>
        <TouchableWithoutFeedback onPress={onClose}>
          <CloseIcon size={20} />
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  replyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  markdownWrapper: {
    flex: 1,
    margin: 0,
    padding: 0,
  },
  markdownContent: {
    padding: 0,
    margin: 0,
    blockquote: {
      borderLeftWidth: 4,
      backgroundColor: "transparent",
      marginBottom: 0,
    },
  },
  closeIconContainer: {
    paddingHorizontal: 16,
  },
});

export default ReplyText;
