import React from "react";
import globalHook from "use-global-hook";

const initialSessionState = {};
const sessionActions = {
  add: (store, newState) => {
    store.setState({
      ...store,
      ...newState,
    });
  },
  remove: (store, key) => {
    let newState = store.state;
    delete newState[key];
    store.setState(newState);
  },
};

const useSession = globalHook(React, initialSessionState, sessionActions);

export default useSession;
