import { useCallback } from "react";

// TODO Write tests for this
// TODO Write out type for this so we can type check name
const generateUpdateQueryForNext =
  (name) =>
  (prev, { fetchMoreResult }) => {
    const result = {};
    result[name] = {
      ...prev[name],
      pageInfo: {
        ...prev[name].pageInfo,
        endCursor: fetchMoreResult[name].pageInfo.endCursor,
        hasNextPage: fetchMoreResult[name].pageInfo.hasNextPage,
      },
      edges: [...prev[name].edges, ...fetchMoreResult[name].edges],
    };
    console.debug(`updateQuery(${name}`);
    console.debug(prev, fetchMoreResult, result);
    return result;
  };

const useFetchNext = ({ fetchMore, loading, name, pageInfo }) =>
  useCallback(() => {
    if (pageInfo === null) {
      console.debug("fetchNext returning early, pageInfo not available");
      return;
    }
    if (loading) {
      console.debug("fetchNext returning early, still loading");
      return;
    }

    if (!pageInfo.hasNextPage) {
      console.debug("fetchNext returning early, no next page");
      return;
    }

    const variables = { after: pageInfo.endCursor };

    return fetchMore({
      variables,
      updateQuery: generateUpdateQueryForNext(name),
    });
  }, [fetchMore, loading, name, pageInfo]);

export default useFetchNext;
