import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { getUniqueIdSync } from "react-native-device-info";
import { useDebugInfo } from "../utils";

export const SET_PUSH_TOKEN = gql`
  mutation ($deviceId: String!, $value: String!) {
    setPushToken(input: { deviceId: $deviceId, value: $value }) {
      pushToken {
        deviceId
        value
        user {
          username
        }
      }
    }
  }
`;

export const useSetPushToken = () => {
  const [mutation, response] = useMutation(SET_PUSH_TOKEN);
  console.debug("useSetPushToken > invoked", { response });
  const { update } = useDebugInfo();
  const successful = response?.data ? true : false;

  const execute = (pushToken) => {
    const deviceId = getUniqueIdSync();
    const variables = { deviceId, value: pushToken };
    update({ deviceId, pushToken });

    return mutation({ variables });
  };

  return { execute, response, successful };
};
