import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_BULLETINS } from "~riata/graphql/queries/GetBulletins";
import { BulletinStatus, CreateBulletinInput } from "~riata/generated/graphql";
import { getCurrentDateTime, getFutureDate } from "~riata/utils/date";
import { useToastMessage } from "~riata/hooks";
import { datesAreValid } from "./helpers";

const CREATE_BULLETIN = gql`
  mutation CreateBulletin($input: CreateBulletinInput) {
    createBulletin(input: $input) {
      bulletin {
        id
        name
      }
    }
  }
`;

export const useCreateBulletin = () => {
  const [mutation, response] = useMutation(CREATE_BULLETIN);
  const { showErrorToast } = useToastMessage();
  // TODO: What about a response that's explicitly false?
  const successful = response?.data?.createBulletin ? true : null;

  const execute = (input: CreateBulletinInput, includeExpirationDate) => {
    const workingInput = input;

    if (
      !workingInput.expires &&
      !workingInput.isDraft &&
      includeExpirationDate
    ) {
      // No expiration date has been set, so we'll set it to 6 months from now
      const workingDate = workingInput.scheduledAt
        ? workingInput.scheduledAt
        : getCurrentDateTime({ resetSeconds: true });

      const expirationDate = getFutureDate(workingDate, 6, "months");

      workingInput.expires = expirationDate.toISOString();
    }

    if (
      !datesAreValid(
        workingInput.scheduledAt,
        workingInput.expires,
        showErrorToast
      )
    ) {
      throw new Error("Invalid dates");
    }

    mutation({
      variables: { input: workingInput },
      refetchQueries: [
        { query: GET_BULLETINS, variables: { filters: { status: "SENT" } } },
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Sent, isScheduled: false },
            first: 20,
          },
        },
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Sent, isScheduled: true },
            first: 20,
          },
        },
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Sent, isDraft: true },
            first: 20,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };
  return { mutation, response, execute, successful };
};
