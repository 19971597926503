import React from "react";
import { StyleSheet, View } from "react-native";

import { CloseIcon, SearchIcon } from "../icons";
import { TextInput } from "../inputs";
import { Button } from "../inputs/Button";
import { Text } from "../typography";
import { useListFilterSearchState } from "./ListFilterSearch.state";
import { useColors, useComposeStyles } from "~riata/hooks";

// TODO: This probably isn't the most accessible component. In the future we should separate the input from the open and close buttons in some way
// FIXME: This shouldn't render _both_ the search and saved searches
const ListFilterSearch = ({ onPress, onSaveFilter }) => {
  const { state, actions } = useListFilterSearchState({ onPress });
  const { newColors } = useColors();

  const buttonStyles = useComposeStyles(
    styles.container,
    state.searching ? styles.containerSearching : {}
  );

  const containerStyles = useComposeStyles(buttonStyles, {
    backgroundColor: newColors.surface.main,
  });

  const wrapperStyles = useComposeStyles(
    styles.wrapper,
    state.searching ? styles.wrapperSearching : {}
  );

  return (
    <Button
      variant="filter"
      style={containerStyles}
      onPress={() =>
        state.searching ? actions.reset() : actions.setSearching(true)
      }
    >
      <View style={wrapperStyles}>
        {state.searching && (
          <TextInput
            fill
            defaultValue={state.term}
            returnKeyType="search"
            shouldFocus={true}
            onSubmitEditing={(event) =>
              actions.handleSubmit(event.nativeEvent.text)
            }
          />
        )}

        {state.searching ? <CloseIcon size={12} /> : <SearchIcon size={18} />}

        {!state.searching && state.term !== "" && (
          <Text style={styles.text} fontWeight="bold">
            "{state.term}"
          </Text>
        )}
      </View>
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 35,
    minWidth: 35,
    paddingHorizontal: 8,
    backgroundColor: "transparent",
  },
  containerSearching: {
    minWidth: 200,
    maxWidth: 300,
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  wrapperSearching: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    marginLeft: 8,
  },
});

export default ListFilterSearch;
