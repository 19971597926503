import gql from "graphql-tag";
import * as GetChannels from "../queries/GetChannels";
import * as GetBulletin from "../queries/GetBulletin";
import * as GetBulletins from "../queries/GetBulletins";
import * as GetMessages from "../queries/GetMessages";
import { SubscriberList } from "./types";
import { fragments } from "../fragments";
import { AuthMutationQuery } from "../mutations/Auth";
import { AuthInput } from "~riata/generated/graphql";
import { Sentry } from "~riata/utils/sentry";

const ON_EVENT_SUBSCRIPTION = gql`
  subscription ($token: String!) {
    onEvent(token: $token) {
      node {
        id
        ... on Message {
          ...MessageContent
        }
        ... on Bulletin {
          ...BulletinContent
        }
      }
    }
  }

  ${fragments.Message.Content}
  ${fragments.Bulletin.Content}
`;

export const initializeSubscription = async (
  client,
  token,
  navigation,
  user_id
) => {
  console.log("initializeSubscription invoked", { token });
  const subscribers: SubscriberList = [
    GetBulletins.subscriber,
    GetChannels.subscriber,
    GetBulletin.subscriber,
    GetMessages.subscriber,
  ];

  // TODO Handle situation where token is a valid JWT already?
  console.debug("starting subscription handler");

  try {
    const variables: { input: AuthInput } = {
      input: { bearer: token },
    };
    const response = await client.mutate({
      mutation: AuthMutationQuery,
      variables,
    });
    console.debug("got a response!", { response });
    try {
      const subscription = await client.subscribe({
        query: ON_EVENT_SUBSCRIPTION,
        variables: { token: response.data.auth.token },
      });
      console.log("initializeSubscription >", { subscription });
      if (!subscription) {
        console.warn(
          "initializeSubscription > client.subscribe > subscription is empty?"
        );
      }

      try {
        console.debug(
          "initializeSubscription > setting up subscription handler"
        );
        return subscription.subscribe({
          error: (error) => {
            console.log("Subscription error");
            Sentry.captureException(error);
          },
          next: (subscriptionData) => {
            subscribers.forEach((o) => {
              if (subscriptionData.data.onEvent.node.__typename === o.type) {
                o.execute({
                  client,
                  data: subscriptionData.data.onEvent,
                  navigation,
                  user_id,
                });
              }
            });
          },
        });
      } catch (err) {
        console.warn("IS Internal", { err });
      }
    } catch (err) {
      console.warn("WTF", { err });
    }
  } catch (err) {
    console.debug("initializeSubscription > caught mutation exception", {
      err,
    });
  }
};
