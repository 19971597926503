import React from "react";
import { StyleSheet, View } from "react-native";

import { Text } from "~riata/components/typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const ArchivedChannelInput = () => {
  const { colors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: colors.error.main,
  });

  return (
    <View style={containerStyles}>
      <Text variant="headline" color={colors.error.on}>
        This channel has been archived. Visit the channel details to unarchive
        it.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 32,
  },
});
export default ArchivedChannelInput;
