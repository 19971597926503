import { removePreviousReply } from "./helpers";
import { useEffect, useMemo, useReducer, useState } from "react";
import { Scalars } from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { useGetCurrentUser } from "~riata/graphql";
import { useIsFocused } from "@react-navigation/core";
import AsyncStorage from "@react-native-community/async-storage";
import _ from "lodash";

export type State = {
  text: Record<string, string>;
  replyText: Record<string, { text?: string; sender?: string }>;
  inFlight: Record<string, boolean>;
  shouldFocus: Record<string, boolean>;
  // TODO Make this a real object
  attachments: Record<string, any[]>;
  suggestions: Record<string, any[]>;
  registeredChannels: string[];
};

const STATE_KEYS = [
  "text",
  "replyText",
  "inFlight",
  "shouldFocus",
  "attachments",
  "suggestions",
];

export const DEFAULT_STATE: State = {
  text: {},
  replyText: {},
  inFlight: {},
  shouldFocus: {},
  attachments: {},
  registeredChannels: [],
  suggestions: {},
};

export enum Actions {
  registerChannel,
  rebuildState,
  addAttachment,
  finishAttachment,
  handleSuggestionSelected,
  removeAttachment,
  reset,
  resetSaveText,
  setInFlight,
  setText,
  setReplyText,
  clearReplyText,
  updateAttachment,
  addSuggestion,
  removeSuggestion,
}

export const reducer = (prevState, action) => {
  switch (action.type) {
    case Actions.rebuildState:
      const newState = { ...prevState };
      STATE_KEYS.forEach((key) => {
        newState[key] = {
          ...prevState[key],
          [action.channelId]: action.storedState[key],
        };
      });
      return newState;
    case Actions.registerChannel:
      if (prevState?.registeredChannels?.includes(action.channelId)) {
        return {
          ...prevState,
        };
      }
      return {
        ...prevState,
        registeredChannels: [...prevState.registeredChannels, action.channelId],
        text: {
          ...prevState.text,
          [action.channelId]: "",
        },
        replyText: {
          ...prevState.replyText,
          [action.channelId]: {},
        },
        inFlight: {
          ...prevState.inFlight,
          [action.channelId]: false,
        },
        shouldFocus: {
          ...prevState.shouldFocus,
          [action.channelId]: false,
        },
        attachments: {
          ...prevState.attachments,
          [action.channelId]: [],
        },
      };

    case Actions.addAttachment:
      console.log("Adding Attachments");
      return {
        ...prevState,
        attachments: {
          ...prevState.attachments,
          [action.channelId]: [
            ...prevState.attachments[action.channelId],
            { file: action.file, loading: true },
          ],
        },
      };

    case Actions.updateAttachment:
      return {
        ...prevState,
        attachments: {
          ...prevState.attachments,
          [action.channelId]: prevState.attachments[action.channelId].map(
            (a) => {
              const attr = isWeb ? "name" : "path";
              return a.file[attr] === action.file[attr]
                ? { ...a, id: action.id }
                : a;
            }
          ),
        },
      };

    case Actions.finishAttachment:
      console.log("Finishing Attachments");
      return {
        ...prevState,
        attachments: {
          ...prevState.attachments,
          [action.channelId]: prevState.attachments[action.channelId].map(
            (a) => ({
              ...a,
              loading: a.id === action.id ? false : a.loading,
            })
          ),
        },
      };

    case Actions.removeAttachment:
      console.log("Remove Attachments");
      return {
        ...prevState,
        attachments: {
          ...prevState.attachments,
          [action.channelId]: prevState.attachments[action.channelId].filter(
            (a) => a.id !== action.id
          ),
        },
      };

    case Actions.reset:
      return {
        ...prevState,
        text: {
          ...prevState.text,
          [action.channelId]: "",
        },
        replyText: {
          ...prevState.replyText,
          [action.channelId]: {},
        },
        inFlight: {
          ...prevState.inFlight,
          [action.channelId]: false,
        },
        shouldFocus: {
          ...prevState.shouldFocus,
          [action.channelId]: false,
        },
        attachments: {
          ...prevState.attachments,
          [action.channelId]: [],
        },
        suggestions: {
          ...prevState.suggestions,
          [action.channelId]: "",
        },
      };

    case Actions.resetSaveText:
      return {
        ...prevState,
        text: {
          ...prevState.text,
          [action.channelId]: prevState.text,
        },
        replyText: {
          ...prevState.replyText,
          [action.channelId]: {},
        },
        inFlight: {
          ...prevState.inFlight,
          [action.channelId]: false,
        },
        shouldFocus: {
          ...prevState.shouldFocus,
          [action.channelId]: false,
        },
        attachments: {
          ...prevState.attachments,
          [action.channelId]: [],
        },
        suggestions: {
          ...prevState.suggestions,
          [action.channelId]: "",
        },
      };

    case Actions.setInFlight:
      return {
        ...prevState,
        inFlight: {
          ...prevState.inFlight,
          [action.channelId]: action.inFlight,
        },
      };

    case Actions.setText:
      return {
        ...prevState,
        text: {
          ...prevState.text,
          [action.channelId]: action.text,
        },
      };

    case Actions.setReplyText:
      return {
        ...prevState,
        replyText: {
          ...prevState.replyText,
          [action.channelId]: removePreviousReply(action.replyText),
        },
      };

    case Actions.clearReplyText:
      return {
        ...prevState,
        replyText: {
          ...prevState.replyText,
          [action.channelId]: {},
        },
      };

    case Actions.addSuggestion:
      return {
        ...prevState,
        suggestions: {
          ...prevState.suggestions,
          [action.channelId]: action.suggestionText,
        },
      };

    case Actions.removeSuggestion:
      return {
        ...prevState,
        suggestions: {
          ...prevState.suggestions,
          [action.channelId]: "",
        },
      };
  }
};

export const useInputBarState = ({ channelId }) => {
  const { loading: loadingUser, user } = useGetCurrentUser();
  const [isBusy, setIsBusy] = useState(false);
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
  const isFocused = useIsFocused();

  useEffect(() => {
    dispatch({ type: Actions.registerChannel, channelId });
  }, [channelId]);

  useEffect(() => {
    if (!user || !channelId || isWeb) {
      return;
    }

    if (isFocused) {
      // Read from storage and update state.
      setIsBusy(true);
      AsyncStorage.getItem(`${user.username}-chatSession@${channelId}`)
        .then((data) => {
          const storedState = JSON.parse(data);

          if (storedState) {
            const currentAttachments = state.attachments?.[channelId] || [];
            const currentReplyText = state.replyText?.[channelId] || {};

            /**
             * While an attachment is being added values may get out of sync
             * we need to make sure we preserve all current values
             */
            if (currentAttachments.length !== storedState.attachments?.length) {
              storedState.attachments = _.uniqBy(
                [...currentAttachments, ...storedState.attachments],
                "file.filename"
              );
            }

            /**
             * When replying a message a new screen will be rendered causing an
             * out of sync state with reply text. We need to make sure we retain
             * this value
             */
            if (currentReplyText.text !== storedState.replyText.text) {
              storedState.replyText = _.every(
                ["sender", "text"],
                _.partial(_.has, currentReplyText)
              )
                ? currentReplyText
                : storedState.replyText;
            }

            dispatch({ type: Actions.rebuildState, channelId, storedState });
          }
        })
        .catch((error) => {
          console.error("Error reading stored chat session", error);
        })
        .finally(() => {
          setIsBusy(false);
        });
    } else {
      // Store to local storage
      setIsBusy(true);
      const channelSession = {};
      STATE_KEYS.forEach((key) => {
        channelSession[key] = state[key][channelId];
      });

      AsyncStorage.setItem(
        `${user.username}-chatSession@${channelId}`,
        JSON.stringify(channelSession)
      )
        .catch((error) => {
          console.error("Error writing chat session", error);
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, channelId]);

  const actions = useMemo(
    () => ({
      // Attachment Related Actions
      addAttachment: (file: { path: string }, channelId: string) => {
        dispatch({ type: Actions.addAttachment, file, channelId });
      },

      updateAttachment: (
        file: { path: string },
        id: Scalars["ID"],
        channelId: string
      ) => {
        dispatch({ type: Actions.updateAttachment, file, id, channelId });
      },

      finishAttachment: (id: string, channelId: string) => {
        dispatch({ type: Actions.finishAttachment, id, channelId });
      },

      removeAttachment: (id: string, channelId: string) => {
        dispatch({ type: Actions.removeAttachment, id, channelId });
      },

      // Input Text Related Actions
      setText: (text: string, channelId: string) => {
        dispatch({ type: Actions.setText, text, channelId });
      },

      resetSaveText: (channelId: string) => {
        dispatch({ type: Actions.resetSaveText, channelId });
      },

      // Reply Text Related Actions
      setReplayText: ({ text, sender }, channelId: string) => {
        dispatch({
          type: Actions.setReplyText,
          replyText: { text, sender },
          channelId,
        });
      },

      clearReplyText: (channelId: string) => {
        dispatch({ type: Actions.clearReplyText, channelId });
      },

      // Editor Related Actions
      reset: (channelId: string) => {
        dispatch({ type: Actions.reset, channelId });
      },

      setInFlight: (channelId: string, inFlight = true) => {
        dispatch({ type: Actions.setInFlight, inFlight, channelId });
      },

      // Suggestions Related Actions
      addSuggestion: (channelId: string, suggestionText: string) => {
        dispatch({ type: Actions.addSuggestion, channelId, suggestionText });
      },

      removeSuggestion: (channelId: string) => {
        dispatch({ type: Actions.removeSuggestion, channelId });
      },
    }),
    []
  );

  return { state, dispatch, actions, isBusy: isBusy || loadingUser };
};
