import React from "react";
import { DatePickerComponent } from "./types";
import moment from "moment";

const DatePicker: DatePickerComponent = ({
  date,
  maximumDate,
  minimumDate,
  onDateChange,
}) => {
  const value = moment(date).format("YYYY-MM-DDThh:mm");
  const min = moment(minimumDate).format("YYYY-MM-DDThh:mm");
  const max = moment(maximumDate).format("YYYY-MM-DDThh:mm");

  return (
    <input
      type="datetime-local"
      value={value}
      min={min}
      max={max}
      onChange={(e) => onDateChange(new Date(e.target.value))}
      style={{
        border: "none",
        outline: "none",
        fontFamily: "HelveticaNeue",
        fontSize: 24,
      }}
    />
  );
};

export default DatePicker;
