import React from "react";
import { Button } from "../inputs";
import { Text } from "../typography";
import emoji from "node-emoji";
import { StyleSheet } from "react-native";

const EmojiReactionItem = ({
  message,
  reaction,
  isFirst,
  addReaction,
  navigation,
}) => (
  <Button
    style={isFirst ? styles.emojiFirstItem : styles.emojiItem}
    onPress={(evt) => {
      evt.preventDefault();
      addReaction(reaction);
      navigation.goBack();
    }}
  >
    <Text style={styles.emoji}>{emoji.emojify(`:${reaction.name}:`)}</Text>
  </Button>
);

const styles = StyleSheet.create({
  emojiFirstItem: {
    marginRight: 8,
  },
  emojiItem: {
    marginHorizontal: 8,
  },
  emoji: {
    fontSize: 30,
  },
});

export default React.memo(EmojiReactionItem);
