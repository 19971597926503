import React, { useRef } from "react";
import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  View,
} from "react-native";
import { useColors } from "~riata/hooks";
import { useConcatStyles } from "~riata/hooks/useConcatStyles";

type TextInputProps = RNTextInputProps & {
  shouldFocus?: boolean;
  fill?: boolean;
  containerStyles?: any;
  inputStyles?: any;
};

const TextInput = ({
  shouldFocus = false,
  fill = false,
  containerStyles = {},
  inputStyles = {},
  ...props
}: TextInputProps) => {
  const ref = useRef(null);
  const { newColors } = useColors();

  const parsedContainerStyles = useConcatStyles([
    styles.container,
    fill ? styles.fill : {},
    containerStyles,
  ]);

  const parseInputStyles = useConcatStyles([
    { backgroundColor: newColors.inputBar.main, color: newColors.inputBar.on },
    styles.input,
    fill ? styles.fill : {},
    inputStyles,
  ]);

  if (shouldFocus) {
    ref?.current?.focus();
  }

  return (
    <View style={parsedContainerStyles}>
      <RNTextInput
        {...props}
        autoFocus={shouldFocus}
        ref={ref}
        style={parseInputStyles}
        placeholderTextColor={newColors.text.placeHolder.main}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  fill: {
    flex: 1,
  },
  input: {
    fontSize: 14,
    lineHeight: 17,
    paddingHorizontal: 8,
  },
});

export default TextInput;
