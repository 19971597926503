import React from "react";
import { StyleSheet } from "react-native";

import { ChevronDown, ChevronUp } from "../icons";
import PluralizeText from "../PluralizeText";
import { Row } from "../layout";
import { useColors } from "~riata/hooks";

const MessageRepliesHeader = ({ expanded, data }) => {
  const { newColors } = useColors();
  const Icon = expanded ? ChevronUp : ChevronDown;

  return (
    <Row justify="left" style={styles.container}>
      <Icon size={8} color={newColors.icon.default.main} />
      <PluralizeText
        style={styles.text}
        length={data.length}
        text="Reply"
        color={newColors.text.caption.main}
      />
    </Row>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 13,
    paddingLeft: 5,
  },
  container: {
    height: 20,
  },
});

export default MessageRepliesHeader;
