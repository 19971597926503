import { Dimension } from "~riata/generated/graphql";

export const isLocalAttachment = (
  attachment: LocalAttachment | any
): attachment is LocalAttachment => {
  return (attachment as LocalAttachment).file !== undefined;
};

export const calculateDimensions = (
  dimensions: Dimension,
  maxWidth: number
) => {
  const max = maxWidth > 500 ? 500 : maxWidth;

  if (dimensions.width <= max) {
    return {
      width: dimensions.width,
      height: dimensions.height,
    };
  }

  const adjustedHeight = dimensions.height * (max / dimensions.width);

  return {
    width: max,
    height: adjustedHeight,
  };
};
