import { useEffect, useState } from "react";
import { useCreateGenericRedirect } from "~riata/graphql";
import { Sentry } from "~riata/utils/sentry";

const useGetRedirectUrl = ({ bookmarkId, destination }) => {
  const { execute, loading, error } = useCreateGenericRedirect({ destination });
  const [isBusy, setIsBusy] = useState(false);
  const [state, setState] = useState({
    bookmark: null,
    url: "",
  });

  useEffect(() => {
    if (loading || isBusy) {
      return;
    }

    if (state.bookmark === bookmarkId) {
      return;
    }
    setIsBusy(true);
    execute()
      .then((response) => {
        const newUrl = response.data.createGenericRedirect.url;
        setState({
          bookmark: bookmarkId,
          url: newUrl,
        });
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      })
      .finally(() => setIsBusy(false));
  }, [bookmarkId, execute, isBusy, loading, state]);

  return {
    isBusy: loading || isBusy,
    error,
    redirectUrl: state.url,
    initializedId: state.bookmark,
  };
};

export default useGetRedirectUrl;
