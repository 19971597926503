import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useListFilterSearchState } from "~riata/components/ListFilterSearch/ListFilterSearch.state";
import { CustomIcon } from "~riata/components/icons";
import { Button, TextInput } from "~riata/components/inputs";
import { useColors, useComposeStyles } from "~riata/hooks";
import { CORE_FONT } from "~riata/theme/fonts";

const HeaderSearchBar: HeaderSearchBarComponent = ({
  isExpanded = false,
  onTextChange,
  onToggleExpanded,
}) => {
  const { newColors } = useColors();
  const { state, actions } = useListFilterSearchState({
    onPress: onTextChange,
  });

  const dismissSearchBar = () => {
    actions.reset();
    onToggleExpanded();
  };

  const inputStyles = useComposeStyles(styles.input, {
    backgroundColor: newColors.inputBar.main,
  });

  const inputContainerStyles = useComposeStyles(styles.inputContainer, {
    backgroundColor: newColors.inputBar.main,
    shadowColor: newColors.shadow.main,
  });

  if (isExpanded) {
    return (
      <View style={styles.searchBarContainer}>
        <TextInput
          value={state.term}
          returnKeyType="search"
          shouldFocus={true}
          onChangeText={actions.setSearchTerm}
          onSubmitEditing={(event) => {
            actions.handleSubmit(event.nativeEvent.text);
          }}
          containerStyles={inputContainerStyles}
          inputStyles={inputStyles}
          placeholder="Search"
        />
        <Button style={styles.cancelButton} onPress={dismissSearchBar}>
          <CustomIcon
            name="close"
            size={20}
            color={newColors.icon.primary.main}
          />
        </Button>
        <View style={styles.searchIconWrapper}>
          <CustomIcon
            name="search"
            size={20}
            color={newColors.icon.primary.main}
          />
        </View>
      </View>
    );
  }

  return (
    <View>
      <CustomIcon
        name="search"
        size={20}
        color={newColors.icon.primary.main}
        onPress={onToggleExpanded}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchBarContainer: {
    width: "100%",
  },
  cancelButton: {
    position: "absolute",
    right: 10,
    top: Platform.OS === "android" ? 11 : 9,
    elevation: 2,
    shadowColor: "transparent",
  },
  searchIconWrapper: {
    position: "absolute",
    left: 10,
    top: Platform.OS === "android" ? 11 : 9,
    elevation: 2,
    shadowColor: "transparent",
  },
  inputContainer: {
    backgroundColor: "transparent",
    paddingVertical: 10,
    paddingHorizontal: 30,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1,
    elevation: 2,
    borderRadius: 12,
    shadowColor: "transparent",
  },

  input: {
    fontSize: 14,
    lineHeight: 17,
    fontFamily: CORE_FONT,
    fontWeight: "400",
    backgroundColor: "transparent",
    padding: 0,
    margin: 0,
  },
});

export default HeaderSearchBar;
