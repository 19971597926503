import { isWeb } from "./../theme/common";
import { useCallback } from "react";
import { Linking, Platform } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import {
  useCreateIntegrationRedirect,
  useGetAssociatedUrls,
} from "~riata/graphql";
import { webIntegrationLauncher } from "~riata/components/integrations/IntegrationListItem/useIntegrationLauncher";
import { IntegrationRedirectInput } from "~riata/generated/graphql";
// FIXME This should look at the existing navigation to figure out the prefix
const PREFIX = "riata://";

export const useLinkOpener = () => {
  const linkTo = useLinkTo();
  // NOTE: We're going to provide a full IntegrationRedirectInput to execute
  const { execute } = useCreateIntegrationRedirect({ integration: null });
  const associatedUrls = useGetAssociatedUrls();
  return useCallback(
    (url, isExternalBookmark = false, bookmarkId = "") => {
      if (url.startsWith(PREFIX)) {
        const cleanUrl: string = url.replace(PREFIX, "/");
        return linkTo(cleanUrl);
      } else {
        let integrations = associatedUrls.filter((i) => url.startsWith(i.url));
        if (integrations.length > 0) {
          // TODO Log error when we get more than one?
          let path = encodeURIComponent(url);
          if (Platform.OS === "web") {
            let input: IntegrationRedirectInput = {
              integration: integrations[0].id,
              destination: path,
            };
            webIntegrationLauncher(execute({ variables: { input } }), {
              integration: integrations[0],
            });
            return false;
          } else {
            return linkTo(`/integration/${integrations[0].id}/${path}`);
          }
        } else {
          // No integration associated link is found we can navigate to internal view
          // ? Is path relevant in this scenario ?
          if (!isExternalBookmark && !isWeb) {
            return linkTo(`/bookmark/${bookmarkId}`);
          }
        }
      }
      Linking.openURL(url);
    },
    [associatedUrls, execute, linkTo]
  );
};
