import React from "react";
import { StyleSheet } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Button } from "~riata/components/inputs";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";
import { CustomIcon } from "~riata/components/icons";

const IOSPicker: PickerComponent = ({ selectedValue, onShowOptions }) => {
  const { newColors } = useColors();

  const pickerStyles = useComposeStyles(styles.pickerContainer, {
    backgroundColor: newColors.background.main,
  });

  return (
    <Button style={pickerStyles} onPress={onShowOptions}>
      <Row justify="between" align="center">
        <Text>{`${selectedValue} hour${selectedValue === 1 ? "" : "s"}`}</Text>
        <CustomIcon
          name="chevron_down"
          size={8}
          color={newColors.icon.lightGray.main}
        />
      </Row>
    </Button>
  );
};

const styles = StyleSheet.create({
  pickerContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 10,
    backgroundColor: "transparent",
    minWidth: 150,
  },
});

export default IOSPicker;
