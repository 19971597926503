import React from "react";
import { StyleSheet, View } from "react-native";

import { DocumentIcon } from "../icons";
import { ActivityIndicator } from "../activityIndicator";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isLocalAttachment } from "./_utils";
import { IntegrationAttachment } from "~riata/generated/graphql";
import { DEFAULT_THUMBNAIL_SIZE } from "./constants";
import { ATTACHMENT_PREVIEW_ICON_SIZE } from "~riata/theme";

// TODO Would be great to add an icon to
export const IntegrationAttachmentPreview: GenericAttachmentView<
  IntegrationAttachment
> = ({
  thumbnail = false,
  thumbnailSize = DEFAULT_THUMBNAIL_SIZE,
  attachment,
}) => {
  const isLoading = isLocalAttachment(attachment) && attachment.loading;
  const { colors } = useColors();

  const previewStyle = useComposeStyles(styles.preview, {
    backgroundColor: colors.icon.accent5.main,
  });
  const iconStyle = useComposeStyles(styles.icon, {
    color: colors.icon.accent5.on,
  });

  console.debug("attachment");
  return (
    <View
      style={[
        previewStyle,
        thumbnail
          ? { ...styles.thumbnail, height: thumbnailSize, width: thumbnailSize }
          : {},

        isLoading ? styles.loading : {},
      ]}
    >
      <DocumentIcon
        size={thumbnail ? thumbnailSize / 2 : ATTACHMENT_PREVIEW_ICON_SIZE}
        style={iconStyle}
      />
      {isLoading && (
        <ActivityIndicator
          color={colors.activityIndicator.lightContrast.main}
          style={styles.loadingIndicator}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  preview: {
    flexDirection: "column",
    borderRadius: 6,
    height: 96,
    width: 96,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  thumbnail: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    marginTop: 0,
    width: 48,
    height: 48,
  },
  loading: { opacity: 0.5 },
  icon: { color: "transparent" },
  loadingIndicator: {
    position: "absolute",
    width: 24,
    height: 24,
    left: 12,
    top: 12,
  },
});

IntegrationAttachmentPreview.canDisplay = (
  obj: any
): obj is IntegrationAttachment =>
  obj.__typename === "IntegrationAttachment" || obj?.file?.integration?.url;
