import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import {
  Button,
  Row,
  Text,
  UserAvatar,
  AvatarOffDutyIndicator,
  CustomIcon,
} from "~riata/components";
import { useGetOrCreateDm } from "~riata/graphql/mutations/CreateDm";
import { useLinkTo } from "@react-navigation/native";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useGetCurrentUser } from "~riata/graphql";

const ChannelUser = ({ user, groupChannelId }) => {
  const { newColors } = useColors();
  const linkTo = useLinkTo();

  const { getOrCreateDirectMessageChannel, channel } = useGetOrCreateDm();
  const { user: currentUser } = useGetCurrentUser();

  const onCreateDM = () => {
    getOrCreateDirectMessageChannel({
      variables: {
        recipients: [user.id],
      },
    });
  };

  const userStatusContainerStyles = useComposeStyles(styles.statusContainer, {
    borderColor: newColors.surface.main,
  });

  const canMessage = useMemo(() => {
    return user.canMessage && user.id !== currentUser.id;
  }, [user, currentUser]);

  if (channel) {
    linkTo(`/channel/${channel}`);
    return null;
  }

  return (
    <Row style={styles.container} align="center">
      <Button
        disabled={!canMessage}
        style={{ flex: 1 }}
        onPress={() => {
          linkTo(`/channel/${groupChannelId}/info/user/${user.id}`);
        }}
      >
        <Row style={{ gap: 16 }}>
          <View>
            <UserAvatar size={40} user={user} />
            <View style={userStatusContainerStyles}>
              <AvatarOffDutyIndicator
                user={user}
                variant="small"
                borderColor={newColors.surface.main}
              />
            </View>
          </View>
          <View style={styles.userInfoContainer}>
            <Text
              variant="newSubhead"
              fontWeight="bold"
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {user.fullName}
            </Text>
          </View>
        </Row>
      </Button>
      {canMessage && (
        <Button onPress={onCreateDM}>
          <CustomIcon
            name="send"
            size={20}
            color={newColors.button.primary.main}
          />
        </Button>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 60,
    marginLeft: 20,
    marginRight: 28,
  },
  statusContainer: {
    position: "absolute",
    top: 28,
    left: 28,
  },
  userInfoContainer: {
    flex: 1,
    marginRight: 17,
  },
  sendIcon: {
    marginLeft: 0,
  },
});

export default ChannelUser;
