import { useEffect, useRef, useState } from "react";
import { Image, StyleSheet } from "react-native";

const useImageSize = (url) => {
  const [style, setImageStyle] = useState(defaultStyle.default);
  const cancel = useRef(false);

  useEffect(() => {
    Image.getSize(
      url,
      (w, h) => {
        const aspectRatio = w / h;
        const calculatedStyle = StyleSheet.create({
          calculated: {
            width: 200,
            height: 200 / aspectRatio,
          },
        });
        if (cancel.current === false) {
          setImageStyle(calculatedStyle.calculated);
        }
      },
      (error) => {}
    );

    return () => {
      cancel.current = true;
    };
  }, [url]);

  return { style };
};

const defaultStyle = StyleSheet.create({
  default: {
    width: 200,
    height: 200,
  },
});

export default useImageSize;
