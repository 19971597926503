import React, { useCallback, useContext } from "react";
import { StyleSheet, View } from "react-native";
import WebView from "react-native-webview";
import { RodioLoader } from "~riata/components";
import { RegistryContext } from "~riata/contexts";
import { useGetIntegration } from "~riata/graphql";
import { useRiataInjection } from "~riata/hooks/useRiataInjection";

const IntegrationAttachmentActionSheet = ({
  navigation,
  route: {
    params: { integration: integrationId },
  },
}) => {
  const registry = useContext(RegistryContext);
  const onAddAttachment = registry.get("onAddAttachment", false);
  console.debug(`typeof onAddAttachment in IAAS ${typeof onAddAttachment}`);
  const addAttachment = async ({ url }) => {
    console.log("onAddAttachment", { url });
    onAddAttachment({
      integration: { integration: integrationId, url },
    });
    navigation.goBack();
  };
  const { loading, data } = useGetIntegration(integrationId);
  const integration = data?.getIntegration ?? false;
  const cancel = useCallback(() => navigation.goBack(), [navigation]);
  const { actions, handleMessageDispatch, ref, toInject } = useRiataInjection({
    navigation,
    customHandlers: { addAttachment, cancel },
    defaultBackScreen: "Integrations",
  });

  return (
    <View style={styles.mainContainer}>
      {loading ? (
        <RodioLoader />
      ) : (
        <WebView
          source={{ uri: integration.attachmentURL }}
          geolocationEnabled
          injectedJavaScriptBeforeContentLoaded={toInject}
          ref={ref}
          allowsBackForwardNavigationGestures
          onMessage={handleMessageDispatch}
          onNavigationStateChange={actions.onWebViewNavigationStateChange}
          containerStyle={styles.webView}
          webviewDebuggingEnabled={true}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  actionBackContainer: {
    height: 130,
  },
  webView: {},
});

export default IntegrationAttachmentActionSheet;
