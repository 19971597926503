import gql from "graphql-tag";
import { useCallback } from "react";
import { Scalars } from "~riata/generated/graphql";
import { useAuthClient } from "~riata/hooks";

const QUERY = gql`
  mutation GenerateSMSAuthCode($phone: String!) {
    generateSMSAuthCode(phone: $phone) {
      errors {
        field
        messages
      }
      success
    }
  }
`;

const useGenerateSMSAuthCode = () => {
  const { client } = useAuthClient();
  const execute = useCallback(
    // TODO Handle non-loaded client.
    ({ phone }: { phone: Scalars["String"] }) =>
      client.mutate({
        mutation: QUERY,
        variables: { phone },
      }),
    [client]
  );
  return { execute };
};

export default useGenerateSMSAuthCode;
