import React from "react";
import { FlatList, FlatListProps } from "react-native";
import ChannelListItem from "./ChannelListItem";
import { RodioLoader } from "../misc";
import { Channel } from "~riata/generated/graphql";
import { useErrorToast } from "~riata/hooks";

type ChannelListProps = {
  channels: Channel[];
  // TODO Document what error can be
  error: any;
  loading: boolean;
} & Omit<FlatListProps<Channel>, "data" | "renderItem">;

const ChannelList = ({
  channels,
  error,
  loading,
  ...props
}: ChannelListProps) => {
  const { RodioError, showToast, isDismissed } = useErrorToast(error);
  if (loading) {
    return <RodioLoader />;
  }

  // TODO This isn't doing what it originally did, should it stay?
  if (error && !isDismissed) {
    showToast("An error occurred while fetching channels");
  }

  return (
    <>
      <FlatList
        data={channels}
        renderItem={(item) => <ChannelListItem channel={item.item} />}
        {...props}
      />
      <RodioError />
    </>
  );
};

export default ChannelList;
