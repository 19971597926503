import React from "react";
import { StatusBar, StyleSheet, View } from "react-native";

import { Row } from "../Row";
import { useColors, useComposeStyles } from "~riata/hooks";

const Header = ({ children }) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.header.main,
  });

  return (
    <View style={containerStyles}>
      <StatusBar barStyle="default" />
      <Row align="center" style={styles.row}>
        {children}
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
  },
  row: {
    height: "100%",
    paddingHorizontal: 8,
  },
});

export default Header;
