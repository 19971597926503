enum MimeType {
  Doc = "application/msword",
  Docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  Xls = "application/vnd.ms-excel",
  Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Ppt = "application/vnd.ms-powerpoint",
  Pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  Pdf = "application/pdf",
  Jgp = "image/jpeg",
  Jpeg = "image/jpeg",
  Png = "image/png",
  Gif = "image/gif",
}

export { MimeType };
