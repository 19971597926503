import { useLinkTo } from "@react-navigation/native";
import { useContext, useEffect } from "react";
import { NotificationsListenerContext } from "~riata/controllers/base";

const useRegisterNotifications = () => {
  const {
    initializeForegroundSubscriber,
    initializeBackgroundSubscriber,
    initializeKillStateListener,
  } = useContext(NotificationsListenerContext);
  const linkTo = useLinkTo();

  useEffect(() => {
    initializeForegroundSubscriber();
  }, [initializeForegroundSubscriber]);

  useEffect(() => {
    if (linkTo) {
      initializeBackgroundSubscriber(linkTo);
    }
  }, [initializeBackgroundSubscriber, linkTo]);

  useEffect(() => {
    if (linkTo) {
      initializeKillStateListener(linkTo);
    }
  }, [initializeKillStateListener, linkTo]);
};

export default useRegisterNotifications;
