import React from "react";
import { StackActions } from "@react-navigation/core";

// Components
import { BulletinComposeForm } from "~riata/components";

// Hooks
import { useAttachmentActionSheetLink } from "~riata/links";
import {
  useBulletinCompose,
  useFeatureFlags,
  useProcessAttachment,
  useSaveBulletinAsDraft,
} from "~riata/hooks";

// Utils
import { generateGoTo, resetBulletinNavigationAfterMutation } from "../helpers";
import { useCreateBulletin } from "~riata/graphql";
import { BulletinScreen } from "../enums";
import { hasSomeTruthyValues } from "~riata/utils/object";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

export const BulletinComposeScreen = ({ navigation }) => {
  const { state, actions } = useBulletinCompose();
  const { execute: createBulletin } = useCreateBulletin();
  const { navigateTo } = useDrawerNavigation();

  const { flags } = useFeatureFlags();

  useProcessAttachment({ actions }, "bulletinCompose");

  useSaveBulletinAsDraft({
    bulletin: {
      ...state.payload,
      attachments: state.attachments.map((attachment) => attachment.id),
      recipients: state.recipientInboxes,
    },
    screen: BulletinScreen.Compose,
    navigation,
  });

  const action = StackActions.push("AttachmentActionSheet", {
    onAddAttachment: "onAddAttachment",
  });

  const { onPress: goToAttachmentActionSheet } = useAttachmentActionSheetLink({
    action: action,
  });

  const saveBulletin = (goBackOnFail = false) => {
    // Removing not accepted value in the backend
    const { id, recipientInboxes, ...rest } = state.payload;
    try {
      const newBulletin = {
        ...rest,
        isDraft: true,
        attachments: state.attachments.map((attachment) => attachment.id),
        recipients: state.recipientInboxes || [],
      };

      const bulletinHasChanges = hasSomeTruthyValues(newBulletin, [
        "allowVisibility",
        "isDraft",
      ]);

      if (bulletinHasChanges) {
        createBulletin(newBulletin, flags.expiringBulletins);
        resetBulletinNavigationAfterMutation(
          navigation,
          BulletinScreen.Compose
        );
      }

      if (!bulletinHasChanges && goBackOnFail) {
        navigateTo("Bulletins");
      }
    } catch {
      return;
    }
  };

  const goToScheduleDateSelection = generateGoTo(
    state,
    actions,
    navigation.navigate,
    "scheduledAt"
  );

  const goToExpiresDateSelection = generateGoTo(
    state,
    actions,
    navigation.navigate,
    "expires"
  );

  return (
    <BulletinComposeForm
      composeActions={actions}
      composeState={state}
      navigate={navigation.navigate}
      onBack={() => saveBulletin(true)}
      onSave={() => saveBulletin(false)}
      onNavigateToAttachment={goToAttachmentActionSheet}
      onNavigateToExpire={goToExpiresDateSelection}
      onNavigateToSchedule={goToScheduleDateSelection}
    />
  );
};

export default BulletinComposeScreen;
