import React from "react";
import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps as RNKeyboardAvoidingViewProps,
  Platform,
  StyleSheet,
} from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";

const DEFAULT_BEHAVIOR = Platform.OS === "ios" ? "padding" : "height";
const DEFAULT_OFFSET = Platform.OS === "ios" ? 60 : 80;
type KeyboardAvoidingViewComponent = (
  props: RNKeyboardAvoidingViewProps & { children: React.ReactNode }
) => JSX.Element;

const KeyboardAvoidingView: KeyboardAvoidingViewComponent = ({
  behavior = DEFAULT_BEHAVIOR,
  ...props
}) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.surface.main,
  });

  return (
    <RNKeyboardAvoidingView
      keyboardVerticalOffset={DEFAULT_OFFSET}
      behavior={behavior}
      style={containerStyles}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    flex: 1,
  },
});

export default KeyboardAvoidingView;
