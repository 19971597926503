const BulletinStatusIcon = {
  Unread: { iconName: "unread", iconLabel: "Unread", iconSize: 20 },
  Read: { iconName: "bulletins_opened", iconLabel: "Read", iconSize: 20 },
  Viewed: {
    iconName: "viewed",
    iconLabel: "Viewed",
    iconSize: 20,
  },
};

export { BulletinStatusIcon };
