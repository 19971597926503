import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GetChannelQuery } from "~riata/graphql/queries/GetChannel";
import { GetChannelsQuery } from "~riata/graphql/queries";
import { ChannelType } from "~riata/generated/graphql";

export const UNMUTE_CHANNEL = gql`
  mutation ($id: ID!) {
    unmuteChannel(input: { id: $id }) {
      success
    }
  }
`;

const ALL_UNARCHIVED_CHANNELS = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false } },
};

const ALL_UNARCHIVED_DIRECT = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false, type: ChannelType.Direct } },
};

const ALL_UNARCHIVED_TEAM = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false, type: ChannelType.Team } },
};

export const useUnmuteChannel = () => {
  const [mutation, response] = useMutation(UNMUTE_CHANNEL);
  const successful = response?.data ? true : false;

  const execute = (channelId) => {
    const variables = { id: channelId };
    return mutation({
      variables,
      refetchQueries: [
        { query: GetChannelQuery, variables: { id: channelId, query: "" } },
        ALL_UNARCHIVED_CHANNELS,
        ALL_UNARCHIVED_DIRECT,
        ALL_UNARCHIVED_TEAM,
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response, successful };
};
