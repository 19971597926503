import React from "react";
import { StyleSheet, View } from "react-native";

import { CustomIcon } from "./icons";
import { Text } from "./typography";
import { Button } from "./inputs";
import { Row } from "./layout";
import { useColors } from "~riata/hooks";

const SelectableListItem = ({ inbox, selected, onSelect }) => {
  const { newColors } = useColors();
  return (
    <Button onPress={() => onSelect(inbox)} style={styles.container}>
      <Row>
        <View style={styles.content}>
          <Text variant="headline">{inbox.name}</Text>
          <Text variant="footnote" color={newColors.text.footnote.main}>
            {inbox.members.total} Members
          </Text>
        </View>
        {selected ? (
          <CustomIcon
            size={25}
            color={newColors.icon.primary.main}
            name="checkmark_full"
          />
        ) : (
          <CustomIcon
            size={25}
            color={newColors.icon.primary.disabled}
            name="checkmark_empty"
          />
        )}
      </Row>
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    flex: 1,
  },
  content: {
    flex: 1,
  },
});

export default SelectableListItem;
