import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NewDMScreen } from "./NewDMScreen";
import { NewGroupScreen } from "./NewGroupScreen";

const StackNavigator = createStackNavigator();

const NewChannelStack = () => {
  return (
    <StackNavigator.Navigator
      initialRouteName="NewDMScreen"
      screenOptions={{
        headerShown: false,
        presentation: "card",
      }}
    >
      <StackNavigator.Screen name="NewDMScreen" component={NewDMScreen} />
      <StackNavigator.Screen name="NewGroupScreen" component={NewGroupScreen} />
    </StackNavigator.Navigator>
  );
};

export default NewChannelStack;
