import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { CustomIcon, SendIcon } from "~riata/components/icons";
import { useColors } from "~riata/hooks";

const Toolbar = ({ canSend, onSendMessage, boldHandler, italicHandler }) => {
  const { newColors } = useColors();

  return (
    <View style={styles.toolbar}>
      <TouchableOpacity style={styles.toolbarItem} onPress={boldHandler}>
        <CustomIcon name="bold" color={newColors.surface.on} size={20} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.toolbarItem} onPress={italicHandler}>
        <CustomIcon name="italic" color={newColors.surface.on} size={20} />
      </TouchableOpacity>
      <TouchableOpacity
        style={{ marginLeft: "auto" }}
        disabled={!canSend}
        onPress={onSendMessage}
      >
        <SendIcon
          size={21}
          color={
            canSend
              ? newColors.icon.primary.main
              : newColors.icon.primary.disabled
          }
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  toolbar: {
    flexDirection: "row",
    marginRight: 16,
    marginBottom: 16,
    alignItems: "center",
    marginLeft: 40,
  },
  buttonLabel: {
    fontSize: 24,
    lineHeight: 24,
  },
  toolbarItem: {
    height: 60,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
  },
});

export { Toolbar };
