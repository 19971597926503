import React from "react";
import { View } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Text } from "../../typography";
import color from "color";
import { Hashtag } from "~riata/generated/graphql";

type HashTagCardProps = {
  hashtag: Hashtag;
};

type HashtagComponent = (props: HashTagCardProps) => JSX.Element;

const HashtagCard: HashtagComponent = ({ hashtag }) => {
  const { newColors } = useColors();

  const hashTagColor = hashtag.color?.color || newColors.tag.primary.main;

  const containerStyles = useComposeStyles(styles.container, {
    borderColor: hashTagColor,
    backgroundColor: color(hashTagColor).fade(0.9).string(),
  });

  const textStyles = useComposeStyles(styles.text, {
    color: hashTagColor,
  });

  return (
    <View style={containerStyles}>
      <Text variant="footnote" style={textStyles}>
        #{hashtag.name}
      </Text>
    </View>
  );
};

const styles = {
  container: {
    borderRadius: 6,
    height: 28,
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  text: {
    color: "transparent",
  },
};

export default HashtagCard;
