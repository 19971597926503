import { useApolloClient } from "@apollo/react-hooks";
import { useCallback } from "react";
import { Sentry } from "~riata/utils/sentry";
import { useCredentials } from "../useCredentials";
import { CookieManager } from "./utils";

const useLogout = () => {
  const credentials = useCredentials();
  const client = useApolloClient();

  const execute = useCallback(() => {
    client
      .clearStore()
      .then(() => {
        // @react-native-cookies/cookies will leave WebKit alone by default so we have
        // to tell it that embedded WebKit instances should be cleared. This forces
        // logouts of any and all web integrations.
        const useWebKit = true;
        return CookieManager.clearAll(useWebKit);
      })
      .then(() => {
        // NOTE: Without this, we still end up with data in the cache for some reason.
        //       Setting data as an empty object forces Apollo to go refetch everything
        //       instead of trying to serve from the somehow still persisted storage / cache.
        client.writeData({ data: {} });
        console.debug("useLogout > clearing credentials");
        credentials.clear({ rerender: true });
      })
      .catch(Sentry.captureException);
  }, [client, credentials]);

  return { logout: execute };
};

export default useLogout;
