import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import { ListFilterSearch } from "./ListFilterSearch";
import ListFilterButton from "./ListFilterButton";

const ScrollableListFilter = ({
  filters,
  searching = false,
  canCompose = false,
  setCurrentFilter,
  onSaveFilter,
  testID = "scrollable-list-filter",
}) => (
  <View testID="testID">
    <ScrollView
      testID="testID"
      keyboardShouldPersistTaps="handled"
      style={styles.scrollView}
      showsHorizontalScrollIndicator={false}
      horizontal
    >
      {filters.map((filter) => {
        if (filter.text === "Search") {
          return (
            <ListFilterSearch
              key="search"
              onPress={(text) => filter.func(text)}
              onSaveFilter={(text) => onSaveFilter(text)}
            />
          );
        }
        return (
          <ListFilterButton
            disabled={searching !== false}
            key={filter.id || filter.name}
            filter={filter}
            onPress={() => filter.func()}
            visible={
              (canCompose && filter.requiresAuthor) || !filter.requiresAuthor
            }
          />
        );
      })}

      {/* Spacer on the right side to ensure there's full padding */}
      <View style={styles.padding} />
    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  scrollView: {
    paddingLeft: 8,
    paddingRight: 16,
    paddingTop: 20,
    paddingBottom: 16,
  },
  padding: {
    width: 30,
    backgroundColor: "transparent",
  },
});

export default ScrollableListFilter;
