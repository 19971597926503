import { useContext } from "react";
import { FeatureFlagsContext } from "~riata/contexts";

const useFeatureFlags = () => {
  const { expiringBulletins, clickableAvatars } =
    useContext(FeatureFlagsContext);

  return {
    flags: { expiringBulletins, clickableAvatars },
  };
};

export { useFeatureFlags };
