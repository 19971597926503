import { Platform, TextStyle } from "react-native";
import color from "color";

export const WIDTH_FOR_PERMANENT_DRAWER = 850;

type RiataFonts = {
  largeTitle: TextStyle;
  title1: TextStyle;
  title2: TextStyle;
  title3: TextStyle;
  title4: TextStyle;
  headline: TextStyle;
  body: TextStyle;
  callout: TextStyle;
  subhead: TextStyle;
  footnote: TextStyle;
  caption1: TextStyle;
  caption2: TextStyle;
};

const textColor = color("#062737");
const primaryColor = color("#1B7899");
const noticeColor = color("#F9CC57");
const warningColor = color("#E77F2B");
const dangerColor = color("#D0021B");
const successColor = color("#0DDA87");

export const AVATAR_SIZES = ["normal", "large", "small"] as const;
export type AvatarSizes = typeof AVATAR_SIZES;
export type AvatarSize = AvatarSizes[number];
export type IconSizes = typeof AVATAR_SIZES;
export type IconSize = IconSizes[number];

export const avatarSize = {
  large: 72,
  normal: 48,
  small: 24,
};

export const avatarToIconRatio = 5 / 8;
export const iconSize = {
  large: avatarSize.large * avatarToIconRatio,
  normal: avatarSize.normal * avatarToIconRatio,
  small: avatarSize.small * avatarToIconRatio,
};

export const colors = {
  text: {
    main: textColor.toString(),
    disabled: textColor.fade(0.75).toString(),
    inverted: "#E0F0FC",
    alt: "#8091A8",
  },

  messageTitle: {
    default: "#005577",
    direct: textColor.toString(),
  },

  primary: {
    main: primaryColor.toString(),
    disabled: primaryColor.fade(0.75).toString(),
    alt: "#0C4E6E",
  },

  notice: {
    main: noticeColor.toString(),
    disabled: noticeColor.fade(0.75).toString(),
  },

  warning: {
    main: warningColor.toString(),
    disabled: warningColor.fade(0.75).toString(),
  },

  success: {
    main: successColor.toString(),
    disabled: successColor.fade(0.75).toString(),
  },

  danger: {
    main: dangerColor.toString(),
    disabled: warningColor.fade(0.75).toString(),
  },

  tertiary: {
    one: "#3CE4AE",
    two: "#8B60B6",
    three: "#A82A73",
    four: "#E79C25",
    five: "#F3685A",
  },

  primitive: {
    white: "#FFFFFF",
    black: "#000000",
    gray: "#A9A9A9",
    limeGreen: "#32cd32",
  },

  status: {
    active: "#32cd32",
    inactive: "#ffffff",
  },

  actionCard: {
    success: "#5cb85c",
    danger: "#D0021B",
    warning: "#f9d957",
    default: "#8091A8",
  },

  rebrand: {
    spaceCadet: "#202859",
    orangeSoda: "#F45C3A",
  },

  background: {
    main: "#F6F9FF",
  },

  safeArea: {
    background: "#EFF2F7",
  },

  header: {
    background: "#E7EAF0",
    text: textColor.toString(),
  },

  card: {
    background: "#FFFFFF",
    border: "#E7EAF0",
  },

  avatar: {
    main: color("#FFFFFF").fade(0.2).toString(),
  },

  reply: {
    background: color("#000000").fade(0.95).toString(),
    border: color("#000000").fade(0.75).toString(),
  },

  twitter: {
    background: color("#1da1f2").fade(0.8).toString(),
    border: color("#000000").fade(0.8).toString(),
  },
};

const semiBold = "600";

export const fonts: RiataFonts = {
  largeTitle: {
    fontSize: 34,
    fontWeight: semiBold,
    lineHeight: 41,
  },

  title1: {
    fontSize: 28,
    fontWeight: semiBold,
    lineHeight: 34,
  },

  title2: {
    fontSize: 22,
    fontWeight: semiBold,
    lineHeight: 28,
  },

  title3: {
    fontSize: 20,
    fontWeight: semiBold,
    lineHeight: 25,
  },

  title4: {
    fontSize: 16,
    fontWeight: semiBold,
    lineHeight: 20,
  },

  headline: {
    fontSize: 17,
    fontWeight: semiBold,
    lineHeight: 22,
  },

  body: {
    fontSize: 17,
    fontWeight: "normal",
    lineHeight: 22,
  },

  callout: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 21,
  },

  subhead: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: 20,
  },

  footnote: {
    fontSize: 13,
    fontWeight: "normal",
    lineHeight: 20,
  },

  caption1: {
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: 16,
  },

  caption2: {
    fontSize: 11,
    fontWeight: "normal",
    lineHeight: 13,
  },
};

export const isWeb = Platform.OS === "web";
export const isWideDisplay = (width) => width >= WIDTH_FOR_PERMANENT_DRAWER;

export const shadows = {
  card: {
    shadowOpacity: 0.1,
    shadowRadius: 96,
    shadowColor: "#000",
    shadowOffset: { width: 4, height: 4 },
  },
};
