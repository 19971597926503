import { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Message } from "~riata/generated/graphql";
import { GET_MESSAGES } from "../queries/GetMessages";

const REPORT_MESSAGE = gql`
  mutation ReportMessage($id: ID!) {
    reportMessage(input: { id: $id }) {
      success
    }
  }
`;

export const useReportMessage = () => {
  const [mutation, response] = useMutation(REPORT_MESSAGE);
  const execute = useCallback(
    async (message: Message) => {
      const refetchQueries = [
        {
          query: GET_MESSAGES,
          variables: {
            after: null,
            before: null,
            first: null,
            last: 20,
            filter: { channel: message.channel.id },
          },
        },
      ];
      // What happens if this fails?
      await mutation({ variables: { id: message.id }, refetchQueries });
    },
    [mutation]
  );

  return { execute, response };
};
