import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { PreviewCard, PreviewCardIcon } from "~riata/generated/graphql";
import { UserAvatar } from "../../avatars";
import TeamAvatar from "~riata/components/avatars/TeamAvatar/TeamAvatar";
import { useColors } from "~riata/hooks";

type PreviewCardAvatarProps = {
  card: PreviewCard;
  unread?: boolean;
  style?: { container: ViewStyle };
};

/**
 * Component used to display avatars.
 *
 * Possible Display Options:
 *  - Single Team Avatar,
 *  - Single User Avatar,
 *  - Two User Avatars
 *
 * TODO: Add back in UNREAD state
 *
 * @param props PreviewCardAvatarProps
 * @returns JSX.Element
 */
const PreviewCardAvatar = (props: PreviewCardAvatarProps) => {
  const { card, style } = props;
  const { newColors } = useColors();

  // Combine Styles
  const composedStyles = StyleSheet.flatten([
    defaultStyles.container,
    style?.container,
  ]);

  const avatarStyle = StyleSheet.flatten([
    defaultStyles.avatar,
    {
      borderColor: newColors.background.main,
    },
  ]);

  const avatars = useMemo(() => {
    if (card.icon === PreviewCardIcon.Team) {
      return <TeamAvatar id={card.externalId} size="regular" />;
    }

    if (card.icon === PreviewCardIcon.Avatar && card.avatarURLs.length === 1) {
      return (
        <UserAvatar
          size={48}
          uriPath={card.avatarURLs[0].url}
          style={defaultStyles.avatarContainer}
        />
      );
    }

    if (card.icon === PreviewCardIcon.Avatar && card.avatarURLs.length > 1) {
      return (
        <View>
          <View style={defaultStyles.multiUserLgAvatar}>
            <UserAvatar size={40} uriPath={card.avatarURLs[0].url} />
          </View>
          <View style={defaultStyles.multiUserAvatar}>
            <UserAvatar
              outlined
              borderWidth={2}
              size={30}
              uriPath={card.avatarURLs[1].url}
              style={avatarStyle}
            />
          </View>
        </View>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return <View style={[composedStyles]}>{avatars}</View>;
};

// Default styles for PreviewCardAvatar
const defaultStyles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "transparent",
  },
  multiUserLgAvatar: {
    position: "relative",
    top: 0,
    left: 0,
  },
  multiUserAvatar: {
    position: "absolute",
    top: 16,
    left: -10,
  },
  avatar: {
    borderColor: "transparent",
  },
  avatarContainer: {
    width: 48,
    height: 48,
  },
});

export default PreviewCardAvatar;
