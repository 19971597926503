import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ModalToast } from "../toasts";
import { Text } from "../typography";
import { Row, VerticalCenter } from "../layout";
import { UserAvatar } from "../avatars";
import { SentTime } from "../chat";
import { Separator } from "../misc";
import MarkdownContent from "../MarkdownContent";

// TODO Theme this
const CommentListItem = ({ comment }) => {
  const [warning, setWarning] = useState(false);

  return (
    <>
      <View style={styles.container}>
        <Row justify="left" style={styles.content}>
          <UserAvatar user={comment.creator} />
          <VerticalCenter>
            <View style={styles.comment}>
              <Text variant="title4">{comment.creator.displayName}</Text>
              <SentTime date={comment.createdAt} />
            </View>
          </VerticalCenter>
        </Row>

        <MarkdownContent text={comment.content} />
        <Separator style={styles.separator} />
      </View>

      {warning && (
        <ModalToast
          warning
          onDismiss={() => setWarning(false)}
          text="Replies not implemented"
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 18,
    marginBottom: 18,
  },
  content: {
    height: 45,
  },
  comment: {
    marginLeft: 10,
  },
  separator: {
    marginTop: 10,
  },
});

export default CommentListItem;
