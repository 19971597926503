import { useEffect } from "react";
import { isWeb } from "~riata/theme/common";

const useWebScrollFix = ({ ref, messageList }) => {
  // Known issue with FlatList and RNW
  // https://github.com/necolas/react-native-web/issues/995#issuecomment-630012292
  useEffect(() => {
    const currentRef = ref.current;
    if (!isWeb || currentRef === null) {
      return;
    }
    const invertedWheelEvent = (e) => {
      currentRef.getScrollableNode().scrollTop -= e.deltaY;
      e.preventDefault();
    };

    currentRef
      .getScrollableNode()
      ?.addEventListener("wheel", invertedWheelEvent);
    currentRef.setNativeProps({
      style: {
        transform: "translate3d(0,0,0) scaleY(1)",
      },
    });
    return () => {
      if (currentRef != null) {
        currentRef
          .getScrollableNode()
          ?.removeEventListener("wheel", invertedWheelEvent);
      }
    };
  }, [ref, messageList]);
};

export default useWebScrollFix;
