import React from "react";
import { View } from "react-native";

type FormContainerProps = {
  children: React.ReactNode;
  onPress: () => void;
};

type FormContainerComponent = (props: FormContainerProps) => JSX.Element;

const FormContainer: FormContainerComponent = ({ children }) => {
  return <View>{children}</View>;
};

export default FormContainer;
