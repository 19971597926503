import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const GET_USER = gql`
  query GetUsers($filter: UserFilter) {
    findUser(first: 1, filter: $filter) {
      edges {
        node {
          id
          username
          fullName
          displayName
          firstName
          lastName
          email
          phone
          offDuty
          avatar {
            url
          }
          jobTitle
          company {
            id
            name
            offDuty
          }
          canMessage
          attributes {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUser = ({
  filter = null,
  fetchPolicy = "cache-and-network",
}) => {
  const { loading, error, data, subscribeToMore, refetch } = useQuery(
    GET_USER,
    { variables: { filter } }
  );
  const user =
    data?.findUser?.edges?.map((a) => {
      return a.node;
    })[0] || null;
  return { loading, error, user, subscribeToMore, refetch };
};
