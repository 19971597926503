import { useEffect, useState } from "react";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";

import { Sentry } from "~riata/utils/sentry";
import { storage } from "./storage";
const createCache = () => new InMemoryCache({});
const initializeCache = async () => {
  const cache = createCache();
  await persistCache({ cache, storage });
  return cache;
};

const createClient = async () => {
  const link = onError(({ graphQLErrors, networkError }) => {
    (graphQLErrors || []).forEach(({ message, path }) => {
      // TODO Do we need to handle anything here?
      Sentry.captureException(message);
      console.warn(`[Auth GraphQL error]: Message: ${message}, Path: ${path}`);
    });
  }).concat(
    createHttpLink({
      uri: process.env.LARIAT_SERVER_HTTP,
      credentials: "same-origin",
    })
  );

  const cache = await initializeCache();
  // TODO Should we limit the queries that can be run to those to do with auth?
  return new ApolloClient({ cache, link });
};

type AuthClientState = ApolloClient<NormalizedCacheObject>;

const useAuthClient: () => { client: AuthClientState } = () => {
  const [client, setClient] = useState<AuthClientState>(null);
  useEffect(() => {
    if (client !== null) {
      return;
    }

    // TODO Handle errors (if any)
    createClient().then(setClient).catch(Sentry.captureException);
  }, [client]);

  return { client };
};

export default useAuthClient;
