import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback } from "react";
import { AuthInput } from "~riata/generated/graphql";

export const QUERY = gql`
  mutation Auth($input: AuthInput!) {
    auth(input: $input) {
      user {
        id
        companies(last: 2) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
      token
      refreshToken
    }
  }
`;

const useAuth = () => {
  const [mutation, response] = useMutation(QUERY);
  const execute = useCallback(
    ({ input }: { input: AuthInput }) => mutation({ variables: { input } }),
    [mutation]
  );
  return { execute, mutation, response };
};

export default useAuth;
