import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import moment from "moment";

import MarkdownContent from "../MarkdownContent";
import { Button } from "../inputs/Button";
import { ListItem, Row } from "../layout";
import { UserAvatar } from "../avatars";
import MessageListItemAttachmentList from "./MessageListItemAttachmentList";
import MessageListItemInfo from "./MessageListItemInfo";
import MessageListItemReactionBar from "./MessageListItemReactionBar";
import ExpandableMessageList from "../ExpandableMessageList";
import DateHeader from "./DateHeader";
import { useMessageActionSheetLink } from "~riata/links";
import { useColors } from "~riata/hooks";
import { Message } from "~riata/generated/graphql";

const messagesOnSameMinute = (message, previous) => {
  if (!previous?.createdAt) {
    return false;
  }
  return (
    moment(message.createdAt).minute() === moment(previous.createdAt).minute()
  );
};

const shouldShowHeader = (message, previous) => {
  return (
    previous == null ||
    !moment(previous.createdAt).isSame(message.createdAt, "day")
  );
};

const MessageListItem = React.memo(
  ({
    message,
    previous,
    style,
    hideHeader = false,
    highlighted = false,
    showsReplies = true,
  }: {
    message: Message;
    previous?: Message;
    style?: ViewStyle & { [any: string]: ViewStyle };
    hideHeader?: boolean;
    highlighted?: boolean;
    showsReplies?: boolean;
  }) => {
    const { newColors } = useColors();
    const showHeader = shouldShowHeader(message, previous);
    const showAvatar =
      message.creator.id !== previous?.creator?.id || showHeader;
    const showInfo =
      showAvatar || !messagesOnSameMinute(message, previous) || showHeader;
    const { onPress: goToActionSheet } = useMessageActionSheetLink({
      node: message,
    });

    const fromServer = message.fromServer;

    // TODO Re-enable if/when we have threaded commenting
    const hasReplies = false;

    const showReactions =
      message.reactions &&
      message.reactions.summary != null &&
      message.reactions.summary.length > 0;

    let content = message.content;
    message?.attachments?.edges?.forEach((edge) => {
      if (edge?.node?.url) {
        if (content.substr(-edge.node.url.length) === edge.node.url) {
          content = content.replace(edge.node.url, "").trim();
        }
      }
    });

    const listItemStyles = useMemo(() => {
      return StyleSheet.flatten([
        styles.listItem,
        {
          backgroundColor: highlighted
            ? newColors.message.highlighted.main
            : "transparent",
        },
      ]);
    }, [highlighted, newColors]);

    return (
      <>
        <ListItem styleName="avatar" style={listItemStyles}>
          <Button
            activeOpacity={0.8}
            onLongPress={() => {
              goToActionSheet();
            }}
            style={styles.button}
          >
            <Row style={showAvatar ? styles.withAvatar : {}}>
              <UserAvatar user={message.creator} empty={!showAvatar} />

              {/* Flex required to allow children to size correctly  */}
              <View style={styles.messageWrapper}>
                {showInfo && (
                  <MessageListItemInfo data={message} showName={showAvatar} />
                )}
                <MarkdownContent
                  styleOverrides={
                    fromServer ? {} : { text: { ...styles.optimisticText } }
                  }
                  text={content}
                />

                {showReactions && (
                  <MessageListItemReactionBar message={message} />
                )}
                {showsReplies && hasReplies && (
                  <ExpandableMessageList message={message} />
                )}
                <MessageListItemAttachmentList message={message} />
              </View>
            </Row>
          </Button>
        </ListItem>

        {/* Note: displayed _after_ because this is inverted */}
        {showHeader && !hideHeader && (
          <ListItem key={`date-header${message.createdAt}`} style={{ flex: 1 }}>
            <DateHeader date={message.createdAt} />
          </ListItem>
        )}
      </>
    );
  }
);

const styles = StyleSheet.create({
  // TODO This should be a Button or Pressable component
  listItem: {
    flex: null,
  },
  messageWrapper: {
    flex: 1,
  },
  button: {
    flex: 1,
    paddingVertical: 0,
    backgroundColor: "transparent",
    paddingHorizontal: 8,
  },
  withAvatar: { paddingTop: 9 },
  optimisticText: {
    opacity: 0.5,
  },
});

export default MessageListItem;
