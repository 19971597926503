import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery } from "../queries/GetChannels";
import { GetChannelQuery } from "../queries/GetChannel";
import { GetDecksQuery } from "../queries/GetDecks";

export const ARCHIVE_CHANNEL = gql`
  mutation ($id: ID!) {
    archiveChannel(input: { id: $id }) {
      channel {
        id
        isDirect
        isArchived
      }
      success
    }
  }
`;

export const useArchiveChannel = (id) => {
  const [mutation, response] = useMutation(ARCHIVE_CHANNEL);
  const successful = response?.data?.archiveChannel?.success ?? false;

  const execute = () => {
    const variables = { id: id };
    return mutation({
      variables,
      refetchQueries: [
        { query: GetChannelQuery, variables: { id: id } },
        { query: GetChannelsQuery },
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: GetChannelsQuery,
          variables: { filter: { type: "DIRECT", isArchived: false } },
        },
        {
          query: GetChannelsQuery,
          variables: { filter: { type: "TEAM", isArchived: false } },
        },
        { query: GetDecksQuery },
      ],
    });
  };

  return { execute, response, successful };
};
