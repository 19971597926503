import React, { useState } from "react";
import { StyleSheet, View, Platform } from "react-native";

import {
  BottomSheet,
  Button,
  CustomIcon,
  DatePicker,
  Row,
  Separator,
  TappableBackground,
  Text,
} from "~riata/components";
import {
  useBulletinCompose,
  useColors,
  useComposeStyles,
  useToastMessage,
} from "~riata/hooks";
import {
  getMinimumDateTime,
  getMaximumDateTime,
  getPreviousDateTime,
  validateDateTime,
  validateScheduleAgainstExpires,
  validateExpiresAgainstSchedule,
} from "./utils";
import { HEADER_ICON_SIZE } from "~riata/theme";

const BulletinDateSelectActionSheet = ({ navigation, route }) => {
  const {
    params: { dateKey, isDraft },
  } = route.params;
  console.log("BulletinDateSelectActionSheet", dateKey);

  const { newColors } = useColors();
  const {
    actions,
    state,
    draft: { actions: draftActions, state: draftState },
  } = useBulletinCompose();
  const { showDangerToast } = useToastMessage();

  const [date, setDate] = useState(
    getPreviousDateTime(
      isDraft ? draftState.payload[dateKey] : state.payload[dateKey],
      dateKey
    )
  );

  const inputContainerStyles = useComposeStyles(
    styles.inputContainer,
    Platform.OS === "web" ? { flex: 1 } : {}
  );

  const minimumDateTime = getMinimumDateTime(dateKey);
  const maximumDateTime = getMaximumDateTime(dateKey);
  const dateIsValid = validateDateTime(date, dateKey);

  const goBack = () => {
    navigation.goBack();
  };

  const onDateSelect = () => {
    const actionsObj = isDraft ? draftActions : actions;
    const stateObj = isDraft ? draftState : state;

    if (dateKey === "scheduledAt") {
      if (validateScheduleAgainstExpires(date, stateObj.payload.expires)) {
        actionsObj.setScheduledAt(date);
      } else {
        showDangerToast(
          "Can't Schedule Bulletin",
          "Date must be before expiration date"
        );
        return;
      }
    }

    if (dateKey === "expires") {
      if (validateExpiresAgainstSchedule(date, stateObj.payload.scheduledAt)) {
        actionsObj.setExpires(date);
      } else {
        showDangerToast(
          "Invalid Expiration Date",
          "Date must be after schedule date"
        );
        return;
      }
    }

    navigation.goBack();
  };

  const onDateChange = (date) => {
    setDate(date);
  };

  return (
    <View style={styles.container}>
      <TappableBackground onPress={goBack} />

      <BottomSheet
        onCloseHandler={goBack}
        snapPoints={[0.41]}
        showDragBar={false}
        enabled={false}
      >
        <Row align="center" justify="center" style={styles.header}>
          <Text variant="newSubhead">Date Select</Text>
          <Button onPress={goBack} style={styles.closeButton}>
            <CustomIcon name="close" size={HEADER_ICON_SIZE} />
          </Button>
        </Row>
        <Separator />
        <Row justify="center" style={inputContainerStyles}>
          <DatePicker
            date={date}
            maximumDate={maximumDateTime}
            minimumDate={minimumDateTime}
            onDateChange={onDateChange}
          />
        </Row>
        <Row justify="center" align="center" style={styles.confirmContainer}>
          <Button
            color="primary"
            style={styles.confirmButton}
            disabled={!dateIsValid}
            onPress={onDateSelect}
          >
            <Row justify="center">
              <Text
                variant="caption3"
                fontWeight="mediumBold"
                color={newColors.button.primary.on}
              >
                Confirm
              </Text>
            </Row>
          </Button>
        </Row>
      </BottomSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  header: {
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  closeButton: {
    position: "absolute",
    right: 30,
  },
  confirmButton: {
    borderRadius: 50,
    width: "80%",
    padding: 12,
  },
  inputContainer: {},
  confirmContainer: {
    marginTop: Platform.OS === "web" ? "auto" : 0,
    paddingBottom: Platform.OS === "web" ? 16 : 0,
  },
});

export default BulletinDateSelectActionSheet;
