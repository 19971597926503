import moment from "moment";
import { getCurrentDateTime } from "~riata/utils/date";

const getExpiresAtLabel = (date?: string) => {
  if (!date) {
    // Ideally this should never happen as all bulletins should have an
    // expiration date. However, this is a fallback in case it does happen.
    return { value: "", color: "regular" };
  }

  const currentDate = getCurrentDateTime({ resetSeconds: false });

  if (moment(currentDate).isBefore(date)) {
    const formattedDate = moment(date).fromNow();
    let color = "blue";

    if (Math.abs(moment(date).diff(currentDate, "days")) <= 7) {
      color = "orange";
    }

    return { value: `Expires: ${formattedDate}`, color };
  }

  return { value: "Expired", color: "footnote" };
};

export { getExpiresAtLabel };
