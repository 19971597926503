import React from "react";
import { FlatList, StyleSheet } from "react-native";

// Components
import { User } from "~riata/generated/graphql";
import { Text } from "./typography";
import { Row } from "./layout";
import { RodioLoader, Separator } from "./misc";
import UserSelectContainer from "./UserSelectContainer";
import RodioError from "./RodioError";

// Hooks
import { useColors } from "~riata/hooks";

const UserList = ({
  data,
  loading,
  error,
  refetch,
  selectedUsers = [],
  onSelectUser,
}) => {
  const { newColors } = useColors();

  const renderIndexPath = (item) => {
    let user = item.item;
    return (
      <UserSelectContainer
        user={user}
        selected={selectedUsers.some((item) => item.id === user.id)}
        onSelectUser={(user) => {
          onSelectUser(user);
        }}
      />
    );
  };

  if (loading) {
    return <RodioLoader />;
  }
  if (error) {
    return (
      <RodioError
        isVisible={true}
        onDismiss={() => {}}
        error={{ message: error.message }}
      />
    );
  }

  return (
    <FlatList
      data={data}
      renderItem={renderIndexPath}
      onRefresh={() => {
        if (refetch) {
          refetch();
        }
      }}
      refreshing={loading}
      keyExtractor={(item: User) => item.id.toString()}
      ItemSeparatorComponent={() => (
        <Separator color={newColors.separator.light} style={styles.separator} />
      )}
      ListEmptyComponent={() => (
        <Row justify="center">
          <Text variant="headline">No users found</Text>
        </Row>
      )}
    />
  );
};

const styles = StyleSheet.create({
  separator: {
    marginLeft: 20,
  },
});

export default UserList;
