import { useCallback, useEffect } from "react";
import { AppState, AppStateStatus } from "react-native";

type AppStateChangeActions = Partial<{
  [T in AppStateStatus]: () => void;
}>;

const useAppStateChange = (actions: AppStateChangeActions) => {
  const changeHandler = useCallback(
    (nextState) => {
      actions[nextState] && actions[nextState]();
    },
    [actions]
  );
  return useEffect(() => {
    const subscription = AppState.addEventListener("change", changeHandler);
    return () => {
      subscription.remove();
    };
  }, [changeHandler]);
};

export default useAppStateChange;
