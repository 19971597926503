import { useQuery } from "@apollo/react-hooks";
import { WatchQueryFetchPolicy } from "apollo-client";
import gql from "graphql-tag";
import { UserFilter } from "~riata/generated/graphql";

export const GET_USERS = gql`
  query GetUsers($filter: UserFilter) {
    findUser(first: 20, filter: $filter) {
      edges {
        node {
          id
          username
          fullName
          firstName
          lastName
          email
          displayName
          offDuty
          avatar {
            url
          }
          company {
            id
            mention
            offDuty
          }
        }
      }
    }
  }
`;

type UseGetUsersArguments = {
  filter?: string | UserFilter;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useGetUsers = ({
  filter = null,
  fetchPolicy = "cache-and-network",
}: UseGetUsersArguments) => {
  const { loading, error, data, subscribeToMore, refetch } = useQuery(
    GET_USERS,
    { variables: { filter }, fetchPolicy }
  );
  const userList = data?.findUser?.edges?.map((a) => a.node) || null;
  return { loading, error, userList, subscribeToMore, refetch };
};
