import { useGetHashtags, useGetUsers } from "~riata/graphql/queries";
import {
  CompanyMention,
  HashtagFilters,
  UserFilter,
} from "~riata/generated/graphql";

enum FilterType {
  None,
  Hashtag,
  Mention,
}

const suggestionDelimeters = [undefined, "#", "@"];

const channelMentions = [
  {
    id: "here",
    type: "mention",
    name: "@here",
    username: "",
    value: "here",
    avatar: false,
  },
  {
    id: "channel",
    type: "mention",
    name: "@channel",
    username: "",
    value: "channel",
    avatar: false,
  },
  {
    id: "all",
    type: "mention",
    name: "@all",
    username: "",
    value: "all",
    avatar: false,
  },
];

const getFilterType = (text, word) => {
  if (word.startsWith("#") && text.endsWith(" ") === false) {
    return FilterType.Hashtag;
  } else if (word.startsWith("@") && text.endsWith(" ") === false) {
    return FilterType.Mention;
  } else {
    return FilterType.None;
  }
};

const getChannelMentions = (text: string) => {
  if (text.length <= 0) {
    return [];
  }

  return channelMentions.filter((mention) => {
    return mention.value.includes(text);
  });
};

const useFindSuggestions = ({ text, isDirect }) => {
  const words = text.trim().split(/\s/);
  const lastWord = words[words.length - 1];
  const filterType = getFilterType(text, lastWord);
  const position = filterType ? text.lastIndexOf(lastWord) : -1;

  const hashTagFilter: HashtagFilters =
    filterType === FilterType.Hashtag ? { query: lastWord.substring(1) } : null;
  const { loading: hashtagsLoading, list: hashtagList } = useGetHashtags({
    filter: hashTagFilter,
  });

  const userFilter: UserFilter =
    filterType === FilterType.Mention ? { query: lastWord.substring(1) } : null;
  const { loading: usersLoading, userList } = useGetUsers({
    filter: userFilter,
  });

  let suggestionList = [];
  switch (filterType) {
    case FilterType.Hashtag: {
      if (hashtagsLoading) {
        break;
      }
      suggestionList = hashtagList.map((item) => ({
        ...item,
        value: item.name,
      }));
      break;
    }
    case FilterType.Mention: {
      suggestionList =
        userList?.map((item) => {
          // This value will be inserted after suggestion select
          const mentionValueKey =
            item.company?.mention === CompanyMention.DisplayTitle
              ? "displayName"
              : "username";

          return {
            id: item.id,
            type: "mention",
            name: item.displayName,
            username: item.username,
            value: item[mentionValueKey],
            avatar: item.avatar ?? false,
          };
        }) ?? [];

      if (!isDirect) {
        suggestionList.unshift(...getChannelMentions(userFilter.query));
      }
      break;
    }
    default: {
      suggestionList = [];
    }
  }
  return {
    loading: hashtagsLoading || usersLoading,
    suggestionList,
    suggestMode: position > -1,
    position,
    suggestionType: suggestionDelimeters[filterType],
  };
};

export default useFindSuggestions;
