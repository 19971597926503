import React, { useEffect, useState } from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import { InputBarIcon } from "~riata/components/icons";
import { useColors } from "~riata/hooks";
import { Toolbar } from "./EditorToolbar";

const RichTextInput = ({
  inputRef,
  registry,
  canSend = false,
  onSendMessage,
  suggestions,
  removeSuggestions,
  inputText,
  onChangeText,
  canAddAttachments = false,
  onAddAttachment,
}) => {
  const { newColors } = useColors();
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  const onSelectionChangeHandler = ({
    nativeEvent: {
      selection: { start, end },
    },
  }) => {
    const newSelection = { start, end };

    setSelection(newSelection);
  };

  const insertSuggestion = (suggestion: string) => {
    // We have to clear previous text from auto complete
    const textToRemoveIndex = inputText.lastIndexOf(suggestion.charAt(0));

    const precedingString = inputText.slice(0, textToRemoveIndex);

    const Text = `${precedingString}**${suggestion}** `;

    onChangeText(Text);
    setSelection({
      start: Text.length,
      end: Text.length,
    });
  };

  const insertToken = (token: string) => {
    const precedingString = inputText.slice(0, selection.start);
    const currentSelection = inputText.slice(selection.start, selection.end);
    const trailingString = inputText.slice(selection.end);

    const Text = `${precedingString}${token}${currentSelection}${token}${trailingString}`;
    let newSelection = selection.end + token.length;

    onChangeText(Text);
    setSelection({ start: newSelection, end: newSelection });
  };

  const insertBold = () => {
    inputRef.current.focus();
    insertToken("**");
  };

  const insertItalic = () => {
    inputRef.current.focus();
    insertToken("_");
  };

  useEffect(() => {
    if (suggestions) {
      insertSuggestion(suggestions);
      removeSuggestions();
      inputRef.current.focus();
    }
    // We only want to insert a new suggestion when suggestions object has been modified
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  return (
    <>
      <View style={styles.inputBarContainer}>
        {canAddAttachments && (
          <TouchableOpacity onPress={onAddAttachment}>
            <InputBarIcon
              name="attachments"
              color={newColors.icon.surfaceContrast.main}
              size={20}
            />
          </TouchableOpacity>
        )}
        <TextInput
          ref={inputRef}
          style={styles.inputBar}
          onSelectionChange={onSelectionChangeHandler}
          selection={selection}
          multiline
          value={inputText}
          onChangeText={onChangeText}
          placeholderTextColor={newColors.text.caption.main}
          placeholder="Type something..."
          onChange={(e: any) => {
            e.target.style.height = 0;
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
        />
      </View>
      <Toolbar
        boldHandler={insertBold}
        italicHandler={insertItalic}
        onSendMessage={onSendMessage}
        canSend={canSend}
      />
    </>
  );
};

const styles = StyleSheet.create({
  inputBarContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingTop: 16,
  },
  inputBar: {
    maxHeight: 100,
    flex: 1,
    marginHorizontal: 8,
    fontSize: 17,
    lineHeight: 22,
  },
});

export { RichTextInput };
