import _ from "lodash";
import { ReactNativeFile } from "apollo-upload-client";

enum Errors {
  Empty = "Value can't be empty",
  MaxLength = "Value can't have more than 80 characters",
  WrongPhoneFormat = "Write a valid phone number. Including international code (+1): +12345678901",
}

// Be sure to match this keys with state properties
const EDITABLE_KEYS = [
  "firstName",
  "lastName",
  "jobTitle",
  "displayName",
  "phone",
];

const _getEditableFields = (state) => {
  return _.pick(state, EDITABLE_KEYS);
};

const isNotEmpty = (originalValue: string, newValue: string) => {
  if (originalValue.length > 0) {
    if (newValue.length <= 0) {
      return Errors.Empty;
    }

    if (newValue.length > 80) {
      return Errors.MaxLength;
    }
  }

  return null;
};

const phoneIsValid = (value: string) => {
  if (value.length > 0) {
    const usPhoneRegex = new RegExp(
      /^(\+1|1)?[-. ]?\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/gim
    );
    if (value.match(usPhoneRegex)) {
      return null;
    }

    return Errors.WrongPhoneFormat;
  }

  return null;
};

const formIsValid = (state) => {
  return _.every(_getEditableFields(state), ["error", null]);
};

const hasBeenEdited = (user, state) => {
  return (
    !_.isEqual(
      _.pick(user, EDITABLE_KEYS),
      _.mapValues(_getEditableFields(state), (obj) => obj.value?.trim())
    ) || state.fileChooserResult
  );
};

const getProfilePicture = (fileChooserResult, userId) => {
  const selectedImage = fileChooserResult?.[0];

  if (selectedImage && userId !== null) {
    try {
      const file = new ReactNativeFile({
        uri: selectedImage.path,
        name:
          selectedImage.name ??
          selectedImage.path.substring(selectedImage.path.lastIndexOf("/") + 1),
        type: selectedImage.mime,
      });

      return file;
    } catch (error) {
      console.warn("UserProfileScreen > onSave: ", error);
      return null;
    }
  }
};

const getEditedFields = (state) => {
  return _.mapValues(_getEditableFields(state), (obj) => obj.value.trim());
};

export {
  EDITABLE_KEYS,
  isNotEmpty,
  formIsValid,
  hasBeenEdited,
  getProfilePicture,
  phoneIsValid,
  getEditedFields,
};
