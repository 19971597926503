import { buildLinkComponent, generateOnPressHook } from "./_utils";

export * from "./AttachmentGalleryLink";

export const useAttachmentActionSheetLink =
  generateOnPressHook("/new/attachment");

export const useMessageAttachmentActionSheetLink = generateOnPressHook(
  "/channel/{{id}}/new/attachment"
);

export const MessageAttachmentActionSheetLink = buildLinkComponent(
  useMessageAttachmentActionSheetLink
);

export const useMessageActionSheetLink = generateOnPressHook(
  "/channel/{{id}}/{{message}}/action"
);

export const MessageActionSheetLink = buildLinkComponent(
  useMessageActionSheetLink
);

export const useMessageIntegrationAttachmentActionSheetLink =
  generateOnPressHook(
    "/channel/_/new/attachment/integration/{{integration_id}}/"
  );

export const MessageIntegrationAttachmentActionSheetLink = buildLinkComponent(
  useMessageIntegrationAttachmentActionSheetLink
);

export const useMessageInChannelLink = generateOnPressHook(
  "/channel/:id/?message=:message"
);
export const MessageInChannelLink = buildLinkComponent(useMessageInChannelLink);

export const useChannelLink = generateOnPressHook("/channel/{{id}}");
export const ChannelLink = buildLinkComponent(useChannelLink);
