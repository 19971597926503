import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import NewTeamChannelAvatar from "./NewTeamChannelAvatar";
import { Channel } from "~riata/generated/graphql";
import { useComposeStyles } from "~riata/hooks";
import NewDirectChannelAvatar from "./NewDirectChannelAvatar";
import { useGetCurrentUser } from "~riata/graphql";
import { AvatarOffDutyIndicator } from "../avatars";

type ChannelAvatarProps = {
  channel: Channel;
  containerStyles?: ViewStyle;
  showOffDuty?: boolean;
  outlineColor?: string;
};

type ChannelAvatarComponent = (props: ChannelAvatarProps) => JSX.Element;

const ChannelAvatar: ChannelAvatarComponent = ({
  channel,
  containerStyles = {},
  showOffDuty,
  outlineColor,
}: ChannelAvatarProps) => {
  const { user } = useGetCurrentUser();

  const channelMembers = channel.members.edges.map((a) => a.node);
  const canShowOffDuty = user?.company?.offDuty && showOffDuty;

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  if (!channel.isDirect) {
    return (
      <View style={composedContainerStyles}>
        <NewTeamChannelAvatar channel={channel} size={48} />
      </View>
    );
  }

  if (channelMembers.length === 2) {
    const peer = channelMembers.find((member) => member.id !== user.id);

    return (
      <>
        <View style={composedContainerStyles}>
          <NewDirectChannelAvatar
            channel={channel}
            size={48}
            containerStyles={{}}
          />
        </View>
        {canShowOffDuty && (
          <View style={styles.offDutyIndicator}>
            <AvatarOffDutyIndicator user={peer} borderColor={outlineColor} />
          </View>
        )}
      </>
    );
  }

  return (
    <View style={composedContainerStyles}>
      <NewDirectChannelAvatar
        multiUser={true}
        outlineColor={outlineColor}
        showOffDuty={showOffDuty}
        channel={channel}
        size={32}
        containerStyles={{
          width: 48,
          alignItems: "flex-end",
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  offDutyIndicator: {
    zIndex: 10,
    position: "absolute",
    top: 56,
    left: 15,
  },
});

export default ChannelAvatar;
