import { useCallback, useEffect, useState } from "react";
import ApolloClient from "apollo-client";
import {
  defaultDataIdFromObject,
  IdGetterObj,
  InMemoryCache,
  NormalizedCacheObject,
} from "apollo-cache-inmemory";
import { Scalars } from "~riata/generated/graphql";

import { persistCache } from "apollo-cache-persist";
import { onError } from "apollo-link-error";
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import gql from "graphql-tag";
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from "apollo-upload-client";

import { Sentry } from "~riata/utils/sentry";
import { fragmentMatcher } from "./fragments";
import { storage } from "./storage";
import { useCredentials } from "../useCredentials";
import { fetchWithTimeout } from "./utils";

const typeDefs = gql`
  type DebugInfo {
    deviceId: String
    pushToken: String
    subscribedTopics: [String]
  }

  extend type Query {
    debugInfo: DebugInfo
  }
`;

// We need to pass a resolver, even if empty, for Apollo to recognize
// the local values
const resolvers = {};

interface ReactionSummaryIdGetterObj extends IdGetterObj {
  messageId: Scalars["ID"];
}

function isReactionSummary(obj: any): obj is ReactionSummaryIdGetterObj {
  return obj.__typename === "ReactionSummary";
}
const createCache = () =>
  new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object) => {
      if (isReactionSummary(object)) {
        return `ReactionSummary:${object.messageId}:${object.id}`;
      }
      return defaultDataIdFromObject(object);
    },
  });

const initializeCache = async () => {
  const cache = createCache();
  await persistCache({ cache, storage });
  return cache;
};

const createClient = async ({ onClientError, token, customFetch }) => {
  // Top level, non-terminating error handling link
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    (graphQLErrors || []).forEach(({ message, path }) => {
      // TODO Handle login required?
      Sentry.captureException(message);
      console.debug(`[GraphQL error]: Message: ${message}, Path: ${path}`, {
        ...operation,
        token,
      });
      onClientError({ message, path });
    });
  });

  // Terminating websocket link for our subscriptions
  const wsLink = new WebSocketLink({
    uri: process.env.LARIAT_SERVER_WS,
    options: { reconnect: true },
  });

  // Terminating http upload link that will have auth headers added by the context.
  const httpLink = createUploadLink({
    uri: process.env.LARIAT_SERVER_HTTP,
    credentials: "same-origin",
    fetch: customFetch,
  });

  // Branch for subscriptions or http requests
  const subscriptionHttpSplit = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    cache: await initializeCache(),
    link: errorLink.concat(subscriptionHttpSplit),
    resolvers,
    typeDefs,
  });
};

const useClient = () => {
  // TODO What do we do if the token value isn't available
  const { get, latest, refresh, triggerRerender } = useCredentials();
  const credentials = get();
  const token = credentials !== false ? credentials.token : false;
  console.log(`useClient: ${token}`);
  const [client, setClient] = useState<
    false | ApolloClient<NormalizedCacheObject>
  >(false);

  const onClientError = useCallback((err) => {
    console.debug("useClient > onClientError", { err });
    Sentry.captureException(err);
  }, []);

  const customFetch = useCallback(
    (uri, options) => {
      options = options || { headers: {} };
      const timeout = options.headers["x-timeout"] || 30000;
      options.headers.authorization = `Bearer ${token}`;
      let initialRequest = fetchWithTimeout(uri, options, timeout);
      return initialRequest
        .then((response) => {
          console.debug(`customFetch > initialRequest > response`, {
            ok: response.ok,
          });
          return response.json();
        })
        .then((json) => {
          const { errors } = json;
          if (!errors) {
            console.debug(`customFetch > initialRequest > no errors`);
            return {
              ok: true,
              json: () => Promise.resolve(json),
              text: () => Promise.resolve(JSON.stringify(json)),
            };
          }
          const message = errors[0].message;
          console.debug(`customFetch > json > got error`, { message });
          if (message === "Login is required") {
            console.debug("attempting refetch");
            return refresh({ rerender: false }).then((success) => {
              console.debug(`customFetch > refresh > ${success}`);
              if (success) {
                const newCredentials = get();
                if (newCredentials !== false) {
                  const newOptions = { ...options };
                  newOptions.headers.authorization = `Bearer ${newCredentials.token}`;
                  return fetch(uri, newOptions).then((response) => {
                    console.debug(`customFetch > refresh > second fetch`, {
                      ok: response.ok,
                    });
                    triggerRerender();
                    return response;
                  });
                }
                console.debug("customFetch > returning");
                return { ok: true, json: () => Promise.resolve(json) };
              }
            });
          } else {
            // return initialRequest
            console.debug(`customFetch > json > non-login error`, { errors });
            return { ok: true, json: () => Promise.resolve(json) };
          }
        })
        .catch((err) => {
          if (err.message.includes("Network request failed")) {
            console.debug("customFetch > err > network request failed");
          } else if (err.message.includes("t.text is not a function")) {
            console.debug("customFetch > err > t.text is not a function");
          } else if (err.message.includes("Load failed")) {
            console.debug("customFetch > err > load failed");
          } else {
            Sentry.captureEvent({
              logger: "UnexpectedState",
              message: "HTTP failure",
              extra: { err },
            });
            console.error("customFetch > err on initial request", { err });
          }

          // allow the error to bubble up, then catch that error in the query hooks
          throw err;
        });
    },
    [get, refresh, token, triggerRerender]
  );

  useEffect(() => {
    console.debug(`useClient > useEffect`, { token });
    createClient({ onClientError, token, customFetch }).then(setClient);
  }, [customFetch, onClientError, setClient, token]);

  return { client, latest };
};

export default useClient;
