import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  GetMessagesFilter,
  Message,
  PageInfo,
  Scalars,
} from "~riata/generated/graphql";
import { fragments } from "../fragments";
import { insertIntoEdges, useFetchPrevious } from "../utils";
import { Sentry } from "~riata/utils/sentry";
import { WatchQueryFetchPolicy } from "apollo-client";
import { isWeb } from "~riata/theme/common";

export const GET_MESSAGES = gql`
  query GetMessages(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $filter: GetMessagesFilter
  ) {
    getMessages(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...MessageContent
        }
      }
    }
  }
  ${fragments.Message.Content}
`;

const GET_MESSAGES_WITH_CHANNEL_DETAILS = gql`
  query GetMessages(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $filter: GetMessagesFilter
  ) {
    getMessages(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...MessageWithChannelDetails
        }
      }
    }
  }
  ${fragments.Message.WithChannelDetails}
`;

const toEdge = (node) => {
  return { __typename: "MessageEdge", cursor: getCursor(node), node: node };
};

export const getCursor = (node) => `chat:message:${node.id}`;

export const getDefaultVariables = (channel) => ({
  after: null,
  before: null,
  first: null,
  last: 20,
  filter: { channel },
});

const displayNotification = (message, user_id, navigation) => {
  if (!isWeb || message.creator.id === user_id) {
    return;
  }

  let notification = new Notification(
    `New message from ${message.creator.displayName} in ${message.channel.name}`,
    {
      body: message.content,
      data: { channel: message.channel.id },
    }
  );
  notification.onclick = (data) => {
    notification.close();
    const payload = (data.target as any).data;
    // TODO Add message param when message highlighting is supported
    navigation.navigate("Conversation", {
      screen: "ConversationDetail",
      params: { channel: payload.channel },
    });
  };
};

export const subscriber = {
  type: "Message",
  execute: async ({ client, data, navigation, user_id }) => {
    if (data.node) {
      // Query might not have been previously run so readQuery will return an
      // undefined object. Notification has to be displayed prior to this to ensure
      // user receives it.
      const message: Message = data.node;
      displayNotification(message, user_id, navigation);
    } else {
      // Data is empty so there is no notification to be shown. Exit flow.
      return;
    }

    const query = GET_MESSAGES;
    const variables = getDefaultVariables(data.node.channel.id);

    let prev;
    try {
      prev = client.readQuery({ query, variables });
    } catch (err) {
      // TODO Add context of the user and such here
      Sentry.captureException(err);
      return;
    }
    const updatedEdges = insertIntoEdges(
      prev.getMessages.edges,
      toEdge(data.node)
    );

    const updated = {
      getMessages: {
        __typename: prev.getMessages.__typename,
        pageInfo: {
          ...prev.getMessages.pageInfo,
          // TODO Why would the cursor be empty?
          startCursor:
            updatedEdges[0].cursor ?? getCursor(updatedEdges[0].node),
          endCursor: updatedEdges[updatedEdges.length - 1].cursor,
          hasNextPage: false,
        },
        edges: updatedEdges,
      },
    };
    client.writeQuery({ query, variables, data: updated });
  },
};

const NULL_PAGE_INFO: PageInfo = {
  startCursor: null,
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
};

type UseGetMessagesProps = {
  channel?: Scalars["ID"];
  message?: Scalars["String"];
  search?: Scalars["String"];
  after?: Scalars["String"];
  first?: Scalars["Int"];
  before?: Scalars["String"];
  last?: Scalars["Int"];
  withDetails?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useGetMessages = ({
  channel,
  message = null,
  after = null,
  before = null,
  last = 20,
  first = null,
  search,
  withDetails = false,
  fetchPolicy = "cache-and-network",
}: UseGetMessagesProps) => {
  const filter: GetMessagesFilter = {};
  if (channel) {
    filter.channel = channel;
  }
  if (search) {
    filter.search = search;
  }
  const query = withDetails ? GET_MESSAGES_WITH_CHANNEL_DETAILS : GET_MESSAGES;
  const variables = {
    after: message ?? after,
    before,
    last: message === null ? last : null,
    first,
    filter,
  };
  // TODO: Store "after" in local cache and use that as starting point
  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables,
    fetchPolicy,
  });
  const pageInfo = data?.getMessages?.pageInfo ?? NULL_PAGE_INFO;
  const { hasNextPage, hasPreviousPage } = pageInfo;
  console.log(data?.getMessages?.pageInfo);

  const list = data?.getMessages?.edges?.map((a) => a.node) ?? [];

  const fetchPrevious = useFetchPrevious("getMessages", {
    variables: {
      after: null,
      before: pageInfo.startCursor,
      last: 50,
      filter: { channel },
    },
    loading,
    hasPreviousPage,
    fetchMore,
  });

  return {
    loading,
    fetchPrevious,
    error,
    data,
    refetch,
    list,
    hasNextPage,
    hasPreviousPage,
  };
};
