import { useEffect } from "react";

const useRefetchOnFocus = ({ navigation, loading, refetch }) => {
  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (!loading && refetch) {
        refetch();
      }
    });
    return unsubscribe;
  }, [navigation, loading, refetch]);
};

export default useRefetchOnFocus;
