import { useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { initializeSubscription } from "~riata/graphql/subscriptions";
import { useGetCurrentUser } from "~riata/graphql";
import { useCredentials } from "../useCredentials";
import { initializeOffDutySubscription } from "~riata/graphql/subscriptions/initializeOffDutySubscriber";
import { useOffDuty } from "../useOffDuty";

const useSubscriptions = ({ navigation }) => {
  const credentials = useCredentials();
  const creds = credentials.get();
  const token = creds !== false ? creds.token : false;

  const { user } = useGetCurrentUser();
  const { checkAndHideBanner } = useOffDuty();
  const client = useApolloClient();

  useEffect(() => {
    (async () => {
      if (!credentials.loaded || !user?.id) {
        return;
      }
      if (!token) {
        console.debug("useSubscriptions invoked without a token");
        return;
      }

      console.debug(`initializing subscription ${token}`);

      let subscription = await initializeSubscription(
        client,
        token,
        navigation,
        user.id
      );
      let offDutySubscription = await initializeOffDutySubscription(
        client,
        token,
        navigation,
        user.id,
        checkAndHideBanner
      );
      return () => {
        console.log("unsubscribing");
        subscription.unsubscribe();
        offDutySubscription.unsubscribe();
      };
    })();
    // We do not want banner visibility to re-create subscriptions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, credentials, navigation, token, user]);
};

export default useSubscriptions;
