import { useCallback, useRef, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import Geolocation from "@react-native-community/geolocation";
import { WebView } from "react-native-webview";

const handleRequestLocation = (payload, ref) => {
  const onSuccess = (info) => {
    const response = {
      type: "geolocation",
      coords: { ...info.coords },
    };
    const src = `window.riata.emit(${JSON.stringify(response)})`;
    ref.current.injectJavaScript(src);
  };
  const onError = (error) => console.warn("ERROR: ", error);
  const options = {
    enableHighAccuracy: true,
    maximumAge: 0.00001,
    timeout: 20000,
  };
  Geolocation.getCurrentPosition(onSuccess, onError, options);
};

const handleMessage = (payload, ref) => {
  const updatedMessage = { type: "message", msg: `From RN: ${payload.msg}` };
  const src = `window.riata.emit(${JSON.stringify(updatedMessage)})`;
  ref.current.injectJavaScript(src);
};

const handleNavigate = (payload, ref, { linkTo }) => {
  linkTo(payload.destination);
};

const handleTitle = (payload, _ref, { setTitle }) => setTitle(payload.title);

const handleLog = (payload) => {
  console[payload.level ?? "log"](payload.message, { payload });
};

const handlers = {
  geolocation: handleRequestLocation,
  log: handleLog,
  message: handleMessage,
  navigate: handleNavigate,
  title: handleTitle,
};

// This is modified to be a proper string because Hermes does not preserve
// the contents of a function when it compiles. According to [this comment][1]
// There's a new 'show source' derective that should work, but testing at the
// time of this writing showed it not to be working. Current assumption is that
// the value is being lost in translation from Typescript. Rather than try to
// track down the issue, we're taking the easy route.
//
// [1]: https://github.com/facebook/hermes/issues/114#issuecomment-887106990
const toInject = `
(function (window) {
  if (window.riata) {
    return;
  }

  var handlers = {};
  window.riata = {
    send: function send(payload) {
      window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    },
    emit: function emit(payload) {
      window.dispatchEvent(new CustomEvent("riata", {
        detail: JSON.stringify(payload)
      }));
    },
    register: function register(type, callback) {
      if (!handlers[type]) {
        handlers[type] = [];
      }

      handlers[type].push(callback);
    }
  };
  window.addEventListener("riata", function (event) {
    var parsed = JSON.parse(event.detail);
    handlers[parsed.type].forEach(function (a) {
      a(parsed);
    });
  });
})(window);`;

const useRiataInjection = ({
  customHandlers = {},
  navigation,
  defaultBackScreen = "Home",
}) => {
  const linkTo = useLinkTo();
  const ref = useRef<WebView>();
  const [{ canGoBack, canGoForward }, setState] = useState({
    canGoBack: false,
    canGoForward: false,
  });
  const [title, setTitle] = useState(null);
  const onWebViewNavigationStateChange = ({
    canGoBack,
    canGoForward,
    ...other
  }) => {
    console.debug("onWebViewNavigationStateChange", other);
    setState({ canGoBack, canGoForward });
  };

  const reload = useCallback(() => ref.current.reload(), [ref]);
  const handleBack = () =>
    canGoBack ? ref.current.goBack() : navigation.navigate(defaultBackScreen);

  const handleForward = () => (canGoForward ? ref.current.goForward() : null);

  const actions = {
    handleBack,
    handleForward,
    onWebViewNavigationStateChange,
    reload,
    setTitle,
  };

  const handleMessageDispatch = (message) => {
    const payload = JSON.parse(message.nativeEvent.data);
    var func = null;
    if (payload.type === "dismiss") {
      return navigation.goBack();
    }
    if (customHandlers && customHandlers[payload.type]) {
      func = customHandlers[payload.type];
    } else if (handlers[payload.type]) {
      func = handlers[payload.type];
    }

    func &&
      func(payload, ref, {
        linkTo,
        ...actions,
      });
  };

  return {
    actions,
    ref,
    state: {
      canGoBack,
      canGoForward,
      title,
    },
    handleMessageDispatch,
    toInject,
  };
};

export default useRiataInjection;
