export const variants = {
  offDuty: {
    icon: "hand",
    bannerColor: "orange",
    title: "You're off-duty",
    canDismiss: false,
  },
  upcomingOffDuty: {
    icon: "upcoming_time",
    bannerColor: "primaryVariant",
    title: "Your shift will be over soon, finish up",
    canDismiss: true,
  },
};
