import { useMemo, useReducer } from "react";

enum Actions {
  handleSubmit,
  reset,
  setSearching,
  setSearchTerm,
  toggleSearch,
}

const DEFAULT_STATE = {
  searching: false,
  term: "",
};

export const useListFilterSearchState = ({ onPress }) => {
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case Actions.handleSubmit:
        return { searching: false, term: action.term };

      case Actions.reset:
        return DEFAULT_STATE;

      case Actions.setSearching:
        return { ...prevState, searching: action.searching };

      case Actions.setSearchTerm:
        return { ...prevState, term: action.term };

      case Actions.toggleSearch:
        return { ...prevState, searching: !prevState.searching };
    }
  }, DEFAULT_STATE);

  const actions = useMemo(
    () => ({
      handleSubmit: (term) => {
        dispatch({ type: Actions.handleSubmit, term });
        onPress(term);
      },
      setSearching: (searching) =>
        dispatch({ type: Actions.setSearching, searching }),
      setSearchTerm: (term) => dispatch({ type: Actions.setSearchTerm, term }),
      toggleSearch: () => dispatch({ type: Actions.toggleSearch }),
      reset: () => {
        dispatch({ type: Actions.reset });
        onPress("");
      },
    }),
    [onPress]
  );

  return { state, dispatch, actions };
};
