import { PageInfo } from "./../../../generated/graphql";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useMemo } from "react";
import { Bulletin, Scalars } from "~riata/generated/graphql";

export const QUERY = gql`
  query BulletinRecipients($id: ID!, $first: Int, $after: String) {
    node(id: $id) {
      ... on Bulletin {
        id
        recipients(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              read
              viewed
              createdAt
              via {
                id
                name
              }
              user {
                id
                username
                displayName
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NULL_PAGE_INFO: PageInfo = {
  startCursor: null,
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
};

const useGetBulletinRecipients = (id: Scalars["ID"]) => {
  const variables = { id, first: 20, after: null };
  const { loading, data, error, fetchMore } = useQuery<{ node: Bulletin }>(
    QUERY,
    {
      variables,
      fetchPolicy: "cache-and-network",
    }
  );
  const pageInfo = data?.node?.recipients?.pageInfo || NULL_PAGE_INFO;

  const recipientsList = useMemo(
    () => data?.node?.recipients?.edges?.map((a) => a.node) ?? [],
    [data]
  );

  const updateQuery = (prev, next) => {
    console.log("Updating Query");
    const { fetchMoreResult } = next;

    const updatedRecipients = {
      __typename: prev.node.recipients.__typename,
      pageInfo: fetchMoreResult.node.recipients.pageInfo,
      edges: [
        ...prev.node.recipients.edges,
        ...fetchMoreResult.node.recipients.edges,
      ],
    };

    return {
      ...prev,
      node: { ...prev.node, recipients: updatedRecipients },
    };
  };

  const fetchNext = () => {
    fetchMore({
      updateQuery,
      variables: { ...variables, after: pageInfo.endCursor },
    });
  };

  return {
    recipientsList,
    loading,
    error,
    fetchNext,
    hasNextPage: pageInfo.hasNextPage,
  };
};

export default useGetBulletinRecipients;
