import React from "react";
import { StyleSheet, View } from "react-native";
import { PreviewCard } from "../PreviewCard";

import { FlatList } from "../../layout";
import { useLinkOpener } from "~riata/hooks";

const PreviewCardList = ({
  cards,
  keyExtractor = ({ id }) => id,
  showsHorizontalScrollIndicator = false,
  ...props
}) => {
  const openLink = useLinkOpener();
  return (
    <FlatList
      {...props}
      horizontal
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      data={cards}
      contentContainerStyle={styles.container}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
      renderItem={({ item: card }) => {
        return (
          <PreviewCard
            key={card.id}
            card={card}
            onPress={() => openLink(card.actionURL)}
          />
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  separator: {
    width: 8,
  },
  container: {
    paddingHorizontal: 8,
  },
});

export default PreviewCardList;
