import { useEffect } from "react";
import { useMarkChannelRead } from "~riata/graphql";

export const useMarkAsRead = ({
  channelID,
  hasUnread,
  isFocused,
  time = 2000,
}) => {
  const { execute: markAsRead } = useMarkChannelRead(channelID);
  return useEffect(() => {
    if (!hasUnread) {
      return;
    }

    let timer = setTimeout(() => {
      if (isFocused) {
        markAsRead();
      }
    }, time);
    return () => clearTimeout(timer);
  });
};
