import React from "react";
import { StyleSheet, View } from "react-native";

import ActionSheetItem from "./ActionSheetItem";
import { ActionSheetModal } from "./misc";
import { useColors, useComposeStyles } from "~riata/hooks";

const RodioActionSheet = ({
  children,
  onToggle,
  actions,
  style,
  showClose = false,
  ...props
}) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.surface.main,
  });

  const barStyles = useComposeStyles(styles.bar, {
    backgroundColor: newColors.surface.main,
  });

  return (
    <ActionSheetModal onToggle={onToggle} {...props}>
      <View style={containerStyles}>
        <View style={barStyles}></View>
        {children}
        {showClose && (
          <ActionSheetItem title="Cancel" icon="close" action={onToggle} />
        )}
      </View>
    </ActionSheetModal>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 300,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bar: {
    width: "20%",
    opacity: 0.3,
    height: 5,
    borderRadius: 10,
    alignSelf: "center",
    marginTop: 8,
    marginBottom: 10,
  },
});

export default RodioActionSheet;
