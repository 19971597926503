import React, { useState } from "react";
import { ModalToast } from "~riata/components/toasts";

const useNotImplementedToast = (text = "Not yet implemented") => {
  const [state, setVisible] = useState({ visible: false, text });
  const toggleVisible = () => setVisible({ ...state, visible: !state.visible });
  return {
    ...state,
    setVisible,
    toggleVisible,
    NotImplementedToast: () => (
      <ModalToast warning onDismiss={() => toggleVisible()} text={state.text} />
    ),
  };
};

export default useNotImplementedToast;
