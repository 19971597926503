import React, { useCallback, useState } from "react";
import { RefreshControl, StyleSheet } from "react-native";
import {
  AppHeader,
  FlatList,
  FoldersListItem,
  HeaderActionButton,
  RodioLoader,
  SafeAreaView,
} from "~riata/components";
import { useGetHashtags } from "~riata/graphql";
import { Sentry } from "~riata/utils/sentry";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { useUpdateSelectedRouteIfFocused } from "~riata/hooks";

const FolderDetailScreen = ({ navigation }) => {
  const [refreshing, setRefreshing] = useState(false);

  const { list, loading, refetch } = useGetHashtags({
    filter: { promoted: true },
  });

  const { updateSelectedRoute } = useDrawerNavigation();

  const renderFolder = (item) => <FoldersListItem hashtag={item.item} />;

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
      })
      .catch(Sentry.captureException);
  }, [refetch]);

  const refreshControl = (
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
  );

  useUpdateSelectedRouteIfFocused(updateSelectedRoute);

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title="Folders"
      />
      {loading && !list ? (
        <RodioLoader />
      ) : (
        <FlatList
          data={list}
          style={styles.listContainer}
          refreshControl={refreshControl}
          renderItem={renderFolder}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  listContainer: { marginTop: 8 },
});

export default FolderDetailScreen;
