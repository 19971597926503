import bs from "binary-search";

const comparator = (a, b) => {
  if (a.node.id < b.node.id) {
    return -1;
  } else if (a.node.id > b.node.id) {
    return 1;
  } else {
    return 0;
  }
};

export const insertIntoEdges = (haystack, needle) => {
  const updated = haystack ? [...haystack] : [];
  if (
    updated.length === 0 ||
    updated[updated.length - 1]?.id < needle.node.id
  ) {
    return updated.concat([needle]);
  }
  let index = bs(haystack, needle, comparator);
  if (index >= 0) {
    updated[index] = needle;
  } else {
    updated.splice(-index - 1, 0, needle);
  }
  return updated;
};

// TODO Test this
export const prependEdges = (type) => (prev, next) => {
  const obj = {};
  obj[type] = {
    __typename: prev[type].__typename,
    edges: [...next[type].edges, ...prev[type].edges],
    pageInfo: {
      ...prev[type].pageInfo,
      hasPreviousPage: next[type].pageInfo.hasPreviousPage,
      startCursor: next[type].pageInfo.startCursor,
    },
  };
  return obj;
};

// TODO Test this
export const prependIfNecessary = (type: string, prev, next) => {
  if (
    prev[type].pageInfo.startCursor === next[type].pageInfo.startCursor ||
    !next[type].edges.length
  ) {
    return prev;
  }
  return prependEdges(type)(prev, next);
};
