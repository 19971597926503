import React from "react";
import BannerButton from "./BannerButton";

const Banner: BannerComponent = ({
  isVisible = false,
  variant = "upcomingOffDuty",
  onDismiss,
  onPress,
  generateBannerMessage,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <BannerButton
      variant={variant}
      onPress={onPress}
      onDismiss={onDismiss}
      generateBannerMessage={generateBannerMessage}
    />
  );
};

export default Banner;
