import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { fragments } from "../../fragments";
import {
  Channel,
  ChannelEdge,
  PageInfo,
  Query,
  QueryGetChannelsArgs,
} from "~riata/generated/graphql";
import { useFetchNext } from "~riata/hooks";

export const QUERY = gql`
  query GetChannels(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: ChannelsFilter
  ) {
    getChannels(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          slug
          isDirect
          isGroup
          isMutedByUser
          unreadCount
          isArchived
          latestMessageAt
          members(last: 2) {
            ...ChannelMembers
          }
          lastMessageContent
        }
      }
    }
  }

  ${fragments.Channel.Members}
  ${fragments.User.Content}
`;

export const subscriber = {
  type: "Message",
  execute: async ({ data, client }) => {
    const variables = { filter: { isArchived: false }, first: 20 };
    var prev;
    try {
      prev = client.readQuery({
        query: QUERY,
        variables,
      });
    } catch (err) {
      // TODO What we do we wanna do here?
      // This is most likely caused by having a paritally initialized cache
      return;
    }
    // const decodedToken = await authentication.decodedToken();
    let otherChannels: Array<ChannelEdge> = [];
    var updatedChannel;
    prev?.getChannels?.edges?.forEach((a) => {
      if (a.node.id === data.node.channel.id) {
        let channel = a.node;
        let typename = "Message";

        // FIXME Should not advance when user is looking at current channel
        let shouldBeUnread = true;

        // let shouldBeUnread = creatorId !== decodedToken.user_id;
        // TODO raise on second match?
        updatedChannel = {
          __typename: "ChannelEdge",
          cursor: `chat:channel:${data.node.id}:${data.node.channel.id}`,
          node: {
            ...channel,
            unreadCount: shouldBeUnread ? (channel.unreadCount || 0) + 1 : 0,
            messages: {
              __typename: `${typename}Connection`,
              edges: [
                {
                  __typename: `${typename}Edge`,
                  cursor: `chat:channel:${data.node.channel.id}:${data.node.id}`,
                  node: {
                    ...data.node,
                    __typename: typename,
                  },
                },
              ],
            },
          },
        };
      } else {
        otherChannels.push(a);
      }
    });

    if (updatedChannel) {
      const newData = {
        getChannels: {
          ...prev.getChannels,
          pageInfo: {
            ...prev.getChannels.pageInfo,
            startCursor: updatedChannel?.cursor,
            endCursor:
              otherChannels.length > 0
                ? otherChannels[otherChannels.length - 1].cursor
                : updatedChannel?.cursor,
          },
          edges: [updatedChannel, ...otherChannels],
        },
      };
      client.writeQuery({
        query: QUERY,
        variables,
        data: newData,
      });
    }

    client.writeQuery({
      query: QUERY,
      variables,
      data: prev,
    });
  },
};

type UseGetChannelsProps = QueryGetChannelsArgs;
type GetChannelsQueryResponse = Pick<Query, "getChannels">;

type UseGetChannels = (props?: UseGetChannelsProps) => QueryResult<
  GetChannelsQueryResponse,
  QueryGetChannelsArgs
> & {
  list: Channel[];
  fetchNext: () => void;
};

export const useGetChannels: UseGetChannels = (props) => {
  const variables = {
    after: props?.after,
    before: props?.before,
    last: props?.last,
    first: props?.first ?? props?.last ? null : 20,
    filter: { ...(props?.filter || {}), isArchived: false },
  };
  console.debug("useGetChannels", { variables });
  const { data, fetchMore, loading, refetch, ...useQueryResult } = useQuery<
    Pick<Query, "getChannels">,
    QueryGetChannelsArgs
  >(QUERY, {
    variables,
    context: {
      headers: {
        "x-timeout": 60000, // Timeout set to 1 min
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const list = data?.getChannels
    ? data?.getChannels?.edges.map((a) => a.node)
    : [];

  const onSearch = (text) => {
    // TODO do something here
  };

  const pageInfo: PageInfo | null = data?.getChannels?.pageInfo ?? null;
  const fetchNext = useFetchNext({
    fetchMore,
    loading,
    pageInfo,
    name: "getChannels",
  });

  return {
    data,
    list,
    loading,
    onSearch,
    fetchMore,
    fetchNext,
    refetch,
    ...useQueryResult,
  };
};
