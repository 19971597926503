import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { UserAvatar } from "~riata/components/avatars";
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { UserDetailsComponent } from "./types";

const UserDetails: UserDetailsComponent = ({
  creator,
  via = "",
  containerStyles = {},
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  return (
    <Row justify="left" align="center" style={composedContainerStyles}>
      <UserAvatar user={creator} size={36} />
      <View>
        <View>
          <Text variant="caption4" color={newColors.text.regular.main}>
            {creator.displayName}
          </Text>
        </View>
        <View style={styles.viaWrapper}>
          <Text variant="preview" color={newColors.text.footnote.main}>
            {via}
          </Text>
        </View>
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8
  },
  viaWrapper: {
    marginTop: 2,
  },
});

export default UserDetails;
