import React from "react";
import { StyleSheet, View } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";

const Backdrop = () => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.backdrop.main,
  });

  return (
    <View style={containerStyles}>
      <View style={styles.inner} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
    opacity: 0.5,
  },

  inner: {
    flex: 1,
  },
});

export default React.memo(Backdrop);
