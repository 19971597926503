import React, { FC } from "react";
import { Image } from "react-native";

const ImagePreview: FC<ImagePreviewProps> = ({
  style,
  dimensions,
  uri,
  resizeMode = "cover",
  onError = () => console.debug("Not implemented"),
  onLoadStart = () => console.debug("Not implemented"),
  onLoadEnd = () => console.debug("Not implemented"),
}) => {
  return (
    <Image
      onError={onError}
      onLoadStart={onLoadStart}
      onLoadEnd={onLoadEnd}
      style={[style, dimensions]}
      source={{ uri }}
      resizeMode={resizeMode}
    />
  );
};

export default ImagePreview;
