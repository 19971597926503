import { useCallback } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const DEBUG_INFO_QUERY = gql`
  query {
    debugInfo @client {
      deviceId
      pushToken
      subscribedTopics
    }
  }
`;

const updateContent = (client, prev) => (newData) => {
  if (newData === prev) {
    return;
  }
  client.writeQuery({
    query: DEBUG_INFO_QUERY,
    data: {
      debugInfo: {
        ...prev,
        ...newData,
      },
    },
  });
};
export const useDebugInfo = () => {
  const { data, client } = useQuery(DEBUG_INFO_QUERY);
  const debugInfo = data?.debugInfo || {
    __typename: "DebugInfo",
    deviceId: "",
    pushToken: "",
    subscribedTopics: [],
  };

  return {
    debugInfo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    update: useCallback(updateContent(client, debugInfo), []),
  };
};
