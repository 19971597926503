import React from "react";
import { Platform, View, StyleSheet, TextStyle } from "react-native";
import Markdownit from "markdown-it";
import { LinkifyIt as ILinkifyIt } from "linkify-it";
import emoji from "node-emoji";
import Markdown, {
  styles as defaultStyles,
} from "react-native-markdown-display";

import { isEmojiOnly } from "~riata/utils/text";
import { Text } from "./typography";
import { useColors } from "~riata/hooks";

const baseFontSize = 17;

const font = ({ size, weight }) => {
  return {
    fontSize: size,
    fontWeight: weight,
    marginBottom: size * 0.25,
    lineHeight: size * 1.45,
  };
};

const addUnderlinedAttr = (ast, node, isUnderlined = false) => {
  return ast.map((el) => {
    if (el.content === "<u>") {
      isUnderlined = true;
    }

    if (el.content === "</u>") {
      isUnderlined = false;
    }

    if (el.key === node.key && isUnderlined) {
      node.attributes = { underline: true };
      return {
        ...el,
        attributes: { underline: true },
      };
    }

    return {
      ...el,
      children: addUnderlinedAttr(el.children, node, isUnderlined),
    };
  });
};

const _getAdditionalStyles = (parent, node) => {
  let additionalStyles = {};
  const ast = parent[0].children;

  const updatedAst = addUnderlinedAttr(ast, node);

  parent[0].children = updatedAst;

  if (node.attributes["underline"]) {
    additionalStyles = {
      textDecorationLine: "underline",
    };
  }

  return additionalStyles;
};

var combine: (
  style: StyleSheet.NamedStyles<any>,
  ...any: StyleSheet.NamedStyles<any>[]
) => StyleSheet.NamedStyles<any> = (...newStyles) =>
  Object.keys(styles).reduce((obj, x) => {
    obj[x] = Object.assign({}, styles[x], ...newStyles.map((y) => y[x] || {}));
    return obj;
  }, {});

function isTextStyle(obj: any): obj is TextStyle {
  return obj?.fontSize;
}

export const validateLinkToken = (token) => {
  return (text, pos, self) => {
    var tail = text.slice(pos);
    if (!self.re.link) {
      self.re.link = new RegExp(
        "^([a-zA-Z0-9_-\u00A0]){1,}(?!_)(?=$|" + self.re.src_ZPCc + ")"
      );
    }
    if (self.re.link.test(tail)) {
      if (pos >= 2 && tail[pos - 2] === token) {
        return false;
      }
      return tail.match(self.re.link)[0].length;
    }
    return 0;
  };
};

export const normalizeLinkToken = (token, target) => {
  return (match) => {
    match.url = match.url.replace(token, target);
  };
};

const tokens = [
  { token: "@", path: "profile" },
  { token: "#", path: "folder" },
];

// TODO We could make combining styles faster by doing the combination _before_ we render and re-using that
const MarkdownContent = ({
  children = null,
  text = "",
  styleOverrides = {},
  largeEmojis = true,
  rules = null,
}) => {
  const { newColors } = useColors();
  const md = Markdownit({
    typographer: true,
    linkify: true,
    breaks: true,
    html: true,
  }).disable(["code"]);

  const linkify = md.linkify as ILinkifyIt;

  tokens.forEach(({ token, path }) => {
    linkify.add(token, {
      validate: validateLinkToken(token),
      normalize: normalizeLinkToken(
        token,
        `${process.env.CALLBACK_SCHEME}://${path}/`
      ),
    });
  });

  // TODO Render emojis as an extension on the markdown renderer
  const content = emoji.emojify(text || children, null, (code, name) => {
    return name.length === 1 ? `:${name}:` : code;
  });

  const coloredStyles = combine(styles, {
    text: {
      color: newColors.text.regular.main,
    },
    blockquote: {
      backgroundColor: "transparent",
      borderLeftColor: newColors.message.reply.border.main,
      color: newColors.message.reply.text.main,
    },
    hr: {
      backgroundColor: newColors.text.regular.main,
    },
    listUnorderedItem: {
      borderColor: newColors.text.regular.main,
    },
    list: {
      borderColor: newColors.text.regular.main,
    },
  });

  let calculatedStyle = combine(coloredStyles, styleOverrides);

  if (
    largeEmojis &&
    isEmojiOnly(content) &&
    isTextStyle(calculatedStyle.text)
  ) {
    calculatedStyle.text.fontSize = calculatedStyle.text.fontSize * 2.25;
  }
  return (
    <View>
      <Markdown
        markdownit={md}
        rules={{
          html_inline: (node, children, parent, styles) => {
            return <View key={node.key}>{children}</View>;
          },
          html_block: (node, children, parent, styles) => {
            return <View key={node.key}>{children}</View>;
          },
          text: (node, children, parent, styles, inheritedStyles = {}) => {
            const additionalStyles = _getAdditionalStyles(parent, node);
            return (
              <Text
                key={node.key}
                style={[inheritedStyles, styles.text, additionalStyles]}
              >
                {node.content}
              </Text>
            );
          },

          strong: (node, children, parent, styles, inheritedStyles = {}) => {
            const additionalStyles = _getAdditionalStyles(parent, node);
            return (
              <Text
                key={node.key}
                style={[styles.strong, additionalStyles, inheritedStyles]}
              >
                {children}
              </Text>
            );
          },

          em: (node, children, parent, styles, inheritedStyles = {}) => {
            const additionalStyles = _getAdditionalStyles(parent, node);
            return (
              <Text
                key={node.key}
                style={[styles.em, additionalStyles, inheritedStyles]}
              >
                {children}
              </Text>
            );
          },
        }}
        style={calculatedStyle}
      >
        {content}
      </Markdown>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
  heading1: {
    ...defaultStyles.heading1,
    ...font({ size: 21, weight: "700" }),
  },
  heading2: {
    ...defaultStyles.heading2,
    ...font({ size: 21, weight: "500" }),
  },
  heading3: {
    ...defaultStyles.heading3,
    ...font({ size: baseFontSize, weight: "700" }),
  },
  heading4: {
    ...defaultStyles.heading4,
    ...font({ size: baseFontSize, weight: "500" }),
  },
  heading5: {
    ...defaultStyles.heading54,
    ...font({ size: baseFontSize, weight: "normal" }),
  },
  text: {
    ...defaultStyles.text,
    fontSize: baseFontSize,
  },
  paragraph: {
    ...defaultStyles.paragraph,
    marginTop: 0,
    marginBottom: baseFontSize * 0.33,
  },
  list: {
    marginTop: 0,
  },
  bullet_list: {
    ...defaultStyles.listUnordered,
  },
  bullet_list_icon: {
    ...defaultStyles.listUnorderedItemIcon,
    marginLeft: 0,
    fontWeight: "bold",
    ...Platform.select({
      android: {
        lineHeight: 16,
      },
      ios: {
        lineHeight: 0,
      },
      web: {
        lineHeight: 16,
      },
    }),
  },
  ordered_list_icon: {
    ...defaultStyles.listOrderedItemIcon,
    marginLeft: 0,
    fontWeight: "bold",
    ...Platform.select({
      android: {
        lineHeight: 16,
      },
      ios: {
        lineHeight: 0,
      },
      web: {
        lineHeight: 16,
      },
    }),
  },
  listUnorderedItem: {
    ...defaultStyles.listUnorderedItem,
  },
  listItem: {
    ...defaultStyles.listItem,
    marginBottom: 0,
  },
  hr: {
    ...defaultStyles.hr,
    opacity: 0.25,
    height: 1,
    marginVertical: baseFontSize * 0.75,
  },
  blockquote: {
    ...defaultStyles.blockquote,
    marginTop: 0,
    marginBottom: baseFontSize * 0.33,
    marginLeft: 0,
    paddingLeft: 10,
    paddingTop: baseFontSize * 0.66,
    paddingBottom: baseFontSize * 0.33,
    borderLeftWidth: 2,
  },
});

export default MarkdownContent;
