import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Button } from "~riata/components/inputs";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { NewCardComponent } from "./types";

const NewCard: NewCardComponent = ({
  children,
  backgroundColor,
  borderRadius = 0,
  containerStyles = {},
  shadowStyles = {},
  shadowVariant = "noShadow",
  onPress,
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(styles.container, {
    borderRadius,
    backgroundColor: backgroundColor || newColors.surface.main,
  });

  const composedShadowStyles = useComposeStyles(shadow[shadowVariant], {
    shadowColor: newColors.shadow.main,
  });

  if (onPress) {
    return (
      <Button
        onPress={onPress}
        style={[
          composedContainerStyles,
          containerStyles,
          composedShadowStyles,
          shadowStyles,
        ]}
      >
        {children}
      </Button>
    );
  }

  return (
    <View
      style={[
        composedContainerStyles,
        containerStyles,
        composedShadowStyles,
        shadowStyles,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
});

const shadow = StyleSheet.create({
  noShadow: {
    shadowColor: "transparent",
  },
  bulletinCard: {
    shadowColor: "transparent",
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.16,
    elevation: 4,
  },
});

export default React.memo(NewCard);
