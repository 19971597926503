import React from "react";
import {
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import { UserAvatar } from "../avatars";
import { CustomIcon } from "../icons";
import SearchBar from "../SearchBar";
import { Text } from "../typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { User } from "~riata/generated/graphql";
import { Separator } from "../misc";

const UserSelectSearch = ({
  selectedUsers,
  removeUser,
  onSearch,
  inputStyles = {},
  inputContainerStyles = {},
  showSearchIcon = false,
}) => {
  const { newColors } = useColors();

  const removeContainerStyles = useComposeStyles(styles.removeContainer, {
    backgroundColor: newColors.button.delete.main,
  });

  return (
    <View>
      <SearchBar
        placeholder={"Search for a user..."}
        onSearch={(text) => onSearch(text)}
        inputStyles={inputStyles}
        containerStyles={inputContainerStyles}
        showSearchIcon={showSearchIcon}
      />
      {selectedUsers.length > 0 && (
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={Platform.OS === "web"}
          data={selectedUsers}
          contentContainerStyle={styles.contentContainer}
          ItemSeparatorComponent={() => <Separator style={styles.separator} />}
          renderItem={(item: { item: User }) => {
            return (
              <View style={styles.userContainer}>
                <UserAvatar size={48} user={item.item} />
                <TouchableOpacity
                  style={removeContainerStyles}
                  onPress={() => {
                    removeUser(item.item);
                  }}
                >
                  <CustomIcon
                    size={12}
                    name="close"
                    color={newColors.button.delete.on}
                  />
                </TouchableOpacity>
                <View style={styles.userText}>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    variant="caption1"
                    color={newColors.text.regular.main}
                  >
                    {item.item.displayName}
                  </Text>
                </View>
              </View>
            );
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    height: 74,
    width: 48,
    alignItems: "center",
    justifyContent: "center",
  },
  separator: {
    width: 38,
    height: 0,
  },
  removeContainer: {
    position: "absolute",
    width: 20,
    height: 20,
    borderRadius: 10,
    top: -6,
    right: -8,
    justifyContent: "center",
    alignItems: "center",
  },
  userText: {
    marginTop: "auto",
    marginBottom: 2,
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 22,
    paddingRight: 16,
    paddingLeft: Platform.OS === "web" ? 16 : 0,
  },
});

export default UserSelectSearch;
