import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { WatchQueryFetchPolicy } from "apollo-boost";
import gql from "graphql-tag";
import {
  Integration,
  IntegrationConnection,
  IntegrationFilter,
  PageInfo,
} from "~riata/generated/graphql";
import { fragments } from "~riata/graphql/fragments";

type GetIntegrationsResult = {
  getIntegrations?: IntegrationConnection;
};

type UseGetIntegrationsProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
  filter?: IntegrationFilter;
};

type UseGetIntegrations = (props?: UseGetIntegrationsProps) => {
  integrations: Array<Integration>;
  hasNextPage: boolean;
  fetchNext: () => void;
} & QueryResult<GetIntegrationsResult>;

type UseGetIntegrationsAssociatedUrls = () => {
  integrations: Array<Partial<Integration>>;
} & QueryResult<GetIntegrationsResult>;

const query = gql`
  query GetIntegrations(
    $filter: IntegrationFilter
    $first: Int
    $after: String
  ) {
    getIntegrations(filter: $filter, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...IntegrationContent
        }
      }
    }
  }
  ${fragments.Integration.Content}
`;

const associatedUrlsQuery = gql`
  query GetIntegrationsAssociatedUrls($filter: IntegrationFilter) {
    getIntegrations(filter: $filter) {
      edges {
        node {
          id
          associatedUrls
        }
      }
    }
  }
`;

const NULL_PAGE_INFO: PageInfo = {
  startCursor: null,
  endCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const useGetIntegrations: UseGetIntegrations = (props) => {
  const fetchPolicy = props?.fetchPolicy ?? "cache-and-network";
  const variables = {
    filter: props?.filter ?? null,
    // Get all if filter is defined (used for integrations with attachments)
    first: props?.filter ? null : 20,
    after: null,
  };

  const { data, loading, error, fetchMore, refetch, ...rest } = useQuery(
    query,
    {
      fetchPolicy,
      variables,
    }
  );

  const pageInfo = data?.getIntegrations?.pageInfo ?? NULL_PAGE_INFO;
  const integrations = data?.getIntegrations?.edges?.map((a) => a.node) ?? [];

  const updateQuery = (prev, { fetchMoreResult }) => {
    const updated = {
      getIntegrations: {
        __typename: prev?.getIntegrations?.__typename,
        pageInfo: {
          ...pageInfo,
          hasNextPage: fetchMoreResult?.getIntegrations?.pageInfo?.hasNextPage,
          endCursor: fetchMoreResult?.getIntegrations?.pageInfo?.endCursor,
        },
        edges: [
          ...prev?.getIntegrations?.edges,
          ...fetchMoreResult?.getIntegrations?.edges,
        ],
      },
    };

    return updated;
  };

  const fetchNext = () => {
    if (pageInfo.hasNextPage) {
      fetchMore({
        variables: { ...variables, after: pageInfo.endCursor },
        updateQuery,
      });
    }
  };

  return {
    data,
    integrations,
    loading,
    error,
    hasNextPage: pageInfo.hasNextPage,
    fetchNext,
    refetch,
    fetchMore,
    ...rest,
  };
};

export const useGetIntegrationsAssociatedUrls: UseGetIntegrationsAssociatedUrls =
  () => {
    const { data, loading, error, refetch, ...rest } = useQuery(
      associatedUrlsQuery,
      {
        fetchPolicy: "cache-first",
        variables: { filter: null },
      }
    );

    const integrations = data?.getIntegrations?.edges?.map((a) => a.node) ?? [];

    return { data, integrations, loading, error, refetch, ...rest };
  };
