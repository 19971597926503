import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Message, Bulletin } from "~riata/generated/graphql";
import { fragments } from "../fragments";

export const QUERY = gql`
  query GetNode($id: ID!) {
    node(id: $id) {
      ... on Message {
        ...MessageContent
      }
      ... on Bulletin {
        ...BulletinContent
      }
    }
  }
  ${fragments.Message.Content}
  ${fragments.Bulletin.Content}
`;

type GetNodeResult = {
  node?: Message | Bulletin;
};
type UseGetNode = (
  id: String
) => QueryResult<GetNodeResult> & { node?: Message | Bulletin };

export const useGetNode: UseGetNode = (id: String) => {
  const { data, ...useQueryResult } = useQuery(QUERY, {
    variables: { id },
  });
  const node = data?.node ?? null;
  return { node, data, ...useQueryResult };
};
