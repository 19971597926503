const useFileChooser = (props) => {
  return {
    error: {},
    showGallery: () => {},
    showCamera: () => {},
    showDocumentPicker: () => {},
  };
};

export default useFileChooser;
