import React, { useEffect } from "react";
import { BulletinComposeOptionsComponent } from "./types";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { Keyboard, StyleSheet, useWindowDimensions } from "react-native";
import ToggleActionSheetItem from "~riata/components/ToggleActionSheetItem";
import ButtonActionSheetItem from "~riata/components/ButtonActionSheetItem";
import { isWideDisplay } from "~riata/theme/common";
import { useColors, useFeatureFlags } from "~riata/hooks";

const BulletinComposeOptions: BulletinComposeOptionsComponent = ({
  composeState,
  composeActions,
  onNavigateToAttachment,
  onNavigateToExpire,
  onNavigateToSchedule,
}) => {
  const { newColors } = useColors();
  const { width } = useWindowDimensions();
  const { flags } = useFeatureFlags();

  const isWide = isWideDisplay(width);

  const offset = useSharedValue<number>(0);
  offset.value = Number(composeState.adminVisible);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: withSpring(offset.value * (flags.expiringBulletins ? 351 : 301)),
    };
  }, [offset]);

  useEffect(() => {
    if (offset.value) {
      Keyboard.dismiss();
    }
  }, [offset.value]);

  if (!composeState.adminVisible) {
    return null;
  }

  return (
    <Animated.View
      testID="bulletin-admin"
      style={[
        styles.admin_container,
        {
          backgroundColor: newColors.surface.main,
        },
        isWide ? styles.admin_container_wide : {},
        animatedStyle,
      ]}
    >
      <ToggleActionSheetItem
        isEnabled={composeState.payload.isPriority}
        onToggle={composeActions.togglePriority}
      >
        Is priority?
      </ToggleActionSheetItem>
      <ToggleActionSheetItem
        isEnabled={composeState.payload.isRequired}
        onToggle={composeActions.toggleRequired}
      >
        Is required?
      </ToggleActionSheetItem>
      <ToggleActionSheetItem
        isEnabled={composeState.payload.allowComments}
        onToggle={composeActions.toggleComments}
      >
        Allow comments?
      </ToggleActionSheetItem>
      <ToggleActionSheetItem
        isEnabled={composeState.payload.allowVisibility}
        onToggle={composeActions.toggleVisibility}
      >
        Allow recipients to see each other?
      </ToggleActionSheetItem>
      <ButtonActionSheetItem
        isEnabled={composeState.payload.allowVisibility}
        onPress={() => {
          composeActions.toggleAdmin();
          onNavigateToAttachment(null);
        }}
      >
        Add attachment...
      </ButtonActionSheetItem>
      <ButtonActionSheetItem
        isEnabled={composeState.payload.allowVisibility}
        onPress={onNavigateToSchedule}
      >
        Schedule Bulletin
      </ButtonActionSheetItem>
      <ButtonActionSheetItem
        isEnabled={composeState.payload.allowVisibility}
        onPress={onNavigateToExpire}
      >
        Set Expiration Date
      </ButtonActionSheetItem>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  admin_container: {
    overflow: "hidden",
    zIndex: 100,
  },
  admin_container_wide: {
    position: "absolute",
    top: 70,
    right: 0,
    shadowRadius: 5,
    shadowOpacity: 0.1,
    shadowColor: "#000",
    shadowOffset: { width: -5, height: 5 },
    borderRadius: 8,
    minWidth: "20vw",
  },
});

export default BulletinComposeOptions;
