const refresh = async (config: RiataAuthConfig, credentials: Credentials) => {
  const body = new URLSearchParams({
    grant_type: "refresh_token",
    client_id: config.clientId,
    client_secret: config.clientSecret,
    refresh_token: credentials.refreshToken,
  });
  const headers = { authorization: `Bearer ${credentials.token}` };
  const response = await fetch(config.serviceConfiguration.tokenEndpoint, {
    body,
    method: "POST",
    mode: "cors",
    headers,
  });
  console.debug("refresh.web > response", { ok: response.ok });
  if (!response.ok) {
    // TODO Make sure useCredentialsInternal can handle this
    return false;
  }
  const json = await response.json();
  const camelCaseJSON = {};
  for (let key in json) {
    const keyParts = key.split("_");
    const newKey =
      keyParts[0] +
      keyParts.slice(1).map((a) => {
        return a[0].toUpperCase() + a.substring(1);
      });
    camelCaseJSON[newKey] = json[key];
  }
  return camelCaseJSON;
};

export default refresh;
