import { useState } from "react";

const useTrackSelectedUsers = () => {
  const [state, setState] = useState({ selectedUsers: [], userToUndo: null });

  const addUser = (user) => {
    setState({
      selectedUsers: [...state.selectedUsers, user],
      userToUndo: null,
    });
  };

  const removeUser = (user) => {
    setState({
      selectedUsers: state.selectedUsers.filter((item) => item.id !== user.id),
      userToUndo: user,
    });
  };

  if (state.userToUndo) {
    // FIXME Re-enable toasts
    // Toast.show({
    //   text: `Removed ${state.userToUndo.displayName}`,
    //   type: "success",
    //   duration: 3000,
    //   buttonText: "Undo",
    //   onClose: reason => {
    //     if (reason === "user") {
    //       addUser(state.userToUndo);
    //     }
    //   }
    // });
  }

  const toggleUser = (user) => {
    if (state.selectedUsers.includes(user)) {
      removeUser(user);
    } else {
      addUser(user);
    }
  };

  const cleanSelectedUsers = () => {
    setState({ selectedUsers: [], userToUndo: null });
  };

  return { ...state, toggleUser, cleanSelectedUsers };
};

export default useTrackSelectedUsers;
