import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Text } from "~riata/components/typography";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { AttributeBadgeComponent } from "./types";

const AttributeBadge: AttributeBadgeComponent = ({
  containerStyles = {},
  title,
}) => {
  const { newColors } = useColors();
  const badgeWrapperStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.channelAttribute.wineVariant.main,
    ...containerStyles,
  });

  return (
    <View style={badgeWrapperStyles}>
      <Text
        variant="newSubhead"
        color={newColors.channelAttribute.wineVariant.on}
      >
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    borderRadius: 4,
    paddingVertical: 6,
    paddingHorizontal: 8,
    width: "auto",
  },
});

export default AttributeBadge;
