export const MODAL_TITLE = "You're off-duty";
export const ADMIN_MESSAGE =
  "Please contact your Administrator if you still need to access the platform.";
export const MODAL_MESSAGE =
  'Attention, you are currently attempting to access the platform during "Off Duty" hours.';
export const ACK_BUTTON_TITLE = "Acknowledge";
export const WEB_IMG_PERCENTAGE = 0.4;
export const NOTIFICATIONS_MESSAGE =
  "Per company’s policy, FieldConnect withholds notifications when you are off-duty. However, you may enable them if needed.";
export const ENABLE_BUTTON_TITLE = "Enable";
