import { useCallback, useImperativeHandle, useState } from "react";
import { Sentry } from "~riata/utils/sentry";
const useRefresh = ({ ref, refetch }) => {
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
      })
      .catch(Sentry.captureException);
  }, [refetch]);

  useImperativeHandle(ref, () => ({ onRefresh }));
  return { onRefresh, refreshing };
};

export default useRefresh;
