import React from "react";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Channel } from "~riata/generated/graphql";
import { useGetCurrentUser } from "~riata/graphql";
import { Row } from "../layout";
import { Text } from "../typography";
import { OffDutyLabel } from "./OffDutyLabel";
import { CustomIcon } from "../icons";
import { useColors } from "~riata/hooks";

type ChatHeaderTitleProps = {
  channel: Channel;
};

type ChatHeaderTitleComponent = (props: ChatHeaderTitleProps) => JSX.Element;

const ChatHeaderTitle: ChatHeaderTitleComponent = ({ channel }) => {
  const { user } = useGetCurrentUser();
  const { newColors } = useColors();
  const channelName = channel.name || "...";
  const isDirect = channel.members.total <= 2;
  const isGroup = channel.isGroup || false;
  const offDutyEnabled = user.company.offDuty;
  const showOffDuty = offDutyEnabled && isDirect && !isGroup;
  const isMutedByUser = channel.isMutedByUser || false;

  const recipientIsOffDuty = useMemo(() => {
    if (!isDirect || isGroup) {
      return false;
    }

    return channel?.members?.edges.filter((el) => el.node.id !== user.id)?.[0]
      ?.node?.offDuty;
  }, [isDirect, channel, user, isGroup]);

  return (
    <View>
      <Row style={styles.container}>
        <Text variant="title6" numberOfLines={1} ellipsizeMode="tail">{channelName}</Text>
        {isMutedByUser && (
          <CustomIcon
            name="muted_filled"
            size={16}
            color={newColors.icon.mediumGray.main}
          />
        )}
      </Row>
      {showOffDuty ? (
        <Row justify="center">
          <OffDutyLabel
            offDuty={recipientIsOffDuty}
            fontVariant="newFootnote"
            fontWeight="extraBold"
          />
        </Row>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8,
    marginLeft: 16
  },
});

export default React.memo(ChatHeaderTitle);
