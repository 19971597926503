import { useEffect } from "react";
import { isWeb } from "~riata/theme/common";
import { Sentry } from "~riata/utils/sentry.web";

const RealTimeMessaging = ({ children }) => {
  useEffect(() => {
    if (!isWeb) {
      return;
    }
    if (!("Notification" in window)) {
      console.warn("Unable to initialize notifications");
      // Returning, if Notification is not in window and tries to access it afterwards
      // the application will crash. Web env in iPad will present this scenario.
      return;
    }
    switch (Notification.permission) {
      case "denied":
        console.warn("Notification permission denied");
        break;

      case "granted":
        console.debug("Notification permission granted");
        break;

      case "default":
        Notification.requestPermission()
          .then((permission) => {
            if (permission !== "granted") {
              console.warn("Permission not granted: " + permission);
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
    }
  });

  console.log("RealTimeMessaging");
  return children;
};

export default RealTimeMessaging;
