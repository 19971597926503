import React from "react";

import { Row } from "../layout";
import { MessageListItemReactionItem } from "./MessageListItemReactionItem";

const MessageListItemReactionBar = ({ message }) => {
  return (
    <Row justify="left">
      {message?.reactions?.summary?.map((a) => (
        <MessageListItemReactionItem
          key={`${message.id}:${a.id}`}
          message={message}
          reaction={a}
        />
      ))}
    </Row>
  );
};

export default MessageListItemReactionBar;
