import React from "react";
import moment from "moment";

// Components
import TextSeparatorHeader from "~riata/components/TextSeparatorHeader";

const BulletinSectionHeader = ({
  isDraft,
  lastEditedAt,
  publishedAt,
  scheduledAt,
}) => {
  const date = isDraft ? lastEditedAt : publishedAt || scheduledAt;
  const sameElse = "[Older]" || "[dd/mm/yyyy]";

  const relative = moment(date).calendar(null, {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[Last Week]",
    nextWeek: "dddd",
    sameElse,
  });

  return <TextSeparatorHeader title={relative} variant="newSubhead" />;
};

export default BulletinSectionHeader;
