import React from "react";
import { StyleSheet, View } from "react-native";
import { Bulletin } from "~riata/generated/graphql";
import { Row } from "../../layout";
import { Text } from "../../typography";
import BulletinDetailsAnalytics from "./BulletinDetailsAnalytics";
import { useColors, useFeatureFlags } from "~riata/hooks";
import { BulletinExpiresBanner } from "../BulletinExpires";

type BulletinDetailHeaderProps = {
  bulletin: Bulletin;
  showAnalytics: boolean;
};

type BulletinDetailHeaderComponent = (
  props: BulletinDetailHeaderProps
) => JSX.Element;

const BulletinDetailHeader: BulletinDetailHeaderComponent = ({
  bulletin,
  showAnalytics,
}) => {
  const { newColors } = useColors();
  const { flags } = useFeatureFlags();

  return (
    <View>
      <Row>
        <Text variant="title2" color={newColors.text.regular.main}>
          {bulletin.name}
        </Text>
      </Row>
      {flags.expiringBulletins && (
        <BulletinExpiresBanner
          bulletin={bulletin}
          containerStyles={styles.expiresContainer}
        />
      )}

      {showAnalytics && (
        <BulletinDetailsAnalytics
          bulletinId={bulletin.id}
          numberOfRecipients={bulletin.numberOfRecipients}
          readRate={bulletin.readRate}
          viewedRate={bulletin.viewedRate}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  expiresContainer: {
    marginTop: 4,
  },
});

export default BulletinDetailHeader;
