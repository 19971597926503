import { useCallback } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery } from "../queries/GetChannels";
import { GetDecksQuery } from "../queries/GetDecks";
import { ChannelType } from "~riata/generated/graphql";

export const MARK_CHANNEL_READ = gql`
  mutation ($id: ID!) {
    markChannelAsRead(input: { id: $id }) {
      channel {
        id
      }
    }
  }
`;

const ALL_UNARCHIVED_CHANNELS = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false } },
};

const ALL_UNARCHIVED_DIRECT = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false, type: ChannelType.Direct } },
};

const ALL_UNARCHIVED_TEAM = {
  query: GetChannelsQuery,
  variables: { filter: { isArchived: false, type: ChannelType.Team } },
};

export const useMarkChannelRead = (id) => {
  const [mutation, response] = useMutation(MARK_CHANNEL_READ);
  const successful = response?.data ? true : false;

  const execute = useCallback(() => {
    const variables = { id: id };
    return mutation({
      variables,
      refetchQueries: [
        ALL_UNARCHIVED_CHANNELS,
        ALL_UNARCHIVED_DIRECT,
        ALL_UNARCHIVED_TEAM,

        // TODO Will this be too expensive? Maybe wwe should just pull the item directly out of the cache?
        { query: GetDecksQuery },
      ],
    });
  }, [id, mutation]);

  return { execute, response, successful };
};
