import React, { useRef, useState } from "react";
import { createContext } from "react";

type Variant = "upcomingOffDuty" | "offDuty";

type OffDutyProviderProps = {
  children: React.ReactNode;
};

type OffDutyProviderComponent = (props: OffDutyProviderProps) => JSX.Element;

const OffDutyContext = createContext({
  bannerIsVisible: false,
  visibleRef: null,
  variant: "upcomingOffDuty" as Variant,
  showBanner: () => console.debug("showBanner Not Implemented"),
  hideBanner: () => console.debug("hideBanner Not Implemented"),
  checkAndHideBanner: ({ navigation }) =>
    console.debug("hideBanner Not Implemented"),
  setBannerVariant: (variant: Variant) =>
    console.debug("setBannerVariant Not Implemented"),
});

const OffDutyProvider: OffDutyProviderComponent = ({ children }) => {
  const [bannerIsVisible, setBannerIsVisible] = useState(false);
  const [variant, setCurrentVariant] = useState<Variant>("upcomingOffDuty");
  const visibleRef = useRef(false);

  const showBanner = () => {
    visibleRef.current = true;
    setBannerIsVisible(true);
  };

  const hideBanner = () => {
    visibleRef.current = false;
    setBannerIsVisible(false);
  };

  const setBannerVariant = (variant: Variant) => {
    visibleRef.current = true;
    setCurrentVariant(variant);
  };

  const checkAndHideBanner = ({ navigation }) => {
    if (visibleRef.current) {
      hideBanner();
    } else {
      navigation.navigate("Home");
    }
  };

  const value = {
    bannerIsVisible,
    visibleRef: visibleRef.current,
    variant,
    showBanner,
    hideBanner,
    setBannerVariant,
    checkAndHideBanner,
  };

  return (
    <OffDutyContext.Provider value={value}>{children}</OffDutyContext.Provider>
  );
};

export default OffDutyProvider;
export { OffDutyContext };
