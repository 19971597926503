import React, { useCallback, useEffect, useState } from "react";
import { PermissionsAndroid, Platform, RefreshControl } from "react-native";

import { useGetIntegrations } from "~riata/graphql/queries";
import {
  AppHeader,
  HeaderActionButton,
  IntegrationList,
  SafeAreaView,
} from "~riata/components";
import { Sentry } from "~riata/utils/sentry";

const IntegrationListScreen = ({ navigation }) => {
  const { integrations, loading, error, hasNextPage, refetch, fetchNext } =
    useGetIntegrations({});

  useEffect(() => {
    if (Platform.OS !== "android") {
      return;
    }

    PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    )
      .then((granted) => {
        // TODO We should do something useful here
        console.log(granted);
      })
      .catch(Sentry.captureException);
  });
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    if (!refetch) {
      return;
    }
    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
      })
      .catch((err) => {
        // TODO Handle error gracefully
        Sentry.captureException(err);
        setRefreshing(false);
      });
  }, [refetch]);
  const refreshControl = (
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
  );

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title="Integrations"
      />
      <IntegrationList
        hasNextPage={hasNextPage}
        refreshControl={refreshControl}
        integrations={integrations}
        error={error}
        loading={loading}
        fetchNext={fetchNext}
      />
    </SafeAreaView>
  );
};

export default IntegrationListScreen;
