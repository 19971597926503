import { Linking, Platform } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { useCreateIntegrationRedirect } from "~riata/graphql";
import { Sentry } from "~riata/utils/sentry";

// TODO This needs to  live somewhere else
export const webIntegrationLauncher = (promise, id) => {
  return promise
    .then((response) => {
      window.open(
        response.data.createIntegrationRedirect.url,
        `rodio-integration-${id}`,
        "noopener,noreferrer"
      );
    })
    .catch((e) => {
      // TODO We need to get Sentry and the web client hooked up so this does more than console.warn
      Sentry.captureException(e);
    });
};

export const useIntegrationLauncher = ({ integration }) => {
  const linkTo = useLinkTo();
  const { execute } = useCreateIntegrationRedirect({
    integration: integration.id,
  });
  return () => {
    if (Platform.OS === "web") {
      webIntegrationLauncher(execute(), integration.id);
      return false;
    }
    if (integration.isExternal) {
      // TODO Add an error when canOpenURL() fails
      Linking.openURL(integration.url);
      return;
    }
    linkTo(`/integration/${integration.id}?path=`);
  };
};
