import React, { useContext, useEffect, useMemo, useState } from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";

// Components
import { Text } from "~riata/components";

// Contexts
import { RegistryContext } from "~riata/contexts";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Utils
import DnDAttachmentsHelpers from "./helpers";

const DragAndDropAttachment = (props: DragAndDropAttachmentProps) => {
  const { children, fillSpace = false, canAttach } = props;
  const registry = useContext(RegistryContext);
  const { colors } = useColors();
  const onAddAttachment = registry.get("onAddAttachment", false);
  const { height } = useWindowDimensions();

  const [dragActive, setDragActive] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const fillStyle = fillSpace ? { flexGrow: 1 } : {};

  const itemContainerStyles = useComposeStyles(styles.dropContainer, {
    height: fillSpace ? "100%" : height * 0.2,
    borderColor: isValid
      ? colors.container.primary.main
      : colors.container.danger.main,
    backgroundColor: isValid
      ? colors.container.primary.disabled
      : colors.container.danger.disabled,
  });

  const dragItem = useMemo(() => {
    const dropText = isValid ? "Drop files here" : "File not supported";
    return (
      <View style={itemContainerStyles}>
        <Text
          variant="title1"
          style={{
            color: isValid
              ? colors.container.primary.main
              : colors.container.danger.main,
          }}
        >
          {dropText}
        </Text>
      </View>
    );
  }, [colors, isValid, itemContainerStyles]);

  const actionSheetItems = useMemo(() => {
    if (canAttach) {
      return (
        <div
          style={fillStyle}
          onDragLeave={() => {
            setDragActive(false);
          }}
          onDragOver={() => {
            if (!dragActive) {
              setDragActive(true);
            }
          }}
          onDrop={(event) => {
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
              const files = event.dataTransfer.files as any;

              const mappedFiles = Object.keys(files)
                .map((key) => {
                  const file = files[key];
                  file.mime = file.type;

                  return file;
                })
                .filter((file) =>
                  DnDAttachmentsHelpers.validateMimeType(file.type)
                );

              if (mappedFiles.length > 0) {
                onAddAttachment(mappedFiles);
                setDragActive(false);
              } else {
                setIsValid(false);
              }
            }
          }}
        >
          {dragActive ? dragItem : children}
        </div>
      );
    }

    return children;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onAddAttachment, children, dragActive, dragItem, canAttach]);

  useEffect(() => {
    const preventDefault = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (canAttach) {
      window.addEventListener("dragover", preventDefault);
      window.addEventListener("drop", preventDefault);
    }

    return () => {
      if (canAttach) {
        window.removeEventListener("dragover", preventDefault);
        window.removeEventListener("drop", preventDefault);
      }
    };
  }, [canAttach]);

  useEffect(() => {
    if (!isValid) {
      setTimeout(() => {
        setIsValid(true);
        setDragActive(false);
      }, 750);
    }
  }, [isValid]);

  return actionSheetItems;
};

const styles = StyleSheet.create({
  dropContainer: {
    borderStyle: "dashed",
    borderWidth: 4,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: 250,
    zIndex: -100,
  },
});

export default DragAndDropAttachment;
