import { useCallback, useContext } from "react";
import { OffDutyContext } from "./OffDutyContext";
import { useGetCurrentUser } from "~riata/graphql";
import { getOffDutyNotificationsStatus } from "~riata/utils/offDuty";

type Variant = "upcomingOffDuty" | "offDuty";

const useOffDuty = () => {
  const {
    bannerIsVisible,
    variant,
    visibleRef,
    hideBanner,
    showBanner,
    setBannerVariant,
    checkAndHideBanner,
  } = useContext(OffDutyContext);
  const { user } = useGetCurrentUser();

  const onPress = variant === "offDuty" ? () => {} : hideBanner;

  const handleShowBanner = (variant: Variant) => {
    setBannerVariant(variant);
    showBanner();
  };

  const generateBannerMessage = useCallback(() => {
    if (variant === "offDuty") {
      const extendNotifications = user?.extendNotifications || null;
      const { endTime } = getOffDutyNotificationsStatus(
        extendNotifications?.startTime,
        extendNotifications?.hours
      );

      return `Notifications enabled until: ${endTime}`;
    }

    return null;
  }, [user, variant]);

  return {
    isVisible: bannerIsVisible,
    visibleRef,
    variant,
    onPress,
    showBanner: handleShowBanner,
    hideBanner,
    checkAndHideBanner,
    generateBannerMessage,
  };
};

export { useOffDuty };
