import React from "react";
import { View, StyleSheet } from "react-native";
import { useComposeStyles } from "~riata/hooks";

/**
 * When pressing this view, the keyboard will be dismissed. By default, the
 * screen will fill the screen. To override this behavior provide custom styles
 * in the containerStyles prop.
 */
const KeyboardDismissView: KeyboardDismissViewComponent = ({
  children,
  containerStyles = {},
}) => {
  const composedContainerStyles = useComposeStyles(styles.container, {
    ...containerStyles,
  });

  return <View style={composedContainerStyles}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
});

export default KeyboardDismissView;
