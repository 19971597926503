import React from "react";

import { Row } from "./layout";
import { Text } from "./typography";
import { Button } from "./inputs/Button";
import { StyleSheet, TextStyle } from "react-native";

type ButtonActionSheetItemProps = {
  children: string | JSX.Element;
  isEnabled: boolean;
  onPress: () => void;
  style?: TextStyle;
};

type ButtonActionSheetItemComponent = (
  props: ButtonActionSheetItemProps
) => JSX.Element;

const ButtonActionSheetItem: ButtonActionSheetItemComponent = ({
  onPress,
  children,
  isEnabled = true,
}) => {
  if (typeof children === "string") {
    children = (
      <Text variant="headline" fontWeight="regular">
        {children}
      </Text>
    );
  }

  return (
    <Row style={styles.container} justify="left">
      <Button onPress={onPress}>{children}</Button>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    paddingHorizontal: 16,
  },
});

export default ButtonActionSheetItem;
