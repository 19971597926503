import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_BULLETINS } from "../../queries/GetBulletins";
import { GetDecksQuery } from "../../queries/GetDecks";
import { BulletinStatus } from "~riata/generated/graphql";

export const MARK_BULLETIN_READ = gql`
  mutation ($id: ID!) {
    markBulletinAsRead(input: { id: $id }) {
      bulletin {
        __typename
        id
        status
      }
    }
  }
`;

export const useMarkBulletinRead = () => {
  const [mutation, response] = useMutation(MARK_BULLETIN_READ);
  const successful = response?.data ? true : false;

  const execute = (id) => {
    const variables = { id: id };
    return mutation({
      variables,
      refetchQueries: [
        // Called from useBulletinTakeOver
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: {
              status: BulletinStatus.Unread,
              isPriority: true,
              isRequired: true,
              isScheduled: false,
            },
            first: 20,
          },
        },
        // Called from BulletinScreen [selected: filter: "all"]
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: {
              isScheduled: false,
            },
            first: 20,
          },
        },
        // Called from BulletinScreen [selected: filter: "read"]
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Read, isScheduled: false },
            first: 20,
          },
        },
        // Called from BulletinScreen [selected: filter: "unread"]
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Unread, isScheduled: false },
            first: 20,
          },
        },
        // TODO Will this be too expensive? Maybe we should just pull the item directly out of the cache?
        { query: GetDecksQuery },
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response, successful };
};
