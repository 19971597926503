import React, { useMemo } from "react";
import { useLinkTo } from "@react-navigation/native";
import { Platform, StyleSheet } from "react-native";
import { CustomIcon } from "../../icons";
import { Button } from "../../inputs";
import { Row } from "../../layout";
import { Text } from "../../typography";
import { getPercentage, getRecipientsLabel } from "./utils";
import { useColors } from "~riata/hooks";

const DEFAULT_ICON_SIZE = 16;

type BulletinDetailsAnalyticsProps = {
  bulletinId: string;
  numberOfRecipients: number;
  readRate: string;
  viewedRate: string;
};

type BulletinDetailsAnalyticsComponent = (
  props: BulletinDetailsAnalyticsProps
) => JSX.Element;

const BulletinDetailsAnalytics: BulletinDetailsAnalyticsComponent = ({
  bulletinId,
  numberOfRecipients,
  readRate,
  viewedRate,
}) => {
  const { newColors } = useColors();
  const linkTo = useLinkTo();
  const readPercentage = useMemo(() => getPercentage(readRate), [readRate]);
  const viewedPercentage = useMemo(
    () => getPercentage(viewedRate),
    [viewedRate]
  );
  const recipientsLabel = useMemo(
    () => getRecipientsLabel(numberOfRecipients),
    [numberOfRecipients]
  );

  return (
    <Row
      style={styles.container}
      justify={Platform.OS === "web" ? "left" : "around"}
    >
      <Button
        onPress={() => {
          linkTo(`/bulletin/${bulletinId}/recipients`);
        }}
      >
        <Row align="center">
          <CustomIcon
            name="outlined_avatar"
            size={DEFAULT_ICON_SIZE}
            color={newColors.icon.primary.main}
          />
          <Text
            style={styles.label}
            fontWeight="bold"
            decoration="underlined"
            color={newColors.text.regular.main}
          >
            {`${numberOfRecipients} ${recipientsLabel}`}
          </Text>
        </Row>
      </Button>
      <Row style={styles.readRateContainer} align="center">
        <CustomIcon
          name="bulletins_opened"
          size={DEFAULT_ICON_SIZE}
          color={newColors.icon.primary.main}
        />
        <Text
          style={styles.label}
          color={newColors.text.regular.main}
        >{`${readPercentage} read`}</Text>
      </Row>
      <Row style={styles.readRateContainer} align="center">
        <CustomIcon
          name="viewed"
          size={DEFAULT_ICON_SIZE}
          color={newColors.icon.primary.main}
        />
        <Text
          style={styles.label}
          color={newColors.text.regular.main}
        >{`${viewedPercentage} viewed`}</Text>
      </Row>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  readRateContainer: {
    marginLeft: 24,
  },
  label: {
    marginLeft: 8,
  },
});

export default BulletinDetailsAnalytics;
