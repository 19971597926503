import React, { createContext } from "react";

// Hooks
import { useBulletinComposeScreenState } from "./BulletinComposeScreen.state";

// Constants
import { INITIAL_ACTIONS, INITIAL_STATE } from "./constants";

// Types
import {
  BulletinComposeContextValue,
  BulletinComposeProviderComponent,
} from "./types";

const BulletinComposeContext = createContext<BulletinComposeContextValue>({
  state: INITIAL_STATE,
  actions: INITIAL_ACTIONS,
  draft: {
    state: INITIAL_STATE,
    actions: INITIAL_ACTIONS,
  },
});

const BulletinComposeProvider: BulletinComposeProviderComponent = ({
  children,
}) => {
  const { state, actions } = useBulletinComposeScreenState();
  const { state: draftState, actions: draftActions } =
    useBulletinComposeScreenState();

  const value = {
    state,
    actions,
    draft: {
      state: draftState,
      actions: draftActions,
    },
  };
  return (
    <BulletinComposeContext.Provider value={value}>
      {children}
    </BulletinComposeContext.Provider>
  );
};

export { BulletinComposeProvider, BulletinComposeContext };
