import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { BookmarkCard } from "~riata/generated/graphql";
import { fragments } from "../fragments";

export const QUERY = gql`
  query GetNode($id: ID!) {
    node(id: $id) {
      ... on BookmarkCard {
        ...BookmarkCardContent
      }
    }
  }
  ${fragments.BookmarkCard.Content}
`;

type GetNodeResult = {
  node?: BookmarkCard;
};
type UseGetNode = (
  id: String
) => QueryResult<GetNodeResult> & { node?: BookmarkCard };

export const useGetBookmark: UseGetNode = (id: String) => {
  const { data, ...useQueryResult } = useQuery(QUERY, {
    variables: { id },
  });
  const node = data?.node ?? null;
  return { node, data, ...useQueryResult };
};
