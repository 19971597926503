import FileViewer from "react-native-file-viewer";
import { RNFetchBlob } from "~riata/components/FetchBlob";
import { MimeTypeMap } from "~riata/graphql/mutations/CreateAttachment";
import {
  OfficeAttachment,
  PdfAttachment,
  ImageAttachment,
  IntegrationAttachment,
} from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { Sentry } from "~riata/utils/sentry";

const isOfficeAttachment = (
  attachment: OfficeAttachment | PdfAttachment | ImageAttachment
): attachment is OfficeAttachment => {
  return (attachment as OfficeAttachment).contentType !== undefined;
};

const isIntegrationAttachment = (a: any): a is IntegrationAttachment =>
  a?.__typename === "IntegrationAttachment";

// TODO Rename this to useAttachmentViewer
const useFileViewer = () => {
  function showFile(
    attachment: OfficeAttachment | PdfAttachment | ImageAttachment
  ) {
    console.log("Invoked showFile");
    if (isWeb) {
      const file = attachment["file"];

      if (file) {
        const _URL = window.URL || window.webkitURL;
        const objectUrl = _URL.createObjectURL(file);
        window.open(objectUrl, "lariat-attachment", "noopener=true");
        _URL.revokeObjectURL(objectUrl);
      }

      window.open(attachment.url, "lariat-attachment", "noopener=true");
      return;
    }
    if (isIntegrationAttachment(attachment)) {
      console.log("GOT AN ATTACHMENT!");
      return;
    } else {
      let dirs = RNFetchBlob.fs.dirs;
      const fileExtension = isOfficeAttachment(attachment)
        ? MimeTypeMap[attachment.contentType]
        : "pdf";
      RNFetchBlob.config({
        fileCache: true,
        path:
          dirs.DocumentDir +
          attachment?.url?.substring(attachment?.url?.lastIndexOf("/")) +
          "." +
          fileExtension,
      })
        .fetch("GET", attachment?.url, {})
        .then((res) => {
          FileViewer.open(res.path(), { showAppsSuggestions: true });
        })
        .catch((err) => {
          // TODO Figure out why this is only capturing if an attachment is present
          if (attachment) Sentry.captureException(err);
        });
    }
  }
  return { showFile };
};

export default useFileViewer;
