import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { HashtagFilters } from "~riata/generated/graphql";
import { WatchQueryFetchPolicy } from "apollo-client";

export const GET_HASHTAGS = gql`
  query GetHashtags($filter: HashtagFilters) {
    getHashtags(filter: $filter) {
      edges {
        node {
          id
          name
          promoted
          color {
            color
          }
        }
      }
    }
  }
`;

type GetHashtagsProps = {
  filter?: HashtagFilters | null;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const useGetHashtags = ({
  filter,
  fetchPolicy = "cache-and-network",
}: GetHashtagsProps) => {
  const variables = { filter };
  const { data, ...response } = useQuery(GET_HASHTAGS, {
    variables,
    fetchPolicy,
  });
  const list = data?.getHashtags?.edges?.map((a) => a.node) || [];
  return { list, ...response };
};
