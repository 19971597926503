import React, { useEffect, useMemo, useState } from "react";
import { KeyboardAvoidingView, StyleSheet, View } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

// Components
import {
  AppHeader,
  Button,
  CustomIcon,
  HeaderActionButton,
  KeyboardDismissView,
  Row,
  Text,
  UserList,
  UserSelectSearch,
} from "~riata/components";

// Hooks
import { useGetCurrentUser, useGetUsers } from "~riata/graphql";
import { useGetOrCreateDm } from "~riata/graphql/mutations/CreateDm";
import {
  useColors,
  useComposeStyles,
  useTrackSelectedUsers,
} from "~riata/hooks";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

// Utils
import { getFilteredUserList, navigateToNewChannel } from "../utils";

export const NewDmScreen = ({ navigation }) => {
  const [filter, setFilter] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const { newColors } = useColors();
  const { navigateTo } = useDrawerNavigation();
  const { selectedUsers, toggleUser } = useTrackSelectedUsers();
  const { getOrCreateDirectMessageChannel, channel: createdDMChannel } =
    useGetOrCreateDm();
  const { user } = useGetCurrentUser();
  const { loading, error, userList, refetch } = useGetUsers({
    filter: { query: filter },
  });

  const isCreateDisabled = selectedUsers.length <= 0 || isWorking;

  const filteredList = useMemo(() => {
    return getFilteredUserList(userList, user);
  }, [userList, user]);

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const newGroupChannelButtonStyles = useComposeStyles(
    styles.newGroupChannelButton,
    {
      backgroundColor: newColors.accents.brightTurquoise[50].main,
      shadowColor: newColors.shadow.main,
    }
  );

  const newGroupChannelButtonWrapperStyles = useComposeStyles(
    styles.newGroupChannelButtonWrapper,
    {
      backgroundColor: newColors.surface.main,
      shadowColor: newColors.shadow.main,
    }
  );

  const userListWrapperStyles = useComposeStyles(styles.userListWrapper, {
    backgroundColor: newColors.surface.main,
  });

  const createDMChannel = () => {
    const selectedUserIDs = selectedUsers.map((a) => {
      return a.id;
    });

    setIsWorking(true);
    getOrCreateDirectMessageChannel({
      variables: {
        recipients: selectedUserIDs,
      },
    }).finally(() => setIsWorking(false));
  };

  const onSearch = (text) => {
    setFilter(text);
  };

  useEffect(() => {
    if (createdDMChannel) {
      // If channel creating is successful then navigate to the channel
      // Rebuild tree when navigation
      navigateToNewChannel(navigation, createdDMChannel);
    }
    // prevent {navigation} from triggering a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdDMChannel]);

  return (
    <KeyboardAvoidingView style={containerStyles}>
      <SafeAreaView style={containerStyles} forceInset={{ bottom: "never" }}>
        <AppHeader
          backgroundColor={newColors.header.main}
          left={
            <HeaderActionButton
              variant="back"
              onPress={() => navigateTo("Messages")}
            />
          }
          title="New Message"
          right={
            <HeaderActionButton
              variant="create"
              onPress={createDMChannel}
              disabled={isCreateDisabled}
            />
          }
        />
        <KeyboardDismissView containerStyles={containerStyles}>
          <Row style={styles.headerWrapper}>
            <Button
              onPress={() => {
                navigation.navigate("NewGroupScreen");
              }}
              style={newGroupChannelButtonWrapperStyles}
            >
              <Row>
                <View style={newGroupChannelButtonStyles}>
                  <CustomIcon
                    name="group_channel"
                    size={16}
                    color={newColors.accents.brightTurquoise[50].on}
                  />
                </View>
                <View style={styles.newGroupChannelButtonLabelWrapper}>
                  <Text variant="title5" color={newColors.text.regular.main}>
                    New Group Channel
                  </Text>
                </View>
              </Row>
            </Button>
          </Row>

          <Row style={styles.newGroupChannelLabelWrapper}>
            <Text variant="newFootnote" color={newColors.text.footnote.main}>
              A private multi-member channel that other users can invite team
              members to.
            </Text>
          </Row>

          <View style={styles.userSelectSearchWrapper}>
            <UserSelectSearch
              showSearchIcon
              selectedUsers={selectedUsers}
              removeUser={(user) => {
                toggleUser(user);
              }}
              onSearch={onSearch}
              inputContainerStyles={styles.input}
            />
          </View>
          <View style={userListWrapperStyles}>
            <UserList
              data={filteredList}
              selectedUsers={selectedUsers}
              loading={loading}
              error={error}
              refetch={refetch}
              onSelectUser={(user) => toggleUser(user)}
            />
          </View>
        </KeyboardDismissView>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  headerWrapper: {
    marginTop: 12,
    paddingHorizontal: 16,
  },
  newGroupChannelButtonWrapper: {
    backgroundColor: "transparent",
    paddingLeft: 4,
    paddingRight: 16,
    paddingVertical: 4,
    borderRadius: 32,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1,
    elevation: 2,
  },
  newGroupChannelButton: {
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: 20,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 0.8,
    },
    shadowOpacity: 0.08,
    shadowRadius: 2.4,
    elevation: 4.8,
  },
  newGroupChannelButtonLabelWrapper: {
    marginLeft: 8,
  },
  newGroupChannelLabelWrapper: {
    marginTop: 4,
    marginBottom: 12,
    paddingHorizontal: 16,
  },
  userSelectSearchWrapper: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  userListWrapper: {
    backgroundColor: "transparent",
    flex: 1,
  },
  input: { borderRadius: 12 },
});

export default NewDmScreen;

