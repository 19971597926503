import React from "react";
import { Platform } from "react-native";
import {
  AppHeader,
  Body,
  HeaderActionButton,
  RodioLoader,
  SafeAreaView,
} from "~riata/components";
import { useGetMessage } from "~riata/graphql/queries/GetMessage";
import { MessageDetailSubScreen } from "../MessageDetailSubScreen";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

const MessageDetailScreen = ({ route }) => {
  const { loading, message } = useGetMessage(route.params.id);
  const { navigateTo } = useDrawerNavigation();
  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="back"
            onPress={() => {
              navigateTo("Messages");
            }}
          />
        }
        title=""
      />

      <Body keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 80}>
        {loading ? (
          <RodioLoader />
        ) : (
          <MessageDetailSubScreen message={message} />
        )}
      </Body>
    </SafeAreaView>
  );
};

export default MessageDetailScreen;
