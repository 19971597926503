import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_BULLETINS } from "../queries/GetBulletins";
import { BulletinStatus } from "~riata/generated/graphql";

export const ARCHIVE_BULLETIN = gql`
  mutation ($id: ID!) {
    archiveBulletin(input: { id: $id }) {
      bulletin {
        id
        status
      }
    }
  }
`;

export const useArchiveBulletin = () => {
  const [mutation] = useMutation(ARCHIVE_BULLETIN);

  const execute = (id) => {
    const variables = { id: id };
    return mutation({
      variables,
      refetchQueries: [
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Sent, isScheduled: true },
            first: 20,
          },
        },
        {
          query: GET_BULLETINS,
          variables: {
            before: null,
            filter: { status: BulletinStatus.Sent, isDraft: true },
            first: 20,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute };
};
