import { useCallback, useContext } from "react";
import { useColorScheme } from "react-native";
import { random } from "lodash";
import { decodeTime } from "ulid";
import { CompanyContext } from "~riata/contexts";

const useColors = () => {
  const colorScheme = useColorScheme();
  // Add darkThemeColors when dark theme has been enabled
  const { lightThemeColors, newLightThemeColors } = useContext(CompanyContext);

  // Switch duplicated lightThemeColors with darkThemeColors when enabled
  const colors = colorScheme === "light" ? lightThemeColors : lightThemeColors;
  const newColors =
    colorScheme === "light" ? newLightThemeColors : newLightThemeColors;

  const randomColorKey = useCallback(
    (objOrId?) => {
      const keyLength = Object.keys(colors.avatar).length;
      const key = objOrId
        ? decodeTime(objOrId.id ? objOrId.id : objOrId) % keyLength
        : random(keyLength - 1);

      return Object.keys(colors.avatar)[key];
    },
    [colors]
  );

  const randomColor = useCallback(
    (objOrId?) => colors.avatar[randomColorKey(objOrId)],
    [randomColorKey, colors]
  );

  return {
    colors,
    newColors,
    randomColorKey,
    randomColor,
  };
};

export default useColors;
