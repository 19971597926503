import { BulletinStatus } from "~riata/generated/graphql";

/**
 * Method that will determine if bulletin's analytics (recipients and read %) are
 * visible for the current user.
 *
 * @param user current logged in user
 * @param bulletin bulletin being view
 * @param loading the current screen is fetching information
 */
const getAnalyticsVisibility = (user, bulletin) => {
  if (
    user?.id === bulletin.creator?.id ||
    user?.isStaff ||
    bulletin.allowVisibility
  ) {
    return true;
  }

  return false;
};

const getArchiveBulletinVisibility = (user, bulletin) => {
  if (
    !bulletin?.publishedAt &&
    bulletin?.scheduledAt &&
    user?.id === bulletin?.creator?.id
  ) {
    return true;
  }

  return false;
};

const getBackAvailability = (bulletin) => {
  if (
    !bulletin.isRequired ||
    bulletin.status === BulletinStatus.Read ||
    bulletin.status === BulletinStatus.Sent
  ) {
    return true;
  }

  return false;
};

export {
  getAnalyticsVisibility,
  getBackAvailability,
  getArchiveBulletinVisibility,
};
