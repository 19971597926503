import React, { useCallback, useMemo } from "react";
import { StyleSheet, View, ScrollView, SafeAreaView } from "react-native";
import {
  Text,
  Separator,
  ChannelInfoHeader,
  ChannelInfoActions,
  AppHeader,
  HeaderActionButton,
} from "~riata/components";
import {
  useArchiveChannel,
  useGetCurrentUser,
  useMuteChannel,
  useUnarchiveChannel,
  useUnmuteChannel,
} from "~riata/graphql";
import { useColors, useComposeStyles, useToastMessage } from "~riata/hooks";
import { isWeb } from "~riata/theme/common";
import { generateAttributes, openPhoneNumber } from "./utils";

const DMChannelInfo = ({ navigation, channel, channelID }) => {
  const { user: currentUser } = useGetCurrentUser();
  const { execute: archive } = useArchiveChannel(channelID);
  const { execute: unarchive } = useUnarchiveChannel(channelID);
  const { execute: muteChannel } = useMuteChannel();
  const { execute: unmuteChannel } = useUnmuteChannel();
  const { showInfoToast } = useToastMessage();
  const { newColors } = useColors();

  const goBack = useCallback(() => navigation.goBack(), [navigation]);

  const toggleArchived = useCallback(() => {
    channel.isArchived ? unarchive() : archive();
  }, [channel, unarchive, archive]);

  const toggleMuted = useCallback(() => {
    channel.isMutedByUser
      ? unmuteChannel(channel.id).then(() =>
          showInfoToast("Notifications are enabled")
        )
      : muteChannel(channel.id).then(() =>
          showInfoToast("Notifications are muted")
        );
    // showInfoToast shouldn't trigger a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, muteChannel, unmuteChannel]);

  const peerUser = channel?.members?.edges.filter(
    (member) => member.node.id !== currentUser.id
  )[0];

  const attributes = peerUser?.node.attributes.edges.map(
    (edge) => edge.node.name
  );

  const canCall = !isWeb && peerUser?.node?.phone?.length > 0;

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const callPeer = useCallback(() => {
    let phoneNumber = `tel:${peerUser?.node?.phone}`;
    openPhoneNumber(phoneNumber);
  }, [peerUser]);

  const actions = useMemo(() => {
    const actions = [
      {
        label: channel?.isMutedByUser ? "Unmute" : "Mute",
        iconName: channel?.isMutedByUser ? "muted" : "unmuted",
        onPressHandler: toggleMuted,
      },
      {
        label: channel?.isArchived ? "Unarchive" : "Archive",
        iconName: "archive",
        onPressHandler: toggleArchived,
      },
    ];

    if (canCall) {
      actions.push({
        label: "Call",
        iconName: "phone",
        onPressHandler: callPeer,
      });
    }

    return actions;
  }, [channel, canCall, callPeer, toggleArchived, toggleMuted]);

  const attributesList = useMemo(() => {
    return generateAttributes(attributes);
  }, [attributes]);

  return (
    <SafeAreaView style={containerStyles}>
      <AppHeader
        backgroundColor={newColors.background.main}
        left={<HeaderActionButton variant="back" onPress={goBack} />}
      />

      <View style={{ flex: 1 }}>
        <ChannelInfoHeader
          avatarDisabled
          channel={channel}
          onBackPress={goBack}
          title={peerUser?.node.fullName}
          subtitle={peerUser?.node.jobTitle}
          offDuty={peerUser?.node.offDuty}
          statusEnabled={peerUser?.node.company.offDuty}
        />
        <ChannelInfoActions
          actions={actions}
          containerStyles={styles.actionsContainer}
        />
        <Separator color={newColors.separator.main} />
        <View style={styles.attributesTitleWrapper}>
          <Text variant="title4">Attributes</Text>
        </View>
        <ScrollView>
          <View style={styles.attributesWrapper}>{attributesList}</View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
  attributesTitleWrapper: {
    paddingHorizontal: 16,
    marginTop: 20,
  },
  attributesWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 20,
    paddingHorizontal: 16,
    gap: 8,
  },
});

export default DMChannelInfo;
