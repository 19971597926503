import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Message } from "~riata/generated/graphql";
import { fragments } from "../fragments";

export const QUERY = gql`
  query GetMessage($id: ID!) {
    node(id: $id) {
      ... on Message {
        ...MessageContent
      }
    }
  }
  ${fragments.Message.Content}
`;

type GetMessageResult = {
  node?: Message;
};
type UseGetMessage = (
  id: String
) => QueryResult<GetMessageResult> & { message?: Message };

export const useGetMessage: UseGetMessage = (id: String) => {
  const { data, ...useQueryResult } = useQuery(QUERY, {
    variables: { id },
  });
  const message = data?.node ?? null;
  return { message, data, ...useQueryResult };
};
