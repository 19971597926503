import React, { createContext } from "react";

// Hooks
import { useGetCurrentUser } from "~riata/graphql";

// Types
import {
  FeatureFlagsContextValue,
  FeatureFlagsProviderComponent,
} from "./types";
import { FeatureFlag } from "./enums";

const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  expiringBulletins: false,
  clickableAvatars: true,
});

const FeatureFlagsProvider: FeatureFlagsProviderComponent = ({ children }) => {
  const { user } = useGetCurrentUser();

  const value = {
    expiringBulletins:
      user?.featureFlags?.includes(FeatureFlag.ExpiringBulletins) || false,
    clickableAvatars:
      user?.featureFlags?.includes(FeatureFlag.ClickableAvatars) || false,
  };

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsProvider, FeatureFlagsContext };
