import React from "react";
import { StyleSheet } from "react-native";

import SentTime from "./SentTime";
import { Row } from "../layout";
import { Text } from "../typography";
import { useColors } from "~riata/hooks";

const MessageListItemInfo = ({ data, showName = true }) => {
  const { newColors } = useColors();

  return (
    <Row justify="left">
      {showName && (
        <Text
          variant="title4"
          style={styles.user}
          color={newColors.text.regular.main}
        >
          {data.creator.displayName}
        </Text>
      )}
      <SentTime date={data.createdAt} />
    </Row>
  );
};
const styles = StyleSheet.create({
  user: {
    marginRight: 8,
  },
});

export default MessageListItemInfo;
