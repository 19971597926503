import React, { useCallback, useRef } from "react";
import { FlatList, StyleSheet, View } from "react-native";

import MessageListItem from "./MessageListItem";
import RodioBotAvatar from "../RodioBotAvatar";
import { Text } from "../typography";
import { RodioLoader, Separator } from "../misc";
import { useWebScrollFix } from "~riata/hooks";
import { Message } from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import SystemMessageListItem from "./SystemMessageListItem";

const CenteredText = (props) => (
  <Text alignment="center" style={styles.centeredText} {...props} />
);

const EmptyMessageList = () => (
  <View style={styles.emptyContainer}>
    <RodioBotAvatar />
    <Separator style={styles.separator} />
    <CenteredText>There are no messages here yet.</CenteredText>
    <CenteredText>Type something in to get started!</CenteredText>
  </View>
);

const MessageList = ({
  messageList,
  highlight = null,
  loading,
  refetch,
  onEndReached,
}) => {
  const ref = useRef(null);
  useWebScrollFix({ ref, messageList });

  const renderItem = useCallback(
    ({ item, index }: { item: Message; index: number }) => {
      const previous = messageList[index + 1];

      if (item.is_system_message) {
        return <SystemMessageListItem message={item} />;
      }

      return (
        <MessageListItem
          highlighted={highlight && highlight === item.id}
          key={item.id}
          message={item}
          previous={previous}
        />
      );
    },
    [messageList, highlight]
  );

  if (loading) {
    return <RodioLoader />;
  }

  return (
    <FlatList
      ref={ref}
      contentContainerStyle={styles.listContainer}
      disableVirtualization={isWeb}
      inverted
      keyExtractor={(item, index) => item.id.toString()}
      ListEmptyComponent={EmptyMessageList}
      data={messageList.reverse()}
      onEndReached={() => {
        onEndReached();
      }}
      onRefresh={() => {
        refetch();
      }}
      refreshing={loading}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  emptyContainer: {
    alignItems: "center",
    transform: [{ scaleY: -1 }],
  },
  centeredText: {
    marginBottom: 6,
  },
  listContainer: {
    paddingTop: 20,
  },
  separator: { marginVertical: 12 },
});

export default MessageList;
