import React, { useEffect, useState } from "react";
import { FlatList } from "react-native";
import {
  AppHeader,
  HeaderActionButton,
  KeyboardAvoidingView,
  RodioLoader,
  Row,
  SafeAreaView,
  SearchBar,
  SelectableListItem,
} from "~riata/components";
import {
  useCreateBulletin,
  useGetBulletinInboxes,
  usePostBulletin,
  useUpdateBulletin,
} from "~riata/graphql";
import {
  useBulletinCompose,
  useFeatureFlags,
  useSaveBulletinAsDraft,
} from "~riata/hooks";
import { BulletinFilter, BulletinScreen } from "./enums";
import { mapRecipients, resetBulletinNavigationAfterMutation } from "./helpers";

export const InboxSelectScreen = ({ navigation, route }) => {
  const [query, setQuery] = useState(null);
  // TODO Switch to direct query once available on the backend
  const filter = query ? { name__contains: query } : null;
  const { list, loading } = useGetBulletinInboxes({ filter });
  const { execute: createBulletin, successful: createSuccess } =
    useCreateBulletin();
  const { execute: updateBulletin, successful: updateSuccess } =
    useUpdateBulletin();
  const { execute: postBulletin, successful: postSuccess } = usePostBulletin();
  const { flags } = useFeatureFlags();

  const {
    actions,
    draft: { actions: draftActions },
  } = useBulletinCompose();

  const bulletin = route.params.bulletin;
  const { recipientInboxes, id, ...rest } = bulletin;
  // TODO Make a useSelectList for this functionality
  const [selected, setSelected] = useState([]);
  const [sending, setSending] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const toggleItem = (inbox) => {
    if (selected.includes(inbox)) {
      setSelected(selected.filter((item) => item.id !== inbox.id));
    } else {
      setSelected([...selected, inbox]);
    }
  };

  if (createSuccess === true || updateSuccess === true || postSuccess) {
    resetBulletinNavigationAfterMutation(
      navigation,
      BulletinScreen.Inbox,
      isDraft
        ? BulletinFilter.Draft
        : bulletin.scheduledAt
        ? BulletinFilter.Scheduled
        : BulletinFilter.Sent
    );
  }

  const emptyInboxes = selected.length === 0;

  const onCreateBulletin = (saveAsDraft: boolean) => {
    setIsDraft(saveAsDraft);
    const selectedInboxIDs = mapRecipients(selected);

    if (saveAsDraft) {
      setSaving(true);
    } else {
      setSending(true);
    }

    if (bulletin.isDraft && !saveAsDraft) {
      try {
        postBulletin(
          {
            ...bulletin,
            recipientInboxes: selectedInboxIDs,
          },
          flags.expiringBulletins
        );
      } catch {
        setSending(false);
      }
    } else if (bulletin.isDraft) {
      try {
        updateBulletin(
          {
            ...bulletin,
            recipientInboxes: selectedInboxIDs,
          },
          flags.expiringBulletins
        );
      } catch {
        setSaving(false);
      }
    } else {
      try {
        createBulletin(
          {
            ...rest,
            recipients: selectedInboxIDs,
            isDraft: saveAsDraft,
          },
          flags.expiringBulletins
        );
      } catch {
        setSending(false);
      }
    }
  };

  const renderInboxListItem = (item) => {
    const inbox = item.item;
    return (
      <SelectableListItem
        inbox={inbox}
        selected={selected.some((item) => item.id === inbox.id)}
        onSelect={(item) => toggleItem(item)}
      />
    );
  };

  const onBack = () => {
    const setter = bulletin.isDraft
      ? draftActions.setRecipientInboxes
      : actions.setRecipientInboxes;
    setter(selected.map((a) => a.id));
    navigation.pop();
  };

  useSaveBulletinAsDraft({
    bulletin: {
      ...bulletin,
      recipients: mapRecipients(selected),
    },
    screen: BulletinScreen.Inbox,
    navigation,
  });

  useEffect(() => {
    // Auto select saved inboxes
    if (bulletin.recipientInboxes.length > 0 && !loading) {
      bulletin.recipientInboxes.forEach((id) => {
        list.some((item) => item.id === id && toggleItem(item));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulletin, loading]);

  return (
    <SafeAreaView>
      <AppHeader
        left={<HeaderActionButton variant="back" onPress={onBack} />}
        title="Recipients"
        right={
          <Row justify="right" style={{ gap: 8 }}>
            <HeaderActionButton
              variant="save"
              loading={saving}
              disabled={sending}
              onPress={() => onCreateBulletin(true)}
            />
            {bulletin.scheduledAt ? (
              <HeaderActionButton
                variant="schedule"
                disabled={emptyInboxes || saving}
                loading={sending}
                onPress={() => onCreateBulletin(false)}
              />
            ) : (
              <HeaderActionButton
                variant="send"
                disabled={emptyInboxes || saving}
                loading={sending}
                onPress={() => onCreateBulletin(false)}
              />
            )}
          </Row>
        }
      />

      <KeyboardAvoidingView>
        <SearchBar placeholder={"Search for an inbox..."} onSearch={setQuery} />

        {(loading && <RodioLoader />) || (
          <FlatList data={list} renderItem={renderInboxListItem} />
        )}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default InboxSelectScreen;
