import { User } from "@sentry/react-native";
import moment from "moment";

const getOffDutyNotificationsStatus = (
  dateTime: string | undefined,
  extendedHours: number | undefined
) => {
  if (!dateTime || !extendedHours) {
    return { canEnable: true, endTime: null };
  }

  const endTime = moment(dateTime)
    .add(extendedHours, "hours")
    .format("hh:mm A");

  return { canEnable: false, endTime };
};

const getOnlineUsersCount = (users: Array<User>) => {
  return users?.filter((item) => !item.offDuty).length;
};

export { getOffDutyNotificationsStatus, getOnlineUsersCount };
