import React from "react";
import { useMemo } from "react";
import { useColors } from "../useColors";
import {
  InfoToast,
  ErrorToast,
  SuccessToast,
} from "react-native-toast-message";
import { StyleSheet, useWindowDimensions } from "react-native";

const useToastMessageConfig = () => {
  const { newColors } = useColors();
  const { width } = useWindowDimensions();
  const config = useMemo(() => {
    return {
      success: (props) => (
        <SuccessToast
          {...props}
          style={{
            borderLeftColor: newColors.toast.success.main,
            maxWidth: width * 0.9,
          }}
          contentContainerStyle={styles.container}
          text1Style={styles.title}
          text2Style={styles.message}
        />
      ),
      info: (props) => (
        <InfoToast
          {...props}
          style={{
            borderLeftColor: newColors.toast.primary.main,
            maxWidth: width * 0.9,
          }}
          contentContainerStyle={styles.container}
          text1Style={styles.title}
          text2Style={styles.message}
        />
      ),
      error: (props) => (
        <ErrorToast
          {...props}
          style={{
            borderLeftColor: newColors.toast.danger.main,
            maxWidth: width * 0.9,
          }}
          contentContainerStyle={styles.container}
          text1Style={styles.title}
          text2Style={styles.message}
        />
      ),
    };
  }, [newColors, width]);

  return { config };
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
    fontWeight: "600",
  },
  message: {
    fontSize: 15,
    lineHeight: 20,
    fontWeight: "normal",
  },
});

export { useToastMessageConfig };
