import React from "react";
import { FlatList, StyleSheet, View } from "react-native";

// Components
import AttachmentPreview from "~riata/components/attachments/AttachmentPreview";

// Types
import { Bulletin } from "~riata/generated/graphql";

type AttachmentsListProps = {
  bulletin: Bulletin;
};

type AttachmentsListComponent = (props: AttachmentsListProps) => JSX.Element;

const AttachmentsList: AttachmentsListComponent = ({ bulletin }) => {
  return (
    <FlatList
      contentContainerStyle={styles.listContainer}
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      numColumns={4}
      data={bulletin.attachments.edges.map((edge) => edge.node)}
      keyExtractor={(item) => item.id}
      renderItem={({ item, index }) => (
        <View style={styles.itemWrapper}>
          <AttachmentPreview
            key={`attachment-${item.id}-${index}`}
            parent={bulletin}
            attachment={item}
            index={index}
            thumbnail
            onAddPressed={() => {}}
            thumbnailSize={60}
          />
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  listContainer: {
    marginTop: 20,
    paddingBottom: 60,
  },
  itemSeparator: {
    height: 20,
  },
  itemWrapper: {
    width: "25%", // Has to match numColumns prop in FlatList
    alignItems: "center",
  },
});

export default AttachmentsList;
