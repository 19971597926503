import gql from "graphql-tag";

const UserContent = gql`
  fragment UserContent on User {
    id
    username
    fullName
    displayName
    firstName
    lastName
    isStaff
    offDuty
    phone
    featureFlags
    avatar {
      url
    }
    jobTitle
    company {
      id
      name
      supportURL
      offDutyDismiss
      inAppCall
      offDuty
    }
    metadata
    extendNotifications {
      hours
      startTime
      endTime
    }
  }
`;

const ChannelMembers = gql`
  fragment ChannelMembers on UserConnection {
    total
    edges {
      node {
        ...UserContent
      }
    }
  }
  ${UserContent}
`;

export const fragments = {
  Integration: {
    Content: gql`
      fragment IntegrationContent on Integration {
        id
        name
        color
        url
        isExternal
        description
        associatedUrls
        attachmentURL
      }
    `,
  },
  Message: {
    Content: gql`
      fragment MessageContent on Message {
        id
        channel {
          id
          name
          slug
        }
        content
        headline
        read
        is_system_message
        createdAt
        reactions {
          summary {
            id
            messageId
            name
            total
            userSent
            users {
              ...UserContent
            }
          }
        }
        attachments {
          edges {
            node {
              ... on ImageAttachment {
                id
                url
                contentType
                dimensions {
                  width
                  height
                }
              }
              ... on PDFAttachment {
                id
                url
              }
              ... on OfficeAttachment {
                id
                url
                contentType
              }
              ... on TwitterAttachment {
                id
                url
                content
              }
            }
          }
        }
        creator {
          ...UserContent
        }
        fromServer
      }
      ${UserContent}
    `,
    WithChannelDetails: gql`
      fragment MessageWithChannelDetails on Message {
        id
        channel {
          id
          name
          slug
          isDirect
          unreadCount
          isArchived
          members {
            ...ChannelMembers
          }
        }
        content
        headline
        read
        is_system_message
        createdAt
        reactions {
          summary {
            id
            messageId
            name
            total
            userSent
            users {
              ...UserContent
            }
          }
        }
        attachments {
          edges {
            node {
              ... on ImageAttachment {
                id
                url
                contentType
                dimensions {
                  width
                  height
                }
              }
              ... on PDFAttachment {
                id
                url
              }
              ... on OfficeAttachment {
                id
                url
                contentType
              }
              ... on TwitterAttachment {
                id
                url
                content
              }
            }
          }
        }
        creator {
          ...UserContent
        }
        fromServer
      }
      ${ChannelMembers}
      ${UserContent}
    `,
  },
  Comment: {
    Content: gql`
      fragment CommentContent on Comment {
        id
        createdAt
        content
        creator {
          ...UserContent
        }
        repliesTo {
          id
        }
      }
      ${UserContent}
    `,
  },
  Bulletin: {
    Content: gql`
      fragment BulletinContent on Bulletin {
        id
        name
        status
        isPriority
        isRequired
        isDraft
        allowComments
        allowVisibility
        publishedAt
        scheduledAt
        lastEditedTimestamp
        expires
        readRate
        isDraft
        recipientInboxes
        viewedRate
        numberOfRecipients
        creator {
          ...UserContent
        }
        hashtags(filter: { promoted: true }) {
          edges {
            node {
              name
              color {
                color
              }
            }
          }
        }
        content
        via {
          id
          name
        }
        attachments {
          edges {
            node {
              ... on ImageAttachment {
                id
                url
                contentType
                dimensions {
                  width
                  height
                }
              }
              ... on IntegrationAttachment {
                id
                integration {
                  id
                  # TODO Add integration ICON here at some point
                }
                url
              }
              ... on PDFAttachment {
                id
                url
              }
              ... on OfficeAttachment {
                id
                url
                contentType
              }
              ... on TwitterAttachment {
                id
                url
                content
              }
            }
          }
        }
        comments {
          edges {
            node {
              id
              content
              createdAt
              creator {
                ...UserContent
              }
            }
          }
        }
        recipients {
          edges {
            node {
              id
            }
          }
        }
      }
      ${UserContent}
    `,
  },
  Channel: {
    Content: gql`
      fragment ChannelContent on Channel {
        id
        name
      }
    `,
    Members: ChannelMembers,
  },
  User: {
    Content: UserContent,
  },
  BookmarkCard: {
    Content: gql`
      fragment BookmarkCardContent on BookmarkCard {
        id
        name
        actionURL
        icon
      }
    `,
  },
};
