const shadows = {
  default: {
    shadowOpacity: 1,
    shadowRadius: 6,
    shadowColor: "transparent",
    shadowOffset: { width: 0, height: 4 },
  },
  small: {
    shadowOpacity: 1,
    shadowRadius: 3,
    shadowColor: "transparent",
    shadowOffset: { width: 0, height: 2 },
  },
};

export { shadows };
