import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Scalars } from "~riata/generated/graphql";

// TODO Support other types as well
const QUERY = gql`
  query GetArchiveStatus($id: ID!) {
    getChannel(filter: { id: $id }) {
      id
      isArchived
    }
  }
`;

const useGetArchiveStatus = (id: Scalars["ID"]) => {
  const { data, ...response } = useQuery(QUERY, { variables: { id: id } });
  if (response.error) {
    // Error handling?
    console.error(response.error);
  }
  return data?.node?.isArchived;
};

export default useGetArchiveStatus;
