import React from "react";
import { StyleSheet, View } from "react-native";

import { Button } from "../../inputs/Button";
import { Row } from "../../layout";

import { PreviewCardAvatar } from "../PreviewCardAvatar";
import { Text } from "../../typography";

import { useColors } from "~riata/hooks";

const PreviewCard = ({ card, onPress }) => {
  const { newColors } = useColors();

  return (
    <Button style={style.container} onPress={onPress}>
      <Row style={style.container} justify="center" align="center">
        <PreviewCardAvatar card={card} />
        <View style={style.contentContainer}>
          <Text
            variant="subtitle"
            fontWeight="bold"
            numberOfLines={2}
            ellipsizeMode="tail"
            color={newColors.text.regular.main}
            style={style.name}
          >
            {card.name}
          </Text>
          <Text variant="caption3" color={newColors.text.caption.main}>
            {card.body}
          </Text>
        </View>
      </Row>
    </Button>
  );
};

const style = StyleSheet.create({
  container: {
    gap: 14,
    padding: 4,
  },
  contentContainer: {
    maxWidth: 160,
  },
  name: {
    lineHeight: 20,
  },
});

export default PreviewCard;
