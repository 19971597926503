import moment from "moment";
import { isFutureDate } from "~riata/utils/date";

const datesAreValid = (scheduledAt, expiresAt, errorCallback) => {
  if (scheduledAt && expiresAt) {
    const isValid = moment(expiresAt).isAfter(scheduledAt);
    if (!isValid) {
      errorCallback(
        "Can't save Bulletin",
        "Expiration date must be after scheduled date",
        new Error("Expiration date must be after scheduled date")
      );
      return false;
    }
  }

  if (scheduledAt) {
    if (!isFutureDate(scheduledAt)) {
      errorCallback(
        "Can't save Bulletin",
        "Scheduled date must be in the future",
        new Error("Scheduled date must be in the future")
      );
      return false;
    }
  }

  if (expiresAt) {
    if (!isFutureDate(expiresAt)) {
      errorCallback(
        "Can't save Bulletin",
        "Expiration date must be in the future",
        new Error("Expiration date must be in the future")
      );
      return false;
    }
  }

  return true;
};

export { datesAreValid };
