import React from "react";

import { Channel } from "~riata/generated/graphql";
import { StyleSheet, View, ViewStyle } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";
import { CustomIcon } from "../icons";

type TeamChannelAvatarProps = {
  channel: Channel;
  containerStyles?: ViewStyle;
  size?: number;
};

type TeamChannelAvatarComponent = (
  props: TeamChannelAvatarProps
) => JSX.Element;

export const DEFAULT_ICON_SIZE = 30;

const TeamChannelAvatar: TeamChannelAvatarComponent = ({
  channel,
  size = DEFAULT_ICON_SIZE,
  containerStyles = {},
}) => {
  const { randomColor, newColors } = useColors();

  const containerColor = randomColor(channel.id);

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  const iconWrapperStyles = useComposeStyles(styles.iconWrapper, {
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundColor: containerColor.main,
  });

  return (
    <View style={composedContainerStyles}>
      <View style={iconWrapperStyles}>
        <CustomIcon
          name="channel"
          size={20}
          node={channel}
          color={newColors.icon.primary.main}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  iconWrapper: {
    width: DEFAULT_ICON_SIZE,
    height: DEFAULT_ICON_SIZE,
    borderRadius: DEFAULT_ICON_SIZE / 2,
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default TeamChannelAvatar;
