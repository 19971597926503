import React, { useMemo } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";

import { Text } from "../../typography";
import { Button } from "~riata/components/inputs";
import { Row } from "~riata/components/layout";
import { useColors, useComposeStyles } from "~riata/hooks";
import { UserAvatar } from "~riata/components/avatars";

const EditProfileAvatar = ({ user, onPress, fileChooserResult }) => {
  const { newColors } = useColors();
  const uriPath = fileChooserResult?.[0]?.path ?? user.avatar?.url;
  const bannerStyles = useComposeStyles(styles.banner, {
    backgroundColor: newColors.icon.surfaceContrast.main,
  });

  const avatar = useMemo(() => {
    if (uriPath) {
      return <Image source={{ uri: uriPath }} style={styles.avatarContainer} />;
    }

    return (
      <View style={styles.fallbackAvatarContainer}>
        <UserAvatar user={user} size={100} />
      </View>
    );
  }, [uriPath, user]);

  return (
    <Row justify="center">
      <Button onPress={onPress} style={styles.button}>
        {avatar}
        <View style={bannerStyles}>
          <Text color={newColors.icon.surfaceContrast.on} style={styles.label}>
            Edit
          </Text>
        </View>
      </Button>
    </Row>
  );
};

const styles = StyleSheet.create({
  button: {
    overflow: "hidden",
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  avatarContainer: {
    height: 100,
    width: 100,
    borderRadius: 50,
    ...Platform.select({
      android: { resizeMode: null },
      default: { resizeMode: "contain" },
    }),
  },
  fallbackAvatarContainer: {
    height: 100,
    width: 100,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  banner: {
    backgroundColor: "transparent",
    position: "absolute",
    width: "100%",
    height: "25%",
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
  },
  label: {
    opacity: 1,
  },
});

export default EditProfileAvatar;
