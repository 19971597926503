import React from "react";
import { Image, StyleSheet, useWindowDimensions, View } from "react-native";
import OffDutyHand from "~riata/resources/off-duty-hand.svg";
import OffDutyBackdrop from "~riata/resources/off-duty-backdrop.png";
import { isWeb } from "~riata/theme/common";

const Hand = () => {
  const { height } = useWindowDimensions();

  const imageSource = isWeb
    ? {
        uri: OffDutyBackdrop,
        width: height * 0.4,
        height: height * 0.4,
      }
    : OffDutyBackdrop;

  return (
    <View style={styles.imageContainer}>
      <Image source={imageSource} />
      <View style={styles.image}>
        <OffDutyHand />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    position: "absolute",
  },
});

export default React.memo(Hand);
