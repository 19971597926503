import React, { useCallback } from "react";
import { FlatList, StyleSheet, View } from "react-native";

// Components
import {
  ChannelAvatar,
  MarkdownContent,
  Row,
  Text,
  RodioLoader,
} from "~riata/components";
import RelativeTime from "~riata/components/RelativeTime";
import { MessageInChannelLink } from "~riata/links";

// Hooks
import { useGetMessages } from "~riata/graphql";

const SearchResultListItem = ({ item }) => {
  const content =
    item.headline ?? item.content.split(" ").slice(0, 15).join(" ");

  return (
    <MessageInChannelLink node={item}>
      <Row style={styles.container} align="center">
        <ChannelAvatar channel={item.channel} />
        <View style={{ flex: 1 }}>
          <MarkdownContent
            styleOverrides={{ text: { fontSize: 16, fontStyle: "italic" } }}
          >
            {content}
          </MarkdownContent>
          <Row>
            <Text variant="footnote" numberOfLines={1} ellipsizeMode="tail">
              in <Text fontWeight="bold">{item.channel.name}</Text>
            </Text>
          </Row>
          <Row>
            <Text>on </Text>
            <RelativeTime time={item.createdAt} preview={false} />
          </Row>
        </View>
      </Row>
    </MessageInChannelLink>
  );
};

const SearchResultSubScreen = ({ search }) => {
  const { list, loading } = useGetMessages({ search, withDetails: true });
  const renderItem = useCallback(({ item }) => {
    return <SearchResultListItem item={item} />;
  }, []);

  if (loading) {
    return <RodioLoader />;
  }
  return <FlatList data={list} renderItem={renderItem} />;
};

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
});

export default SearchResultSubScreen;
