import { TextStyle } from "react-native";

type RiataFonts = {
  title2: TextStyle;
  title5: TextStyle;
  title6: TextStyle;
  title7: TextStyle;
  subtitle: TextStyle;
  subhead: TextStyle;
  caption1: TextStyle;
  caption2: TextStyle;
  caption3: TextStyle;
  caption4: TextStyle;
  footnote: TextStyle;
  label1: TextStyle;
  preview: TextStyle;
  figure: TextStyle;
};

const CORE_FONT = "HelveticaNeue";

const fonts: RiataFonts = {
  title2: {
    fontFamily: "HelveticaNeue",
    fontSize: 22,
    lineHeight: 22,
    fontWeight: "500",
  },
  title5: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "400",
  },
  title6: {
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    lineHeight: 22,
    fontWeight: "500",
  },
  title7: {
    fontFamily: "HelveticaNeue",
    fontSize: 16,
    lineHeight: 16,
    fontWeight: "500",
  },
  subtitle: {
    fontFamily: "HelveticaNeue",
    fontSize: 15,
    fontWeight: "400",
    lineHeight: 16,
  },
  subhead: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 17,
  },
  caption1: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 15,
  },
  caption2: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 20,
  },
  caption3: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 16,
  },
  caption4: {
    fontFamily: "HelveticaNeue",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 18,
  },
  footnote: {
    fontFamily: "HelveticaNeue",
    fontSize: 13,
    fontWeight: "400",
    lineHeight: 16,
  },
  preview: {
    fontFamily: "HelveticaNeue",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: 16,
  },
  label1: {
    fontFamily: "HelveticaNeue",
    fontSize: 11,
    fontWeight: "500",
    lineHeight: 12,
  },
  figure: {
    fontFamily: "HelveticaNeue",
    fontSize: 10,
    fontWeight: "500",
    lineHeight: 16,
  },
};

export { fonts as newFonts, CORE_FONT };
