import { useMemo } from "react";
import { Scalars } from "~riata/generated/graphql";

type MessageForChannel = {
  channel: {
    id: Scalars["String"];
  };
};

type ShouldDisplayLoadingProps = {
  list: MessageForChannel[];
  loading: boolean;
  channelID: string;
};

export const useShouldDisplayLoading = ({
  list,
  loading,
  channelID,
}: ShouldDisplayLoadingProps) =>
  useMemo(() => {
    if (list.length === 0 || loading === false) {
      return loading;
    }
    const loadedChannel = list[0].channel.id ?? "unknown";
    return loading && loadedChannel !== channelID;
  }, [list, loading, channelID]);
