import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Text } from "~riata/components/typography";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { User } from "~riata/generated/graphql";

type AvatarOffDutyIndicatorProps = {
  user: User;
  variant?: "full" | "small";
  borderColor?: string;
};

type AvatarOffDutyIndicatorComponent = (
  props: AvatarOffDutyIndicatorProps
) => JSX.Element;

const AvatarOffDutyIndicator: AvatarOffDutyIndicatorComponent = ({
  user,
  variant = "full",
  borderColor,
}) => {
  const { newColors } = useColors();

  const offDutyEnabled = user?.company?.offDuty || false;
  const offDuty = user?.offDuty || false;

  const label = useMemo(() => {
    return offDuty ? "Off-duty" : "On-duty";
  }, [offDuty]);

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: offDuty
      ? newColors.offDuty.off.main
      : newColors.offDuty.on.main,
    borderColor: borderColor ? borderColor : "transparent",
    borderWidth: borderColor ? 2 : 0,
  });

  const smallContainerStyles = useComposeStyles(styles.smallContainer, {
    borderColor: borderColor ? borderColor : newColors.background.main,
  });

  const smallStatus = useComposeStyles(styles.smallStatus, {
    backgroundColor: offDuty
      ? newColors.offDuty.off.main
      : newColors.offDuty.on.on,
  });

  if (!user || !offDutyEnabled) {
    return null;
  }

  if (variant === "full") {
    return (
      <View style={containerStyles}>
        <Text
          variant="figure"
          color={offDuty ? newColors.offDuty.off.on : newColors.offDuty.on.on}
        >
          {label}
        </Text>
      </View>
    );
  }

  return (
    <View style={smallContainerStyles}>
      <View style={smallStatus} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    paddingHorizontal: 5,
  },
  smallContainer: {
    borderWidth: 2,
    borderColor: "transparent",
    borderRadius: 100,
  },
  smallStatus: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
});

export default AvatarOffDutyIndicator;
