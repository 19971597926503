import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { ContentType } from "~riata/generated/graphql";

export const CREATE_ATTACHMENT = gql`
  mutation (
    $contentType: ContentType!
    $integration: IntegrationAttachmentInput
    $dimensions: DimensionInput
  ) {
    createAttachment(
      input: {
        contentType: $contentType
        integration: $integration
        dimensions: $dimensions
      }
    ) {
      clientMutationId
      attachment {
        ... on IntegrationAttachment {
          id
          url
        }
        ... on ImageAttachment {
          id
          uploadUrl
          dimensions {
            width
            height
          }
        }
        ... on PDFAttachment {
          id
          uploadUrl
        }
        ... on OfficeAttachment {
          id
          uploadUrl
          contentType
        }
      }
    }
  }
`;

export const MimeTypeMap = {
  "application/msword": ContentType.Doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ContentType.Docx,
  "application/vnd.ms-powerpoint": ContentType.Ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ContentType.Pptx,
  "application/vnd.ms-excel": ContentType.Xls,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    ContentType.Xlsx,
  "application/pdf": ContentType.Pdf,
  "image/jpeg": ContentType.ImageJpg,
  "image/jpg": ContentType.ImageJpg,
  "image/png": ContentType.ImagePng,
  "image/gif": ContentType.ImageGif,
};

export const useCreateAttachment = () => {
  const [mutation, response] = useMutation(CREATE_ATTACHMENT);

  const execute = ({
    mime = null,
    integration = null,
    height = null,
    width = null,
  }) => {
    // TODO We should do some sort of type checking of integration
    const variables = {
      contentType: mime ? MimeTypeMap[mime] : ContentType.Integration,
      integration,
      dimensions: height && width ? { height, width } : null,
    };
    console.debug(variables);
    return mutation({ variables });
  };

  const successful = response?.data ? true : false;

  return {
    execute,
    response,
    successful,
  };
};
