import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

export const REVOKE_TOKENS = gql`
  mutation RevokeTokens($token: RevokeTokensInput!) {
    revokeTokens(input: $token) {
      payload
    }
  }
`;

export const useRevokeTokens = () => {
  const [mutation, response] = useMutation(REVOKE_TOKENS);
  const successful = response?.data ? true : false;

  const execute = (token) => {
    const variables = { token: { "token": token } };
    return mutation({
      variables,
    });
  };

  return { execute, response, successful };
};
