import React from "react";
import { createIconSetFromFontello } from "react-native-vector-icons";

import { iconSize } from "~riata/theme/common";

const iconConfig = require("~riata/resources/config.json");
const Icon = createIconSetFromFontello(iconConfig);

export const MEDIUM_ICON_SIZE = iconSize.normal;

const CustomIcon = ({ name, size = MEDIUM_ICON_SIZE, ...props }) => (
  <Icon name={name} size={size} {...props} />
);

export default CustomIcon;
