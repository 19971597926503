import { useLinkTo } from "@react-navigation/native";
import React, { useMemo, useState } from "react";
import { Alert, RefreshControl, StyleSheet, View } from "react-native";

import {
  AppHeader,
  Body,
  SafeAreaView,
  BulletinCardList,
  ScrollableListFilter,
  Row,
  HeaderActionButton,
} from "~riata/components";
import { BulletinFilters, BulletinStatus } from "~riata/generated/graphql";
import { useGetBulletinInboxes, useGetBulletins } from "~riata/graphql/queries";
import { isWeb } from "~riata/theme/common";
import { useFeatureFlags, useUpdateSelectedRouteIfFocused } from "~riata/hooks";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { Sentry } from "~riata/utils/sentry.web";
import { BulletinFilter } from "./enums";

type ListFilterOptions = {
  id: number;
  text: string;
  filter: BulletinFilters;
  func: () => void;
  selected: boolean;
  custom?: boolean;
  group?: string;
  requiresAuthor?: boolean;
};

const BulletinScreen = ({ navigation, route }) => {
  const { params } = route;

  const linkTo = useLinkTo();
  const { list: inboxes } = useGetBulletinInboxes({});
  const { updateSelectedRoute } = useDrawerNavigation();
  const { flags } = useFeatureFlags();

  const preSelectedFilter = useMemo(() => {
    return params?.filter || BulletinFilter.Unread;
  }, [params]);

  const canSendBulletins = useMemo(() => inboxes.length > 0, [inboxes]);

  const [filters, setCurrentFilter] = useState<Array<ListFilterOptions>>([
    //  TODO: implement search
    // {
    //   id: 1,
    //   text: "Search",
    //   filter: {},
    //   func: function() {},
    //   selected: false
    // },

    {
      id: 2,
      text: BulletinFilter.All,
      filter: { isScheduled: false },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.All,
    },
    {
      id: 3,
      text: BulletinFilter.Unread,
      filter: { status: BulletinStatus.Unread, isScheduled: false },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.Unread,
    },

    ...(flags.expiringBulletins
      ? [
          {
            id: 4,
            text: BulletinFilter.Expired,
            filter: {
              status: BulletinStatus.Expired,
              isScheduled: false,
              isExpired: true,
            },
            func: function () {
              setNewlySelected(this);
            },
            selected: preSelectedFilter === BulletinFilter.Expired,
          },
        ]
      : []),

    {
      id: 5,
      text: BulletinFilter.Read,
      filter: { status: BulletinStatus.Read, isScheduled: false },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.Read,
    },
    {
      id: 6,
      text: BulletinFilter.Sent,
      filter: {
        status: BulletinStatus.Sent,
        isScheduled: false,
        isDraft: false,
      },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.Sent,
    },
    {
      id: 7,
      text: BulletinFilter.Scheduled,
      filter: { status: BulletinStatus.Sent, isScheduled: true },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.Scheduled,
      requiresAuthor: true,
    },
    {
      id: 8,
      text: BulletinFilter.Draft,
      filter: { status: BulletinStatus.Sent, isDraft: true },
      func: function () {
        setNewlySelected(this);
      },
      selected: preSelectedFilter === BulletinFilter.Draft,
      requiresAuthor: true,
    },
  ]);

  const setNewlySelected = (selected) => {
    setCurrentFilter(
      filters
        .filter((f) => f !== null)
        .map((a) => {
          if (a.group === selected.group) {
            a.selected = a.id === selected.id;
          }
          return a;
        })
    );
  };

  const currentFilter = filters
    .filter((a) => a.selected)
    .reduce((acc, a) => {
      return { ...acc, ...a.filter };
    }, {});

  const { loading, error, bulletins, refetch } = useGetBulletins({
    filter: currentFilter,
  });

  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refetch()
      .then(() => {
        setRefreshing(false);
      })
      .catch(Sentry.captureException);
  }, [refetch]);

  const refreshControl = (
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
  );

  const right = useMemo(() => {
    const addButton = canSendBulletins ? (
      <HeaderActionButton
        variant="add"
        onPress={() => linkTo("/bulletin/new")}
      />
    ) : null;
    if (isWeb) {
      return (
        <Row align="center">
          <HeaderActionButton variant="refresh" onPress={onRefresh} />
          {addButton && <View style={styles.spaceLeft}>{addButton}</View>}
        </Row>
      );
    }
    return addButton;
  }, [linkTo, onRefresh, canSendBulletins]);

  useUpdateSelectedRouteIfFocused(updateSelectedRoute);

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title="Bulletins"
        right={right}
      />
      <Body>
        <ScrollableListFilter
          testID="scrollable-list-filter"
          canCompose={canSendBulletins}
          filters={filters}
          setCurrentFilter={setCurrentFilter}
          onSaveFilter={(text) => {
            setCurrentFilter([
              ...filters,
              {
                id: text,
                text: text,
                func: function () {
                  console.warn("TODO searching…", +JSON.stringify(this));
                },
                filter: {},
                selected: false,
                custom: true,
              },
            ]);
            Alert.alert("Search Saved!");
          }}
        />

        <BulletinCardList
          fetchPrevious={onRefresh}
          bulletins={bulletins}
          error={error}
          loading={loading}
          refreshControl={refreshControl}
        />
      </Body>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  spaceLeft: {
    marginLeft: 8,
  },
});

export default BulletinScreen;
