import moment from "moment";
import { getCurrentDateTime } from "~riata/utils/date";

const getMinimumDateTime = (dateKey) => {
  const currentDateTime = getCurrentDateTime({ resetSeconds: true });

  if (dateKey === "scheduledAt") {
    return moment(currentDateTime).add(5, "minutes").toDate();
  }

  // Expires value
  return moment(currentDateTime).add(30, "minutes").toDate();
};

const getMaximumDateTime = (dateKey) => {
  const currentDateTime = getMinimumDateTime(dateKey);

  if (dateKey === "scheduledAt") {
    return moment(currentDateTime).add(1, "year").toDate();
  }

  // Expires Value
  return moment(currentDateTime).add(10, "years").toDate();
};

const getPreviousDateTime = (currentDateTime: string, dateKey: string) => {
  const currentDate = getMinimumDateTime(dateKey);

  if (currentDateTime) {
    const previousDate = moment(currentDateTime).toDate();
    return validateDateTime(previousDate, dateKey) ? previousDate : currentDate;
  }

  return currentDate;
};

const validateDateTime = (currentDateTime: Date, dateKey: string) => {
  const currentMoment = moment(getCurrentDateTime({ resetSeconds: true }));
  const previousMoment = moment(currentDateTime);

  if (dateKey === "scheduledAt") {
    const minimumMoment = currentMoment.add(4, "minutes");
    return previousMoment.isAfter(minimumMoment);
  }

  // Expires value
  const minimumMoment = currentMoment.add(29, "minutes");
  return previousMoment.isAfter(minimumMoment);
};

const validateScheduleAgainstExpires = (schedule: Date, expires: Date) => {
  if (!expires) {
    // Expire date is not set, so we can schedule it
    return true;
  }

  const scheduleAt = moment(schedule);
  const expiresAt = moment(expires);

  if (scheduleAt.isAfter(expiresAt)) {
    // We can't schedule a bulletin after it's expiration date
    return false;
  }

  return true;
};

const validateExpiresAgainstSchedule = (expires: Date, schedule: Date) => {
  if (!schedule) {
    // Schedule date is not set, so we can set expiration date
    return true;
  }

  const scheduleAt = moment(schedule);
  const expiresAt = moment(expires);

  if (expiresAt.isBefore(scheduleAt)) {
    // We can't set expiration date before schedule date
    return false;
  }

  return true;
};

export {
  getMinimumDateTime,
  getMaximumDateTime,
  getPreviousDateTime,
  validateDateTime,
  validateScheduleAgainstExpires,
  validateExpiresAgainstSchedule,
};
