import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

export const CHANGE_PASSWORD = gql`
  mutation ($old: String!, $new: String!) {
    changePassword(input: { old: $old, new: $new }) {
      success
      errors {
        messages
      }
    }
  }
`;

export const useChangePassword = () => {
  const [mutation, response] = useMutation(CHANGE_PASSWORD);
  const execute = ({ currentPassword, newPassword }) => {
    const variables = {
      old: currentPassword,
      new: newPassword,
    };
    return mutation({
      variables,
    });
  };
  return {
    execute,
    success: response.data?.changePassword.success,
    errors: response.data?.changePassword.errors ?? [],
  };
};
