import React from "react";
import { StyleSheet } from "react-native";

import { Button } from "./inputs/Button";
import { Text } from "./typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const ListFilterButton = ({
  disabled = false,
  visible = true,
  filter,
  onPress,
}) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    opacity: disabled ? 0.2 : 1,
    shadowColor: newColors.shadow.main,
    backgroundColor: filter.selected
      ? newColors.filter.selected.main
      : newColors.filter.default.main,
  });

  const labelColor = filter.selected
    ? newColors.filter.selected.on
    : newColors.filter.default.on;

  if (!visible) {
    return null;
  }

  return (
    <Button disabled={disabled} onPress={onPress} style={containerStyles}>
      <Text variant="newSubhead" color={labelColor} fontWeight="regular">
        {filter.custom ? `"${filter.text}"` : filter.text}
      </Text>
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 32,
    paddingHorizontal: 16,
    paddingVertical: 8,
    opacity: 1,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
    borderRadius: 20,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.06,
    shadowRadius: 2,
    elevation: 4,
  },
});

export default ListFilterButton;
