import React, { useContext, useMemo } from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { useLinkProps } from "@react-navigation/native";

import { Button, Row, Text, TappableBackground } from "~riata/components";
import { isWeb, isWideDisplay } from "~riata/theme/common";
import { RodioLoader } from "~riata/components/misc";
import { useColors, useComposeStyles, useFileChooser } from "~riata/hooks";
import { RegistryContext } from "~riata/contexts";
import { ulid } from "ulid";
import {
  AddDocumentIcon,
  CameraIcon,
  CustomIcon,
} from "~riata/components/icons";
import { useGetIntegrations } from "~riata/graphql";
import { IntegrationEdge } from "~riata/generated/graphql";

const ATTACHMENT_ICON_SIZE = 24;

const getFilesWithMimeType = (files) => {
  return Object.keys(files).map((key) => {
    const file = files[key];
    file.mime = file.type;

    return file;
  });
};

const AttachmentActionSheetItem = ({ action, icon, title, buttonColor }) => {
  const buttonStyles = useComposeStyles(styles.sheetItemButton, {
    backgroundColor: buttonColor,
  });

  return (
    <View style={styles.sheetItemContainer}>
      <Button style={buttonStyles} onPress={action}>
        {icon}
      </Button>
      <Text
        variant="caption2"
        style={styles.sheetItemLabel}
        ellipsizeMode="tail"
        numberOfLines={1}
      >
        {title}
      </Text>
    </View>
  );
};

const IntegrationAttachmentActionSheetItem = ({
  integration,
}: {
  integration: IntegrationEdge;
}) => {
  const { newColors } = useColors();
  const { onPress } = useLinkProps({
    to: {
      screen: "IntegrationAttachmentActionSheet",
      params: { integration: integration.node.id },
    },
  });

  return (
    <AttachmentActionSheetItem
      key={integration.cursor}
      // FIXME Change icon
      icon={
        <AddDocumentIcon
          size={ATTACHMENT_ICON_SIZE}
          color={newColors.attachment.button.default.on}
        />
      }
      action={onPress}
      title={integration.node.name}
      buttonColor={newColors.attachment.button.default.main}
    />
  );
};

const AttachmentActionSheet = ({ navigation, route }) => {
  const registry = useContext(RegistryContext);
  const onAddAttachment = registry.get(route.params.onAddAttachment, false);
  console.debug(typeof onAddAttachment);
  const { newColors } = useColors();
  const onAdd = async (fileChooserResult) => {
    onAddAttachment(fileChooserResult);
    navigation.goBack();
  };

  const { loading, data } = useGetIntegrations({
    filter: { canHandleAttachments: true },
  });

  // TODO process error
  const { showGallery, showCamera, showDocumentPicker } = useFileChooser({
    setResult: onAdd,
    cropping: false,
    includeBase64: false,
  });

  const { width } = Dimensions.get("window");
  const isWide = useMemo(() => isWideDisplay(width), [width]);
  const isLoading = loading;

  const actionSheetItems = useMemo(() => {
    if (isWeb) {
      const imageInputId = ulid();
      const onImagePicked = () => {
        const files = (document.getElementById(imageInputId) as any).files;

        const mappedFiles = getFilesWithMimeType(files);

        onAddAttachment(mappedFiles);
        navigation.goBack();
      };

      const docInputId = ulid();
      const onDocPicked = () => {
        const files = (document.getElementById(docInputId) as any).files;
        const mappedFiles = getFilesWithMimeType(files);

        onAddAttachment(mappedFiles);
        navigation.goBack();
      };

      return (
        <Row style={styles.sheetItemsContainer}>
          <AttachmentActionSheetItem
            icon={
              <CustomIcon
                name="pictures"
                size={ATTACHMENT_ICON_SIZE}
                color={newColors.attachment.button.photos.on}
              />
            }
            action={() => {
              document.getElementById(imageInputId).click();
            }}
            title={"Photos"}
            buttonColor={newColors.attachment.button.photos.main}
          />
          <AttachmentActionSheetItem
            icon={
              <CustomIcon
                name="doc"
                size={ATTACHMENT_ICON_SIZE}
                color={newColors.attachment.button.documents.on}
              />
            }
            action={() => {
              document.getElementById(docInputId).click();
            }}
            title={"Documents"}
            buttonColor={newColors.attachment.button.documents.main}
          />
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            id={imageInputId}
            multiple
            onChange={onImagePicked}
            style={{ display: "none" }}
          />
          <input
            accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf"
            type="file"
            id={docInputId}
            onChange={onDocPicked}
            multiple
            style={{ display: "none" }}
          />
        </Row>
      );
    }

    return (
      <Row style={styles.sheetItemsContainer}>
        <AttachmentActionSheetItem
          icon={
            <CustomIcon
              name="pictures"
              color={newColors.attachment.button.photos.on}
            />
          }
          action={showGallery}
          title={"Photos"}
          buttonColor={newColors.attachment.button.photos.main}
        />
        <AttachmentActionSheetItem
          icon={
            <CameraIcon
              size={ATTACHMENT_ICON_SIZE}
              color={newColors.attachment.button.camera.on}
            />
          }
          action={showCamera}
          title={"Camera"}
          buttonColor={newColors.attachment.button.camera.main}
        />
        <AttachmentActionSheetItem
          icon={
            <CustomIcon
              name="doc"
              size={ATTACHMENT_ICON_SIZE}
              color={newColors.attachment.button.documents.on}
            />
          }
          action={showDocumentPicker}
          title={"Documents"}
          buttonColor={newColors.attachment.button.documents.main}
        />
        {data?.getIntegrations &&
          data.getIntegrations.edges?.map((integration) => (
            <IntegrationAttachmentActionSheetItem
              key={`integration-attachment-${integration.cursor}`}
              integration={integration}
            />
          ))}
      </Row>
    );
  }, [
    showGallery,
    showCamera,
    showDocumentPicker,
    onAddAttachment,
    navigation,
    data,
    newColors,
  ]);

  const containerStyles = useComposeStyles(
    styles.container,
    isWide ? styles.container_wide : {}
  );

  const sheetStyles = useComposeStyles(styles.sheet, {
    ...(isWide ? styles.sheet_wide : {}),
    backgroundColor: newColors.surface.main,
  });

  return (
    <View style={containerStyles}>
      <TappableBackground onPress={() => navigation.goBack()} />

      <View style={sheetStyles}>
        {isLoading ? <RodioLoader /> : <View>{actionSheetItems}</View>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sheetItemContainer: {
    flexDirection: "column",
    alignItems: "center",
    margin: 4,
    width: 70,
  },
  sheetItemLabel: {
    textAlign: "center",
  },
  sheetItemButton: {
    borderRadius: 6,
    height: 48,
    width: 48,
    alignItems: "center",
    justifyContent: "center",
  },
  sheetItemsContainer: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    flexWrap: "wrap",
  },
  hiddenInput: {
    display: "none",
  },
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  container_wide: {
    justifyContent: "center",
  },
  sheet: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    minHeight: 360,
  },
  sheet_wide: {
    width: "70%",
    alignSelf: "center",
    borderRadius: 12,
  },
});

export default AttachmentActionSheet;
