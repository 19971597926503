import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { CustomIcon, Row, Text, UserAvatar } from "~riata/components";
import { BulletinRecipient } from "~riata/generated/graphql";
import { useColors } from "~riata/hooks";
import { BulletinStatusIcon } from "./constants";

type BulletinRecipientCardProps = {
  recipient: BulletinRecipient;
};

type BulletinRecipientCardComponent = (
  props: BulletinRecipientCardProps
) => JSX.Element;

const BulletinRecipientCard: BulletinRecipientCardComponent = ({
  recipient,
}: {
  recipient: BulletinRecipient;
}) => {
  const { newColors } = useColors();

  const { iconName, iconLabel, iconSize } = useMemo(() => {
    let status = "";

    if (recipient.read) {
      status = "Read";
    } else if (recipient.viewed) {
      status = "Viewed";
    } else {
      status = "Unread";
    }

    return BulletinStatusIcon[status];
  }, [recipient.read, recipient.viewed]);

  return (
    <Row justify="left" align="center" style={styles.container}>
      <UserAvatar user={recipient.user} />
      <View style={styles.detailsContainer}>
        <Text variant="subhead" fontWeight="bold">
          {recipient.user.displayName}
        </Text>
        <View style={styles.verticalSpace} />
        <Text
          variant="subhead"
          color={newColors.text.caption.main}
        >{`Via ${recipient.via.name}`}</Text>
      </View>
      <View style={styles.statusContainer}>
        <CustomIcon
          name={iconName}
          size={iconSize}
          color={newColors.icon.primary.main}
        />
        <View style={styles.verticalSpace} />
        <Text variant="footnote" color={newColors.icon.primary.main}>
          {iconLabel}
        </Text>
      </View>
    </Row>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingLeft: 21, // UserAvatar has 5px margin to the left
    paddingRight: 26,
    paddingVertical: 8,
  },
  detailsContainer: {
    marginLeft: 8,
  },
  verticalSpace: {
    height: 8,
  },
  statusContainer: {
    marginLeft: "auto",
    alignItems: "center",
    minWidth: 50,
  },
});

export default React.memo(BulletinRecipientCard);
