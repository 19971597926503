import { MimeType } from "./MimeTypes";

const validateMimeType = (mime: string) => {
  return Object.values(MimeType).includes(mime as MimeType);
};

const DnDAttachmentsHelpers = {
  validateMimeType,
};

export default DnDAttachmentsHelpers;
