import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { CustomIcon } from "~riata/components/icons";
import { Button } from "~riata/components/inputs";
import { Text } from "~riata/components/typography";
import { Row } from "../Row";
import { useColors, useComposeStyles } from "~riata/hooks";
import { variants } from "./constants";

const BannerButton: BannerButtonComponent = ({
  variant,
  onPress,
  onDismiss,
  generateBannerMessage = () => null,
}) => {
  const { newColors } = useColors();

  const { bannerColor, title, canDismiss, icon } = variants[variant];

  const containerJustify = canDismiss ? "between" : "left";

  const message = generateBannerMessage();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.banner[bannerColor].main,
  });

  return (
    <Button style={styles.button} onPress={onPress}>
      <Row style={containerStyles} justify={containerJustify}>
        <Row>
          <CustomIcon
            name={icon}
            color={newColors.banner[bannerColor].on}
            size={24}
            style={styles.iconWrapper}
          />
          <View>
            <Text
              variant="subhead"
              fontWeight="bold"
              color={newColors.banner[bannerColor].on}
            >
              {title}
            </Text>
            {message && (
              <Text variant="subhead" color={newColors.banner[bannerColor].on}>
                {message}
              </Text>
            )}
          </View>
        </Row>
        {canDismiss && (
          <Button onPress={onDismiss}>
            <CustomIcon
              name="close"
              size={16}
              color={newColors.banner[bannerColor].on}
            />
          </Button>
        )}
      </Row>
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    width: "100%",
    paddingHorizontal: Platform.OS === "web" ? 0 : 16,
    borderRadius: Platform.OS === "web" ? 0 : 12,
  },
  container: {
    backgroundColor: "transparent",
    paddingHorizontal: 16,
    borderRadius: 12,
    height: 60,
  },
  iconWrapper: {
    marginRight: 8,
  },
});

export default BannerButton;
