import React from "react";

import { ImageAttachmentPreview } from "./ImageAttachmentPreview";
import { AttachmentGalleryLink } from "~riata/links";
import { DocumentAttachmentPreview } from "./DocumentAttachmentPreview";
import { PdfAttachmentPreview } from "./PdfAttachmentPreview";
import { AttachmentThumbnailListAddButton } from "./AttachmentThumbnailListAddButton";
import { IntegrationAttachmentPreview } from "./IntegrationAttachmentPreview";
import { DEFAULT_THUMBNAIL_SIZE } from "./constants";

const KNOWN_PREVIEW_COMPONENTS = [
  ImageAttachmentPreview,
  PdfAttachmentPreview,
  DocumentAttachmentPreview,
  IntegrationAttachmentPreview,
];

export const AttachmentPreview = ({
  attachment,
  parent,
  index,
  thumbnailSize = DEFAULT_THUMBNAIL_SIZE,
  onLongPress = (attachment: any) => {},
  thumbnail = false,
  onAddPressed,
}) => {
  const PreviewComponent = KNOWN_PREVIEW_COMPONENTS.find((a) =>
    a.canDisplay(attachment)
  );

  if (PreviewComponent === undefined) {
    if (attachment.type === "add_button") {
      //? Should this be in this list or independent floating against the right side?
      return (
        <AttachmentThumbnailListAddButton
          onAddPressed={() => {
            onAddPressed();
          }}
        />
      );
    }

    console.warn("Encountered unknown attachment", attachment);
    return null;
  }

  return (
    <AttachmentGalleryLink
      attachment={attachment}
      key={attachment.id}
      node={parent}
      index={index}
      activeOpacity={1}
      onLongPress={() => {
        onLongPress && onLongPress(attachment);
      }}
    >
      <PreviewComponent
        key={attachment.id}
        attachment={attachment}
        thumbnail={thumbnail}
        thumbnailSize={thumbnailSize}
      />
    </AttachmentGalleryLink>
  );
};

export default AttachmentPreview;
