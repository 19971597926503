import React from "react";
import { StyleSheet } from "react-native";

import { Button, Text } from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";

export const LoginButton = ({ onPress, ...props }) => {
  const { newColors } = useColors();

  const buttonStyles = useComposeStyles(styles.button, {
    shadowColor: newColors.shadow.main,
  });

  return (
    <Button
      color="primary"
      variant="form"
      onPress={() => {
        onPress();
      }}
      style={buttonStyles}
    >
      <Text
        color={newColors.button.primary.on}
        style={styles.textButton}
        variant="headline"
      >
        Login
      </Text>
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    width: "100%",
    height: 48,
    alignSelf: "center",
    justifyContent: "center",
    borderRadius: 50,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,

    elevation: 4,
  },
  textButton: {
    textAlign: "center",
  },
});
