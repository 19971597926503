import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { User } from "~riata/generated/graphql";
import ChannelUser from "./ChannelUser";
import { Separator } from "~riata/components";
import { useColors } from "~riata/hooks";

const ChannelUsersList = ({ data, channelId }) => {
  const { newColors } = useColors();

  const renderChannelUser = (item) => {
    let user = item.item;
    return <ChannelUser user={user} groupChannelId={channelId} />;
  };

  return (
    <FlatList
      data={data}
      renderItem={renderChannelUser}
      keyExtractor={(item: User) => item.id.toString()}
      ItemSeparatorComponent={() => (
        <Separator color={newColors.separator.light} style={styles.separator} />
      )}
    />
  );
};

const styles = StyleSheet.create({
  separator: {
    marginLeft: 20,
  },
});

export default ChannelUsersList;
