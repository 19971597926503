import React from "react";

import { Text } from "../typography";
import { useColors, useComposeStyles } from "~riata/hooks";
import { StyleSheet, View } from "react-native";
import { Row } from "../layout";
import { CustomIcon } from "../icons";

const ChannelListItemTitle = ({ channel, hasUnreadMessages }) => {
  const { newColors } = useColors();

  const isMutedByUser = channel.isMutedByUser || false;
  const fontWeight = hasUnreadMessages ? "extraBold" : "mediumBold";

  const textStyles = useComposeStyles(styles.text, {
    color: newColors.text.regular.main,
  });

  return (
    <Row justify="left" style={styles.container}>
      <View style={styles.textWrapper}>
        <Text
          numberOfLines={1}
          variant="title5"
          fontWeight={fontWeight}
          style={textStyles}
        >
          {channel.name}
        </Text>
      </View>
      {isMutedByUser && (
        <CustomIcon
          name="muted_filled"
          size={16}
          color={newColors.icon.mediumGray.main}
        />
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingTop: 0,
  },
  textWrapper: {
    maxWidth: "92%",
  },
  container: {
    gap: 8,
  },
});

export default ChannelListItemTitle;
