import { GetChannelQuery } from "~riata/graphql/queries/GetChannel";
import gql from "graphql-tag";

// Hooks
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery } from "~riata/graphql/queries";
import { ChannelType } from "~riata/generated/graphql";

const UPDATE_CHANNEL_DETAILS = gql`
  mutation ($channel: ID!, $name: String!) {
    editGroupChannel(input: { channel: $channel, name: $name }) {
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;

const useUpdateChannelDetails = () => {
  const [mutation, response] = useMutation(UPDATE_CHANNEL_DETAILS);

  const execute = (channelId: string, name: string) => {
    const variables = { channel: channelId, name };

    return mutation({
      variables,
      refetchQueries: [
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: GetChannelsQuery,
          variables: {
            filter: { isArchived: false, type: ChannelType.Direct },
          },
        },
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false, type: ChannelType.Team } },
        },
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false, type: ChannelType.Group } },
        },
        {
          query: GetChannelQuery,
          variables: { id: channelId, query: "" },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response: response?.data?.leaveChannel };
};

export { useUpdateChannelDetails };
