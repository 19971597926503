import color from "color";

// Primary Swatches
const charleStoneGreySwatch = {
  "100": color("#262B2F"),
  "75": color("#5C6063"),
  "65": color("#727578"),
  "50": color("#929597"),
  "30": color("#BEBFC1"),
  "20": color("#D4D5D5"),
  "12": color("#E5E6E6"),
  "6": color("#F2F2F3"),
  "4": color("#F6F7F7"),
};

const deepOceanSwatch = {
  "150": color("#0E3C4D"),
  "120": color("#16607A"),
  "100": color("#1B7899"),
  "40": color("#A4C9D6"),
  "16": color("#DBE9EF"),
  "8": color("#EDF4F7"),
  "6": color("#F1F7F9"),
};

// Secondary and Tertiary Swatches
const wineSwatch = {
  "100": color("#781D42"),
  "75": color("#9A5571"),
  "50": color("#BB8EA0"),
  "25": color("#DDC6D0"),
  "10": color("#F2E8EC"),
};

const yellowSwatch = {
  "100": color("#F1BA24"),
  "75": color("#F4CB5B"),
  "50": color("#F8DC91"),
  "25": color("#FBEEC8"),
  "10": color("#FEF8E9"),
};

const chalkySwatch = {
  "75": color("#EEE2BC"),
  "10": color("#FDFBF6"),
};

const orangeSodaSwatch = {
  "100": color("#F45C3A"),
  "75": color("#F7856B"),
  "50": color("#F9AD9C"),
  "25": color("#FCD6CE"),
  "10": color("#FEEFEB"),
};

const brightTurquoiseSwatch = {
  "100": color("#00D9EB"),
  "75": color("#40E2F0"),
  "50": color("#80ECF5"),
  "25": color("#BFF5FA"),
  "10": color("#E6FBFD"),
};

const blueColaSwatch = {
  "100": color("#0B98E5"),
  "75": color("#48B2EB"),
  "50": color("#85CBF2"),
  "25": color("#CEEAFA"),
  "10": color("#E7F5FC"),
};

const keppelSwatch = {
  "100": color("#37B496"),
  "75": color("#69C7B0"),
  "50": color("#9BD9CA"),
  "25": color("#CDECE5"),
  "10": color("#EBF7F5"),
};

const viridianGreenSwatch = {
  "100": color("#0A9396"),
};

const chinaPinkSwatch = {
  "100": color("#E861B2"),
  "75": color("#EE88C5"),
  "50": color("#F3B0D8"),
  "25": color("#F9D7EC"),
  "10": color("#FDEFF7"),
};

const mediumOrchidSwatch = {
  "100": color("#C661E8"),
  "75": color("#D488EE"),
  "50": color("#E2B0F3"),
  "25": color("#F4DFFA"),
  "10": color("#F9EFFD"),
};

// Status Swatches
const warningSwatch = {
  "100": color("#EE9B00"),
};

const failSwatch = {
  "100": color("#D0021B"),
};

const successSwatch = {
  "100": color("#046C41"),
};

// Pure Colors
const white = color("#ffffff");

// Core Variants
const variants = {
  primary: {
    main: deepOceanSwatch["100"].toString(),
    disabled: deepOceanSwatch["40"].toString(),
    on: white.toString(),
  },
  primaryVariant: {
    main: charleStoneGreySwatch["100"].toString(),
    disabled: charleStoneGreySwatch["50"].toString(),
    on: white.toString(),
  },
  warning: {
    main: warningSwatch["100"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  notice: {
    main: yellowSwatch["100"].toString(),
    disable: yellowSwatch["50"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  success: {
    main: successSwatch["100"].toString(),
    on: white.toString(),
  },
  danger: {
    main: failSwatch["100"].toString(),
    disabled: failSwatch["100"].fade(0.6).toString(),
    on: white.toString(),
  },
  disabled: {
    main: charleStoneGreySwatch["12"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  default: {
    main: charleStoneGreySwatch["30"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
};

const tertiaryAccents = {
  accent1: {
    main: keppelSwatch["100"].toString(),
    on: white.toString(),
  },
  accent2: {
    main: blueColaSwatch["100"].toString(),
    on: white.toString(),
  },
  accent3: {
    main: wineSwatch["100"].toString(),
    on: white.toString(),
  },
  accent4: {
    main: orangeSodaSwatch["100"].toString(),
    on: white.toString(),
  },
  accent5: {
    main: mediumOrchidSwatch["100"].toString(),
    on: white.toString(),
  },
  accent6: {
    main: brightTurquoiseSwatch["100"].toString(),
    on: white.toString(),
  },
  accent7: {
    main: chinaPinkSwatch["100"].toString(),
    on: white.toString(),
  },
  accent8: {
    main: deepOceanSwatch["100"].toString(),
    on: white.toString(),
  },
  accent9: {
    main: orangeSodaSwatch["75"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  accent10: {
    main: brightTurquoiseSwatch["50"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
};

// Light Theme Color Components
const lightThemeColors = {
  text: {
    body: {
      main: charleStoneGreySwatch["100"].toString(),
      disabled: charleStoneGreySwatch["65"].toString(),
    },
    accent1: {
      main: blueColaSwatch["100"].toString(),
      disabled: blueColaSwatch["50"].toString(),
    },
    title: {
      main: deepOceanSwatch["100"].toString(),
      disabled: deepOceanSwatch["40"].toString(),
    },
    caption: {
      main: charleStoneGreySwatch["65"].toString(),
      disabled: charleStoneGreySwatch["30"].toString(),
    },
    error: {
      main: failSwatch["100"].toString(),
      disabled: failSwatch["100"].fade(0.35).toString(),
    },
  },

  surface: {
    main: white.toString(),
    on: charleStoneGreySwatch[100].toString(),
  },

  backdrop: {
    main: charleStoneGreySwatch[100].toString(),
    on: white.toString(),
  },

  shadow: {
    main: charleStoneGreySwatch["30"].toString(),
  },

  banner: {
    ...variants,
    ...tertiaryAccents,
  },

  background: {
    main: charleStoneGreySwatch["6"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  lightBackground: {
    main: charleStoneGreySwatch["4"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  error: {
    main: failSwatch["100"].toString(),
    on: white.toString(),
  },

  header: {
    main: charleStoneGreySwatch["6"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  card: {
    main: white.toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  drawer: {
    main: white.toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  button: {
    ...variants,
    ...tertiaryAccents,
    accent1: {
      main: blueColaSwatch["100"].toString(),
      on: white.toString(),
    },
    transparent: {
      main: "transparent",
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  activityIndicator: {
    darkContrast: {
      main: blueColaSwatch["10"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    lightContrast: {
      main: blueColaSwatch["100"].toString(),
      on: white.toString(),
    },
  },

  inputBar: {
    main: white.toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  reply: {
    main: charleStoneGreySwatch["12"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  mdReply: {
    main: charleStoneGreySwatch["12"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  separator: {
    extraLight: {
      main: charleStoneGreySwatch["4"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    light: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    light20: {
      main: charleStoneGreySwatch["20"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    mediumLight: {
      main: charleStoneGreySwatch["30"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    default: {
      main: charleStoneGreySwatch["50"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    dark: {
      main: charleStoneGreySwatch["65"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    error: {
      main: failSwatch["100"].toString(),
      on: white.toString(),
    },
  },

  lock: {
    main: deepOceanSwatch["40"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  actionCard: {
    ...variants,
    danger: {
      main: orangeSodaSwatch["100"].toString(),
      on: white.toString(),
    },
  },

  indicator: {
    ...variants,
    dark: {
      main: deepOceanSwatch["150"].toString(),
      on: white.toString(),
    },
    light: {
      main: deepOceanSwatch["40"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  status: {
    active: {
      main: keppelSwatch["100"].toString(),
      on: white.toString(),
    },
    inactive: {
      main: yellowSwatch["100"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    disabled: variants.disabled,
  },

  tag: { ...variants },

  toast: { ...variants },

  icon: {
    ...variants,
    ...tertiaryAccents,
    surfaceContrast: {
      main: charleStoneGreySwatch["100"].toString(),
      on: white.toString(),
    },
    mediumSurfaceContrast: {
      main: charleStoneGreySwatch["75"].toString(),
      on: white.toString(),
    },
    onDarkContrast: {
      main: white.toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  avatar: { ...tertiaryAccents },

  message: {
    highlighted: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    system: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  container: { ...variants },

  offDuty: {
    on: {
      main: keppelSwatch["25"].toString(),
      on: keppelSwatch["100"].toString(),
    },
    off: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["75"].toString(),
    },
  },

  unreadIndicator: {
    main: blueColaSwatch["100"].toString(),
    on: white.toString(),
  },

  channelAttribute: {
    main: wineSwatch["10"].toString(),
    on: wineSwatch["100"].toString(),
  },
};

const darkThemeColors = {
  text: {
    body: {
      main: white.toString(),
      disabled: charleStoneGreySwatch["30"].toString(),
    },
    accent1: {
      main: blueColaSwatch["100"].toString(),
      disabled: blueColaSwatch["50"].toString(),
    },
    title: {
      main: deepOceanSwatch["100"].toString(),
      disabled: deepOceanSwatch["40"].toString(),
    },
    caption: {
      main: charleStoneGreySwatch["30"].toString(),
      disabled: charleStoneGreySwatch["30"].toString(),
    },
    error: {
      main: failSwatch["100"].toString(),
      disabled: failSwatch["100"].fade(0.35).toString(),
    },
  },

  surface: {
    main: "#383f45",
    on: white.toString(),
  },

  backdrop: {
    main: charleStoneGreySwatch[100].toString(),
    on: white.toString(),
  },

  banner: {
    ...variants,
    ...tertiaryAccents,
  },

  shadow: {
    main: charleStoneGreySwatch["100"].toString(),
  },

  background: {
    main: "#262b2f",
    on: charleStoneGreySwatch["100"].toString(),
  },

  lightBackground: {
    main: charleStoneGreySwatch["4"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  error: {
    main: failSwatch["100"].toString(),
    on: white.toString(),
  },

  header: {
    main: "#262b2f",
    on: charleStoneGreySwatch["100"].toString(),
  },

  card: {
    main: charleStoneGreySwatch["100"].fade(0.24).toString(),
    on: white.toString(),
  },

  drawer: {
    main: charleStoneGreySwatch["100"].fade(0.24).toString(),
    on: white.toString(),
  },

  button: {
    ...variants,
    ...tertiaryAccents,
    accent1: {
      main: blueColaSwatch["100"].toString(),
      on: white.toString(),
    },
    transparent: {
      main: "transparent",
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  activityIndicator: {
    darkContrast: {
      main: blueColaSwatch["10"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    lightContrast: {
      main: blueColaSwatch["100"].toString(),
      on: white.toString(),
    },
  },

  inputBar: {
    main: "#383f45",
    on: white.toString(),
  },

  reply: {
    main: "#2f353a",
    on: white.toString(),
  },

  mdReply: {
    main: "#2f353a",
    on: white.toString(),
  },

  separator: {
    extraLight: {
      main: charleStoneGreySwatch["75"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    light: {
      main: charleStoneGreySwatch["75"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    mediumLight: {
      main: charleStoneGreySwatch["75"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    default: {
      main: charleStoneGreySwatch["65"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    dark: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  lock: {
    main: deepOceanSwatch["40"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },

  actionCard: {
    ...variants,
    danger: {
      main: orangeSodaSwatch["100"].toString(),
      on: white.toString(),
    },
  },

  indicator: {
    ...variants,
    dark: {
      main: deepOceanSwatch["150"].toString(),
      on: white.toString(),
    },
    light: {
      main: deepOceanSwatch["40"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  status: {
    active: {
      main: keppelSwatch["100"].toString(),
      on: white.toString(),
    },
    inactive: {
      main: yellowSwatch["100"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    disabled: variants.disabled,
  },

  tag: { ...variants },

  toast: { ...variants },

  icon: {
    ...variants,
    ...tertiaryAccents,
    surfaceContrast: {
      main: white.toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    mediumSurfaceContrast: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    onDarkContrast: {
      main: white.toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  avatar: { ...tertiaryAccents },

  message: {
    highlighted: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    system: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },

  container: { ...variants },

  offDuty: {
    on: {
      main: keppelSwatch["25"].toString(),
      on: keppelSwatch["100"].toString(),
    },
    off: {
      main: charleStoneGreySwatch["4"].toString(),
      on: charleStoneGreySwatch["75"].toString(),
    },
  },

  unreadIndicator: {
    main: blueColaSwatch["100"].toString(),
    on: white.toString(),
  },

  channelAttribute: {
    main: wineSwatch["10"].toString(),
    on: wineSwatch["100"].toString(),
  },
};

const newAccents = {
  brightTurquoise: {
    50: {
      main: brightTurquoiseSwatch["50"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },
  orangeSodaSwatch: {
    100: {
      main: orangeSodaSwatch["100"].toString(),
      on: white.toString(),
    },
    75: {
      main: orangeSodaSwatch["75"].toString(),
      on: white.toString(),
    },
  },
};

const newVariants = {
  primary: {
    main: charleStoneGreySwatch["100"].toString(),
    on: white.toString(),
    disabled: charleStoneGreySwatch["30"].toString(),
  },
  warning: {
    main: warningSwatch["100"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  notice: {
    main: yellowSwatch["100"].toString(),
    disable: yellowSwatch["50"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  success: {
    main: successSwatch["100"].toString(),
    on: white.toString(),
  },
  danger: {
    main: failSwatch["100"].toString(),
    on: white.toString(),
  },
  default: {
    main: charleStoneGreySwatch["30"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  disabled: {
    main: charleStoneGreySwatch["12"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  transparent: {
    main: "transparent",
    on: charleStoneGreySwatch["100"].toString(),
  },
};

const newLightThemeColors = {
  background: {
    main: charleStoneGreySwatch["4"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  surface: {
    main: white.toString(),
    on: charleStoneGreySwatch[100].toString(),
  },
  header: {
    main: charleStoneGreySwatch["4"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  shadow: {
    main: charleStoneGreySwatch["100"].toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  backdrop: {
    main: charleStoneGreySwatch[100].toString(),
    on: white.toString(),
  },
  inputBar: {
    main: white.toString(),
    on: charleStoneGreySwatch["100"].toString(),
  },
  text: {
    regular: {
      main: charleStoneGreySwatch["100"].toString(),
    },
    subtitle: {
      main: charleStoneGreySwatch["75"].toString(),
    },
    caption: {
      main: charleStoneGreySwatch["50"].toString(),
    },
    footnote: {
      main: charleStoneGreySwatch["65"].toString(),
    },
    placeHolder: {
      main: charleStoneGreySwatch["30"].toString(),
    },
    disabled: {
      main: charleStoneGreySwatch["30"].toString(),
    },
    error: {
      main: failSwatch["100"].toString(),
    },
    orange: {
      main: orangeSodaSwatch["100"].toString(),
    },
    blue: {
      main: blueColaSwatch["100"].toString(),

    },
  },
  message: {
    system: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["75"].toString(),
    },
    highlighted: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    reply: {
      border: {
        main: charleStoneGreySwatch["30"].toString(),
      },
      text: {
        main: charleStoneGreySwatch["65"].toString(),
      },
    },
  },
  attachment: {
    preview: {
      document: {
        main: keppelSwatch["75"].toString(),
        on: charleStoneGreySwatch["100"].toString(),
      },
      image: {},
      pdf: {},
      integration: {},
    },
    button: {
      photos: {
        main: mediumOrchidSwatch["75"].toString(),
        on: charleStoneGreySwatch["100"].toString(),
      },
      camera: {
        main: blueColaSwatch["75"].toString(),
        on: charleStoneGreySwatch["100"].toString(),
      },
      documents: {
        main: yellowSwatch["75"].toString(),
        on: charleStoneGreySwatch["100"].toString(),
      },
      default: {
        main: charleStoneGreySwatch["12"].toString(),
        on: charleStoneGreySwatch["100"].toString(),
      },
    },
  },
  reaction: {
    default: {
      main: yellowSwatch["10"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    userSent: {
      main: yellowSwatch["25"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
  },
  separator: {
    dark: charleStoneGreySwatch["30"].toString(),
    main: charleStoneGreySwatch["20"].toString(),
    light: charleStoneGreySwatch["12"].toString(),
  },
  card: {
    bookmark: {
      main: chalkySwatch["10"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
      border: chalkySwatch["75"].toString(),
    },
  },
  channelAttribute: {
    wineVariant: {
      main: wineSwatch["10"].toString(),
      on: wineSwatch["100"].toString(),
    },
  },
  filter: {
    default: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    selected: {
      main: charleStoneGreySwatch["100"].toString(),
      on: white.toString(),
    },
  },
  button: {
    ...newVariants,
    delete: newAccents.orangeSodaSwatch[100],
  },
  icon: {
    ...newVariants,
    surfaceContrast: {
      main: charleStoneGreySwatch["100"].toString(),
      on: white.toString(),
    },
    mediumGray: {
      main: charleStoneGreySwatch["50"].toString(),
    },
    lightGray: {
      main: charleStoneGreySwatch["30"].toString(),
    },
    unreadCount: {
      main: blueColaSwatch["100"].toString(),
      on: white.toString(),
    },
    orange: {
      main: orangeSodaSwatch["100"].toString(),
      on: white.toString(),
    },
  },
  drawer: {
    selected: {
      main: "#BFF5FA", // Unique color from design
    },
  },
  offDuty: {
    on: {
      main: keppelSwatch["25"].toString(),
      on: viridianGreenSwatch["100"].toString(),
    },
    off: {
      main: charleStoneGreySwatch["12"].toString(),
      on: charleStoneGreySwatch["75"].toString(),
    },
  },
  status: {
    active: {
      main: keppelSwatch["100"].toString(),
      on: white.toString(),
    },
    inactive: {
      main: yellowSwatch["100"].toString(),
      on: charleStoneGreySwatch["100"].toString(),
    },
    disabled: variants.disabled,
  },
  graphics: {
    orangeSoda: {
      main: orangeSodaSwatch["75"].toString(),
    },
    turquoise: {
      main: brightTurquoiseSwatch["25"].toString(),
    },
  },
  toast: { ...variants },
  banner: {
    ...newVariants,
    orange: {
      main: orangeSodaSwatch["75"].toString(),
    },
  },
  tag: {
    ...newVariants,
  },
  accents: {
    ...newAccents,
  },
  variants: {
    ...newVariants,
  },
};

export { lightThemeColors, darkThemeColors, newLightThemeColors };
