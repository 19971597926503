import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { debounce } from "debounce";
import { Company } from "~riata/generated/graphql";

const FIND_COMPANIES = gql`
  query FindCompany($search: String!) {
    companiesBySlug: findCompany(filter: { slug__contains: $search }) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
    companiesByName: findCompany(filter: { name__contains: $search }) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`;

type SimpleFindCompanies = (search: string) => void;

type UseCompanyFinderResponse = [
  {
    findCompanies: SimpleFindCompanies;
  },
  { loading: any; error: any; data: any; list: Array<Company> }
];
export const useCompanyFinder = (): UseCompanyFinderResponse => {
  const [findCompanies, { loading, error, data }] = useLazyQuery(
    FIND_COMPANIES,
    { fetchPolicy: "network-only" }
  );

  let list = [];
  let companyIds = {};
  if (data?.companiesBySlug) {
    list = data.companiesBySlug.edges.map((a) => {
      companyIds[a.node.id] = true;
      return a.node;
    });
  }
  if (data?.companiesByName) {
    list = list.concat(
      data.companiesByName.edges
        .filter((a) => !companyIds[a.node.id])
        .map((a) => {
          companyIds[a.node.id] = true;
          return a.node;
        })
    );
  }

  const simpleFindCompanies = (search: string) => {
    return findCompanies({ variables: { search } });
  };
  return [
    {
      findCompanies: debounce(simpleFindCompanies, 250),
    },
    { loading, error, data, list },
  ];
};
