import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  query GetEmptyDashboardLinks {
    getEmptyDashboardLinks {
      edges {
        node {
          id
          name
          url
          icon
        }
      }
    }
  }
`;

const useGetEmptyDashboardLinks = () => {
  const { data, ...response } = useQuery(QUERY);
  const links = data?.getEmptyDashboardLinks?.edges?.map((a) => a.node) ?? [];

  return { data, links, ...response };
};

export default useGetEmptyDashboardLinks;
