import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import BaseSafeAreaView from "react-native-safe-area-view";

import { useColors, useComposeStyles } from "~riata/hooks";

type SafeAreaViewStyle = {
  bottom: ViewStyle;
  top: ViewStyle;
};

type SafeAreaProps = {
  top?: boolean;
  bottom?: boolean;
  children: React.ReactNode | JSX.Element;
  style?: SafeAreaViewStyle;
  backgroundColor?: string;
};

const SafeTopView = ({ children, ...props }) => (
  <BaseSafeAreaView forceInset={{ bottom: "never" }} {...props}>
    {children}
  </BaseSafeAreaView>
);

const SafeBottomView = ({ children, ...props }) => (
  <BaseSafeAreaView forceInset={{ top: "never" }} {...props}>
    {children}
  </BaseSafeAreaView>
);

type SafeAreaViewElement = (props: SafeAreaProps) => JSX.Element;

const SafeAreaView: SafeAreaViewElement = ({
  top = true,
  bottom = true,
  backgroundColor,
  children,
}) => {
  const TopWrapper = top ? SafeTopView : View;
  const BottomWrapper = bottom ? SafeBottomView : View;

  const { newColors } = useColors();

  const topStyles = useComposeStyles(styles.wrapper, {
    backgroundColor: backgroundColor || newColors.background.main,
  });

  const bottomStyles = useComposeStyles(styles.wrapper, {
    backgroundColor: backgroundColor || newColors.background.main,
  });

  return (
    <TopWrapper style={topStyles}>
      <BottomWrapper style={bottomStyles}>{children}</BottomWrapper>
    </TopWrapper>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
});

export default SafeAreaView;
