import { useContext } from "react";
import { BulletinComposeContext } from "~riata/contexts";

const useBulletinCompose = () => {
  const { actions, state, draft } = useContext(BulletinComposeContext);

  return { actions, state, draft };
};

export { useBulletinCompose };
