import { ApolloError } from "apollo-client";
import React from "react";
import { RodioLoader } from "~riata/components/misc";
import Error from "./Error";
type LoadingErrorGuardProps = {
  isLoading: boolean;
  error: ApolloError | string;
  refetch?: () => void;
  children: React.ReactElement;
};

type LoadingErrorGuardComponent = (
  props: LoadingErrorGuardProps
) => JSX.Element;

const LoadingErrorGuard: LoadingErrorGuardComponent = ({
  isLoading,
  error,
  refetch,
  children,
}) => {
  if (isLoading) {
    return <RodioLoader />;
  }

  if (error) {
    return <Error error={error} refetch={refetch} />;
  }

  return children;
};

export default LoadingErrorGuard;
