import React, { useCallback, useMemo } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";

import BulletinCommentList from "./BulletinCommentList";
import { InputBar } from "../chat";
import { KeyboardAvoidingView, Row, TappableBackground } from "../layout";
import { useColors, useComposeStyles } from "~riata/hooks";
import { isWideDisplay } from "~riata/theme/common";
import { useGetComments, usePostComment } from "~riata/graphql";
import { HeaderActionButton } from "../misc";

const BulletinCommentSheet = ({ navigation, route }) => {
  const id = route?.params?.id ?? {};

  const { newColors } = useColors();
  const { width } = useWindowDimensions();

  const isWide = useMemo(() => isWideDisplay(width), [width]);
  const sheetStyle = useComposeStyles(styles.sheet, {
    backgroundColor: newColors.surface.main,
  });
  const { comments, loading, fetchPrevious, hasPreviousPage } =
    useGetComments(id);
  const { execute: postComment } = usePostComment({ nodeId: id });

  const onEndReachCallback = useCallback(() => {
    if (!loading && hasPreviousPage) {
      try {
        fetchPrevious();
      } catch (err) {
        console.warn(err.message);
      }
    }
  }, [loading, hasPreviousPage, fetchPrevious]);

  const onBackCallback = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View style={[styles.container, isWide ? styles.container_wide : []]}>
      <TappableBackground onPress={onBackCallback} />
      <View style={[sheetStyle, isWide ? styles.sheet_wide : []]}>
        <KeyboardAvoidingView style={styles.inputContainer}>
          <Row
            align="center"
            justify="right"
            style={styles.backButtonContainer}
          >
            <HeaderActionButton variant="close" onPress={onBackCallback} iconSize={20}/>
          </Row>
          <BulletinCommentList
            onEndReached={onEndReachCallback}
            style={styles.listContainer}
            comments={comments}
          />
          <InputBar
            nodeID={id}
            allowAttachments={false}
            onSendMessage={postComment}
          />
        </KeyboardAvoidingView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "flex-end",
  },
  container_wide: {
    justifyContent: "center",
  },
  sheet: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: "90%",
    justifyContent: "flex-end",
  },
  sheet_wide: {
    width: "70%",
    minHeight: "50%",
    alignSelf: "center",
    borderRadius: 12,
    overflow: "hidden",
  },
  backButtonContainer: {
    height: 50,
    paddingHorizontal: 16,
  },
  inputContainer: {
    flex: 1,
  },
  listContainer: {
    height: "85%",
    marginTop: 5,
  },
});

export default BulletinCommentSheet;
