import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { fragments } from "../fragments";
import { UserEditPayload } from "~riata/generated/graphql";

export const QUERY = gql`
  query MeQuery {
    me {
      ...UserContent
    }
  }
  ${fragments.User.Content}
`;

export const subscriber = {
  type: "User",
  execute: async ({ data, navigation, checkAndHideBanner }) => {
    const payload: UserEditPayload = data;

    // TODO: -1min ETA show upcomingOffDuty banner
    // TODO: -10min ETA show upcomingOffDuty banner
    if (payload) {
      if (payload.offDuty) {
        navigation.navigate("OffDutyModal");
      } else {
        checkAndHideBanner({ navigation });
      }
    }
  },
};

const useGetCurrentUser = () => {
  const { data, loading, error, refetch } = useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const user = data?.me ?? null;
  return { loading, user, error, refetch };
};

export default useGetCurrentUser;
