import React from "react";
import { useColors } from "~riata/hooks";
import { createStackNavigator } from "@react-navigation/stack";
import {
  AttachmentActionSheet,
  IntegrationAttachmentActionSheet,
} from "../attachments";
import BulletinComposeScreen from "./BulletinComposeScreen/BulletinComposeScreen";
import { BulletinDraftScreen } from "./BulletinDraftScreen";
import InboxSelectScreen from "./InboxSelect";
import { BulletinDateSelectActionSheet } from "./BulletinDateSelectActionSheet";
import { BulletinComposeProvider } from "~riata/contexts";

const ComposeStack = createStackNavigator();
const ComposeCoreStack = createStackNavigator();

const BulletinComposeCore = () => {
  return (
    <ComposeCoreStack.Navigator
      screenOptions={{
        cardOverlayEnabled: true,
        cardStyle: { backgroundColor: "transparent", opacity: 1 },
        cardStyleInterpolator: ({ current, next }) => {
          const controller = next ? next : current;
          return {
            cardStyle: {
              opacity: controller.progress.interpolate({
                inputRange: [0, 0.5, 0.75, 1],
                outputRange: [1, 1, 1, 1],
              }),
              transform: [
                {
                  scale: controller.progress.interpolate({
                    inputRange: [0, 0.5, 0.9, 1],
                    outputRange: [1, 1, 1, 1],
                  }),
                },
              ],
            },
            overlayStyle: {
              opacity: controller.progress.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.5],
                extrapolate: "clamp",
              }),
              backdropFilter: "blur(1px)",
            },
            containerStyle: {},
            shadowStyle: {},
          };
        },
        headerShown: false,
        presentation: "transparentModal",
      }}
    >
      <ComposeStack.Screen
        name="BulletinCompose"
        component={BulletinComposeScreen}
      />
      <ComposeStack.Screen
        name="BulletinDraft"
        component={BulletinDraftScreen}
      />
      <ComposeCoreStack.Screen
        name="IntegrationAttachmentActionSheet"
        component={IntegrationAttachmentActionSheet}
        options={{ headerShown: true, presentation: "modal" }}
      />
      <ComposeCoreStack.Screen
        name="AttachmentActionSheet"
        component={AttachmentActionSheet}
      />
      <ComposeCoreStack.Screen
        name="DateSelectActionSheet"
        component={BulletinDateSelectActionSheet}
      />
    </ComposeCoreStack.Navigator>
  );
};

const BulletinComposeStack = () => {
  const { newColors } = useColors();
  return (
    <BulletinComposeProvider>
      <ComposeStack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: newColors.header.main,
          },
          headerShown: false,
        }}
      >
        <ComposeStack.Screen
          name="BulletinComposeCore"
          component={BulletinComposeCore}
        />
        <ComposeStack.Screen
          name="BulletinInboxSelect"
          component={InboxSelectScreen}
        />
      </ComposeStack.Navigator>
    </BulletinComposeProvider>
  );
};

export default BulletinComposeStack;
