import moment from "moment";
import { BulletinStatus } from "~riata/generated/graphql";

const getActionButton = (bulletin) => {
  const isScheduled = !bulletin?.publishedAt && bulletin?.scheduledAt;

  if (isScheduled) {
    return { buttonBgColor: "danger", buttonText: "Delete" };
  }

  if (
    bulletin.status !== BulletinStatus.Unread &&
    bulletin.status !== BulletinStatus.Viewed
  ) {
    return { buttonBgColor: "primary", buttonText: "Dismiss" };
  }

  if (bulletin.isRequired) {
    return { buttonBgColor: "warning", buttonText: "Acknowledge" };
  }

  if (bulletin.isPriority) {
    return { buttonBgColor: "primary", buttonText: "Acknowledge" };
  }

  return { buttonBgColor: "primary", buttonText: "Mark as Read" };
};

const getRecipientsLabel = (recipients) => {
  if (recipients > 1) {
    return "recipients";
  } else {
    return "recipient";
  }
};

const getPercentage = (value) => {
  return value === "0.0" ? "0%" : `${value}%`;
};

const getFromNowDate = (date) => {
  const suffix = moment(date).isBefore() ? " ago" : "";
  return moment(date).fromNow() + suffix;
};

export { getActionButton, getFromNowDate, getPercentage, getRecipientsLabel };
