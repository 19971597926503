import moment from "moment";

type GetCurrentDateTimeParams = {
  resetSeconds?: boolean;
};
type GetCurrentDateTime = (params: GetCurrentDateTimeParams) => Date;

const getCurrentDateTime: GetCurrentDateTime = ({ resetSeconds = true }) => {
  const currentDateTime = new Date(moment.now());
  if (resetSeconds) {
    currentDateTime.setSeconds(0, 0);
  }

  return new Date(currentDateTime);
};

const getFormattedDateTime = (
  dateTime: Date | string,
  format = "MMMM DD YYYY h:mm A"
) => {
  return moment(dateTime).format(format);
};

const getFutureDate = (
  date: string | Date,
  amount: number,
  unit: "days" | "months" | "years"
) => {
  return moment(date).add(amount, unit).toDate();
};

const isFutureDate = (date: string | Date) => {
  return moment(date).isAfter(getCurrentDateTime({ resetSeconds: false }));
};

export {
  getCurrentDateTime,
  getFormattedDateTime,
  getFutureDate,
  isFutureDate,
};
