import { ActionCard } from "./ActionCard";
import { ContentCard } from "./ContentCard";
export { ActionCard, ContentCard };

export { BookmarkCardList } from "./BookmarkCardList";
export { DeckHeader } from "./DeckHeader";
export { PreviewCard } from "./PreviewCard";
export { PreviewCardList } from "./PreviewCardList";

export const getRenderingCard = ({ __typename }) => {
  switch (__typename) {
    case "ActionCard":
      return ActionCard;
    case "ContentCard":
      return ContentCard;
  }
};
