import React from "react";
import OAuth2Login from "react-simple-oauth2-login";
import { LoginButton } from "./LoginButton";
import { useCredentials } from "~riata/hooks";

const config = process.env.RIATA_AUTH_CONFIG as unknown as RiataAuthConfig;

const LoginButtonWrapper = () => {
  const { set } = useCredentials();

  return (
    <OAuth2Login
      redirectUri={process.env.LARIAT_WEB_AUTH}
      responseType="code"
      clientId={config.clientId}
      authorizationUrl={config.serviceConfiguration.authorizationEndpoint}
      isCrossOrigin={true}
      scope="read write"
      onSuccess={(credentials) => {
        set(credentials, { rerender: true });
      }}
      onFailure={console.log}
      render={(args) => <LoginButton onPress={args.onClick} />}
    />
  );
};

export default LoginButtonWrapper;
