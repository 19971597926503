import React from "react";
import { Message } from "~riata/generated/graphql";
import { Row } from "../layout";
import MarkdownContent from "../MarkdownContent";
import { Platform, StyleSheet, View } from "react-native";
import { useColors, useComposeStyles } from "~riata/hooks";

type SystemMessageListItemProps = {
  message: Message;
};

type SystemMessageListItemComponent = (
  props: SystemMessageListItemProps
) => JSX.Element;

const SystemMessageListItem: SystemMessageListItemComponent = ({ message }) => {
  const { newColors } = useColors();
  const content = message.content;

  const contentWrapperStyles = useComposeStyles(styles.contentWrapper, {
    backgroundColor: newColors.message.system.main,
  });

  return (
    <Row style={styles.container} justify="center">
      <View style={contentWrapperStyles}>
        <MarkdownContent
          text={content}
          styleOverrides={{
            text: {
              fontSize: 13,
              lineHeight: 16,
              textAlign: "center",
              color: newColors.message.system.on,
            },
          }}
        />
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 0,
    margin: 0,
    marginVertical: 12,
    marginHorizontal: 16,
  },
  contentWrapper: {
    backgroundColor: "transparent",
    paddingTop: Platform.OS === "web" ? 0 : 5,
    paddingHorizontal: 8,
    borderRadius: 12,
  },
  contentText: {
    fontSize: 13,
    lineHeight: 16,
    fontWeight: "400",
    fontFamily: "Helvetica Neue",
    textAlign: "center",
  },
});

export default React.memo(SystemMessageListItem);
