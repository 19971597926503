import { useEffect } from "react";
import { useMarkBulletinViewed } from "~riata/graphql";

export const useMarkAsViewed = ({ bulletinId, canMarkAsViewed }) => {
  const { execute: markAsViewed } = useMarkBulletinViewed();

  return useEffect(() => {
    // Bulletin has been viewed, acknowledged or marked as read
    if (!canMarkAsViewed) {
      return;
    }

    let timer = setTimeout(() => {
      console.log("Marked as viewed");

      markAsViewed(bulletinId)
        .then(() => {
          console.log("Success");
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 4000);

    return () => clearTimeout(timer);
    // prevent markAsViewed from triggering renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canMarkAsViewed, bulletinId]);
};
