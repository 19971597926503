import React from "react";

// Components
import { Row } from "~riata/components/layout";
import { Text } from "~riata/components/typography";

// Hooks
import { useGetCurrentUser } from "~riata/graphql";
import { useColors } from "~riata/hooks";

// Utils
import { getExpiresAtLabel } from "./helpers";

// Types
import { BulletinExpiresBannerComponent } from "./types";

const BulletinExpiresBanner: BulletinExpiresBannerComponent = ({
  bulletin,
  containerStyles = {},
  justify = "left",
}) => {
  const { user } = useGetCurrentUser();
  const { newColors } = useColors();

  const showExpirationDate = user?.id === bulletin?.creator?.id;
  const expiresAtLabel = getExpiresAtLabel(bulletin?.expires);

  if (showExpirationDate) {
    return (
      <Row
        style={containerStyles}
        justify={justify}
        key={bulletin?.id}
        testID="expiration-date-container"
      >
        <Text
          variant="caption2"
          color={newColors.text[expiresAtLabel.color].main}
        >
          {expiresAtLabel.value}
        </Text>
      </Row>
    );
  }

  return null;
};

export default BulletinExpiresBanner;
