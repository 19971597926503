import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  GenericRedirectInput,
  GenericRedirectPayload,
} from "~riata/generated/graphql";

export const QUERY = gql`
  mutation CreateGenericRedirect($input: GenericRedirectInput!) {
    createGenericRedirect(input: $input) {
      errors {
        field
        messages
      }
      url
    }
  }
`;

export const useCreateGenericRedirect = ({
  destination = "",
}: GenericRedirectInput) => {
  const [mutation, response] = useMutation<
    { createGenericRedirect: GenericRedirectPayload },
    { input: GenericRedirectInput }
  >(QUERY, {
    variables: {
      input: {
        destination:
          destination !== "" ? decodeURIComponent(destination) : null,
      },
    },
  });
  return { execute: mutation, ...response };
};
