import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import DirectChannelAvatar from "./DirectChannelAvatar";
import TeamChannelAvatar from "./TeamChannelAvatar";
import { Channel } from "~riata/generated/graphql";
import { useComposeStyles } from "~riata/hooks";

type ChannelAvatarProps = {
  channel: Channel;
  avatarDisabled?: boolean;
  unread?: boolean;
  showStatus?: boolean;
  style?: { container: ViewStyle };
  size?: string;
};

const ChannelAvatar = ({
  channel,
  unread = false,
  showStatus = true,
  style = { container: {} },
  size = "normal",
  avatarDisabled = false,
}: ChannelAvatarProps) => {
  const channelMembers = channel.members.edges.map((a) => a.node);

  const containerStyles = useComposeStyles(styles.container, style.container);

  return (
    <View style={containerStyles}>
      {!channel.isDirect ? (
        <TeamChannelAvatar channel={channel} unread={unread} size={size} />
      ) : channelMembers.length === 2 ? (
        <DirectChannelAvatar
          channel={channel}
          unread={unread}
          size={size}
          showStatus={showStatus}
          avatarDisabled={avatarDisabled}
        />
      ) : (
        <DirectChannelAvatar
          multiUser={true}
          channel={channel}
          unread={unread}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: null,
    height: "100%",
    justifyContent: "center",
    minHeight: 72,
    width: 72,
    backgroundColor: "transparent",
  },
});

export default ChannelAvatar;
