import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const FIND_COMPANY = gql`
  query getCompany($id: ID!) {
    node(id: $id) {
      ... on Company {
        id
        slug
        name
      }
    }
  }
`;

export const useFindCompany = (id) => {
  const { loading, data, ...response } = useQuery(FIND_COMPANY, {
    variables: { id },
  });
  const company = data?.node || null;
  return { loading, company, ...response };
};
