import { useQuery } from "@apollo/react-hooks";
import { WatchQueryFetchPolicy } from "apollo-client";
import gql from "graphql-tag";
import { HashtagConnection } from "~riata/generated/graphql";

const QUERY = gql`
  query GetHashtag($filter: HashtagFilters) {
    getHashtags(filter: $filter) {
      edges {
        node {
          id
          messageCount
          bulletinCount
        }
      }
    }
  }
`;

type ExpectedResponse = {
  getHashtags?: HashtagConnection;
};

type GetHashtagProps = {
  name?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
};

// TODO Make this so we have something more stable to grab hold of
const useGetHashtag = ({
  name,
  fetchPolicy = "cache-and-network",
}: GetHashtagProps) => {
  const { data, ...response } = useQuery<ExpectedResponse>(QUERY, {
    variables: { filter: { query: name } },
    fetchPolicy,
  });
  const hashtag = data?.getHashtags?.edges[0]?.node ?? null;

  // FIXME What happens with multiple matchces?
  // FIXME What happens when nothing matches?

  return {
    hashtag,
    data,
    ...response,
  };
};

export default useGetHashtag;
