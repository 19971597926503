import React from "react";
import { StyleSheet, View } from "react-native";

import { useFileChooser } from "~riata/hooks";
import ActionSheetItem from "../ActionSheetItem";
import { ActionSheetModal } from "../misc/ActionSheetModal";
import { ModalToast } from "../toasts";

const FileChooserModal = ({
  setResult,
  dismiss,
  multipleImages = true,
  ...props
}) => {
  const {
    error: fileChooserError,
    showGallery,
    showCamera,
  } = useFileChooser({
    setResult,
    showMultiple: multipleImages,
    circleCrop: true,
  });
  return (
    <ActionSheetModal onToggle={dismiss} {...props}>
      <View style={styles.container}>
        <ActionSheetItem
          icon="square_menu"
          title="Choose From Gallery"
          action={() => {
            showGallery();
          }}
        />
        <ActionSheetItem
          icon="camera"
          title="Use Camera"
          action={() => {
            showCamera();
          }}
        />
        <ActionSheetItem
          icon="close"
          title="Cancel"
          action={() => {
            dismiss();
          }}
        />
      </View>
      {fileChooserError && (
        <ModalToast onDismiss={() => {}} dark text={fileChooserError.message} />
      )}
    </ActionSheetModal>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 350,
    backgroundColor: "white",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingVertical: 16,
  },
});

export default FileChooserModal;
