import React, { useMemo } from "react";
import {
  KeyboardAvoidingView,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import {
  AppHeader,
  Button,
  EditProfileAvatar,
  EditProfileError,
  EditProfileRow,
  FileChooserModal,
  FormContainer,
  HeaderActionButton,
  OffDutyLabel,
  RodioLoader,
  Row,
  Text,
} from "~riata/components";
import { useGetCurrentUser, useModifyUser } from "~riata/graphql";
import { navigate } from "~riata/navigation";
import { useColors, useComposeStyles } from "~riata/hooks";
import { DEFAULT_STATE, useEditProfileState } from "./UserProfileScreen.state";
import {
  isNotEmpty,
  formIsValid,
  hasBeenEdited,
  getProfilePicture,
  getEditedFields,
  phoneIsValid,
} from "./helpers";
import { Platform } from "react-native";
import { Keyboard } from "react-native";

const UserProfileScreen = ({ navigation }) => {
  const { user, error, loading } = useGetCurrentUser();
  const { actions, state } = useEditProfileState({ user });
  const { execute: modifyUser } = useModifyUser();
  const { newColors } = useColors();

  const isValid = formIsValid(state);

  const isEdited = hasBeenEdited(user, state);

  const canSave = isValid && isEdited;

  const canEditPhone = user.company.inAppCall;
  const offDutyIndicator = useMemo(() => {
    const offDutyEnabled = user.company.offDuty;

    if (!offDutyEnabled) {
      return null;
    }

    return (
      <Row justify="center" style={styles.offDutyContainer}>
        <OffDutyLabel
          offDuty={user.offDuty}
          fontWeight="extraBold"
          fontVariant="title7"
        />
      </Row>
    );
  }, [user]);

  const setFileChooserResult = (fileChooserResult) => {
    actions.updateFileChooserResult(fileChooserResult);
  };

  const onSave = () => {
    actions.setLoading(true);

    const file = getProfilePicture(state.fileChooserResult, user.id);
    const editedFields = getEditedFields(state);

    modifyUser({ user, file, ...editedFields })
      .then((result) => {
        const user = result.data?.modifyUser?.user;
        if (user) {
          actions.resetState(user);
        } else {
          actions.resetState(DEFAULT_STATE);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => actions.setLoading(false));
  };

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  if (loading) {
    return <RodioLoader />;
  }

  if (error || user === null) {
    return <EditProfileError error={error} navigation={navigation} />;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={{ flex: 1 }}
      keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 80}
    >
      <SafeAreaView style={containerStyles}>
        <AppHeader
          backgroundColor={newColors.header.main}
          left={
            <HeaderActionButton
              variant="drawer"
              onPress={() => navigation.toggleDrawer()}
            />
          }
          right={
            <HeaderActionButton
              variant="save"
              disabled={!canSave}
              loading={state.loading}
              onPress={onSave}
            />
          }
        />
        <FormContainer onPress={Keyboard.dismiss}>
          <View
            style={{
              justifyContent: "flex-end",
            }}
          >
            <EditProfileAvatar
              user={user}
              onPress={() => actions.setModal(true)}
              fileChooserResult={state.fileChooserResult}
            />
            <Row justify="center" style={styles.titleContainer}>
              <Text variant="title1">{user.displayName}</Text>
            </Row>
            {offDutyIndicator}
            <Row align="center" justify="between" style={styles.resetContainer}>
              {user.username && (
                <Text variant="headline" color={newColors.text.footnote.main}>
                  @{user.username}
                </Text>
              )}
              {isEdited && (
                <Button
                  onPress={() => actions.resetState(user)}
                  style={styles.resetFormButton}
                >
                  <Text variant="body" color={newColors.text.error.main}>
                    Reset
                  </Text>
                </Button>
              )}
            </Row>
            <EditProfileRow
              name="First Name"
              value={state.firstName.value}
              error={state.firstName.error}
              containerStyles={styles.editProfileItem}
              autoCapitalize="words"
              editable
              onEdit={(firstName) => {
                const error = isNotEmpty(user?.firstName, firstName);
                actions.updateFirstName(firstName, error);
              }}
            />
            <EditProfileRow
              name="Last Name"
              value={state.lastName.value}
              error={state.lastName.error}
              containerStyles={styles.editProfileItem}
              autoCapitalize="words"
              editable
              onEdit={(lastName) => {
                const error = isNotEmpty(user?.lastName, lastName);
                actions.updateLastName(lastName, error);
              }}
            />
            <EditProfileRow
              name="Display Name"
              value={state.displayName.value}
              error={state.displayName.error}
              containerStyles={styles.editProfileItem}
              autoCapitalize="words"
              editable
              onEdit={(displayName) => {
                const error = isNotEmpty(user?.displayName, displayName);
                actions.updateDisplayName(displayName, error);
              }}
            />
            <EditProfileRow
              name="Job Title"
              value={state.jobTitle.value}
              error={state.jobTitle.error}
              containerStyles={styles.editProfileItem}
              autoCapitalize="words"
              editable
              onEdit={(jobTitle) => {
                const error = isNotEmpty(user?.jobTitle, jobTitle);
                actions.updateJobTitle(jobTitle, error);
              }}
            />
            {canEditPhone && (
              <EditProfileRow
                name="Phone"
                value={state.phone.value}
                error={state.phone.error}
                containerStyles={styles.editProfileItem}
                autoCapitalize="words"
                editable
                onEdit={(phone) => {
                  const error = phoneIsValid(phone);
                  actions.updatePhone(phone, error);
                }}
              />
            )}

            <Button
              color="primary"
              style={styles.changePasswordButton}
              onPress={() => {
                navigate("ChangePassword");
              }}
            >
              Change Password
            </Button>
          </View>
        </FormContainer>
      </SafeAreaView>
      <FileChooserModal
        multipleImages={false}
        setResult={setFileChooserResult}
        dismiss={() => actions.setModal(false)}
        isVisible={state.modal}
      />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerRightContainer: {
    display: "flex",
    flexDirection: "row",
  },
  resetContainer: {
    margin: 16,
  },
  resetFormButton: {
    marginRight: 8,
  },
  editProfileItem: {
    marginHorizontal: 16,
    marginVertical: 8,
  },
  changePasswordButton: {
    padding: 8,
    borderRadius: 16,
    marginTop: 16,
    marginHorizontal: 16,
  },
  titleContainer: {
    marginTop: 16,
  },
  offDutyContainer: {
    marginTop: 16,
  },
});

export default UserProfileScreen;
