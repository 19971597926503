import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useUpdateBulletin } from "./UpdateBulletin";
import { UpdateBulletinInput } from "~riata/generated/graphql";
import { GET_BULLETINS } from "~riata/graphql/queries/GetBulletins";

const POST_BULLETIN = gql`
  mutation PostBulletin($input: PostDraftBulletinInput) {
    postDraftBulletin(input: $input) {
      bulletin {
        id
        name
      }
    }
  }
`;

const usePostBulletin = () => {
  const { execute: updateBulletin } = useUpdateBulletin();
  const [mutation, response] = useMutation(POST_BULLETIN);

  const successful = response?.data?.postDraftBulletin ? true : null;

  const execute = (input: UpdateBulletinInput, includeExpirationDate) => {
    // Update any existing modification in the bulletin before posting
    updateBulletin({ ...input, isDraft: false }, includeExpirationDate).then(
      (response) => {
        const bulletinId = response.data?.updateBulletin.bulletin.id;
        mutation({
          variables: { input: { id: bulletinId } },
          refetchQueries: [
            {
              query: GET_BULLETINS,
              variables: { id: bulletinId },
            },
            {
              query: GET_BULLETINS,
              variables: { filters: { status: "SENT", isDraft: true } },
            },
            {
              query: GET_BULLETINS,
              variables: { filters: { status: "SENT", isDraft: false } },
            },
          ],
          awaitRefetchQueries: true,
        });
      }
    );
  };

  return { mutation, response, execute, successful };
};

export { usePostBulletin };
