import React, { useCallback, useState } from "react";
import { RefreshControl } from "react-native";
import { ApolloQueryResult, OperationVariables } from "apollo-boost";
import { OperationData } from "@apollo/react-hooks/lib/data/OperationData";
import { Sentry } from "~riata/utils/sentry";

// TODO This should be removed / merged with useRefresh
//      Please use useRefresh() directly
const useRefreshControl = (
  fetches: Array<
    (
      variables?: OperationVariables
    ) => Promise<ApolloQueryResult<OperationData>>
  >
) => {
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    const refetchPromises = fetches
      .filter((a) => a instanceof Function)
      .map((a) => a());
    Promise.all(refetchPromises)
      .then(() => {
        setRefreshing(false);
      })
      .catch(Sentry.captureException);
  }, [fetches]);

  return <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />;
};

export default useRefreshControl;
