import React from "react";
import { SectionList } from "react-native";

import { IntegrationListItem } from "./IntegrationListItem";
import IntegrationListHeader from "./IntegrationListHeader";
import { Integration } from "~riata/generated/graphql";
import { useErrorToast } from "~riata/hooks";
import { RodioLoader } from "../misc";

const IntegrationList = ({
  integrations,
  error,
  loading,
  hasNextPage,
  refreshControl = null,
  fetchNext,
}) => {
  const { RodioError, showToast, isDismissed } = useErrorToast(error);
  const fetchMore = !loading && hasNextPage;
  const sections = [
    {
      title: "Integrations",
      data: integrations,
    },
  ];

  // TODO Empty state
  if (error && !isDismissed) {
    showToast("An error occurred fetching integrations");
  }

  if (loading && !integrations) {
    return <RodioLoader />;
  }

  return (
    <>
      <SectionList
        stickySectionHeadersEnabled={false}
        removeClippedSubviews
        sections={sections}
        keyExtractor={(item: Integration) => item.id}
        refreshControl={refreshControl}
        renderItem={({ item }) => <IntegrationListItem integration={item} />}
        renderSectionHeader={({ section: { data, title } }) => {
          return data.length > 0 && sections.length > 1 ? (
            <IntegrationListHeader title={title} />
          ) : null;
        }}
        onEndReachedThreshold={0.3}
        onEndReached={fetchMore && fetchNext}
        maxToRenderPerBatch={20}
        updateCellsBatchingPeriod={200}
        windowSize={5}
        initialNumToRender={20}
      />
      <RodioError />
    </>
  );
};

export default IntegrationList;
