import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import { Text } from "./typography";
import { UserAvatar } from "./avatars";
import { useGetCurrentUser } from "~riata/graphql";
import { Button } from "./inputs/Button";
import { useLinkTo } from "@react-navigation/native";
import { Row } from "./layout";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const ProfileDrawerItem = () => {
  const { user } = useGetCurrentUser();
  const linkTo = useLinkTo();
  const { newColors } = useColors();
  const insets = useSafeAreaInsets();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.surface.main,
    borderColor: newColors.separator.light,
    marginTop: Platform.OS === "ios" ? -insets.top : -(insets.top - 16),
  });

  if (!user) {
    return null;
  }

  return (
    <Button onPress={() => linkTo("/me")} style={containerStyles}>
      <Row>
        <UserAvatar user={user} size={40} />
        <View style={styles.content}>
          <Text variant="headline">{user.displayName}</Text>
          <Text variant="caption1" color={newColors.text.footnote.main}>
            {user.jobTitle}
          </Text>
        </View>
      </Row>
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 56,
    paddingHorizontal: 8,
    justifyContent: "center",
    borderBottomWidth: 1,
    paddingBottom: 16,
  },
  content: {
    marginLeft: 8,
  },
});

export default ProfileDrawerItem;
