import { useQuery } from "@apollo/react-hooks";
import { QueryResult } from "@apollo/react-common";
import { WatchQueryFetchPolicy } from "apollo-client";
import gql from "graphql-tag";
import { ReactionType } from "~riata/generated/graphql";
const QUERY = gql`
  query GetPrimaryReactionTypes(
    $after: String
    $first: Int
    $before: String
    $last: Int
  ) {
    getPrimaryReactionTypes(
      after: $after
      first: $first
      before: $before
      last: $last
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type GetPrimaryReactionTypesProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type GetPrimaryReactionTypes = (
  props?: GetPrimaryReactionTypesProps
) => QueryResult & {
  list?: Array<ReactionType>;
};
export const useGetPrimaryReactionTypes: GetPrimaryReactionTypes = (props) => {
  const fetchPolicy = props?.fetchPolicy ?? "cache-and-network";
  const { data, ...response } = useQuery(QUERY, { fetchPolicy });
  let list = data?.getPrimaryReactionTypes?.edges?.map((a) => a.node) || [];
  return { list, data, ...response };
};
