import React from "react";
import { StyleSheet, TextInputProps, View, ViewStyle } from "react-native";

import { Text } from "~riata/components/typography";
import { TextInput } from "../inputs";
import { Row } from "../layout";
import { useColors, useComposeStyles } from "~riata/hooks";

type EditProfileRowProps = TextInputProps & {
  name: string;
  value?: string;
  error?: string | null;
  onEdit?: (text: string) => void;
  containerStyles?: ViewStyle;
};

const EditProfileRow = ({
  name,
  value,
  error = null,
  editable = false,
  autoCapitalize = "none",
  onEdit = () => console.log("Not Implemented"),
  secureTextEntry = false,
  containerStyles = {},
}: EditProfileRowProps) => {
  const { newColors } = useColors();

  const inputStyles = useComposeStyles(styles.input, {
    color: editable ? newColors.text.regular.main : newColors.text.caption.main,
    backgroundColor: newColors.inputBar.main,
  });

  const inputContainerStyles = useComposeStyles(styles.inputContainer, {
    backgroundColor: newColors.inputBar.main,
    shadowColor: newColors.shadow.main,
  });

  return (
    <Row style={containerStyles}>
      <View style={styles.label}>
        <Text variant="headline">{name}</Text>
      </View>
      <View style={styles.content}>
        <TextInput
          onChangeText={(text) => {
            onEdit(text);
          }}
          editable={editable}
          containerStyles={inputContainerStyles}
          inputStyles={inputStyles}
          autoCapitalize={autoCapitalize}
          autoCorrect={false}
          value={value}
          secureTextEntry={secureTextEntry}
        />
        {error && (
          <Text variant="footnote" color={newColors.text.error.main}>
            {error}
          </Text>
        )}
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  input: {
    padding: 0,
    height: 20,
    fontSize: 16,
    lineHeight: 20,
  },
  inputContainer: {
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 10,
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 1,
    elevation: 2,
  },
  label: {
    flex: 1,
  },
  content: {
    flex: 2,
  },
});

export default React.memo(EditProfileRow);
