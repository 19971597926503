import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";

import {
  AppHeader,
  DebugItem,
  Text,
  SafeAreaView,
  HeaderActionButton,
} from "~riata/components";
import { useDebugInfo } from "~riata/graphql";
import { getUniqueIdSync } from "react-native-device-info";
import { secureGet } from "~riata/utils/storage";

const DebugScreen = ({ navigation }) => {
  const { debugInfo } = useDebugInfo();
  const [creds, setCreds] = useState();

  useEffect(() => {
    secureGet().then((creds) => {
      setCreds(creds);
    });
  }, []);

  const subscribedTopics = debugInfo.subscribedTopics.map((item) => (
    <Text selectable>{item}</Text>
  ));

  return (
    <SafeAreaView bottom={false}>
      <AppHeader
        left={
          <HeaderActionButton
            variant="drawer"
            onPress={() => navigation.toggleDrawer()}
          />
        }
        title="Debug"
      />
      <ScrollView style={styles.container}>
        <DebugItem label={"Device ID"} value={getUniqueIdSync()} />
        <DebugItem label={"Push Token"} value={debugInfo.pushToken} />
        <DebugItem label={"Subscribed Topics"} value={subscribedTopics} />
        {creds && (
          <DebugItem
            label={"Stored Credentials"}
            value={JSON.stringify(creds, undefined, 2)}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
});

export default DebugScreen;
