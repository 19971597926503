import React, { useState } from "react";
import { StyleSheet, Switch, TextStyle } from "react-native";

import { Row } from "./layout";
import { Text } from "./typography";
import { Button } from "./inputs/Button";

type ToggleActionSheetItemStyle = {
  text?: TextStyle;
};

type ToggleActionSheetItemProps = {
  children: string | JSX.Element;
  isEnabled: boolean;
  onToggle: () => void;
  style?: ToggleActionSheetItemStyle;
};

type ToggleActionSheetItemComponent = (
  props: ToggleActionSheetItemProps
) => JSX.Element;

const ToggleActionSheetItem: ToggleActionSheetItemComponent = ({
  onToggle,
  children,
  isEnabled = true,
}) => {
  const [enabled, setEnabled] = useState(isEnabled);
  const toggleSwitch = () => {
    setEnabled(!enabled);
    onToggle();
  };

  if (typeof children === "string") {
    children = (
      <Text variant="headline" fontWeight="regular">
        {children}
      </Text>
    );
  }

  return (
    <Row style={styles.container} justify="between">
      <Button onPress={toggleSwitch}>{children}</Button>
      <Switch value={isEnabled} onValueChange={toggleSwitch} />
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 50,
    paddingHorizontal: 16,
  },
});

export default ToggleActionSheetItem;
