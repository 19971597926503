import React from "react";

import { CustomIcon } from "../../icons";
import { AvatarContainer } from "../AvatarContainer";
import { useColors } from "~riata/hooks";

type TeamAvatarProps = {
  id: string;
  size?: "normal" | "regular";
};

type TeamAvatarComponent = (props: TeamAvatarProps) => JSX.Element;

const SIZE_MAP = {
  large: 59.5,
  normal: 32.5,
  regular: 20.0,
  small: 18,
};

const TeamAvatar: TeamAvatarComponent = ({ id, size = "normal" }) => {
  const { newColors } = useColors();
  // TODO support large / small

  const avatarSize = size === "regular" ? "normal" : size;
  const actualSize = SIZE_MAP[size];

  return (
    <AvatarContainer id={id} size={avatarSize}>
      <CustomIcon
        name="channel"
        color={newColors.icon.primary.main}
        size={actualSize}
      />
    </AvatarContainer>
  );
};

export default TeamAvatar;
