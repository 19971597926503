import React, { useLayoutEffect } from "react";
import { StyleSheet, View } from "react-native";
import {
  Row,
  HeaderActionButton,
  CustomIcon,
  ChatHeaderTitle,
} from "~riata/components";
import { buildLinkComponent, generateOnPressHook } from "~riata/links/_utils";
import { HEADER_ICON_SIZE } from "~riata/theme";
import { isWeb } from "~riata/theme/common";

export const useHeaderLayout = ({ channel, navigation, goBack, refetch }) =>
  useLayoutEffect(() => {
    if (!channel) {
      return;
    }
    const useChannelInfoSheetLink = generateOnPressHook("/channel/{{id}}/info");
    const ChannelInfoSheetLink = buildLinkComponent(useChannelInfoSheetLink);

    navigation.setOptions({
      headerLeft: () => {
        return (
          <View style={styles.leftContainer}>
            <HeaderActionButton
              variant="back"
              onPress={() => goBack(navigation)}
              iconSize={HEADER_ICON_SIZE}
            />
          </View>
        );
      },
      headerRight: () => {
        return (
          <Row style={styles.rightContainer}>
            {isWeb && (
              <>
                <HeaderActionButton
                  variant="refresh"
                  onPress={() => refetch()}
                  iconSize={HEADER_ICON_SIZE}
                />
                <View style={styles.spaceDivider}></View>
              </>
            )}
            <ChannelInfoSheetLink node={channel}>
              <Row justify="center" align="center" style={styles.rightIcon}>
                <CustomIcon
                  name="menu"
                  size={HEADER_ICON_SIZE}
                  style={{ transform: [{ rotate: "90deg" }] }}
                />
              </Row>
            </ChannelInfoSheetLink>
          </Row>
        );
      },
      headerTitle: () => {
        return <ChatHeaderTitle channel={channel} />;
      },
    });
  }, [navigation, channel, refetch, goBack]);

const styles = StyleSheet.create({
  rightContainer: {
    paddingRight: 16,
  },
  leftContainer: {
    paddingLeft: 16,
  },
  rightIcon: {
    minWidth: 18,
  },
  spaceDivider: {
    width: 8,
  },
});
