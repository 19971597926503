import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { RodioLoader, Separator } from "~riata/components/misc";
import { BulletinRecipient } from "~riata/generated/graphql";
import BulletinRecipientCard from "./BulletinRecipientCard";

type BulletinRecipientsListProps = {
  recipientsList: Array<BulletinRecipient>;
  canFetchMore: boolean;
  fetchNext: () => void;
  isLoading: boolean;
};

type BulletinRecipientsListComponent = (
  props: BulletinRecipientsListProps
) => JSX.Element;

const renderItem = ({ item }) => {
  return <BulletinRecipientCard recipient={item} />;
};

const renderSeparator = () => {
  return <Separator />;
};

const getItemLayout = (_, index) => {
  // 50 Is based of BulletinRecipientCard padding(10) and avatar's height(40)
  return {
    length: 50,
    offset: 50 * (index + 1),
    index,
  };
};

const BulletinRecipientsList: BulletinRecipientsListComponent = ({
  recipientsList,
  isLoading,
  canFetchMore,
  fetchNext,
}) => (
  <FlatList
    removeClippedSubviews
    style={styles.cardsList}
    data={recipientsList}
    keyExtractor={(item) => item.id}
    onEndReached={canFetchMore && fetchNext}
    onEndReachedThreshold={0}
    ListFooterComponent={isLoading ? <RodioLoader /> : null}
    ItemSeparatorComponent={renderSeparator}
    getItemLayout={getItemLayout}
    renderItem={renderItem}
    maxToRenderPerBatch={20}
    updateCellsBatchingPeriod={200}
    windowSize={5}
    initialNumToRender={20}
  />
);

const styles = StyleSheet.create({
  cardsList: {
    flex: 1,
  },
});

export default BulletinRecipientsList;
