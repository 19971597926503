import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { Row } from "~riata/components/layout";
import { Button } from "~riata/components/inputs";
import { CustomIcon } from "~riata/components/icons";
import { Text } from "~riata/components/typography";

// Hooks
import { useColors, useComposeStyles } from "~riata/hooks";

// Types
import { ChannelInfoActionsComponent } from "./types";

const ChannelInfoActions: ChannelInfoActionsComponent = ({
  actions,
  containerStyles = {},
}) => {
  const { newColors } = useColors();

  const composedContainerStyles = useComposeStyles(containerStyles, {
    gap: actions.length <= 2 ? 20 : 180 / actions.length,
    ...styles.container,
  });

  const mappedActions = actions.map((action, index) => {
    const actionColor = action.color || newColors.text.regular.main;

    if (actions.length <= 2) {
      return (
        <View key={`${action.label}-${index}`}>
          <Button onPress={action.onPressHandler}>
            <Row justify="center" align="center" style={styles.verticalAction}>
              <CustomIcon
                name={action.iconName}
                size={20}
                color={actionColor}
              />
              <Text
                variant="newSubhead"
                style={styles.label}
                color={actionColor}
              >
                {action.label}
              </Text>
            </Row>
          </Button>
        </View>
      );
    }

    return (
      <View key={`${action.label}-${index}`}>
        <Button onPress={action.onPressHandler} style={styles.button}>
          <Row justify="center">
            <CustomIcon name={action.iconName} size={20} color={actionColor} />
          </Row>
          <Row justify="center" style={styles.labelWrapper}>
            <Text variant="newSubhead" style={styles.label} color={actionColor}>
              {action.label}
            </Text>
          </Row>
        </Button>
      </View>
    );
  });

  return (
    <Row style={composedContainerStyles} justify="center">
      {mappedActions}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {},
  verticalAction: { gap: 12 },
  labelWrapper: {
    marginTop: 12,
  },
  label: {
    lineHeight: 16,
  },
  button: {
    alignItems: "center",
  },
});

export default ChannelInfoActions;
