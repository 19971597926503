import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_BULLETINS } from "~riata/graphql/queries/GetBulletins";
import { UpdateBulletinInput } from "./types";
import { getCurrentDateTime, getFutureDate } from "~riata/utils/date";
import { GET_BULLETIN } from "~riata/graphql/queries/GetBulletin";
import { datesAreValid } from "./helpers";
import { useToastMessage } from "~riata/hooks";

export const UPDATE_BULLETIN = gql`
  mutation UpdateBulletin($input: UpdateBulletinInput) {
    updateBulletin(input: $input) {
      bulletin {
        id
        name
      }
    }
  }
`;

export const useUpdateBulletin = () => {
  const [mutation, response] = useMutation(UPDATE_BULLETIN);
  const { showErrorToast } = useToastMessage();

  const successful = response?.data?.updateBulletin ? true : null;

  const execute = (input: UpdateBulletinInput, includeExpirationDate) => {
    const { isDraft, ...workingInput } = input;

    if (!workingInput.expires && !isDraft && includeExpirationDate) {
      // No expiration date has been set, so we'll set it to 6 months from now
      const workingDate = workingInput.scheduledAt
        ? workingInput.scheduledAt
        : getCurrentDateTime({ resetSeconds: true });

      const expirationDate = getFutureDate(workingDate, 6, "months");

      workingInput.expires = expirationDate.toISOString();
    }

    if (
      !datesAreValid(
        workingInput.scheduledAt,
        workingInput.expires,
        showErrorToast
      )
    ) {
      throw new Error("Invalid dates");
    }

    return mutation({
      variables: { input: workingInput },
      refetchQueries: [
        {
          query: GET_BULLETIN,
          variables: { id: workingInput.id },
        },
        {
          query: GET_BULLETINS,
          variables: { filters: { status: "SENT", isDraft: true } },
        },
      ],
      awaitRefetchQueries: true,
    });
  };
  return { mutation, response, execute, successful };
};
