import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  IntegrationRedirectInput,
  IntegrationRedirectPayload,
} from "~riata/generated/graphql";

export const QUERY = gql`
  mutation CreateIntegrationRedirect($input: IntegrationRedirectInput!) {
    createIntegrationRedirect(input: $input) {
      errors {
        field
        messages
      }
      integration
      url
    }
  }
`;

const useCreateIntegrationRedirect = ({
  integration = null,
  destination = "",
}: IntegrationRedirectInput) => {
  const [mutation, response] = useMutation<
    { createIntegrationRedirect: IntegrationRedirectPayload },
    { input: IntegrationRedirectInput }
  >(QUERY, {
    variables: {
      input: {
        integration,
        destination:
          destination !== "" ? decodeURIComponent(destination) : null,
      },
    },
  });
  return { execute: mutation, ...response };
};

export default useCreateIntegrationRedirect;
