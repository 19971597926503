import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

export const RodioLoader = ({ large = false }) => {
  return (
    <View accessibilityLabel="Loading screen" style={styles.container}>
      <ActivityIndicator
        testID="actual-loader"
        size={large ? "large" : "small"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  normal: {
    height: 50,
    width: 50,
  },

  large: {
    height: 100,
    width: 100,
  },
});

export default RodioLoader;
