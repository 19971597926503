import React, { FC } from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import { useColors } from "~riata/hooks";

type SeparatorProps = ViewProps & {
  color?: string;
};

const Separator: FC<SeparatorProps> = ({ color, ...props }) => {
  const { newColors } = useColors();

  const overrideStyles = props.style;

  props.style = StyleSheet.compose(props.style, {
    width: "100%",
    height: 1,
    backgroundColor: color || newColors.separator.main,
  });

  props.style = StyleSheet.compose(props.style, overrideStyles);

  return <View style={props.style} {...props} />;
};

export default Separator;
