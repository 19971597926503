import { createContext } from "react";

const _registry = {};
const _persistent = {};

const remove = (key) => {
  if (_registry[key]) {
    delete _registry[key];
  } else {
    // TODO What should we do if it doesn't exist?
    console.warn(`Unable to find ${key} in registry`);
  }
};

// FIXME What if we have a collision of keys?
export const RegistryContext = createContext({
  add: (key, value) => {
    _registry[key] = value;
  },
  get: (key, _del = true) => {
    // TODO Should we error when it doesn't exist?
    if (_registry[key]) {
      const value = _registry[key];
      if (_del) {
        console.debug(`removing ${key}`);
        remove(key);
      }
      return value;
    }

    if (_persistent[key]) {
      return _persistent[key];
    }
  },
  remove,
  set: (key, value) => {
    _persistent[key] = value;
  },
});
