import React from "react";
import RodioAlert from "./RodioAlert";

const RodioError = ({ isVisible, error, onDismiss }) => {
  if (!error) {
    return null;
  }
  return (
    <RodioAlert
      isVisible={isVisible}
      title="Error"
      body={`${error.message}`}
      variant="danger"
      buttonText="OK"
      onDismiss={onDismiss}
    />
  );
};

RodioError.withProps = (props) => () => <RodioError {...props} />;

export default RodioError;
