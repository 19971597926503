import React, { createContext, useEffect, useReducer, useRef } from "react";
import { Notifications, Notification } from "react-native-notifications";
import { NotificationCompletion } from "react-native-notifications/lib/dist/interfaces/NotificationCompletion";
import { NotificationActionResponse } from "react-native-notifications/lib/dist/interfaces/NotificationActionResponse";
import { check, PERMISSIONS, request, RESULTS } from "react-native-permissions";
import DeviceInfo from "react-native-device-info";

// Utils
import { isWeb } from "~riata/theme/common";
import { Sentry } from "~riata/utils/sentry";
import { Platform } from "react-native";

const _registerAndroidNotifications = () => {
  Notifications.registerRemoteNotifications();
  Notifications.setNotificationChannel({
    channelId: "messaging",
    name: "Messaging",
    importance: 4,
    description:
      "Controls incoming notifications for private and group messages",
    enableLights: false,
    enableVibration: true,
    showBadge: true,
  });
};

const NotificationsListenerContext = createContext({
  initializeForegroundSubscriber: () => console.debug("Not Implemented"),
  initializeBackgroundSubscriber: (linkTo) => console.debug("Not Implemented"),
  initializeKillStateListener: (linkTo) => console.debug("Not Implemented"),
  foregroundSubscriber: null,
  backgroundSubscriber: null,
});

const NotificationsListener = ({ children }) => {
  const [hasInitializedCore, setHasInitializedCore] = useReducer(
    () => true,
    false
  );
  const subscribers = useRef({
    foreground: null,
    background: null,
  });

  const initializeForegroundSubscriber = () => {
    if (subscribers.current.foreground) {
      console.debug("Subscriber Already Initialized");
      return;
    }

    const subscriber =
      Notifications.events().registerNotificationReceivedForeground(
        (
          notification: Notification,
          completion: (response: NotificationCompletion) => void
        ) => {
          // TODO: Handle Foreground notifications
          console.warn(
            "@useRegisterNotifications > Received Notification in Foreground"
          );

          try {
            completion({ alert: true, sound: true, badge: false });
          } catch (error) {
            console.error("Completing Foreground Notification Failed", error);
            Sentry.captureException(error);
          }
        }
      );

    subscribers.current.foreground = subscriber;
  };

  const initializeBackgroundSubscriber = (linkTo) => {
    if (subscribers.current.background) {
      console.debug("Subscriber Already Initialized");
      return;
    }

    const subscriber = Notifications.events().registerNotificationOpened(
      (
        notification: Notification,
        completion: () => void,
        action: NotificationActionResponse
      ) => {
        try {
          const navigationPath = notification.payload.path;

          if (navigationPath) {
            linkTo(navigationPath);
          }

          completion();
        } catch (error) {
          console.error("Completing Background Notification Failed", error);
          Sentry.captureException(error);
        }
      }
    );

    subscribers.current.background = subscriber;
  };

  const initializeKillStateListener = (linkTo) => {
    console.debug("Initialize Kill State Listener");

    Notifications.getInitialNotification()
      .then((notification) => {
        if (!notification?.payload) {
          return;
        }

        const navigationPath = notification.payload.path;

        if (navigationPath) {
          linkTo(navigationPath);
        }
      })
      .catch((error) => {
        console.error("getInitialNotification() failed", error);
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    // check for permissions
    if (Platform.OS === "android") {
      const androidVersion = DeviceInfo.getSystemVersion();

      if (Number(androidVersion) >= 13) {
        // Notification permissions are revoked by default in android 13+
        check(PERMISSIONS.ANDROID.POST_NOTIFICATIONS)
          .then((result) => {
            switch (result) {
              case RESULTS.BLOCKED:
              case RESULTS.UNAVAILABLE:
                /**
                 * Blocked:
                 *  User has blocked permissions and can't be requested again.
                 *  The user will have to grant them manually in setting.
                 * Unavailable:
                 *  The device has no capability to provide the requested permission.
                 */
                break;
              case RESULTS.DENIED:
                // No permission, but it can be requested
                request(PERMISSIONS.ANDROID.POST_NOTIFICATIONS)
                  .then((result) => {
                    switch (result) {
                      case RESULTS.BLOCKED:
                      case RESULTS.DENIED:
                      case RESULTS.UNAVAILABLE:
                        // User has manually denied permission to the requested resource
                        break;
                      case RESULTS.LIMITED:
                      case RESULTS.GRANTED:
                        _registerAndroidNotifications();
                        break;
                    }
                  })
                  .catch((error) => {
                    Sentry.captureException(error);
                  })
                  .finally(() => setHasInitializedCore());
                break;
              case RESULTS.LIMITED:
              case RESULTS.GRANTED:
                // Permission has been previously granted by the user
                _registerAndroidNotifications();
                break;
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
          })
          .finally(() => setHasInitializedCore());
      } else {
        // Notification permissions are granted by default in previous versions
        _registerAndroidNotifications();
        setHasInitializedCore();
      }
    }

    if (Platform.OS === "ios") {
      Notifications.registerRemoteNotifications();
      setHasInitializedCore();
    }

    if (Platform.OS === "web") {
      setHasInitializedCore();
    }
  }, []);

  const value = {
    initializeForegroundSubscriber: isWeb
      ? () => console.debug("Not Supported in Web Env")
      : initializeForegroundSubscriber,
    initializeBackgroundSubscriber: isWeb
      ? () => console.debug("Not Supported in Web Env")
      : initializeBackgroundSubscriber,
    initializeKillStateListener: isWeb
      ? () => console.debug("Not Supported in Web Env")
      : initializeKillStateListener,
    foregroundSubscriber: subscribers.current.foreground,
    backgroundSubscriber: subscribers.current.background,
  };

  return (
    <NotificationsListenerContext.Provider value={value}>
      {hasInitializedCore ? children : null}
    </NotificationsListenerContext.Provider>
  );
};

export default NotificationsListener;
export { NotificationsListenerContext };
