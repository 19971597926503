import { CommonActions } from "@react-navigation/routers";
import { User } from "~riata/generated/graphql";

const getFilteredUserList = (users: Array<User>, currentUser: User) => {
  if (!users || users.length <= 0) {
    return [];
  }

  if (currentUser) {
    return users.filter((user) => user.id !== currentUser.id);
  }

  return users;
};

const navigateToNewChannel = (navigation, channelId: string) => {
  navigation.dispatch(
    CommonActions.reset({
      index: 2,
      routes: [
        { name: "Home" },
        { name: "Messages" },
        {
          name: "Conversation",
          params: {
            screen: "ConversationDetail",
            params: { channel: channelId },
          },
        },
      ],
    })
  );
};

export { getFilteredUserList, navigateToNewChannel };
