import React, { useEffect, useMemo } from "react";
import { RodioLoader } from "~riata/components";
import { CredentialsContext } from "~riata/contexts/CredentialsContext";
import { LoginScreen } from "~riata/controllers/authentication";
import { useCredentialsInternal } from "~riata/hooks";
import { useUnregisterFirebase } from "./useUnregisterFirebase";

const CredentialsProvider = ({ children }) => {
  const credentials = useCredentialsInternal();

  // Reload credentials any time latest changes
  const actual = useMemo(() => {
    console.log(`CredentialsProvider > loading ${credentials.latest}`);
    return credentials.get();
  }, [credentials]);

  useUnregisterFirebase(credentials.loaded && !actual);
  useEffect(() => {
    if (credentials.loaded) {
      return;
    }
    credentials.init();
  }, [credentials]);

  console.debug(`CredentialsProvider > invoked ${credentials.latest}`, {
    actual,
  });
  const toRender = useMemo(
    () => (actual === false ? <LoginScreen testID="login-screen" /> : children),
    [actual, children]
  );
  return credentials.loaded === false ? (
    <RodioLoader />
  ) : (
    <CredentialsContext.Provider value={credentials}>
      {toRender}
    </CredentialsContext.Provider>
  );
};

export default CredentialsProvider;
