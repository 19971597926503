import { useMemo, useReducer } from "react";

// Constants
import { isWeb } from "~riata/theme/common";
import { INITIAL_STATE } from "./constants";

// Enums
import { Actions } from "./enums";

// Types
import { Attachment, AttachmentFile, BulletinComposeState } from "./types";
import { Bulletin, Scalars } from "~riata/generated/graphql";
import moment from "moment";

export type BulletinComposeReducerAction =
  | { type: Actions.ADD_ATTACHMENT; file: AttachmentFile }
  | { type: Actions.FINISH_ATTACHMENT; id: Scalars["ID"] }
  | { type: Actions.REMOVE_ATTACHMENT; id: Scalars["ID"] }
  | { type: Actions.SET_CONTENT; content: string }
  | { type: Actions.SET_NAME; name: string }
  | { type: Actions.SET_SCHEDULED_AT; scheduledAt: Date }
  | { type: Actions.SET_EXPIRES; expires: Date }
  | { type: Actions.SET_RECIPIENT_INBOXES; inboxes: Array<Scalars["ID"]> }
  | { type: Actions.REMOVE_EXPIRES }
  | { type: Actions.REMOVE_SCHEDULED_AT }
  | { type: Actions.TOGGLE_ADMIN }
  | { type: Actions.TOGGLE_COMMENTS }
  | { type: Actions.TOGGLE_PRIORITY }
  | { type: Actions.TOGGLE_REQUIRED }
  | { type: Actions.TOGGLE_VISIBILITY }
  | { type: Actions.RESET }
  | { type: Actions.SET_PREVIOUS_VALUES; bulletin: Bulletin }
  | ({ type: Actions.UPDATE_ATTACHMENT } & Attachment);

export const reducer = (
  prevState: BulletinComposeState,
  action: BulletinComposeReducerAction
) => {
  switch (action.type) {
    case Actions.ADD_ATTACHMENT:
      return {
        ...prevState,
        attachments: [
          ...prevState.attachments,
          { file: action.file, loading: true },
        ],
      };

    case Actions.FINISH_ATTACHMENT:
      return {
        ...prevState,
        attachments: prevState.attachments.map((a) => ({
          ...a,
          loading: a.id === action.id ? false : a.loading,
        })),
      };

    case Actions.REMOVE_ATTACHMENT: {
      return {
        ...prevState,
        attachments: [
          ...prevState.attachments.filter((a) => a.id !== action.id),
        ],
      };
    }
    case Actions.SET_NAME:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          name: action.name,
        },
      };

    case Actions.SET_SCHEDULED_AT:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          scheduledAt: moment(action.scheduledAt).toISOString(),
        },
      };

    case Actions.REMOVE_SCHEDULED_AT:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          scheduledAt: null,
        },
      };

    case Actions.SET_EXPIRES:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          expires: moment(action.expires).toISOString(),
        },
      };

    case Actions.REMOVE_EXPIRES:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          expires: null,
        },
      };

    case Actions.SET_CONTENT:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          content: action.content,
        },
      };

    case Actions.SET_RECIPIENT_INBOXES:
      return {
        ...prevState,
        recipientInboxes: action.inboxes,
      };

    case Actions.SET_PREVIOUS_VALUES:
      return {
        ...prevState,
        payload: {
          id: action.bulletin.id,
          name: action.bulletin.name,
          content: action.bulletin.content,
          isRequired: action.bulletin.isRequired,
          isPriority: action.bulletin.isPriority,
          isDraft: action.bulletin.isDraft,
          allowComments: action.bulletin.allowComments,
          allowVisibility: action.bulletin.allowVisibility,
          scheduledAt: action.bulletin.scheduledAt,
          expires: action.bulletin.expires,
        },
        adminVisible: false,
        attachments:
          action.bulletin.attachments?.edges.map((el) => ({
            ...el.node,
            loading: false,
          })) || [],
        recipients:
          action.bulletin.recipients?.edges.map((el) => el.node) || [],
        recipientInboxes: action.bulletin.recipientInboxes,
      };

    case Actions.TOGGLE_ADMIN:
      return { ...prevState, adminVisible: !prevState.adminVisible };

    case Actions.TOGGLE_PRIORITY:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          isPriority: !prevState.payload.isPriority,
        },
      };

    case Actions.TOGGLE_REQUIRED:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          isRequired: !prevState.payload.isRequired,
        },
      };

    case Actions.TOGGLE_COMMENTS:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          allowComments: !prevState.payload.allowComments,
        },
      };

    case Actions.TOGGLE_VISIBILITY:
      return {
        ...prevState,
        payload: {
          ...prevState.payload,
          allowVisibility: !prevState.payload.allowVisibility,
        },
      };

    case Actions.RESET:
      return INITIAL_STATE;

    case Actions.UPDATE_ATTACHMENT:
      return {
        ...prevState,
        attachments: prevState.attachments.map((a) => {
          const attr = isWeb ? "name" : "path";

          if (a.file?.[attr]) {
            return a.file[attr] === action.file[attr]
              ? { ...a, id: action.id }
              : a;
          }

          return a;
        }),
      };
  }
};

export const useBulletinComposeScreenState = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const actions = useMemo(() => {
    return {
      addAttachment: (file: AttachmentFile) =>
        dispatch({ type: Actions.ADD_ATTACHMENT, file }),
      finishAttachment: (id: Scalars["ID"]) =>
        dispatch({ type: Actions.FINISH_ATTACHMENT, id }),
      removeAttachment: (id: Scalars["ID"]) =>
        dispatch({ type: Actions.REMOVE_ATTACHMENT, id }),
      setName: (name: string) => dispatch({ type: Actions.SET_NAME, name }),
      setScheduledAt: (scheduledAt: Date) =>
        dispatch({ type: Actions.SET_SCHEDULED_AT, scheduledAt }),
      setExpires: (expires: Date) =>
        dispatch({ type: Actions.SET_EXPIRES, expires }),
      removeScheduledAt: () => dispatch({ type: Actions.REMOVE_SCHEDULED_AT }),
      removeExpires: () => dispatch({ type: Actions.REMOVE_EXPIRES }),
      setContent: (content: string) =>
        dispatch({ type: Actions.SET_CONTENT, content }),
      setRecipientInboxes: (inboxes: Array<Scalars["ID"]>) =>
        dispatch({ type: Actions.SET_RECIPIENT_INBOXES, inboxes }),
      setPreviousValues: (bulletin: Bulletin) =>
        dispatch({ type: Actions.SET_PREVIOUS_VALUES, bulletin }),
      toggleAdmin: () => dispatch({ type: Actions.TOGGLE_ADMIN }),
      togglePriority: () => dispatch({ type: Actions.TOGGLE_PRIORITY }),
      toggleRequired: () => dispatch({ type: Actions.TOGGLE_REQUIRED }),
      toggleComments: () => dispatch({ type: Actions.TOGGLE_COMMENTS }),
      toggleVisibility: () => dispatch({ type: Actions.TOGGLE_VISIBILITY }),
      updateAttachment: (file: AttachmentFile, id: Scalars["ID"]) =>
        dispatch({ type: Actions.UPDATE_ATTACHMENT, file, id }),
    };
  }, []);

  return { actions, dispatch, state };
};
