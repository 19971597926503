import React from "react";
import { StyleSheet } from "react-native";

import { Card, Row } from "../../layout";
import { Text } from "../../typography";
import { useColors } from "~riata/hooks";
import { Button } from "~riata/components/inputs";

const ActionCard = ({ data, first = false, onPress }) => {
  const { colors } = useColors();
  const cardStyle = data.style.toLowerCase();

  const cardColor =
    colors.actionCard[cardStyle]?.main || colors.actionCard.default.main;

  const textColor =
    colors.actionCard[cardStyle]?.on || colors.actionCard.default.on;

  const cardContainerStyles = first ? styles.firstItemContainer : {};

  return (
    <Card
      spaced={!first}
      rounded
      customColor={cardColor}
      style={cardContainerStyles}
    >
      <Button onPress={onPress} style={styles.container}>
        <Row justify="center">
          <Text variant="title3" color={textColor}>
            {data.name}
          </Text>
        </Row>
      </Button>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 12,
  },
  firstItemContainer: {
    marginTop: 0,
    marginHorizontal: 12,
    marginBottom: 12,
    borderRadius: 64,
  },
  itemContainer: {
    borderRadius: 64,
  },
});

export default ActionCard;
