import React from "react";
import { StyleSheet, View } from "react-native";

// Media
import OffDutyDots from "~riata/resources/off-duty-dots.svg";
import OffDutySpots from "~riata/resources/off-duty-spots.svg";
import OffDutyLogo from "~riata/resources/off-duty-logo.svg";
import OffDutyLoop from "~riata/resources/off-duty-loop.svg";

/**
 * ! Inline styles are used for svg components. Otherwise web env interprets
 * ! them as string causing an error
 */
const Header = () => {
  return (
    <View>
      <OffDutyDots
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      <OffDutyLogo
        style={{
          position: "absolute",
          top: 60,
          right: 0,
        }}
      />
      <View style={styles.wrapper}>
        <View>
          <OffDutySpots />
          <OffDutyLoop
            style={{
              marginTop: -50,
              marginLeft: 20,
            }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 16,
  },
});

export default React.memo(Header);
