import React from "react";
import { StyleSheet, View } from "react-native";
import { useComposeStyles } from "~riata/hooks";
import { useColors } from "~riata/hooks/useColors";
import { AvatarSize, avatarSize } from "~riata/theme/common";

type AvatarContainerProps = {
  id?: string;
  children: React.ReactNode;
  outlined?: boolean;
  primary?: boolean;
  size?: AvatarSize;
};

const AvatarContainer = ({
  id,
  children,
  size = "normal",
  outlined = false,
  primary = false,
}: AvatarContainerProps) => {
  const { randomColorKey, colors } = useColors();
  const styleKey = primary ? "accent8" : randomColorKey(id);

  const containerStyles = useComposeStyles(styles.container, {
    borderRadius: avatarSize[size],
    height: avatarSize[size],
    width: avatarSize[size],
    backgroundColor: colors.avatar[styleKey].main,
  });

  return <View style={containerStyles}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 48,
    height: 48,
    width: 48,
    backgroundColor: "transparent",
  },
});
export default AvatarContainer;
