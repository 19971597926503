import React from "react";
import { Platform, StyleSheet } from "react-native";

import { PlusIcon } from "~riata/components/icons";
import { ControlledButton } from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";

const AttachmentThumbnailListAddButton = ({ onAddPressed }) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.button.disabled.main,
  });

  return (
    <ControlledButton
      key="add-attachment"
      focusedPress
      style={containerStyles}
      onPress={onAddPressed}
    >
      <PlusIcon
        size={22}
        style={{
          color: newColors.button.disabled.on,
        }}
      />
    </ControlledButton>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    marginHorizontal: Platform.OS === "web" ? 10 : 0, // Match buildLinkComponent default styles
    marginVertical: Platform.OS === "web" ? 5 : 0, // Match buildLinkComponent default styles
    height: 48,
    width: 48,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AttachmentThumbnailListAddButton;
