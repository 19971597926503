import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useColors, useComposeStyles } from "~riata/hooks";
import { generatePickerOptions } from "./utils";

const AndroidPicker: PickerComponent = ({ selectedValue, onValueChange }) => {
  const { newColors } = useColors();

  const pickerStyles = useComposeStyles(styles.picker, {
    backgroundColor: newColors.background.main,
  });

  const pickerOptions = useMemo(() => generatePickerOptions(), []);

  return (
    <View style={styles.pickerContainer}>
      <Picker
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        mode="dropdown"
        style={pickerStyles}
        dropdownIconColor={newColors.icon.lightGray.main}
        dropdownIconRippleColor={newColors.icon.lightGray.main}
      >
        {pickerOptions}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  pickerContainer: {
    borderRadius: 10,
    overflow: "hidden",
  },
  picker: {
    borderRadius: 10,
    backgroundColor: "transparent",
    minWidth: 150,
  },
});

export default AndroidPicker;
