import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { useLinkProps } from "@react-navigation/native";
import {
  Bulletin,
  Channel,
  Integration,
  Message,
  Node,
} from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { AttachmentImageGallery } from "~riata/controllers/attachments";
import { isBulletin, isMessage } from "~riata/graphql";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";

const map: {
  [any: string]: (
    obj: Node,
    extra?: any
  ) => string | { screen: (props?: any) => object; params: any };
} = Object.freeze({
  "/channel/{{id}}": (c: Channel) => `/channel/${c.id}`,
  "/channel/:id/?message=:message": (m: Message) => `/message/${m.id}`,
  "/channel/{{id}}/info": (c: Channel) => `/channel/${c.id}/info`,
  "/channel/{{id}}/new/attachment": (c: Channel) =>
    `/channel/${c.id}/new/attachment`,
  "/channel/_/new/attachment/integration/{{integration_id}}/": (
    i: Integration
  ) => `/channel/_/new/attachment/integration/${i.id}`,
  "/channel/{{id}}/{{message}}/action": (m: Message) =>
    `/channel/${m.channel.id}/${m.id}/action`,
  "/new/attachment": () => "new/attachment",
  "/attachments": (n: Bulletin | Message) => {
    const params = isMessage(n)
      ? { channel: n.channel.id, message: n.id }
      : isBulletin(n)
      ? { id: n.id }
      : { id: "new" };
    return { screen: AttachmentImageGallery, params };
  },
});

type KnownPatterns = keyof typeof map;

export const generateOnPressHook = (pattern: KnownPatterns) => {
  return function useGeneratedOnPressHook({
    node,
    action,
  }: {
    node?: Node;
    action?: any;
  }) {
    const to = map[pattern](node) as To;
    console.log({ to });
    return useLinkProps({ to, action });
  };
};

export const buildLinkComponent = (onPressGenerator, beforePress?) => {
  return ({
    node,
    action,
    children,
    ...props
  }: {
    node?: Node;
    action?: any;
    children: any;
    [any: string]: any;
  }) => {
    const { onPress, ...rest } = onPressGenerator({ node, action });
    const [isHovered, setIsHovered] = useState(false);

    const touchable = (
      <TouchableOpacity
        onPress={(evt) => {
          if (typeof beforePress === "function") {
            beforePress();
          }
          if (props.onPress) {
            props.onPress(evt);
          } else {
            onPress(evt);
          }
        }}
        {...rest}
        {...props}
      >
        {children}
      </TouchableOpacity>
    );
    if (isWeb) {
      // Here to make sure onClick prop doesn't cause an issue
      const SomeView: any = View;
      return (
        <SomeView
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            transitionDuration: "150ms",
            opacity: isHovered ? 0.5 : 1,
            cursor: isHovered ? "pointer" : null,
            paddingHorizontal: 10,
            paddingVertical: 5,
          }}
        >
          {touchable}
        </SomeView>
      );
    }
    return touchable;
  };
};
