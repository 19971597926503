import React from "react";
import { StyleSheet } from "react-native";
import { useLinkTo } from "@react-navigation/native";

import { Hashtag } from "~riata/generated/graphql";
import { useColors, useComposeStyles } from "~riata/hooks";
import { Button } from "../inputs";
import { Card } from "../layout";
import { Text } from "../typography";
import color from "color";

const FoldersListItem = ({ hashtag }) => {
  const { newColors } = useColors();
  const linkTo = useLinkTo();

  const tag: Hashtag = hashtag as Hashtag;
  const tagColor = tag.color?.color || newColors.tag.primary.main;

  const cardStyles = useComposeStyles(styles.itemContainer, {
    borderColor: tagColor,
    backgroundColor: color(tagColor).fade(0.9).string(),
  });

  return (
    <Button
      onPress={() => linkTo(`/folder/${tag.name}`)}
      style={styles.itemWrapper}
    >
      <Card rounded style={cardStyles}>
        <Text variant="title2">#{tag.name}</Text>
      </Card>
    </Button>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    padding: 16,
    borderWidth: 1,
    borderColor: "transparent",
    backgroundColor: "transparent",
  },
  itemWrapper: { overflow: "hidden", margin: 8 },
});

export default FoldersListItem;
