import React from "react";
import { StyleSheet, View } from "react-native";
import { useComposeStyles } from "~riata/hooks";

const ListItem = (props) => {
  const composedStyles = useComposeStyles(styles.container, props.style);

  return <View style={composedStyles} {...props} />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
});

export default ListItem;
