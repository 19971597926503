import React from "react";
import moment from "moment";
import TextSeparatorHeader from "../TextSeparatorHeader";

const DateHeader = ({ date }) => {
  const formattedDate = moment(date).format("MMM D");
  return <TextSeparatorHeader title={formattedDate} variant="message" />;
};

export default DateHeader;
