import React, { useState } from "react";
import Modal from "react-native-modal";
import { StyleSheet, View } from "react-native";

import { Text } from "./typography";
import { useColors, useComposeStyles } from "~riata/hooks";

export const ModalToast = ({
  danger = false,
  warning = false,
  success = false,
  delay = 1500,
  onDismiss,
  text,
  ...props
}) => {
  const [visible, setVisible] = useState(true);
  const { newColors } = useColors();
  const hide = () => setVisible(false);
  var timer = setTimeout(hide, delay);
  const onBackdropPress = () => {
    clearTimeout(timer);
    hide();
  };
  let toastColor = "primary";
  if (danger) {
    toastColor = "danger";
  } else if (warning) {
    toastColor = "warning";
  } else if (success) {
    toastColor = "success";
  }

  const contentStyles = useComposeStyles(styles.content, {
    backgroundColor: newColors.toast[toastColor].main,
  });

  return (
    <Modal
      {...props}
      isVisible={visible}
      onModalHide={onDismiss}
      onBackdropPress={onBackdropPress}
      backdropOpacity={0}
      style={styles.container}
    >
      <View style={contentStyles}>
        <Text color={newColors.toast[toastColor].on}>{text}</Text>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: { justifyContent: "flex-end", minHeight: 50, marginTop: 200 },
  content: {
    borderRadius: 8,
    alignContent: "center",
    justifyContent: "center",
    paddingLeft: 20,
    height: 50,
    backgroundColor: "transparent",
  },
});
