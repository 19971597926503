import gql from "graphql-tag";

// Hooks
import { useMutation } from "@apollo/react-hooks";
import { GetChannelsQuery } from "~riata/graphql/queries";
import { GetChannelQuery } from "~riata/graphql/queries/GetChannel";

const ADD_MEMBERS_TO_CHANNEL = gql`
  mutation ($channel: ID!, $members: [ID!]!) {
    joinMembersInChannel(input: { channel: $channel, members: $members }) {
      errors {
        field
        messages
      }
      clientMutationId
      success
    }
  }
`;

const useAddMembersToChannel = () => {
  const [mutation, response] = useMutation(ADD_MEMBERS_TO_CHANNEL);

  const execute = (channelId: string, members: Array<string>) => {
    const variables = { channel: channelId, members };

    return mutation({
      variables,
      refetchQueries: [
        {
          query: GetChannelsQuery,
          variables: { filter: { isArchived: false } },
        },
        {
          query: GetChannelQuery,
          variables: { id: channelId, query: "" },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response: response?.data?.leaveChannel };
};

export { useAddMembersToChannel };
