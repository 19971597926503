import React, { useEffect, useMemo } from "react";
import { StyleSheet, View, ScrollView, SafeAreaView } from "react-native";
import {
  Text,
  Separator,
  ChannelInfoActions,
  AppHeader,
  HeaderActionButton,
  UserInfoHeader,
  AttributeBadge,
} from "~riata/components";
import { useColors, useComposeStyles } from "~riata/hooks";
import { useGetUser } from "~riata/graphql/queries/GetUser";
import { useGetOrCreateDm } from "~riata/graphql/mutations/CreateDm";
import { CommonActions } from "@react-navigation/routers";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";

const UserProfileScreen = ({ navigation, route }) => {
  const userId = route.params?.user ?? false;

  const { user } = useGetUser({ filter: { id: userId } });
  const { navigateToCheckpoint } = useDrawerNavigation();

  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const { getOrCreateDirectMessageChannel, channel } = useGetOrCreateDm();

  const actions = useMemo(() => {
    const actions = [];

    actions.push({
      label: "Chat",
      iconName: "chat_outlined",
      onPressHandler: () => {
        if (user?.id) {
          getOrCreateDirectMessageChannel({
            variables: {
              recipients: [user.id],
            },
          });
        }
      },
    });

    return actions;
  }, [getOrCreateDirectMessageChannel, user]);

  const attributesList = useMemo(() => {
    const attributes =
      user?.attributes.edges.map((edge) => edge.node.name) || [];

    return attributes.map((attribute, idx) => (
      <AttributeBadge key={idx} title={attribute} />
    ));
  }, [user]);

  useEffect(() => {
    if (channel) {
      navigation.dispatch(
        CommonActions.reset({
          index: 2,
          routes: [
            { name: "Home" },
            { name: "Messages" },
            {
              name: "Conversation",
              params: {
                screen: "ConversationDetail",
                params: { channel },
              },
            },
          ],
        })
      );
    }
    // prevent {navigation} from triggering a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <SafeAreaView style={containerStyles}>
      <AppHeader
        backgroundColor={newColors.background.main}
        left={
          <HeaderActionButton variant="back" onPress={navigateToCheckpoint} />
        }
      />

      <View style={{ flex: 1 }}>
        <UserInfoHeader
          user={user}
          onBackPress={navigateToCheckpoint}
          title={user?.displayName}
          subtitle={user?.jobTitle}
          offDuty={user?.offDuty}
          statusEnabled={user?.company.offDuty}
        />
        <ChannelInfoActions
          actions={actions}
          containerStyles={styles.actionsContainer}
        />
        <Separator color={newColors.separator.main} />
        <View style={styles.attributesTitleWrapper}>
          <Text variant="headline">Attributes</Text>
        </View>
        <ScrollView>
          <View style={styles.attributesWrapper}>{attributesList}</View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    paddingHorizontal: 16,
    marginVertical: 26,
  },
  attributesTitleWrapper: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  attributesWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 16,
    gap: 8,
  },
});

export default UserProfileScreen;
