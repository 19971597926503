import React from "react";
import { FlatList } from "react-native";
import { isWeb } from "~riata/theme/common";
import BulletinCommentListItem from "./BulletinCommentListItem";

const BulletinCommentList = ({ comments, onEndReached, ...props }) => {
  return (
    <FlatList
      disableVirtualization={isWeb}
      onEndReached={() => {
        onEndReached();
      }}
      onEndReachedThreshold={0.3}
      {...props}
      inverted
      data={comments}
      keyExtractor={(item, index) => item.id.toString()}
      renderItem={(item) => <BulletinCommentListItem comment={item.item} />}
    />
  );
};

export default BulletinCommentList;
