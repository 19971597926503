import React from "react";
import moment from "moment";

import { Text } from "./typography";
import { useColors } from "~riata/hooks";

const RelativeTime = ({ time, preview = true }) => {
  const relative = moment(time).calendar({
    lastDay: "[Yesterday]",
    sameDay: "h:mm A",
    nextDay: "[Tomorrow]",
    lastWeek: "dddd",
    nextWeek: "dddd",
    sameElse: "MM/DD/YYYY",
  });
  const { newColors } = useColors();

  const textColor = preview
    ? newColors.text.caption.main
    : newColors.text.regular.main;

  return (
    <Text variant="newCaption2" color={textColor}>
      {relative}
    </Text>
  );
};

export default RelativeTime;
