import React, { useState } from "react";
import { TouchableOpacity, FlatList, View, StyleSheet } from "react-native";

import MessageListItemRepliesItem from "./chat/MessageListItemRepliesItem";
import MessageRepliesHeader from "./chat/MessageRepliesHeader";
import { Row } from "./layout";
import { Separator } from "./misc";

const ExpandableMessageList = ({ message }) => {
  const [expanded, setExpanded] = useState(false);

  // TODO: Get actual replies from message here.
  const data = [message];

  const rand = useState(Math.floor(Math.random() * 5))[0];
  for (let i = 0; i < rand; i++) {
    data.push(message);
  }

  return (
    <View>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <MessageRepliesHeader data={data} expanded={expanded} />
      </TouchableOpacity>
      {expanded && (
        <Row>
          <Separator />
          <FlatList
            style={styles.list}
            keyExtractor={(item, index) => `${index}`}
            data={data}
            renderItem={(item) => {
              const message = item.item;
              return (
                <MessageListItemRepliesItem
                  key={message.id}
                  message={message}
                  index={item.index}
                />
              );
            }}
          />
        </Row>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  list: {
    flex: 1,
    paddingTop: 8,
  },
});

export default ExpandableMessageList;
