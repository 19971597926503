import React from "react";
import { KeyboardAvoidingView, SafeAreaView } from "~riata/components/layout";
import { BulletinComposeHeader } from "../BulletinComposeHeader";
import { BulletinComposeInput } from "../BulletinComposeInput";
import { Separator } from "~riata/components/misc";
import { BulletinComposeOptions } from "../BulletinComposeOptions";
import { BulletinDateBanner } from "../../BulletinDate";
import { BulletinComposeAttachments } from "../BulletinComposeAttachments";
import { BulletinComposeFormComponent } from "./types";
import { isWideDisplay } from "~riata/theme/common";
import { StyleSheet, useWindowDimensions } from "react-native";

const BulletinComposeForm: BulletinComposeFormComponent = ({
  composeActions,
  composeState,
  navigate,
  onBack,
  onSave,
  onDelete,
  onNavigateToAttachment,
  onNavigateToExpire,
  onNavigateToSchedule,
}) => {
  const { width } = useWindowDimensions();

  const isWide = isWideDisplay(width);

  return (
    <SafeAreaView bottom={false}>
      <BulletinComposeHeader
        composeState={composeState}
        navigationCallback={navigate}
        onBack={onBack}
        onSave={onSave}
      />
      <KeyboardAvoidingView>
        <BulletinComposeInput
          composeActions={composeActions}
          composeState={composeState}
          onDelete={onDelete}
        />
        <Separator />
        <BulletinComposeOptions
          composeState={composeState}
          composeActions={composeActions}
          onNavigateToAttachment={onNavigateToAttachment}
          onNavigateToExpire={onNavigateToExpire}
          onNavigateToSchedule={onNavigateToSchedule}
        />
        {!isWide && composeState.adminVisible && <Separator />}
        <BulletinDateBanner
          onPress={onNavigateToSchedule}
          onCancel={composeActions.removeScheduledAt}
          date={composeState.payload.scheduledAt}
          containerStyles={styles.bulletinDateContainer}
        />
        <BulletinDateBanner
          onPress={onNavigateToExpire}
          onCancel={composeActions.removeExpires}
          icon="clock"
          date={composeState.payload.expires}
          containerStyles={styles.bulletinDateContainer}
        />
        <BulletinComposeAttachments
          composeActions={composeActions}
          composeState={composeState}
          onNavigateToAttachment={onNavigateToAttachment}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bulletinDateContainer: {
    marginTop: 16,
    marginLeft: 8,
    marginRight: 12,
  },
});

export default BulletinComposeForm;
