import React, { useMemo } from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

import { CustomIcon } from "../icons";
import { useColors } from "~riata/hooks";
import { isLocalAttachment } from "./_utils";
import { PdfAttachment } from "~riata/generated/graphql";
import { DEFAULT_THUMBNAIL_SIZE } from "./constants";
import { ATTACHMENT_PREVIEW_ICON_SIZE } from "~riata/theme";

export const PdfAttachmentPreview: GenericAttachmentView<PdfAttachment> = ({
  thumbnail = false,
  thumbnailSize = DEFAULT_THUMBNAIL_SIZE,
  attachment,
}) => {
  const { colors } = useColors();

  const thumbnailStyles = useMemo(
    () => (thumbnail ? styles.thumbnail : {}),
    [thumbnail]
  );

  const loadingStyles = useMemo(() => {
    return isLocalAttachment(attachment) && attachment.loading
      ? styles.loading
      : {};
  }, [attachment]);

  const containerStyles = useMemo(() => {
    return StyleSheet.flatten([
      styles.preview,
      { backgroundColor: colors.icon.accent4.main },
      thumbnailStyles,
      { width: thumbnailSize, height: thumbnailSize },
      loadingStyles,
    ]);
  }, [thumbnailStyles, loadingStyles, colors, thumbnailSize]);

  return (
    <View style={containerStyles}>
      <CustomIcon
        name="doc"
        size={thumbnail ? thumbnailSize / 2 : ATTACHMENT_PREVIEW_ICON_SIZE}
        style={{ color: colors.icon.accent4.on }}
      />
      {isLocalAttachment(attachment) && attachment.loading && (
        <ActivityIndicator
          color={colors.activityIndicator.lightContrast.main}
          style={styles.loadingIndicator}
        />
      )}
    </View>
  );
};

PdfAttachmentPreview.canDisplay = (obj: any): obj is PdfAttachment =>
  obj.__typename === "PDFAttachment" ||
  obj?.file?.mime?.includes("application/pdf");

const styles = StyleSheet.create({
  thumbnail: {
    marginTop: 0,
    width: 48,
    height: 48,
  },
  preview: {
    flexDirection: "column",
    borderRadius: 6,
    height: 96,
    width: 96,
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    opacity: 0.5,
  },
  loadingIndicator: {
    position: "absolute",
    width: 24,
    height: 24,
    left: 12,
    top: 12,
  },
});
