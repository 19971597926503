import React from "react";
import { StackActions } from "@react-navigation/core";
import {
  Bulletin,
  Message,
  OfficeAttachment,
  PdfAttachment,
  ImageAttachment,
  IntegrationAttachment,
} from "~riata/generated/graphql";
import { buildLinkComponent, generateOnPressHook } from "./_utils";
import { isWeb } from "~riata/theme/common";
import useFileViewer from "~riata/hooks/useFileViewer";

const useBaseAttachment = generateOnPressHook("/attachments");

const BaseAttachmentGalleryLink = buildLinkComponent(useBaseAttachment);

const isIntegrationAttachment = (a: any): a is IntegrationAttachment =>
  a?.__typename === "IntegrationAttachment";

const isImageAttachment = (
  attachment: OfficeAttachment | PdfAttachment | ImageAttachment
): attachment is ImageAttachment => {
  return (attachment as ImageAttachment).__typename === "ImageAttachment";
};

export const AttachmentGalleryLink = ({
  node,
  index = 0,
  children,

  ...props
}: {
  node?: Bulletin | Message;
  index?: number;
  children: JSX.Element | JSX.Element[];
  // TODO Make this a real type
  [any: string]: any;
}) => {
  let action;

  // FIXME Only do this if it's an image
  action = StackActions.push("AttachmentImageGallery", {
    node: node?.id,
    index,
  });
  const { showFile } = useFileViewer();
  const attachment = props.attachment;

  if (isWeb && !props.onPress) {
    props.onPress = (evt) => {
      evt.preventDefault();
      showFile(attachment);
    };
  } else if (isIntegrationAttachment(attachment)) {
    // props.onPress = () => console.log("INTEGRATION ATTACHMENT");
    action = StackActions.push("IntegrationAttachmentDetail", {
      id: node.id,
      attachment: attachment.url,
    });
    console.log(action);
  } else if (!isImageAttachment(attachment)) {
    props.onPress = () => {
      showFile(attachment);
    };
  }

  return (
    <BaseAttachmentGalleryLink
      style={{ margin: 0 }}
      node={node}
      action={action}
      {...props}
    >
      {children}
    </BaseAttachmentGalleryLink>
  );
};
