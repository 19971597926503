import React from "react";
import { BulletinCommentSheet } from "~riata/components";
import { createStackNavigator } from "@react-navigation/stack";
import { BulletinDetailScreen } from "./BulletinDetailScreen";
import { BulletinAttachmentsScreen } from "./BulletinAttachmentsScreen";
import { BulletinRecipientsScreen } from "./BulletinRecipientsScreen";

const Stack = createStackNavigator();

const BulletinStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        cardOverlayEnabled: true,
        cardStyle: { backgroundColor: "transparent", opacity: 1 },
        cardStyleInterpolator: ({ current: { progress } }) => ({
          cardStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 0.5, 0.9, 1],
              outputRange: [0, 0.25, 0.7, 1],
            }),
            transform: [
              {
                scale: progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                  extrapolate: "clamp",
                }),
              },
            ],
          },
          overlayStyle: {
            opacity: progress.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.5],
              extrapolate: "clamp",
            }),
            backdropFilter: "blur(1px)",
          },
        }),
        headerShown: false,
        presentation: "transparentModal",
      }}
    >
      <Stack.Screen name="BulletinDetails" component={BulletinDetailScreen} />
      <Stack.Screen
        name="BulletinAttachments"
        component={BulletinAttachmentsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BulletinRecipients"
        component={BulletinRecipientsScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BulletinComments"
        component={BulletinCommentSheet}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default BulletinStack;
