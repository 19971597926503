import React from "react";
import { StatusBar, StyleSheet, View, ViewStyle } from "react-native";

import { useColors, useComposeStyles } from "~riata/hooks";
import { Title } from "../../typography";
import { Row } from "../Row";

type AppHeaderProps = {
  style?: ViewStyle;
  left?: React.ReactElement;
  center?: React.ReactElement;
  title?: React.ReactNode;
  right?: React.ReactNode;
  backgroundColor?: string;
};

type AppHeaderComponent = (props: AppHeaderProps) => JSX.Element;

const AppHeader: AppHeaderComponent = ({
  left = null,
  title = "",
  center = null,
  right = null,
  backgroundColor,
}) => {
  const { newColors } = useColors();

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: backgroundColor || newColors.header.main,
  });

  return (
    <View style={containerStyles}>
      <StatusBar barStyle="default" />
      <Row align="center" justify="between" style={styles.actionsContainer}>
        {left}
        <View style={styles.rightActionContainer}>{right}</View>
      </Row>
      <View style={styles.titleContainer}>
        {center}
        <Title>{title}</Title>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
  },
  actionsContainer: {
    height: 50,
    paddingHorizontal: 16,
  },
  rightActionContainer: {
    marginLeft: "auto",
  },
  titleContainer: {
    position: "absolute",
    height: 50,
    paddingHorizontal: 32,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    zIndex: -10,
  },
});

export default AppHeader;
