import { HeaderActionVariant } from "./types";

export const ICON_SIZE: number = 20;

export const VARIANTS: Record<string, HeaderActionVariant> = {
  add: {
    icon: {
      name: "add",
      styles: {},
    },
    title: null,
    accessibilityLabel: "add",
  },
  attachments: {
    icon: {
      name: "attachments",
      styles: {},
    },
    title: null,
    accessibilityLabel: "attachments",
  },
  back: {
    icon: {
      name: "arrow_left",
      styles: { fontSize: 16, lineHeight: 20, fontFamily: "HelveticaNeue" },
    },
    title: "Back",
    accessibilityLabel: "back",
  },
  chatOutlined: {
    icon: {
      name: "chat_outlined",
      styles: {},
    },
    title: null,
    accessibilityLabel: "messages",
  },
  close: {
    icon: {
      name: "close",
      styles: {},
    },
    title: null,
    accessibilityLabel: "close",
  },
  down: {
    icon: {
      name: "back",
      styles: {
        transform: [{ rotate: "270deg" }],
      },
    },
    title: null,
    accessibilityLabel: "back",
  },
  drawer: {
    icon: {
      name: "hamburger",
      styles: {},
    },
    title: null,
    accessibilityLabel: "drawer",
  },
  forward: {
    icon: {
      name: "arrow_left",
      styles: { transform: [{ rotate: "180deg" }] },
    },
    title: null,
    accessibilityLabel: "forward",
  },
  menu: {
    icon: {
      name: "menu",
      styles: {},
    },
    title: null,
    accessibilityLabel: "menu",
  },
  next: {
    icon: {
      name: null,
      styles: {},
    },
    title: "Next",
    accessibilityLabel: "next",
  },
  create: {
    icon: {
      name: null,
      styles: {},
    },
    title: "Create",
    accessibilityLabel: "create",
  },
  refresh: {
    icon: {
      name: "refresh",
      styles: {},
    },
    title: null,
    accessibilityLabel: "refresh",
  },
  save: {
    icon: {
      name: null,
      styles: {},
    },
    title: "Save",
    accessibilityLabel: "save",
  },
  send: {
    icon: {
      name: null,
      styles: {},
    },
    title: "Send",
    accessibilityLabel: "send",
  },
  schedule: {
    icon: {
      name: null,
      styles: {},
    },
    title: "Schedule",
    accessibilityLabel: "schedule",
  },
  text: {
    icon: {
      name: null,
      styles: {},
    },
    title: null,
    accessibilityLabel: "",
  },
};
