import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Keyboard, TextInput, View } from "react-native";
import { StackActions } from "@react-navigation/routers";
import { useSafeAreaInsets } from "react-native-safe-area-context";

// Components
import { RichTextInput } from "~riata/components/inputs/RichTextInput/RichTextInput";
import ReplyText from "./ReplySnippet";
import { useInputBarState } from "./InputBar.state";
import { AttachmentThumbnailList } from "~riata/components/attachments";
import { AutoCompleteList } from "~riata/components/inputs";
import { DragAndDropAttachment } from "~riata/components/attachments";

// Hooks
import {
  useColors,
  useComposeStyles,
  useFindSuggestions,
  useProcessAttachment,
} from "~riata/hooks";
import { useMessageAttachmentActionSheetLink } from "~riata/links";

// Utils
import { RegistryContext } from "~riata/contexts";
import { Sentry } from "~riata/utils/sentry";
import { affixReply, editorIsEmpty } from "./helpers";

// Types
import { Node } from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { useGetChannel } from "~riata/graphql";

const InputBar = ({ nodeID, onSendMessage, allowAttachments = true }) => {
  const insets = useSafeAreaInsets();
  const { newColors } = useColors();
  const inputRef = useRef<TextInput>();
  const paddingBottom = Math.max(insets.bottom, 0);
  const registry = useContext(RegistryContext);

  const { channel } = useGetChannel(nodeID);
  const { state, actions, isBusy } = useInputBarState({ channelId: nodeID });

  const currentText = state?.text[nodeID] || "";
  const currentAttachments = useMemo(() => {
    return state?.attachments[nodeID] || [];
  }, [state, nodeID]);
  const currentInFlight = state?.inFlight[nodeID] || false;
  const currentReplyText = state?.replyText[nodeID] || {};
  const currentSuggestions = state?.suggestions[nodeID] || "";

  useProcessAttachment({ actions }, nodeID);

  const { suggestMode, loading, suggestionList, suggestionType } =
    useFindSuggestions({ text: currentText, isDirect: channel?.isDirect });

  const goToAddAttachment = StackActions.push("AttachmentActionSheet", {
    onAddAttachment: "onAddAttachment",
  });

  const attachmentsLoading = useMemo(
    () => !currentAttachments.every((a) => a.loading === false),
    [currentAttachments]
  );

  const isEmpty = editorIsEmpty(
    currentText,
    currentAttachments,
    currentInFlight,
    attachmentsLoading
  );

  const { onPress: goToAttachmentActionSheet } =
    useMessageAttachmentActionSheetLink({
      node: { id: nodeID } as Node,
      action: goToAddAttachment,
    });

  // TODO: Rework suggestion insert
  const handleSuggestionSelected = useCallback(
    (suggestion) => {
      const value = suggestion.item.value;
      const token = suggestion.type;
      const insertText = `${token}${value}`;

      actions.addSuggestion(nodeID, insertText);
    },

    [nodeID, actions]
  );

  const onSendHandler = () => {
    if (currentInFlight || isEmpty) {
      // Can't send while we're working or no text is in place
      return;
    }

    let text = currentText;
    if (!text) {
      if (currentAttachments.length === 0) {
        return;
      } else {
        text = "{user} posted an attachment";
      }
    }
    actions.setInFlight(nodeID);
    let attachments =
      currentAttachments.length !== 0
        ? currentAttachments.map((attachment) => {
            return attachment.id;
          })
        : null;

    let messageToSend = currentText;

    if (currentReplyText.text && currentReplyText.sender) {
      messageToSend = affixReply(messageToSend, currentReplyText);
    }

    onSendMessage(messageToSend, attachments)
      .then(() => {
        actions.reset(nodeID);
        if (isWeb) {
          // In web, when a message is send InputBar won't update it's height.
          // If message send was successful reset to it's original size
          (inputRef as any).current.style.height = "22px";
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        actions.resetSaveText(nodeID);
        // TODO Display some sort of failure notification
      });
  };

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.inputBar.main,
    paddingBottom,
  });

  useEffect(() => {
    registry.set("setReplyText", (text) => actions.setReplayText(text, nodeID));
  }, [registry, actions, nodeID]);

  return (
    <DragAndDropAttachment canAttach={allowAttachments}>
      <View style={containerStyles}>
        <ReplyText
          message={currentReplyText}
          isVisible={currentReplyText}
          onClose={() => actions.clearReplyText(nodeID)}
        />
        {suggestMode && (
          <AutoCompleteList
            loading={loading}
            suggestions={suggestionList}
            onSuggestionSelect={handleSuggestionSelected}
            suggestionType={suggestionType}
          />
        )}
        {currentAttachments.length > 0 && (
          <AttachmentThumbnailList
            style={{ margin: 12 }}
            attachments={currentAttachments}
            onAddPressed={(evt) => {
              Keyboard.dismiss();
              goToAttachmentActionSheet(evt);
            }}
            onLongPress={(attachment) =>
              actions.removeAttachment(attachment.id, nodeID)
            }
            thumbnail={true}
          />
        )}
        <RichTextInput
          editable={!isBusy}
          inputRef={inputRef}
          registry={registry}
          canSend={!isEmpty && !isBusy}
          suggestions={currentSuggestions}
          inputText={currentText}
          onSendMessage={onSendHandler}
          removeSuggestions={() => actions.removeSuggestion(nodeID)}
          onChangeText={(text) => actions.setText(text, nodeID)}
          canAddAttachments={allowAttachments}
          onAddAttachment={goToAttachmentActionSheet}
        />
      </View>
    </DragAndDropAttachment>
  );
};

const styles = {
  container: {
    backgroundColor: "transparent",
    shadowColor: "transparent",
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.12,
    shadowRadius: 3,

    elevation: 6,
  },
  attachments: {
    margin: 12,
  },
};

export default InputBar;
