import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { GET_BULLETIN } from "../../queries/GetBulletin";

export const MARK_BULLETIN_VIEWED = gql`
  mutation ($id: ID!) {
    markBulletinAsViewed(input: { id: $id }) {
      bulletin {
        id
        status
      }
    }
  }
`;

export const useMarkBulletinViewed = () => {
  const [mutation, response] = useMutation(MARK_BULLETIN_VIEWED);
  const successful = response?.data ? true : false;

  const execute = (id) => {
    const variables = { id: id };
    return mutation({
      variables,
      refetchQueries: [
        {
          query: GET_BULLETIN,
          variables: { id },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return { execute, response, successful };
};
