import { Platform } from "react-native";
import Toast from "react-native-toast-message";
import { Sentry } from "~riata/utils/sentry";

const useToastMessage = () => {
  const _showToastMessage = (type, title, message) => {
    Toast.show({
      type,
      text1: title,
      text2: message,
      onPress: () => Toast.hide(),
      position: "bottom",
      visibilityTime: 2000,
      autoHide: true,
      topOffset: Platform.OS === "ios" ? 60 : 20,
    });
  };

  const showInfoToast = (title, message = undefined) => {
    _showToastMessage("info", title, message);
  };

  const showSuccessToast = (title, message = undefined) => {
    _showToastMessage("success", title, message);
  };

  const showDangerToast = (title, message = undefined) => {
    _showToastMessage("error", title, message);
  };

  const showErrorToast = (title, message, error) => {
    Sentry.captureException(error);
    _showToastMessage("error", title, message);
  };

  return { showInfoToast, showSuccessToast, showErrorToast, showDangerToast };
};

export { useToastMessage };
