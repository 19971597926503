import React, { useState } from "react";
import { useGetChannel } from "~riata/graphql";
import CompanyChannelInfo from "./CompanyChannelInfo";
import DMChannelInfo from "./DMChannelInfo";
import GroupChannelInfo from "./GroupChannelInfo";

const ChannelInfoSheet = ({ navigation, route }) => {
  const [userFilter, setUserFilter] = useState("");
  const channelID = route.params?.channel ?? false;
  const { channel } = useGetChannel(channelID, userFilter);
  const isDMChannel = channel?.isDirect;
  const isGroupChannel = channel?.isGroup;

  if (isDMChannel && channel.members.total === 2) {
    return (
      <DMChannelInfo
        navigation={navigation}
        channel={channel}
        channelID={channelID}
      />
    );
  }

  if (isGroupChannel) {
    return (
      <GroupChannelInfo
        navigation={navigation}
        channel={channel}
        setUserFilter={setUserFilter}
      />
    );
  }

  return (
    <CompanyChannelInfo
      navigation={navigation}
      channel={channel}
      setUserFilter={setUserFilter}
    />
  );
};

export default ChannelInfoSheet;
