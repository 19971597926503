import React from "react";
import { useMemo } from "react";
import { Channel } from "~riata/generated/graphql";
import { useGetCurrentUser } from "~riata/graphql";
import { NewUserAvatar } from "../avatars";
import { StyleSheet, View, ViewStyle } from "react-native";
import { useComposeStyles } from "~riata/hooks";

type DirectChannelAvatarProps = {
  channel: Channel;
  multiUser?: boolean;
  showOffDuty?: boolean;
  containerStyles?: ViewStyle;
  size?: number;
  outlineColor?: string;
};

type DirectChannelAvatarComponent = (
  props: DirectChannelAvatarProps
) => JSX.Element;

export const DEFAULT_ICON_SIZE = 30;

const DirectChannelAvatar: DirectChannelAvatarComponent = ({
  channel,
  multiUser = false,
  showOffDuty = false,
  size = DEFAULT_ICON_SIZE,
  containerStyles = {},
  outlineColor,
}) => {
  const { user } = useGetCurrentUser();

  const channelMembers = useMemo(() => {
    return channel.members.edges.map((a) => a.node);
  }, [channel]);

  const composedContainerStyles = useComposeStyles(
    styles.container,
    containerStyles
  );

  if (multiUser) {
    return (
      <View style={composedContainerStyles}>
        <View>
          <NewUserAvatar size={size} user={channelMembers[0]} />
          <NewUserAvatar
            containerStyle={{
              position: "absolute",
              top: size / 2,
              right: size / 2 - 2,
            }}
            size={size}
            outlineColor={outlineColor || "white"}
            outlined
            user={channelMembers[1]}
          />
        </View>
      </View>
    );
  }

  if (user) {
    const peer = channelMembers.find((member) => member.id !== user.id);
    return (
      <View style={composedContainerStyles}>
        <NewUserAvatar size={size} user={peer} />
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  container: {},
});

export default DirectChannelAvatar;
