import React from "react";
import { StyleSheet, View } from "react-native";

import { AvatarOffDutyIndicator, UserAvatar } from "./avatars";
import { CustomIcon } from "./icons";
import { Button } from "./inputs";
import { Row } from "./layout";
import { Text } from "./typography";
import { useColors, useComposeStyles } from "~riata/hooks";

const UserSelectContainer = ({ user, selected, onSelectUser }) => {
  const { newColors } = useColors();

  const userStatusContainerStyles = useComposeStyles(styles.statusContainer, {
    borderColor: newColors.surface.main,
  });

  return (
    <Button onPress={() => onSelectUser(user)}>
      <Row style={styles.container}>
        <View>
          <UserAvatar size={40} user={user} />
          <View style={userStatusContainerStyles}>
            <AvatarOffDutyIndicator
              user={user}
              variant="small"
              borderColor={newColors.surface.main}
            />
          </View>
        </View>
        <Text
          style={styles.text}
          variant="newSubhead"
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {user.displayName}
        </Text>
        {selected ? (
          <CustomIcon
            size={20}
            color={newColors.icon.primary.main}
            name="checkmark_full"
          />
        ) : (
          <CustomIcon
            size={20}
            color={newColors.icon.primary.disabled}
            name="checkmark_empty"
          />
        )}
      </Row>
    </Button>
  );
};

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    marginRight: 28,
    height: 60,
  },
  text: {
    flex: 1,
    marginLeft: 16,
  },
  statusContainer: {
    position: "absolute",
    top: 26,
    left: 26,
  },
});

export default UserSelectContainer;
