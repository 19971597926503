import React from "react";
import { StyleSheet, View } from "react-native";

import { UserAvatar } from "./avatars";
import { Text } from "./typography";
import { useColors } from "~riata/hooks";

const UserInfoContainer = ({ user, footer }) => {
  const { newColors } = useColors();
  return (
    <>
      <UserAvatar user={user} />
      <View style={styles.nameContainer}>
        <Text variant="footnote" color={newColors.text.regular.main}>
          {user.displayName}
        </Text>
        {footer?.length > 0 && (
          <Text variant="footnote" color={newColors.text.footnote.main}>
            {footer}
          </Text>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  nameContainer: {
    justifyContent: "center",
    marginLeft: 5,
  },
});

export default UserInfoContainer;
