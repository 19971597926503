import React from "react";
import { View, StyleSheet } from "react-native";

import { useColors } from "~riata/hooks";
import { Row, SafeAreaView } from "~riata/components/layout/";
import UpperLoginImage from "~riata/resources/login-dots.svg";
import LowerLoginImage from "~riata/resources/login-stripes.svg";
import FieldConnectorLogo from "~riata/resources/field-connector.svg";
import RepFirstLogo from "~riata/resources/repFirst-logo.svg";
import LoginButton from "./LoginButtonWrapper";
import DeviceInfo from "react-native-device-info";

const LoginScreen = ({ testID = null }) => {
  const { newColors } = useColors();

  const variant = DeviceInfo.getBundleId();
  const isRepfirst = variant.includes("repfirst");

  return (
    <SafeAreaView bottom={false}>
      <View testID={testID} style={styles.container}>
        <Row>
          <UpperLoginImage fill={newColors.graphics.orangeSoda.main} />
        </Row>
        <Row justify="center">
          <View>
            {isRepfirst ? <RepFirstLogo /> : <FieldConnectorLogo />}
            <View style={styles.loginButtonWrapper}>
              <LoginButton />
            </View>
          </View>
        </Row>
        <Row justify="right">
          <LowerLoginImage fill={newColors.graphics.turquoise.main} />
        </Row>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  loginButtonWrapper: {
    marginVertical: 16,
  },
});

export default LoginScreen;
