import { useEffect, useState } from "react";
import { useUpdateChannelDetails } from "~riata/graphql";

const useGroupName = ({ channel }) => {
  const [inputText, setInputText] = useState("");
  const [inputIsVisible, setInputIsVisible] = useState(false);
  const { execute: updateChannelDetails } = useUpdateChannelDetails();

  useEffect(() => {
    if (channel) {
      setInputText(channel.name);
    }
  }, [channel]);

  const toggleInputIsVisible = () => {
    setInputIsVisible(!inputIsVisible);
  };

  const handleInputTextChange = (text) => {
    setInputText(text);
  };

  const submitGroupName = () => {
    if (inputText.trim().length > 0) {
      updateChannelDetails(channel.id, inputText);
    }
    setInputIsVisible(false);
  };

  return {
    toggleInputIsVisible,
    submitGroupName,
    handleInputTextChange,
    inputText,
    inputIsVisible,
  };
};

export { useGroupName };
