import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { fragments } from "../fragments";

const MUTATION = gql`
  mutation AddReaction($input: AddReactionInput!) {
    addReaction(input: $input) {
      message {
        ...MessageContent
      }
    }
  }
  ${fragments.Message.Content}
`;

export const useAddReaction = ({ message }) => {
  const [mutation, response] = useMutation(MUTATION, {
    update(cache, { data }) {
      // TODO Should we verify the response before processing?
      cache.writeFragment({
        id: `Message:${data.addReaction.message.id}`,
        fragment: fragments.Message.Content,
        fragmentName: "MessageContent",
        data: data.addReaction.message,
      });
    },
  });

  const execute = (reaction) => {
    const variables = {
      input: {
        id: message.id,
        reaction: reaction.id,
      },
    };
    return mutation({ variables });
  };

  return {
    mutation,
    execute,
    response,
    successful: (response?.data?.addReaction?.message && true) || false,
  };
};
