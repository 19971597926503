import React, { useCallback, useMemo } from "react";
import SafeAreaView from "react-native-safe-area-view";
import { CommonActions } from "@react-navigation/routers";
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View,
} from "react-native";

// Components
import ChannelUsersList from "./ChannelUsersList";
import {
  AppHeader,
  Button,
  ChannelInfoActions,
  ChannelInfoHeader,
  HeaderActionButton,
  KeyboardDismissView,
  Row,
  SearchBar,
  Separator,
  Text,
} from "~riata/components";

// Hooks
import { useColors, useComposeStyles, useToastMessage } from "~riata/hooks";
import { useGroupName } from "./useGroupName";

// Utils
import { Sentry } from "~riata/utils/sentry";

// Types
import {
  useGetCurrentUser,
  useLeaveGroupChannel,
  useMuteChannel,
  useUnmuteChannel,
} from "~riata/graphql";
import { getOnlineUsersCount } from "~riata/utils/offDuty";

const GroupChannelInfo = ({ navigation, channel, setUserFilter }) => {
  const { newColors } = useColors();

  const { user } = useGetCurrentUser();
  const { execute: muteChannel } = useMuteChannel();
  const { execute: unmuteChannel } = useUnmuteChannel();
  const { execute: leaveChannel } = useLeaveGroupChannel();
  const { showInfoToast } = useToastMessage();
  const {
    inputIsVisible,
    submitGroupName,
    toggleInputIsVisible,
    handleInputTextChange,
    inputText,
  } = useGroupName({ channel });

  const containerStyles = useComposeStyles(styles.container, {
    backgroundColor: newColors.background.main,
  });

  const inputContainerStyles = useComposeStyles(styles.inputContainer, {
    backgroundColor: newColors.background.main,
  });

  const userListWrapperStyles = useComposeStyles(styles.userListWrapper, {
    backgroundColor: newColors.surface.main,
  });

  const mappedUsersList = useMemo(() => {
    return channel?.members.edges.map((item) => ({
      id: item.node.id,
      fullName: item.node.displayName,
      jobTitle: item.node.jobTitle,
      canMessage: item.node.canMessage,
      offDuty: item.node.offDuty,
      company: item.node.company,
      avatar: item.node.avatar,
    }));
  }, [channel]);

  const showOnlineUsers = user.company.offDuty;
  const onlineUsers = useMemo(() => {
    return getOnlineUsersCount(mappedUsersList);
  }, [mappedUsersList]);

  // Discriminate who can update channel's name
  const cantEditGroupName = true;

  const onSearch = (text) => setUserFilter(text);

  const goBack = useCallback(() => navigation.goBack(), [navigation]);

  const handleLeaveChannel = useCallback(() => {
    leaveChannel(channel.id)
      .then(() => {
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [{ name: "Home" }, { name: "Messages" }],
          })
        );
      })
      .catch((error) => {
        console.log("Error leaving channel");
        Sentry.captureException(error);
      });
  }, [channel, leaveChannel, navigation]);

  const toggleMuted = useCallback(() => {
    channel.isMutedByUser
      ? unmuteChannel(channel.id).then(() =>
          showInfoToast("Notifications are enabled")
        )
      : muteChannel(channel.id).then(() =>
          showInfoToast("Notifications are muted")
        );
    // showInfoToast shouldn't trigger a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, muteChannel, unmuteChannel]);

  const actions = useMemo(() => {
    const actions = [
      {
        label: "Add",
        iconName: "add_member",
        onPressHandler: () => {
          console.log("Add pressed");
          navigation.navigate("AddUsersModal", { channel: channel.id });
        },
      },
      {
        label: channel?.isMutedByUser ? "Unmute" : "Mute",
        iconName: channel?.isMutedByUser ? "muted" : "unmuted",
        onPressHandler: toggleMuted,
      },
      {
        label: "Leave",
        iconName: "exit",
        color: newColors.variants.danger.main,
        onPressHandler: () => {
          const message = "Are you sure you would like to leave channel?";

          if (Platform.OS !== "web") {
            Alert.alert("Leave channel", message, [
              {
                text: "Cancel",
                style: "cancel",
              },
              {
                text: "Leave",
                onPress: handleLeaveChannel,
                style: "destructive",
              },
            ]);
          } else {
            if (window.confirm(message)) {
              handleLeaveChannel();
            }
          }
        },
      },
    ];

    return actions;
    // Don't trigger a re-render if color changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, toggleMuted, navigation, handleLeaveChannel]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={containerStyles}
      keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 80}
    >
      <KeyboardDismissView>
        <SafeAreaView style={containerStyles} forceInset={{ bottom: "never" }}>
          <AppHeader
            backgroundColor={newColors.background.main}
            left={<HeaderActionButton variant="back" onPress={goBack} />}
          />
          {channel && (
            <ChannelInfoHeader
              channel={channel}
              onBackPress={goBack}
              title={channel?.name}
              subtitle={`${channel?.members?.total} members${
                showOnlineUsers ? `, ${onlineUsers} online` : ""
              }`}
              avatarContainerStyles={{ justifyContent: "flex-start" }}
              submitGroupName={submitGroupName}
              onChangeText={handleInputTextChange}
              inputText={inputText}
              inputIsVisible={inputIsVisible}
              cantEditGroupName={cantEditGroupName}
            />
          )}
          {cantEditGroupName && !inputIsVisible && (
            <Row justify="center" style={{ marginTop: 12 }}>
              <Button onPress={toggleInputIsVisible}>
                <Text variant="body" fontWeight="bold">
                  Change Name
                </Text>
              </Button>
            </Row>
          )}
          <ChannelInfoActions
            actions={actions}
            containerStyles={styles.actionsContainer}
          />
          <Separator color={newColors.separator.main} />
          <View style={styles.userListTitleWrapper}>
            <Text variant="title4">Group Channel Members</Text>
          </View>
          <View style={inputContainerStyles}>
            <SearchBar
              showSearchIcon
              placeholder={"Search for a user..."}
              onSearch={(text) => onSearch(text)}
              inputStyles={styles.inputStyles}
              containerStyles={styles.inputStyles}
            />
          </View>
          <View style={userListWrapperStyles}>
            <ChannelUsersList data={mappedUsersList} channelId={channel?.id} />
          </View>
        </SafeAreaView>
      </KeyboardDismissView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  actionsContainer: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
  inputContainer: {
    marginTop: 20,
    paddingHorizontal: 16,
    backgroundColor: "transparent",
  },
  inputStyles: {
    borderRadius: 8,
  },
  userListTitleWrapper: {
    paddingHorizontal: 16,
    marginTop: 20,
  },
  userListWrapper: {
    flex: 1,
    backgroundColor: "transparent",
    marginTop: 12,
  },
});

export default GroupChannelInfo;
