import React, { useMemo, useRef, useState } from "react";
import { useLinkTo } from "@react-navigation/native";
import { Platform, StyleSheet, View } from "react-native";
import {
  AppHeader,
  Body,
  Row,
  SafeAreaView,
  ScrollableListFilter,
  HeaderActionButton,
  HeaderSearchBar,
  Separator,
} from "~riata/components";
import {
  ChannelsFilter,
  ChannelStatus,
  ChannelType,
  GetMessagesFilter,
} from "~riata/generated/graphql";
import { isWeb } from "~riata/theme/common";
import { ChannelListSubScreen } from "./ChannelListSubScreen";
import { SearchResultSubScreen } from "./SearchResultSubScreen";
import { useDrawerNavigation } from "~riata/hooks/useDrawerNavigation";
import { useColors, useUpdateSelectedRouteIfFocused } from "~riata/hooks";

type ListFilterOptions = {
  id: number;
  text: string;
  filter?: ChannelsFilter;
  messageFilter?: GetMessagesFilter;
  func: (text?: string) => void;
  selected: boolean;
  custom?: boolean;
  group?: string;
  deselectable?: boolean;
};

export const ChatChannelsScreen = ({ navigation }) => {
  const { newColors } = useColors();
  const [searchExpanded, setSearchExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filters, setCurrentFilter] = useState<Array<ListFilterOptions>>([
    {
      id: 2,
      text: "All",
      group: "ChannelType",
      func: function () {
        setNewlySelected(this);
      },
      filter: {},
      selected: true,
      deselectable: false,
    },

    {
      id: 3,
      text: "Team",
      group: "ChannelType",
      func: function () {
        setNewlySelected(this);
      },
      filter: { type: ChannelType.Team },
      selected: false,
      deselectable: false,
    },
    {
      id: 4,
      text: "Direct",
      group: "ChannelType",
      func: function () {
        setNewlySelected(this);
      },
      filter: { type: ChannelType.Direct },
      selected: false,
      deselectable: false,
    },
    {
      id: 5,
      text: "Unread",
      group: "ChannelStatus",
      func: function () {
        setNewlySelected(this);
      },
      filter: { status: ChannelStatus.Unread },
      selected: false,
      deselectable: true,
    },
    {
      id: 6,
      text: "Read",
      group: "ChannelStatus",
      func: function () {
        setNewlySelected(this);
      },
      filter: { status: ChannelStatus.Read },
      selected: false,
      deselectable: true,
    },
  ]);
  const { updateSelectedRoute } = useDrawerNavigation();

  const setNewlySelected = (selected) => {
    setCurrentFilter(
      filters.map((a) => {
        if (a.group === selected.group) {
          a.selected =
            a.id === selected.id && selected.deselectable
              ? !selected.selected
              : a.id === selected.id;
          if (a.id === selected.id) {
            a.messageFilter = selected.messageFilter;
          } else {
            console.log(a, selected);
          }
        }
        return a;
      })
    );
  };

  const filteredFilters = filters.filter((a) => a.selected);
  const currentFilter = filteredFilters.reduce((acc, a) => {
    return { ...acc, ...a.filter };
  }, {});

  const linkTo = useLinkTo();
  const refreshRef = useRef(null);
  const onRefresh = React.useCallback(() => {
    refreshRef.current.onRefresh();
  }, [refreshRef]);

  const right = useMemo(() => {
    const toggleIsExpanded = () => {
      setSearchExpanded(!searchExpanded);
    };

    const addButton = (
      <HeaderActionButton
        variant="add"
        onPress={() => linkTo("/messages/new")}
      />
    );
    return (
      <Row align="center" style={styles.searchBarContainer}>
        <HeaderSearchBar
          isExpanded={searchExpanded}
          onToggleExpanded={toggleIsExpanded}
          onTextChange={setSearchTerm}
        />
        {isWeb && !searchExpanded && (
          <View style={styles.spaceLeft}>
            <HeaderActionButton variant="refresh" onPress={onRefresh} />
          </View>
        )}
        {addButton && !searchExpanded && (
          <View style={styles.spaceLeft}>{addButton}</View>
        )}
      </Row>
    );
  }, [linkTo, onRefresh, searchExpanded]);

  useUpdateSelectedRouteIfFocused(updateSelectedRoute);

  return (
    <SafeAreaView backgroundColor={newColors.background.main}>
      <AppHeader
        left={
          !searchExpanded && (
            <HeaderActionButton
              variant="drawer"
              onPress={() => navigation.toggleDrawer()}
            />
          )
        }
        title={searchExpanded ? "" : "Messages"}
        right={right}
        backgroundColor={newColors.background.main}
      />
      <Body>
        <ScrollableListFilter
          searching={searchTerm.length > 0}
          filters={filters}
          setCurrentFilter={setCurrentFilter}
          onSaveFilter={(text) => {
            // FIXME This causes a double save icon
            // TODO: This only saves during the current session with useState.
            setCurrentFilter([
              ...filters,
              {
                id: text,
                text: text,
                func: function () {
                  // TODO Do something here
                },
                filter: {},
                selected: false,
                custom: true,
              },
            ]);
          }}
        />
        <Separator color={newColors.separator.light} />
        <View style={styles.listWrapper}>
          {searchTerm ? (
            <SearchResultSubScreen search={searchTerm} />
          ) : (
            <ChannelListSubScreen
              ref={refreshRef}
              filter={currentFilter}
              navigation={navigation}
            />
          )}
        </View>
      </Body>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  spaceLeft: {
    marginLeft: 21,
  },
  listWrapper: {
    paddingTop: 16,
  },
  searchBarContainer: {
    marginTop: Platform.OS === "android" ? 6 : 0,
  },
});

export default ChatChannelsScreen;
